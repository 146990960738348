import type { GraphQLError } from 'graphql';

import type { AdInputV5, CampaignTagV5, CreateBatchAdsMutation } from '../../../../../../../apis/graphql';
import { AdReviewV5, AdStatusV5, AdTypeV5, removeTypeNamesFromObject } from '../../../../../../../apis/graphql';
import { AdFormSection } from '../../../../../../../common/AdForm/AdSideNav/AdSideNav';
import {
  convertAdTagToAdTagInput,
  getAdTagInput,
  getFrequencyCapInput,
  getSchedule,
  getTargetingTermValuesInput,
  getViewabilityVendorEnumsFromFormValue,
} from '../../../../../../../common/AdForm/hooks/modelConverters/adsPageAdModelToNode';
import type { NielsenTagProps } from '../../../../../../../common/AdForm/utils';
import { getNielsenTag, isNielsenTag } from '../../../../../../../common/AdForm/utils';
import { AlertType } from '../../../../../../../common/Alert';
import type { ToastAlertProps } from '../../../../../../../common/ToastAlert/toastAlert';
import { openToastAlert } from '../../../../../../../common/ToastAlert/toastAlert';
import type { AdTag, Nullable } from '../../../../../../../models';
import type { DuplicateLineItem } from '../../../DuplicateAdsStepperProvider/types';
import type { DuplicateAdFormValue } from '../types';
import type { BatchCreateAdFieldError } from './types';

const getCreativeData = (duplicateAdFromValues: DuplicateAdFormValue): Pick<AdInputV5, 'creativeId'> => {
  if (!duplicateAdFromValues.selectedSections.includes(AdFormSection.CREATIVE) || !duplicateAdFromValues.creative)
    return {};

  return { creativeId: duplicateAdFromValues.creative.id || null };
};

const generateNewNielsenTagIfEligible = (lineItem: DuplicateLineItem, isPausedAd: boolean = false): Nullable<AdTag> => {
  const campaignTags: Nullable<CampaignTagV5[]> =
    lineItem.campaign?.campaignTagList?.map((tag) => ({
      ...tag,
      id: '',
      isVerified: true,
    })) ?? null;

  const nielsenTagProps: NielsenTagProps = {
    campaignTagList: campaignTags,
    lineItem_billableThirdParty: lineItem.billableThirdParty,
    lineItem_isCoppaOrCaru: lineItem.isCoppaOrCaru,
    lineItem_country: lineItem.country ?? '',
    isPausedAd,
  };

  return getNielsenTag(nielsenTagProps);
};

const getTagsData = (
  duplicateAdFromValues: DuplicateAdFormValue,
  lineItem: DuplicateLineItem
): Pick<AdInputV5, 'adTagList'> => {
  const newNielsenTag = generateNewNielsenTagIfEligible(lineItem, duplicateAdFromValues.type === AdTypeV5.Pause);

  // If tags unselected to copy, return new Nielsen Tag if eligible or else no tags
  if (!duplicateAdFromValues.selectedSections.includes(AdFormSection.TAGS)) {
    return newNielsenTag ? { adTagList: [convertAdTagToAdTagInput(newNielsenTag)] } : {};
  }

  // tags should be unverified and nielsen tags should be sorted out for duplicated ads due to business requirements.
  let adTagList = getAdTagInput(duplicateAdFromValues.adTagList)
    .filter((tag) => !isNielsenTag(tag.url))
    .map((tag) => ({ ...tag, isVerified: false }));

  // Set New Nielsen Tag as first Ad Tag
  if (newNielsenTag) {
    adTagList.unshift(convertAdTagToAdTagInput(newNielsenTag));
  }

  return { adTagList };
};

const getTargetingData = (
  duplicateAdFromValues: DuplicateAdFormValue
): Pick<AdInputV5, 'targetingTermValues' | 'targetingRule'> => {
  if (!duplicateAdFromValues.selectedSections.includes(AdFormSection.TARGETING)) return {};

  return {
    targetingTermValues: getTargetingTermValuesInput(duplicateAdFromValues.targetingTermValues),
    targetingRule: removeTypeNamesFromObject(duplicateAdFromValues.targetingRule),
  };
};

const getScheduleData = (duplicateAdFromValues: DuplicateAdFormValue): Pick<AdInputV5, 'schedule'> => {
  if (!duplicateAdFromValues.selectedSections.includes(AdFormSection.SCHEDULING)) return {};

  return {
    schedule: getSchedule(duplicateAdFromValues.schedule),
  };
};

const getMetadata = (
  duplicateAdFromValues: DuplicateAdFormValue
): Pick<AdInputV5, 'frequencyCapList' | 'viewabilityVendorList'> => {
  if (!duplicateAdFromValues.selectedSections.includes(AdFormSection.METADATA)) return {};

  return {
    frequencyCapList: getFrequencyCapInput(duplicateAdFromValues.frequencyCapList ?? []),
    viewabilityVendorList: getViewabilityVendorEnumsFromFormValue(duplicateAdFromValues.viewabilityVendorList),
  };
};

const getAdInputFromSelectedSections = (
  duplicateAdFromValues: DuplicateAdFormValue,
  lineItem: DuplicateLineItem
): AdInputV5 => {
  return {
    status: AdStatusV5.Off,
    review: AdReviewV5.Draft,
    type: duplicateAdFromValues.type,
    rating: duplicateAdFromValues.rating,
    name: duplicateAdFromValues.name,
    lineItemId: lineItem.id,
    ...getCreativeData(duplicateAdFromValues),
    ...getTagsData(duplicateAdFromValues, lineItem),
    ...getTargetingData(duplicateAdFromValues),
    ...getScheduleData(duplicateAdFromValues),
    ...getMetadata(duplicateAdFromValues),
  };
};

export const getCreateBatchAdsInputs = (
  duplicateLineItems: DuplicateLineItem[],
  duplicateAdsFromValues: DuplicateAdFormValue[]
): AdInputV5[] => {
  if (!duplicateLineItems.length) return [];

  return duplicateLineItems
    .map((lineItem) => {
      return Object.entries(lineItem.adNames).reduce((acc, [adId, value]) => {
        const adToDuplicate = duplicateAdsFromValues.find(({ id }) => id === adId);

        if (adToDuplicate) {
          acc.push(getAdInputFromSelectedSections({ ...adToDuplicate, name: value.name }, lineItem));
        }

        return acc;
      }, new Array<AdInputV5>());
    })
    .flat();
};

export const parseCreateBatchAdsError = (
  adsInputs: AdInputV5[],
  errors: readonly GraphQLError[]
): ToastAlertProps[] => {
  return errors.reduce((acc, error) => {
    if (error.extensions?.response.status === 400) {
      error.extensions?.response.body.forEach((field: BatchCreateAdFieldError) => {
        const adIndex = field['request-object-element'];
        const lineItemId = adsInputs[adIndex].lineItemId;
        const adName = adsInputs[adIndex].name;
        const message =
          field.error.details && field.error.details.length && field.error.details[0].issue
            ? field.error.details[0].issue
            : field.error.message;

        acc.push({
          alertType: AlertType.ERROR,
          message,
          description: `Ad ${adName} can't be copied to Line Item with ID: ${lineItemId}`,
          duration: 30,
        });
      });
    } else {
      acc.push({
        alertType: AlertType.ERROR,
        message: error.message,
      });
    }

    return acc;
  }, new Array<ToastAlertProps>());
};

export const onSavingAdsError = (adsInputs: AdInputV5[], errors: readonly GraphQLError[]): void => {
  const formattedErrors = parseCreateBatchAdsError(adsInputs, errors);

  formattedErrors.forEach((error) => {
    openToastAlert(error);
  });
};

export const onSavingAdsSuccess = (data: CreateBatchAdsMutation): void => {
  const adsCreated = data.createAdsBatchV5.length;

  openToastAlert({
    alertType: AlertType.SUCCESS,
    message: `Ads (${adsCreated}) successfully created. Please refresh page to see ads`,
  });
};
