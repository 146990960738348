import { bem } from 'mission-control-common-components';
import React from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { CreativeReviewStatus } from '../../../../../configs';
import { creativeReviewStatuses } from '../../../../../configs';
import Alert from '../../../../Alert';
import { CreativeFooterStyling } from '../../components';
import type { CreativesBuilderValues } from '../../types';
import { reviewAlertPropsMap } from '../../utils';
import RejectionMessage from '../RejectionMessage';

type Props = {
  assets: AssetExtended[];
  creative: CreativesBuilderValues;
  rightSideComponent?: React.JSX.Element | React.JSX.Element[];
};

const creativeFooterElement = bem('creative-footer')[1];

const InstreamDxcCreativeFooter = ({ rightSideComponent, assets, creative }: Props): React.JSX.Element => {
  const review = creative.review ? reviewAlertPropsMap?.[creative.review as CreativeReviewStatus] : null;
  const reviewMessage =
    creative.review === creativeReviewStatuses.REJECTED.key ? (
      <RejectionMessage bodyText={review?.bodyText} assets={assets} creative={creative} />
    ) : (
      review?.bodyText
    );

  return (
    <CreativeFooterStyling>
      <hr />
      <div className={creativeFooterElement('split-info-wrapper')}>
        <div className={creativeFooterElement('content', 'information')}>
          {review ? <Alert {...review} bodyText={reviewMessage} /> : null}
        </div>
        <div className={creativeFooterElement('content', 'interaction')}>{rightSideComponent}</div>
      </div>
    </CreativeFooterStyling>
  );
};

export default InstreamDxcCreativeFooter;
