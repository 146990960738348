import type { LineItemV5 } from '../../../../../../../../../apis/graphql';
import { convertAdProduct, convertBillableThirdParty } from '../../../../../../../../../configs';
import type { DuplicateLineItem } from '../../../../../DuplicateAdsStepperProvider/types';

export const convertLineItemToDuplicateLineItem = (lineItem: LineItemV5): DuplicateLineItem => {
  return {
    id: lineItem.id,
    name: lineItem.name,
    schedule: lineItem.schedule,
    startDate: lineItem.startDate,
    endDate: lineItem.endDate,
    adProduct: convertAdProduct(lineItem.adProduct),
    billableThirdParty: lineItem.billableThirdParty ? convertBillableThirdParty(lineItem.billableThirdParty) : null,
    isCoppaOrCaru: lineItem.isCoppaOrCaru,
    campaign: lineItem.campaign,
    selected: false,
    conflicted: false,
    adNames: {},
    country: lineItem.country,
  };
};
