import './VastPreviewInput.scss';

import React, { memo } from 'react';

import bem from '../../../../../../utils/bem';
import type { TagInputProps } from '../../../../../Form/TagInput';
import { makeLabelChild } from '../../../../../Form/utils';

type VastPreviewInputProps = {
  label: string;
  tagInputProps: TagInputProps;
  isTagInputDisabled: boolean;
};

const [block, element] = bem('vast-preview-input');

/**
 * Custom input component with a textarea for better word-breaking and wrapping support.
 * The textarea is used instead of an input due to the default single-line text behavior of input elements. */

const VastPreviewInput = ({ label, tagInputProps, isTagInputDisabled }: VastPreviewInputProps): React.JSX.Element => {
  return (
    <div className={block()}>
      <span className={element('custom-input-label')}>{makeLabelChild(label, tagInputProps?.secondaryLabel)}</span>
      <textarea value={tagInputProps.url} disabled={isTagInputDisabled || tagInputProps.isSubmitted} />
    </div>
  );
};

export default memo(VastPreviewInput);
