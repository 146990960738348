import './styles/index.scss';
import './configs/datadogInit';
import 'antd/dist/antd.css';
import 'antd/dist/antd.variable.min.css';

import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from '@hulu/react-router-dom';
import { MCTheme } from 'mission-control-common-components';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { campaignClient } from './apis/graphql';
import App from './App';
import { serviceWorkerInit } from './configs/serviceWorkerInit';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import LDProvider from './utils/launchdarklyProvider';

serviceWorkerInit(navigator);

ReactDOM.render(
  <ThemeProvider theme={MCTheme}>
    <ApolloProvider client={campaignClient}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <LDProvider>
            <App />
            <div id="tooltip-container" />
          </LDProvider>
        </I18nextProvider>
      </Router>
    </ApolloProvider>
  </ThemeProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
