import './DuplicatedAdData.scss';

import { bem } from 'mission-control-common-components';
import React, { memo, useCallback } from 'react';

import TextInput from '../../../../../../../../common/TextInput';

const [block, element] = bem('duplicated-ad-data');

type Props = {
  lineItemName: string;
  lineItemId: string;
  adName: string;
  adId: string;
  onSetAdName: (lineItemId: string, adId: string, newName: string) => void;
};

const DuplicatedAdData = ({ lineItemName, lineItemId, adName, adId, onSetAdName }: Props): JSX.Element => {
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSetAdName(lineItemId, adId, e.target.value);
    },
    [onSetAdName, lineItemId, adId]
  );

  return (
    <div className={block()} key={`inputs_container_${lineItemId}_${adId}`}>
      <div className={element('input-wrapper')}>
        <div className={`${element('label-wrapper')} ${element('line-item-label')}`}>
          <h3>Line Item</h3>
          <h3>{lineItemId}</h3>
        </div>
        <TextInput
          name={`LI-${lineItemId}-${adId}`}
          disabled={true}
          value={lineItemName}
          className={element('line-item-input')}
        />
      </div>
      <div className={element('input-wrapper')}>
        <div className={element('label-wrapper')}>
          <h3>Duplicated Ad Name</h3>
        </div>
        <TextInput
          name={`AD-${lineItemId}-${adId}`}
          errMsg={!adName ? 'Ad name can not be empty' : ''}
          value={adName}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default memo(DuplicatedAdData);
