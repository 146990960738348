import { gql } from '@apollo/client';

import { ADS_PAGE_AD } from '../../../../../../../common/AdForm/hooks/fragments';

export const GET_ADS_PAGE_ADS_BY_IDS = gql`
  query getAdsPageAdsByIDs($adIds: [ID!]!, $lineItemIds: [String!]!, $adsLimit: Int!) {
    ads: adsV5(filterOptions: { idList: $adIds }, paginationOptions: { limit: $adsLimit }) {
      edges {
        node {
          ...adsPageAd
        }
      }
    }
    getTargetingTermValuesByLineItemIDsV5(lineItemIds: $lineItemIds) {
      lineItemId
      targetingTermValues {
        category {
          name
          displayName
        }
        dimension {
          name
          displayName
        }
        value {
          name
          displayName
        }
        include
      }
    }
  }
  ${ADS_PAGE_AD}
`;
