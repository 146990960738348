import { SearchInput } from '@hulu/react-style-components';
import React, { useEffect, useMemo } from 'react';

import { filterTargetingTermValuesAsSet } from '../../../../common/AdForm/Targeting/util/filterTargetingTerms';
import { useFieldArray } from '../../../../common/Form/hooks';
import { DEFAULT_DEBOUNCE_TIMEOUT } from '../../../../common/hooks/constants';
import useDebounceFilter from '../../../../common/hooks/useDebounceFilter';
import TargetingDropdowns from '../../../../common/TargetingDropdowns';
import TargetingTable from '../../../../common/TargetingTable';
import type { TargetingTableRowGroup } from '../../../../common/TargetingTable/TargetingTable';
import type { TargetingTermValue, TargetingValue } from '../../../../models';
import { EntityType } from '../../TraffickingPage/hooks/makePageDrawerPlugin';
import { generateTargetingTableData } from '../utils/utils';

interface Props {
  editMode: boolean;
  entityType?: string;
  countryLineItem?: TargetingTermValue;
  publisherLineItem?: TargetingValue[];
}

function DrawerTargetingBody({ editMode, entityType, countryLineItem, publisherLineItem }: Props): JSX.Element {
  const isAd = entityType === EntityType.AD;
  const { filterValue, debouncedFilterValue, onChange, onClear } = useDebounceFilter(DEFAULT_DEBOUNCE_TIMEOUT);

  const [
    ,
    { value: adTargetingTermValues, error: adTargetingError },
    adListHelpers,
  ] = useFieldArray<TargetingTermValue>('adTargetingTermValues');
  const [
    ,
    { value: lineItemTermValues, error: lineItemTargetingError },
    lineItemListHelpers,
  ] = useFieldArray<TargetingTermValue>('lineItemTargetingTermValues');

  const tableData: TargetingTableRowGroup[] = useMemo(
    (): TargetingTableRowGroup[] => generateTargetingTableData(adTargetingTermValues, lineItemTermValues),
    [adTargetingTermValues, lineItemTermValues]
  );

  // ids in this set should be displayed in the table
  const filteredTargetingValues: Set<string> = useMemo(
    () =>
      filterTargetingTermValuesAsSet({
        searchTerm: debouncedFilterValue,
        targetingTermValues: [...adTargetingTermValues, ...lineItemTermValues],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedFilterValue, adTargetingTermValues?.length, lineItemTermValues?.length]
  );

  const listHelpers = useMemo(() => {
    if (isAd) {
      return adListHelpers;
    }
    return lineItemListHelpers;
  }, [isAd, adListHelpers, lineItemListHelpers]);

  // clear filter value when targeting value length changes
  useEffect(() => {
    if (filterValue?.length) {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adTargetingTermValues?.length, lineItemTermValues?.length]);

  return (
    <div>
      {editMode && (
        <TargetingDropdowns
          targetings={isAd ? adTargetingTermValues : lineItemTermValues}
          listHelpers={listHelpers}
          validationError={isAd ? adTargetingError : lineItemTargetingError}
          countryOption={countryLineItem}
          publisherOption={publisherLineItem}
        />
      )}
      {tableData.length ? (
        <>
          <SearchInput
            className="targeting-filter"
            placeholder="Search Targeted Values"
            onInput={onChange}
            onClear={onClear}
            value={filterValue}
          />
          <TargetingTable
            editMode={editMode}
            data={tableData}
            listHelpers={listHelpers}
            isAd={isAd}
            isReadOnly
            isTableToggleable
            isTableDataFiltered={!!debouncedFilterValue?.length}
            filteredTargetingValues={filteredTargetingValues}
          />
        </>
      ) : (
        <div>No current targeting data for this {entityType}.</div>
      )}
    </div>
  );
}

export default DrawerTargetingBody;
