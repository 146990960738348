import React from 'react';

import { Warning } from '../../../components/Warning/Warning';
import type { PriorityWarningProps } from '../../checkConflictsAndWarnings/getAllConflictsAndWarnings/useGetAllConflictsAndWarnings';

type Props = {
  warningsData: PriorityWarningProps[];
};

export const getPriorityWarningComponents = ({ warningsData }: Props): React.JSX.Element[] => {
  const uniqueWarningsKeys: Set<string> = new Set();

  const warningsMessages = warningsData.reduce((messagesToShow, warning) => {
    const warningKey = `${warning.newPacing.displayName}_${warning.newTier.displayName}`;

    if (!uniqueWarningsKeys.has(warningKey)) {
      uniqueWarningsKeys.add(warningKey);
      messagesToShow.push(
        `Changing priority to ${warning.newTier.displayName} tier will change the pacing type to ${warning.newPacing.displayName}`
      );
    }

    return messagesToShow;
  }, new Array<string>());

  return warningsMessages.length ? [<Warning conflictMessages={warningsMessages} />] : [];
};
