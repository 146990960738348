import type { TargetingTermValue } from '../../../../models';
import { getTargetingKey } from './getTargetingKey';

const isTargetingTermAMatch = ({
  targetingTermValue: { category, dimension, value },
  searchTerm,
}: {
  targetingTermValue: TargetingTermValue;
  searchTerm: string;
}): boolean => {
  const lowerCasedSearchTerm = searchTerm.toLowerCase();

  return (
    category.name.toLowerCase().includes(lowerCasedSearchTerm) ||
    dimension.name.toLowerCase().includes(lowerCasedSearchTerm) ||
    value.name.toLowerCase().includes(lowerCasedSearchTerm)
  );
};

export const filterTargetingTermValues = ({
  searchTerm,
  targetingTermValues,
}: {
  targetingTermValues: TargetingTermValue[] | undefined;
  searchTerm: string;
}): TargetingTermValue[] => {
  return targetingTermValues
    ? targetingTermValues.filter((targetingTermValue) => isTargetingTermAMatch({ targetingTermValue, searchTerm }))
    : [];
};

export const filterTargetingTermValuesAsSet = ({
  searchTerm,
  targetingTermValues,
}: {
  targetingTermValues: TargetingTermValue[] | undefined;
  searchTerm: string;
}): Set<string> => {
  const targetingSet = new Set<string>();

  if (targetingTermValues) {
    targetingTermValues.forEach((targetingTermValue) => {
      if (isTargetingTermAMatch({ targetingTermValue, searchTerm })) {
        targetingSet.add(getTargetingKey(targetingTermValue));
      }
    });
  }

  return targetingSet;
};
