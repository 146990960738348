import { SearchInput } from '@hulu/react-style-components';
import React, { useCallback, useState } from 'react';

import { pickerBlock, pickerElement } from './constants';
import DuplicateLineItemsSavedTable from './DuplicateLineItemsSavedTable/DuplicateLineItemsSavedTable';
import DuplicateLineItemsSearchTable from './DuplicateLineItemsSearchTable/DuplicateLineItemsSearchTable';

const SelectLineItemsSection = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('');

  const isSearchActive = searchValue.length === 0;

  const handleSearchInput = useCallback((newSearchValue: string): void => {
    setSearchValue(newSearchValue);
  }, []);

  const handleClearSearch = useCallback((): void => {
    setSearchValue('');
  }, []);

  return (
    <>
      <div className={pickerBlock()} data-testid="duplicate-line-items-picker">
        <div className={pickerElement('search-container')}>
          <SearchInput
            placeholder="Search"
            className={pickerElement('search')}
            value={searchValue}
            onInput={handleSearchInput}
            onClear={handleClearSearch}
            data-testid="duplicate-line-items-search"
          />
        </div>
        {isSearchActive ? (
          <DuplicateLineItemsSavedTable />
        ) : (
          <DuplicateLineItemsSearchTable searchValue={searchValue} handleClearSearch={handleClearSearch} />
        )}
      </div>
    </>
  );
};

export default SelectLineItemsSection;
