import './Warning.scss';

import { IconAlertWarningYellow } from '@hulu-react-style-components/icons';
import React from 'react';

import bem from '../../../../../../../../../utils/bem';

const [block, element] = bem('multiple-edit-confirm-warning');

type Props = {
  conflictMessages: string[];
};

export const Warning = ({ conflictMessages }: Props): React.JSX.Element => {
  return (
    <div className={block()}>
      <div data-testid="error-icon" className={element('warning-icon')}>
        <IconAlertWarningYellow />
      </div>

      <div data-testid="error-text" className={element('wrapper-error-text')}>
        <div>
          {conflictMessages.map((conflictMessage, index) => (
            <div key={index}>{conflictMessage}</div>
          ))}
        </div>
      </div>
    </div>
  );
};
