import './SelectLineItemsSection.scss';

import type { Column } from 'react-table';

import bem from '../../../../../../../utils/bem';
import type { DuplicateLineItem } from '../../../DuplicateAdsStepperProvider/types';
import DuplicateLineItemsNameCell from './DuplicateLineItemsNameCell/DuplicateLineItemsNameCell';

export const [pickerBlock, pickerElement] = bem('select-line-items-section');

export const sharedTableColumns: Column<DuplicateLineItem>[] = [
  {
    id: 'line_item_name',
    Header: 'Line item name',
    accessor: 'name',
    Cell: DuplicateLineItemsNameCell,
    width: 350,
  },
  {
    id: 'line_item_id',
    Header: 'Line item id',
    accessor: 'id',
    width: 325,
  },
];

export const LINE_ITEMS_LIMIT = 10;

export const EMPTY_LINE_ITEM_SELECTION_MESSAGE =
  'No Line Items selected. Search for line items you want to copy the ad into.';

export const TYPE_DIVERGENCE_HOVER_MESSAGE = 'Cannot copy ad to line item with different ad type.';
