import './SelectLineItemsStep.scss';

import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';

import { useDuplicateAdsStepperContext } from '../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import type { DuplicateAdTab } from '../../DuplicateAdsTabs/DuplicateAdsTabs';
import DuplicateAdsTabs from '../../DuplicateAdsTabs/DuplicateAdsTabs';
import DuplicatedAdDetails from '../../DuplicatedAdDetails';
import MultipleDuplicateAdsContentWrapper from '../../MultipleDuplicateAdsContentWrapper/MultipleDuplicateAdsContentWrapper';
import { DUPLICATE_ADS_TITLE } from '../constants';
import MultipleDuplicateAdsStepperHeader from '../MultipleDuplicateAdsStepperHeader/MultipleDuplicateAdsStepperHeader';
import { DUPLICATE_LINE_ITEM_HEADER } from './constants';
import SelectLineItemsSection from './SelectLineItemsSection/SelectLineItemsSection';

const [, tabElement] = bem('duplicate-ads-tabs');

const [block, element] = bem('select-line-items-step');

const SelectLineItemsStep = (): React.JSX.Element => {
  const { lineItems, ads } = useDuplicateAdsStepperContext();

  const someLineItemIsChosen = useMemo(() => {
    return lineItems.some((lineItem) => lineItem.selected);
  }, [lineItems]);

  const tabs = useMemo(
    () =>
      ads.map(
        (ad): DuplicateAdTab => {
          return {
            id: ad.id,
            name: ad.name,
            showCheck: false,
            component: (
              <div className={tabElement('content-wrapper')}>
                <DuplicatedAdDetails
                  campaignName={ad.lineItem?.campaign.name}
                  lineItemName={ad.lineItem?.name}
                  adId={ad.id}
                  adType={ad.type.displayName}
                  adName={ad.name}
                />
              </div>
            ),
          };
        }
      ),
    [ads]
  );

  return (
    <div className={block()}>
      <MultipleDuplicateAdsStepperHeader confirmButtonDisabled={!someLineItemIsChosen} title={DUPLICATE_ADS_TITLE} />
      <MultipleDuplicateAdsContentWrapper>
        <DuplicateAdsTabs tabs={tabs} />
        <p className={element('confirm-line-items-text')}>{DUPLICATE_LINE_ITEM_HEADER}</p>
        <SelectLineItemsSection />
      </MultipleDuplicateAdsContentWrapper>
    </div>
  );
};

export default SelectLineItemsStep;
