import type { DuplicateAd } from '../../../DuplicateAdsStepperProvider/types';
import type { DuplicateAdFormValue } from '../types';
import { getSelectedSections } from '../utils';

export const getAdsWithVisitedAd = (
  ads: DuplicateAd[],
  adIndex: number,
  duplicateAdFormValue: DuplicateAdFormValue
): DuplicateAd[] => {
  const newAds = [...ads];
  const updatedAd = { ...newAds[adIndex] };

  // if state is failed, then sections are already set, and we do not need to set initial ones
  const preSelectedSections = duplicateAdFormValue.isFailed ? duplicateAdFormValue.selectedSections : undefined;

  newAds[adIndex] = {
    ...updatedAd,
    isVisited: true,
    isFailed: false,
    selectedSections: getSelectedSections(
      !!duplicateAdFormValue.schedulingConflictLineItemIds.length,
      preSelectedSections
    ),
  };

  return newAds;
};
