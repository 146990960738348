import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from './types';
import { convertToDisplayEnum, makeConfig } from './utils';

export type CampaignStatus = keyof typeof campaignStatusDisplayNames;

const campaignStatusDisplayNames = {
  OFF: enumToTitleCase,
  PAUSED: enumToTitleCase,
  CANCELLED: enumToTitleCase,
  READY: enumToTitleCase,
  LIVE: enumToTitleCase,
  LATE: enumToTitleCase,
  ENDED: enumToTitleCase,
  DID_NOT_RUN: enumToTitleCase,
};

export const campaignStatuses = makeConfig<CampaignStatus>(campaignStatusDisplayNames);

export function convertCampaignStatus(apiValue: string): DisplayEnum<CampaignStatus> {
  return convertToDisplayEnum(apiValue, campaignStatuses);
}
