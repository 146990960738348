import './DuplicateAdAssigningToLineItem.scss';

import { Typography } from '@hulu/react-style-components';
import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { memo } from 'react';

import bem from '../../../../../../utils/bem';
import { DUPLICATE_REVIEW_AD_HEADER } from '../../../constants';
import { FieldName } from '../../enums';
import type { DuplicateAdFormValues } from '../../types';
import DuplicatedAdData from './DuplicatedAdData';

const [block, element] = bem('assign-to-line-item');

const DuplicateAdAssigningToLineItem = (): JSX.Element => {
  const {
    values: { lineItems },
  } = useFormikContext<Pick<DuplicateAdFormValues, 'lineItems'>>();

  return (
    <section className={block()}>
      <Typography className={element('title')} variant="p">
        {DUPLICATE_REVIEW_AD_HEADER}
      </Typography>
      <FieldArray name={FieldName.lineItems}>
        {({ form: { setFieldValue: setAdName, errors } }): React.JSX.Element => {
          return (
            <div className={element('line-items-wrapper')}>
              {lineItems &&
                lineItems.map((lineItem, index) => {
                  const errorMessage: string = getIn(errors, `${FieldName.lineItems}[${index}].adName`);

                  return (
                    <DuplicatedAdData
                      key={lineItem.id}
                      index={index}
                      lineItem={lineItem}
                      errorMessage={errorMessage}
                      onSetAdName={setAdName}
                    />
                  );
                })}
            </div>
          );
        }}
      </FieldArray>
    </section>
  );
};

export default memo(DuplicateAdAssigningToLineItem);
