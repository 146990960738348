import { sortBy as _sortBy } from 'lodash';

import type { AdType, DisplayEnum } from '../../../../../../configs';
import { getPermittedAdTypes } from '../../../../../../configs';
import type { AdModel } from '../../../../TraffickingPage/modelConverters';
import type { LineItemModel } from '../../../../TraffickingPage/modelConverters';
import type { DuplicateAd } from '../types';
import type { DuplicateLineItem, LineItemAdNames } from '../types';

export const getDuplicateAdsFromOriginalAds = (originalAds: AdModel[]): DuplicateAd[] => {
  return originalAds.map(
    (ad): DuplicateAd => {
      return {
        ...ad,
        selectedSections: [],
        isVisited: false,
      };
    }
  );
};

export const updateLineItemAdNames = (
  lineItemsToSetAdNames: DuplicateLineItem[],
  ads: AdModel[]
): DuplicateLineItem[] => {
  const adNames = ads.map((ad) => ({ id: ad.id, name: ad.name, indexToUse: 1 }));

  return lineItemsToSetAdNames.map((lineItemToSetAdName) => {
    if (!lineItemToSetAdName.selected || lineItemToSetAdName.conflicted) {
      lineItemToSetAdName.adNames = {};

      return lineItemToSetAdName;
    }

    const lineItemAdNames: LineItemAdNames = {};
    adNames.forEach((adName) => {
      if (lineItemToSetAdName.adNames[adName.id] && lineItemToSetAdName.adNames[adName.id].editedName) {
        lineItemAdNames[adName.id] = lineItemToSetAdName.adNames[adName.id];
      } else {
        lineItemAdNames[adName.id] = {
          name: `${adName.name}_${adName.indexToUse}`,
        };
        adName.indexToUse++;
      }
    });

    lineItemToSetAdName.adNames = lineItemAdNames;

    return lineItemToSetAdName;
  });
};

export const getInitialDuplicatedLineItems = (
  selectedLineItems: LineItemModel[],
  originalDuplicatedAds: AdModel[],
  adType: DisplayEnum<AdType>
): DuplicateLineItem[] => {
  const sortedLineItemsByName = _sortBy(selectedLineItems, ['name']);

  if (!originalDuplicatedAds.length) {
    return [];
  }

  const adLineItemIds = new Set<string>();

  originalDuplicatedAds.forEach((originalDuplicatedAd) => {
    if (originalDuplicatedAd.lineItem?.id) {
      adLineItemIds.add(originalDuplicatedAd.lineItem.id);
    }
  });

  const firstLineItemId = [...adLineItemIds][0] || '';

  const lineItemsList = sortedLineItemsByName.map((lineItem) => {
    const allowedLineItemAdTypes =
      lineItem.adProduct.key !== 'UNRECOGNIZED' ? getPermittedAdTypes(lineItem.adProduct?.key) : [];
    const isConflictedLineItems = !allowedLineItemAdTypes.some(
      (allowedLineItemAdType) => allowedLineItemAdType.key === adType.key
    );
    const selectedLineItem = adLineItemIds.size !== 1 ? true : lineItem.id !== firstLineItemId;

    return {
      id: lineItem.id,
      name: lineItem.name,
      schedule: lineItem.schedule,
      startDate: lineItem.startDate,
      endDate: lineItem.endDate,
      adProduct: lineItem.adProduct,
      billableThirdParty: lineItem.billableThirdParty,
      isCoppaOrCaru: lineItem.isCoppaOrCaru,
      campaign: lineItem.campaign,
      adNames: {},
      selected: selectedLineItem && !isConflictedLineItems,
      conflicted: isConflictedLineItems,
      country: lineItem.country,
    };
  });

  return updateLineItemAdNames(lineItemsList, originalDuplicatedAds);
};
