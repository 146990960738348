import './TodoItem.scss';

import { IconAlertErrorRed, IconAlertSuccessGreen } from '@hulu-react-style-components/icons';
import React, { useMemo } from 'react';

import bem from '../../utils/bem';
import Tooltip from '../Tooltip';
import IncompleteCheck from './IncompleteCheck.svg';

const [block, element] = bem('todo-item');

interface TodoItemProps {
  isComplete: boolean;
  isFailed?: boolean;
  text: string;
  showCheck?: boolean;
  tooltip?: string;
  tooltipId?: string;
  showTodoItem?: boolean;
  iconComplete?: React.ReactNode;
  iconFailed?: React.ReactNode;
  iconIncomplete?: React.ReactNode | null;
}

function TodoItem({
  isComplete,
  text,
  isFailed,
  tooltip,
  tooltipId,
  showCheck = true,
  iconComplete = <IconAlertSuccessGreen className={element('check', 'complete')} title="Complete Check" />,
  iconFailed = <IconAlertErrorRed className={element('check', 'failed')} title="Validation Failed Check" />,
  iconIncomplete = <img className={element('check', 'incomplete')} alt="Incomplete Check" src={IncompleteCheck} />,
}: TodoItemProps): React.JSX.Element {
  const completionComponent = useMemo(() => {
    if (isFailed) return iconFailed;

    if (isComplete) return iconComplete;

    return iconIncomplete;
  }, [iconComplete, iconFailed, iconIncomplete, isComplete, isFailed]);

  return (
    <div className={block()}>
      {tooltipId && tooltip ? (
        <Tooltip id={tooltipId} message={tooltip}>
          <span className={element('text')}>{text}</span>
        </Tooltip>
      ) : (
        <span className={element('text')}>{text}</span>
      )}
      {showCheck && (
        <span role="checkbox" aria-checked={isComplete} className={element('check-wrapper')}>
          {completionComponent}
        </span>
      )}
    </div>
  );
}

export default TodoItem;
