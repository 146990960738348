import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import type { BufferConflictIdsResult } from '../../checkConflictsAndWarnings/checkBufferConflicts/checkBufferConflicts';

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
  bufferConflicts?: BufferConflictIdsResult | null;
  isLineItem: boolean;
};

type Value = {
  id: string;
  value: number | null;
}[];

type Result = {
  buffer?: {
    buffer?: Value;
    frontload?: Value;
  };
};

export const getPreparedInputForBuffer = ({ values, bufferConflicts, isLineItem }: Props): Result => {
  if (
    !isLineItem ||
    (!values.buffer.deliveryBufferPercent && !values.buffer.frontLoadPercent) ||
    !values.selectedSections.includes('Buffer & Frontload')
  ) {
    return {};
  }

  const lineItemsWithUpdateBuffer = values.buffer.deliveryBufferPercent
    ? {
        buffer: values.entities
          .filter((entity) => !bufferConflicts?.bufferConflictIds.includes(entity.id))
          .map((entity) => ({
            id: entity.id,
            value: values.buffer.deliveryBufferPercent,
          })),
      }
    : {};

  const lineItemsWithUpdateFrontload = values.buffer.frontLoadPercent
    ? {
        frontload: values.entities
          .filter((entity) => !bufferConflicts?.frontloadConflictIds.includes(entity.id))
          .map((entity) => ({
            id: entity.id,
            value: values.buffer.frontLoadPercent,
          })),
      }
    : {};

  return {
    buffer: {
      ...lineItemsWithUpdateBuffer,
      ...lineItemsWithUpdateFrontload,
    },
  };
};
