import './DrawerTargetingTable.scss';

import TextViewer from '@ad-platform/tm-rule-text-viewer';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { User } from 'mission-control-common-components';
import React, { useMemo, useState } from 'react';

import TargetingViewButton from '../../../common/AdForm/TargetingViewButton';
import Loader from '../../../common/Loader';
import type { PermissionsNames } from '../../../constants';
import bem from '../../../utils/bem';
import { getPublisherTargetingList } from '../TraffickingPage/columns';
import type { EntityType } from '../TraffickingPage/hooks/makePageDrawerPlugin';
import DrawerTargetingBody from './Body';
import DrawerTargetingHeader from './Header/DrawerTargetingHeader';
import { useFetchTargeting, useUpdateTargeting } from './hooks';
import type { DrawerTargetingFormValues } from './hooks/types/formValues';
import {
  addCountryTargetLineItem,
  filterOutCountryTarget,
  getCountryTargetLineItem,
  getCountryTargetName,
  getPublisherTargetLineItem,
} from './utils/utils';
// using commonjs require as tslint breaks for launch-darkly
const { useFlags, withLDConsumer } = require('launchdarkly-react-client-sdk');

const [block, element] = bem('drawer-targeting-table');

interface Props {
  id?: string;
  entityType: EntityType.AD | EntityType.LINE_ITEM;
}

function DrawerTargetingTable({ id, entityType }: Props): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const [editMode, setEditMode] = useState(false);
  const [textView, setTextView] = useState(false);

  const { adTermValues, displayPublisherTarget, lineItemTermValues, isFetching, targetingRule } = useFetchTargeting({
    entityType,
    id,
  });

  const { isUpdating, submitForm } = useUpdateTargeting({
    entityType,
    id,
    onSubmitSuccessful: (): void => setEditMode(false),
  });

  const lineItemTermValuesNoCountry = filterOutCountryTarget({ value: lineItemTermValues });
  const countryLineItem = getCountryTargetLineItem({ value: lineItemTermValues });

  const formikInitialValues: DrawerTargetingFormValues = useMemo(
    () => ({ adTargetingTermValues: adTermValues, lineItemTargetingTermValues: lineItemTermValuesNoCountry }),
    [adTermValues, lineItemTermValuesNoCountry]
  );

  const publisherLineItem = useMemo(() => getPublisherTargetLineItem(displayPublisherTarget) || undefined, [
    displayPublisherTarget,
  ]);

  const hasAccessToEdit = entityType === 'Ad' ? permissions?.updateAd : permissions?.updateLineItem;

  const flags = useFlags();

  if (isFetching) return <Loader text="Loading..." />;

  function submitFormWithCountry(
    values: DrawerTargetingFormValues,
    actions: FormikHelpers<DrawerTargetingFormValues>
  ): void {
    const valuesWithCountry = addCountryTargetLineItem(values, countryLineItem);
    submitForm(valuesWithCountry, actions, targetingRule);
  }

  return (
    <div className={block()}>
      <Formik initialValues={formikInitialValues} onSubmit={submitFormWithCountry} enableReinitialize>
        {({ submitForm, resetForm, isSubmitting }): JSX.Element => (
          <div>
            <DrawerTargetingHeader
              editMode={editMode}
              disableSubmitting={isSubmitting || isUpdating}
              canEdit={hasAccessToEdit}
              setEditMode={setEditMode}
              onSubmit={submitForm}
              onCancel={resetForm}
              loading={isUpdating}
            />
            <div className={element('subheader')}>
              <div>
                <div className={element('country')}>Country: {getCountryTargetName({ value: lineItemTermValues })}</div>
                <div className={element('publisher')}>
                  Publisher: {getPublisherTargetingList({ value: displayPublisherTarget })}
                </div>
              </div>
              {flags.targetingTextViewRelease && (
                <TargetingViewButton isActive={textView} setActive={setTextView} editMode={editMode} />
              )}
            </div>
            {!flags.targetingTextViewRelease || !textView || editMode ? (
              <DrawerTargetingBody
                editMode={editMode}
                entityType={entityType}
                countryLineItem={countryLineItem || undefined}
                publisherLineItem={publisherLineItem}
              />
            ) : (
              <p className={element('placeholder-text')}>
                This area is under construction.
                <div className={element('placeholder-component')}>
                  <TextViewer rule={targetingRule} />
                </div>
              </p>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
}

export default withLDConsumer()(DrawerTargetingTable);
