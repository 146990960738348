import './FilterChip.scss';

import { IconClose } from '@hulu-react-style-components/icons';
import React, { useMemo } from 'react';

import type { DropdownOption } from '../../../../../../common/Dropdown';
import type { SearchParamsValuesType } from '../../../../../../constants';
import bem from '../../../../../../utils/bem';

const [block, element] = bem('filter-chip');

export type FilterChipProps = {
  label: string;
  searchParam: SearchParamsValuesType;
  selectedValues: (string | DropdownOption)[];
  onRemoveSingleChip(searchParam: SearchParamsValuesType): void;
};

const FilterChip = ({ label, searchParam, selectedValues, onRemoveSingleChip }: FilterChipProps): JSX.Element => {
  const handleRemove = (): void => {
    onRemoveSingleChip(searchParam);
  };

  const textValues = useMemo(() => `${selectedValues.join(', ')}`, [selectedValues]);

  return (
    <div data-testid="filterChip" className={block()}>
      <div className={element('chip')}>
        <div className={element('label')} data-testid="chip-label">
          {label}:
        </div>
        <div className={element('values')} data-testid="chip-values">
          {textValues}
        </div>
        <IconClose
          data-testid="filterChipRemove"
          width={12}
          height={12}
          className={element('close-icon')}
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

export default FilterChip;
