import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import type { PriorityWarningProps } from '../getAllConflictsAndWarnings/useGetAllConflictsAndWarnings';
import { getNewPacingTypeValue } from './utils';

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
};

export const checkPriorityWarnings = ({ values }: Props): PriorityWarningProps[] => {
  const isPrioritySectionSelected = values.selectedSections.includes('Priority');

  if (!isPrioritySectionSelected) {
    return [];
  }

  return values.initialStatuses.reduce((warnings, entity) => {
    if (values.priority.tier) {
      const newPacing = getNewPacingTypeValue({ pacingType: entity.pacingType, tier: values.priority.tier });

      if (newPacing) {
        warnings.push({
          id: entity.id,
          newPacing,
          newTier: values.priority.tier.name,
        });
      }
    }
    return warnings;
  }, new Array<PriorityWarningProps>());
};
