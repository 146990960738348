import type { CreativeV5Type } from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';

export const getAdSelectorSlateAssetIds = (adFormValues: AdFormValues): string[] => {
  const optionListIds =
    adFormValues.creative.optionList?.reduce<string[]>((acc, item) => {
      if (item.thumbnailId) acc.push(item.thumbnailId);

      return acc;
    }, []) || [];

  if (!adFormValues.creative.logo?.id) return optionListIds;

  const logoId = adFormValues.creative.logo.id;

  return [logoId, ...optionListIds];
};

export function getAssetId(
  creativeType: CreativeV5Type,
  adFormValues: AdFormValues
): string | string[] | null | undefined {
  switch (creativeType) {
    case 'AD_SELECTOR_SLATE':
      return getAdSelectorSlateAssetIds(adFormValues);
    case 'AD_SELECTOR_VIDEO':
    case 'VIDEO':
      return adFormValues.creative.video?.id ? adFormValues.creative.video?.id : null;
    case 'PAUSE':
      return adFormValues.creative.image?.id ? adFormValues.creative.image?.id : null;
    case 'BRIGHTLINE':
    case 'DRAX':
    case 'INNOVID':
    case 'MAGNITE':
    case 'VAST_VIDEO':
      return adFormValues.creative.videoAssetList?.map((item) => item.id);
    case 'SLATE':
      return adFormValues.creative.slate?.id ? adFormValues.creative.slate?.id : null;
    case 'GATEWAY_GO': // GatewayGo asset IDs are resolved differently
    default:
      return null;
  }
}
