import type { Hooks, TableInstance } from 'react-table';

import type { SetDrawerProps } from '../../../../common/Drawer/useDrawerProps';
import type { AdModel, CampaignModel, LineItemModel } from '../modelConverters';

export type TraffickingPageDrawerCallback = (data: TraffickingPageDrawerData) => void;

export enum EntityType {
  AD = 'Ad',
  LINE_ITEM = 'Line Item',
  CAMPAIGN = 'Campaign',
}

export enum EntityTypeArray {
  AD_ARRAY = 'Ad Array',
  LINE_ITEM_ARRAY = 'Line Item Array',
}

export enum DrawerType {
  ENTITY_DETAILS = 'Entity details',
  MULTIPLE_EDIT = 'Multiple edit',
  MULTIPLE_DUPLICATE = 'Multiple duplicate',
}

// Explicitly define model when dataType value is present
export type EntityDetailsDrawerData =
  | ({ drawerType: DrawerType.ENTITY_DETAILS; dataType: EntityType.AD } & Partial<AdModel>)
  | ({ drawerType: DrawerType.ENTITY_DETAILS; dataType: EntityType.LINE_ITEM } & Partial<LineItemModel>)
  | ({ drawerType: DrawerType.ENTITY_DETAILS; dataType: EntityType.CAMPAIGN } & Partial<CampaignModel>)
  | ({ drawerType?: DrawerType.ENTITY_DETAILS; dataType?: undefined } & Partial<
      AdModel | LineItemModel | CampaignModel
    >);

export type MultipleEditDrawerAdData = {
  drawerType: DrawerType.MULTIPLE_EDIT;
  dataType: EntityTypeArray.AD_ARRAY;
  entities: Array<AdModel>;
};

export type MultipleEditDrawerLineItemData = {
  drawerType: DrawerType.MULTIPLE_EDIT;
  dataType: EntityTypeArray.LINE_ITEM_ARRAY;
  entities: Array<LineItemModel>;
};

export type MultipleDuplicateAdsDrawerData = {
  drawerType: DrawerType.MULTIPLE_DUPLICATE;
  selectedAds: AdModel[];
  selectedLineItems: LineItemModel[];
};

export type MultipleEditDrawerData = MultipleEditDrawerLineItemData | MultipleEditDrawerAdData;

export type TraffickingPageDrawerData =
  | EntityDetailsDrawerData
  | MultipleEditDrawerData
  | MultipleDuplicateAdsDrawerData;

function makeUseInstance<T extends object>(setPageDrawer: SetDrawerProps<TraffickingPageDrawerData>) {
  return function useInstance(instance: TableInstance<T>): void {
    const openTraffickingPageDrawer: TraffickingPageDrawerCallback = (data: TraffickingPageDrawerData) =>
      setPageDrawer({ data, isOpen: true });

    Object.assign(instance, { openTraffickingPageDrawer });
  };
}

function makePageDrawerPlugin<T extends object>(setPageDrawer: SetDrawerProps<TraffickingPageDrawerData>) {
  return function usePageDrawerPlugin(hooks: Hooks<T>): void {
    hooks.useInstance.push(makeUseInstance(setPageDrawer));
  };
}

export default makePageDrawerPlugin;
