import { pacingTypes } from '../../../../../../../../../../configs';
import type { Tier } from '../../../../../../../../../../models/tier';
import type { LineItemModel } from '../../../../../../../../TraffickingPage/modelConverters';

type Props = {
  tier: Tier | null;
  pacingType?: LineItemModel['pacingType'];
};

export const getIsTierNameHasConflictByPacingType = ({ tier, pacingType }: Props): boolean => {
  if (!pacingType || !tier) return false;

  return pacingType === pacingTypes.SOV;
};
