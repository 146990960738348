import type { ChangeEvent } from 'react';
import React from 'react';
import type { Row } from 'react-table';

import ControlledCheckbox from '../../../../../../../../../common/ControlledCheckbox';
import Tooltip from '../../../../../../../../../common/Tooltip';
import type { DuplicateLineItem } from '../../../../../DuplicateAdsStepperProvider/types';
import { pickerElement, TYPE_DIVERGENCE_HOVER_MESSAGE } from '../../constants';

type TableCellProps = {
  row: Row<DuplicateLineItem>;
  onChange: (e: ChangeEvent<HTMLInputElement>, lineItemId: string) => void;
};

const CheckboxLineItemBasicCell: React.FC<TableCellProps> = ({ row, onChange }) => {
  const lineItemCheckbox = (
    <ControlledCheckbox
      {...row.getToggleRowSelectedProps({ checked: row.original.selected })}
      onChange={(e): void => {
        onChange(e, row.id);
      }}
      title={row.original.conflicted ? '' : row.getToggleRowSelectedProps().title}
      disabled={row.original.conflicted}
      className={pickerElement('checkbox')}
      data-testid="line-item-basic-controlled-checkbox"
    />
  );

  if (!row.original.conflicted) {
    return lineItemCheckbox;
  }

  return (
    <Tooltip
      id={'disabled-checkbox-tooltip'}
      message={row.original.conflicted ? TYPE_DIVERGENCE_HOVER_MESSAGE : ''}
      className={pickerElement('name-tooltip')}
      data-testid="tooltip-basic-controlled-checkbox"
      shouldRenderInPortal={false}
    >
      {lineItemCheckbox}
    </Tooltip>
  );
};

export default CheckboxLineItemBasicCell;
