import './AssetPreview.scss';

import envConfig from '@hulu/env-config';
import React from 'react';
import ReactPlayer from 'react-player';

import { SPReviewStatus } from '../_ForCommonLibrary/components/atoms/AssetStatusPill/constants';
import { AssetTypeV5, TranscodeStatusV5 } from '../../apis/graphql';
import { assetReviewStatuses } from '../../configs';
import { useAsset } from '../../hooks/useAsset';
import type { AssetV5, TextAssetV5 } from '../../models';
import bem from '../../utils/bem';
import { titleCase } from '../../utils/formatting';
import useGetAssetById from '../AdForm/CreativeBuilders/hooks/useGetAssetById';
import useTranscodingFailure from '../AdForm/CreativeBuilders/hooks/useTranscodingFailure';
import AssetDetails from '../AssetDetails';
import Loader from '../Loader';
import Pill, { PillColor } from '../Pill';
import AssetRejectionReasonsModal from '../StatusModal/AssetRejectionReasonsModal/AssetRejectionReasonsModal';
import { getAcceptableUrl, getColor, getDuration, getPreviewUrl } from './util';

export interface AssetPreviewProps {
  asset: Partial<AssetV5>;
  creativeId?: string;
  additionalErrorClassName?: string;
}

const [block, element] = bem('asset-preview');

function AssetPreview({ asset, creativeId, additionalErrorClassName }: AssetPreviewProps): React.JSX.Element {
  const { asset: assetDetails, hasReviewTaskId, loading, spReview } = useGetAssetById(asset.id, creativeId, asset.type);

  const transcodeStatus =
    assetDetails?.transcodeStatus && asset.type === AssetTypeV5.Video
      ? assetDetails.transcodeStatus === TranscodeStatusV5.StreamableChecking
        ? TranscodeStatusV5.PublishingComplete
        : assetDetails.transcodeStatus
      : undefined;

  const { message, name: transcodingFailureName } = useTranscodingFailure({
    assetId: asset.id,
    transcodeStatus,
  });

  const { assetUrl } = useAsset({ url: getPreviewUrl(asset.id), assetType: asset.type });

  const lockedSPReview = spReview === SPReviewStatus.LOCKED;
  const availableReview = assetDetails?.review || asset.review;
  const isVideoAsset = asset.type === AssetTypeV5.Video;
  const isDisplayAsset = asset.type === AssetTypeV5.Display;
  const isTextAsset = asset.type === AssetTypeV5.Text;

  const renderVideoAsset = (
    <div className={element('video-container')}>
      {!assetUrl ? (
        <>
          <div className={element('loading-content')}>
            <Loader />
          </div>
          <video controls src={''} aria-label="video-player" />
        </>
      ) : (
        <ReactPlayer url={assetUrl} controls={true} width={`100%`} height={`100%`} />
      )}
    </div>
  );

  const renderImageAsset = (
    <div className={element('image-container')}>
      {!assetUrl ? <Loader /> : <img alt={assetDetails?.name} src={assetUrl} className={additionalErrorClassName} />}
    </div>
  );

  const renderTextAsset = (
    <div className={element('text-asset-container')}>
      <span className={element('asset-name')}>{asset?.name}</span>
      <textarea value={(asset as TextAssetV5).text} disabled />
    </div>
  );

  return (
    <div className={block()}>
      {lockedSPReview && (
        <Pill className={element('review-status-pill')} text={'Under S&P Review'} color={PillColor.ORANGE} />
      )}
      {availableReview && !lockedSPReview && !isTextAsset && (
        <Pill
          className={element('review-status-pill')}
          textComponent={
            <div className={element('review-status-content')}>
              <div>{titleCase(availableReview)}</div>
              {assetDetails && availableReview === assetReviewStatuses.REJECTED.key && (
                <AssetRejectionReasonsModal asset={assetDetails} />
              )}
            </div>
          }
          color={getColor(availableReview)}
        />
      )}
      {isDisplayAsset && renderImageAsset}
      {isVideoAsset && renderVideoAsset}
      {isTextAsset && renderTextAsset}

      <AssetDetails
        assetDetailsUrl={getAcceptableUrl(loading, creativeId, asset.id, hasReviewTaskId)}
        assetTagList={assetDetails?.assetTagList ?? []}
        createdAt={assetDetails?.createdAt ? new Date(assetDetails?.createdAt) : new Date()}
        filename={assetDetails?.name}
        fileSize={assetDetails?.fileSize || 0}
        fileUrl={`${envConfig.REACT_APP_CAMPAIGN_GRAPHQL_ORIGIN}/api/asset/${asset.id}`}
        review={asset.review || assetDetails?.review || ''}
        transcodeStatus={transcodeStatus}
        transcodingDetails={{ name: transcodingFailureName, message }}
        videoDuration={getDuration(asset.type, asset.type === AssetTypeV5.Video ? asset.duration : undefined)}
        type={asset.type}
      />
    </div>
  );
}

export default AssetPreview;
