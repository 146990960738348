import './SubmitInput.scss';

import { IconAlertErrorOutlined } from '@hulu-react-style-components/icons';
import type { SearchFieldProps as TrekSearchFieldProps } from '@hulu/react-style-components';
import { SearchField as TrekSearchField } from '@hulu/react-style-components';
import type { ModalProps } from 'antd';
import React, { useMemo, useState } from 'react';

import bem, { withOptionalClassName } from '../../../utils/bem';
import Loader from '../../assets/Loader.svg';
import ModalPopup from '../../ModalPopup';
import { makeLabelChild } from '../utils';
import { getClassNameByState } from './utils';

export interface SubmitInputProps extends TrekSearchFieldProps {
  className?: string;

  label: string;

  onClear: () => void;

  disabled?: boolean;
  isURLRemovable?: boolean;
  inputSubmitted: boolean;
  loading: boolean;

  modalProps?: ModalProps;
  modalMessage?: string;

  style?: React.CSSProperties;
  customInputComponent?: React.JSX.Element | null;
}

const [block, element] = bem('submit-input');

/**
 * This is a wrapper around Trek's SearchField and Ant Design's Modal.
 * It includes a "submit" button, loading state, and a confirmation modal when clearing the input.
 */
export const SubmitInput = ({
  className,
  onClear,
  inputSubmitted,
  loading,
  modalProps,
  modalMessage,
  disabled,
  style,
  label,
  secondaryLabel,
  errMsg,
  customInputComponent,
  isURLRemovable,
  ...searchInputProps
}: SubmitInputProps): JSX.Element => {
  const classNameByState = useMemo(
    () =>
      getClassNameByState({
        isLoading: loading,
        isDisabled: disabled,
        isInputSubmitted: inputSubmitted,
        hasCustomInputComponent: !!customInputComponent,
      }),
    [loading, disabled, inputSubmitted, customInputComponent]
  );

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const showModalPopup = (): void => {
    setModalVisible(true);
  };

  // If the user confirm the modal after clicking on 'x' button, component will run the clear function provided, clear the text in input form, then close the modal.
  const confirmModalPopup = (): void => {
    onClear();
    setModalVisible(false);
  };

  const cancelModalPopup = (): void => {
    setModalVisible(false);
  };

  return (
    <div className={withOptionalClassName(block(), className)} style={style} data-testid="submit-input-wrapper">
      <div className={element(classNameByState)}>
        {customInputComponent ? (
          customInputComponent
        ) : (
          <TrekSearchField
            disabled={inputSubmitted || disabled}
            {...searchInputProps}
            label={makeLabelChild(label, secondaryLabel)}
            errMsg={errMsg}
          />
        )}
        {inputSubmitted && (typeof isURLRemovable !== 'undefined' ? isURLRemovable : true) && !loading && (
          <IconAlertErrorOutlined
            aria-label="clear-button"
            data-testid="clear-button"
            className={element('clear-button')}
            onClick={showModalPopup}
            tabIndex={0}
          />
        )}
        {loading && (
          <span className="loading-content">
            <img src={Loader} alt="spinner" />
            <span className="loading-text">Loading...</span>
          </span>
        )}
        <ModalPopup
          modalProps={modalProps}
          visible={modalVisible}
          onOk={confirmModalPopup}
          onCancel={cancelModalPopup}
          modalMessage={modalMessage}
        />
      </div>
    </div>
  );
};
