import { sortBy as _sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import type {
  DuplicateAd,
  DuplicateAdsStepperProviderProps,
  DuplicateLineItem,
  UseDuplicateAdsResponse,
} from '../types';
import { getDuplicateAdsFromOriginalAds, getInitialDuplicatedLineItems, updateLineItemAdNames } from './utils';

export type Props = Pick<DuplicateAdsStepperProviderProps, 'preSelectedLineItems' | 'originalDuplicatedAds'>;

const useDuplicateAds = ({ preSelectedLineItems, originalDuplicatedAds }: Props): UseDuplicateAdsResponse => {
  const adType = originalDuplicatedAds[0]?.type || null;
  const [lastAppliedSectionsAdName, setLastAppliedSectionsAdName] = useState<string | null>(null);
  const [isSelectedSectionsChanged, setIsSelectedSectionsChanged] = useState<boolean>(true);

  const [lineItems, setLineItems] = useState<DuplicateLineItem[]>(() => {
    return getInitialDuplicatedLineItems(preSelectedLineItems, originalDuplicatedAds, adType);
  });

  const selectedLineItems = useMemo(() => lineItems.filter(({ selected }) => selected), [lineItems]);

  const [ads, setAds] = useState<DuplicateAd[]>(getDuplicateAdsFromOriginalAds(originalDuplicatedAds));

  const replaceLineItems = useCallback(
    (newLineItems: DuplicateLineItem[]): void => {
      const sortedLineItemsByName = _sortBy(newLineItems, ['name']);

      const newLineItemsWithAdNames = updateLineItemAdNames(sortedLineItemsByName, ads);

      setLineItems(newLineItemsWithAdNames);

      // if we change line items after we came back on the first step from the third one,
      // we need to reset isVisited as there can be changes in line items and their conflicts
      setAds(
        ads.map((ad) => ({
          ...ad,
          isVisited: false,
          isFailed: false,
        }))
      );
      setIsSelectedSectionsChanged(true);
      setLastAppliedSectionsAdName(null);
    },
    [ads]
  );

  const updateLineItemAdName = useCallback(
    (lineItemId: string, adId: string, newName: string): void => {
      const newLineItems = lineItems.map((lineItem) => {
        if (lineItem.id === lineItemId) {
          lineItem.adNames[adId] = {
            name: newName,
            editedName: newName,
          };
        }

        return lineItem;
      });

      setLineItems(newLineItems);
    },
    [lineItems]
  );

  const handleRemoveLineItem = useCallback(
    (lineItemId: string) => {
      const newLineItems = lineItems.filter((lineItem) => lineItem.id !== lineItemId);

      replaceLineItems(newLineItems);
    },
    [lineItems, replaceLineItems]
  );

  const handleUpdateLineItemsSelection = useCallback(
    (selected: boolean, specificLineItemIds?: string[]) => {
      const newLineItems = lineItems.map((lineItem) => {
        if (specificLineItemIds && !specificLineItemIds.includes(lineItem.id)) return lineItem;

        if (!selected || (selected && !lineItem.conflicted)) {
          lineItem.selected = selected;
        }

        return lineItem;
      });

      replaceLineItems(newLineItems);
    },
    [lineItems, replaceLineItems]
  );

  return {
    lineItems,
    replaceLineItems,
    ads,
    setAds,
    updateLineItemAdName,
    handleRemoveLineItem,
    handleUpdateLineItemsSelection,
    adType,
    lastAppliedSectionsAdName,
    setLastAppliedSectionsAdName,
    isSelectedSectionsChanged,
    setIsSelectedSectionsChanged,
    selectedLineItems,
  };
};

export default useDuplicateAds;
