import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { GetLineItemsToDuplicateQuery, LineItemV5 } from '../../../../../../../../../apis/graphql';
import { getDataFromNodes } from '../../../../../../../../../apis/graphql';
import type { QueryVariables } from '../../../../../../../TraffickingPage/hooks';
import type { DuplicateLineItem } from '../../../../../DuplicateAdsStepperProvider/types';
import { LINE_ITEMS_LIMIT } from '../../constants';
import { GET_LINE_ITEMS_TO_DUPLICATE } from '../queries/getLineItemsToDuplicate';
import { convertLineItemToDuplicateLineItem } from './utils';

export type UseFetchDuplicateLineItems = {
  data?: DuplicateLineItem[];
  loading: boolean;
  error?: ApolloError;
  hasMore: boolean;
  handleSearch: (searchTerm: string) => Promise<DuplicateLineItem[]>;
};

const INITIAL_OFFSET = 0;

const useFetchDuplicateLineItems = (
  variables: QueryVariables,
  searchValue: string,
  skip?: boolean
): UseFetchDuplicateLineItems => {
  const { data: fetchedData, loading, error, refetch: fetchData } = useQuery<
    GetLineItemsToDuplicateQuery,
    QueryVariables
  >(GET_LINE_ITEMS_TO_DUPLICATE, {
    variables,
    skip,
  });

  let initialTotal = 0;

  if (fetchedData) {
    initialTotal = fetchedData.lineItemsV5.total;
  }

  const [offset, setOffset] = useState<number>(0);

  const initialHasMore = offset + LINE_ITEMS_LIMIT < initialTotal;
  const [hasMore, setHasMore] = useState<boolean>(initialHasMore);

  const data = useMemo(() => {
    if (!fetchedData) return undefined;

    const getTransformedData = getDataFromNodes<LineItemV5, DuplicateLineItem>(fetchedData, 'lineItemsV5', (node) =>
      convertLineItemToDuplicateLineItem(node)
    );

    return getTransformedData();
  }, [fetchedData]);

  const handleSearch = useCallback(
    async (searchTerm: string): Promise<DuplicateLineItem[]> => {
      const { data: searchedData } = await fetchData({
        searchTerm,
        ids: undefined,
        paginationOptions: { limit: LINE_ITEMS_LIMIT, offset },
      });
      const getTransformedData = getDataFromNodes<LineItemV5, DuplicateLineItem>(searchedData, 'lineItemsV5', (node) =>
        convertLineItemToDuplicateLineItem(node)
      );

      const newOffset = offset + LINE_ITEMS_LIMIT;
      const newHasMore = newOffset < searchedData.lineItemsV5.total;
      setHasMore(newHasMore);

      if (newHasMore) {
        setOffset(newOffset);
      }

      return getTransformedData();
    },
    [fetchData, offset]
  );

  useEffect(() => {
    setOffset(INITIAL_OFFSET);
  }, [searchValue]);

  return { data, loading, error, handleSearch, hasMore };
};

export default useFetchDuplicateLineItems;
