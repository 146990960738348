import { fromEmailToName } from '../../../../utils/common';
import { formatStringDate } from '../../../../utils/formatting';
import { CampaignColumn } from '../../constants/campaign';
import { getUniqueCountriesString } from '../../TraffickingPage/cells/CountryCell/utils';
import { microCurrencyToDollarAmount } from '../../TraffickingPage/columns';
import type { CampaignModelForExport } from '../convertors/campaign';
import type { CampaignModelValueAccessor } from '../types';
import { getAlertMessage, getCreatedAt, getTrackingId, getUpdatedAt } from './commonAccessors';

export const campaignModelColumnAccessors: CampaignModelValueAccessor = {
  [CampaignColumn.Alert]: (campaign: CampaignModelForExport): string => {
    return getAlertMessage({
      hasZeroDeliveryRisk: !!campaign.hasZeroDeliveryRisk,
      hasMisalignedSchedule: !!campaign.hasMisalignedSchedule,
    });
  },
  [CampaignColumn.Name]: (campaign: CampaignModelForExport): string => campaign.name,
  [CampaignColumn.TrackingId]: getTrackingId,
  [CampaignColumn.Brand]: (campaign: CampaignModelForExport): string => campaign.crmLink?.name || '',
  [CampaignColumn.Country]: (campaign: CampaignModelForExport): string =>
    getUniqueCountriesString(campaign.campaignLineItems),
  [CampaignColumn.OrderType]: (campaign: CampaignModelForExport): string => campaign.orderType?.displayName || '',
  [CampaignColumn.OrderOmsLinkId]: (campaign: CampaignModelForExport): string => campaign.orderOmsLink?.id || '',
  [CampaignColumn.TraffickerEmail]: (campaign: CampaignModelForExport): string =>
    fromEmailToName(campaign.traffickerEmail),
  [CampaignColumn.OrderSalespersonEmail]: (campaign: CampaignModelForExport): string =>
    campaign.salespersonEmail ? fromEmailToName(campaign.salespersonEmail) : '',
  [CampaignColumn.Start]: (campaign: CampaignModelForExport): string => formatStringDate(campaign.startDate),
  [CampaignColumn.End]: (campaign: CampaignModelForExport): string => formatStringDate(campaign.endDate),
  [CampaignColumn.Advertiser]: (campaign: CampaignModelForExport): string => campaign.advertiser || '',
  [CampaignColumn.IndustryName]: (campaign: CampaignModelForExport): string => campaign.industryName || '',
  [CampaignColumn.Agency]: (campaign: CampaignModelForExport): string => campaign.agency || '',
  [CampaignColumn.CampaignID]: (campaign: CampaignModelForExport): string => campaign.id,
  [CampaignColumn.CreatedAt]: getCreatedAt,
  [CampaignColumn.UpdatedAt]: getUpdatedAt,
  [CampaignColumn.AdAccount]: (campaign: CampaignModelForExport): string => campaign.adAccount?.name || '',
  [CampaignColumn.AdAccountId]: (campaign: CampaignModelForExport): string => campaign.adAccount?.id || '',
  [CampaignColumn.BrandId]: (campaign: CampaignModelForExport): string => campaign.crmLink?.id || '',
  [CampaignColumn.CampaignBudget]: (campaign: CampaignModelForExport): string =>
    microCurrencyToDollarAmount({ value: campaign.budget }) || '',
  [CampaignColumn.Status]: (campaign: CampaignModelForExport): string => campaign.status?.displayName || '',
};
