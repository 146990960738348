import React, { memo } from 'react';
import type { Cell } from 'react-table';

import Pill from '../../../../../common/Pill';
import type { DisplayEnum } from '../../../../../configs';
import type { CampaignStatus } from '../../../../../configs/campaignStatus';
import { getIdFromTableCell } from '../../columns';
import type { CampaignModel } from '../../modelConverters';
import { CAMPAIGN_STATUS_PILL_COLOR_CONFIG } from './constants';

type CampaignStatusCellProps = Cell<CampaignModel, DisplayEnum<CampaignStatus>>;

const CampaignStatusCell = (props: CampaignStatusCellProps): React.JSX.Element => {
  const { value } = props;

  const pillColor = CAMPAIGN_STATUS_PILL_COLOR_CONFIG[value?.key];

  return <Pill text={value?.displayName} color={pillColor} />;
};

export const areCampaignStatusPropsEqual = (prev: CampaignStatusCellProps, next: CampaignStatusCellProps): boolean => {
  const prevId = getIdFromTableCell(prev);
  const nextId = getIdFromTableCell(next);

  return (
    prevId === nextId && prev.value?.displayName === next.value?.displayName && prev.value?.key === next.value?.key
  );
};

export default memo(CampaignStatusCell, areCampaignStatusPropsEqual);
