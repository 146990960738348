import React, { memo } from 'react';
import type { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps } from 'react-table';

import type { TraffickingTableName } from '../../../constants';
import type { EntityModel } from '../../../pages/Trafficking/TraffickingPage/modelConverters';
import bem from '../../../utils/bem';
import type { DragAndDropColumns } from '../hooks/useDragAndDropColumns';
import { TraffickingTableBody } from '../TraffickingTableBody';

export type ComposedTraffickingTableProps = DragAndDropColumns & {
  errorMessage?: string;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<EntityModel>) => TableBodyProps;
  getTableProps: (propGetter?: TablePropGetter<EntityModel>) => TableProps;
  headerGroups: HeaderGroup<EntityModel>[];
  initialLoading: boolean;
  isColumnResizing: boolean;
  isSequenceViewEnabled: boolean;
  lastSelectedRowId: string;
  onKeyDownHandler: (e: React.KeyboardEvent<HTMLTableElement>) => void;
  prepareRow: (row: Row<EntityModel>) => void;
  selectedRowIndex: number;
  tableModifier: 'invisible' | null;
  tableName: TraffickingTableName;
  headerGroupToElement: (
    props: HeaderGroup<EntityModel> &
      DragAndDropColumns & {
        tableName: TraffickingTableName;
        isSequenceViewEnabled?: boolean;
        rows: Row<EntityModel>[];
        tableIndex: number;
      }
  ) => React.JSX.Element;
  rows: Row<EntityModel>[];
  setLastSelectedRowId: (value: React.SetStateAction<string>) => void;
  tableIndex: number;
};

const [, element] = bem('trafficking-table');

const ComposedTraffickingTable = ({
  errorMessage,
  getTableBodyProps,
  getTableProps,
  headerGroups,
  initialLoading,
  isColumnResizing,
  isSequenceViewEnabled,
  lastSelectedRowId,
  onKeyDownHandler,
  prepareRow,
  selectedRowIndex,
  tableModifier,
  tableName,
  headerGroupToElement,
  rows,
  setLastSelectedRowId,
  tableIndex,
  handleDragStart,
  handleDrop,
  handleDragOver,
  handleDragEnter,
  draggedOverColumnId,
  handleDragEnd,
  handleDrag,
  draggedColumnIndex,
}: ComposedTraffickingTableProps): React.JSX.Element => {
  return (
    <table
      data-testid="composed-table"
      className={element('table', tableModifier)}
      onKeyDown={onKeyDownHandler}
      {...getTableProps()}
    >
      <thead data-testid="composed-table-header">
        {headerGroups.map((headerContext: HeaderGroup<EntityModel>) =>
          headerGroupToElement({
            ...headerContext,
            tableName,
            isSequenceViewEnabled,
            rows,
            tableIndex,
            handleDragStart,
            handleDrop,
            handleDragOver,
            handleDragEnter,
            draggedOverColumnId,
            handleDragEnd,
            handleDrag,
            draggedColumnIndex,
          })
        )}
      </thead>
      <TraffickingTableBody
        getTableBodyProps={getTableBodyProps}
        loading={initialLoading}
        errorMessage={errorMessage}
        rows={rows}
        prepareRow={prepareRow}
        selectedColIndex={selectedRowIndex}
        selectedRowIndex={selectedRowIndex}
        isColumnResizing={isColumnResizing}
        lastSelectedRowId={lastSelectedRowId}
        setLastSelectedRowId={setLastSelectedRowId}
      />
    </table>
  );
};

export default memo(ComposedTraffickingTable);
