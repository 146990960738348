/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconCalendar } from '@hulu-react-style-components/icons';
import envConfig from '@hulu/env-config';
import React from 'react';
import type { Cell } from 'react-table';

import { resizingData, stickyBlock } from '../../../../common/TraffickingTable/utils';
import { TraffickingTableName } from '../../../../constants';
import bem from '../../../../utils/bem';
import { fromEmailToName } from '../../../../utils/common';
import { DEFAULT_COLUMN_MIN_WIDTH } from '../../constants';
import { CampaignColumn } from '../../constants/campaign';
import { AlertCell, CampaignLeadCell, MicroStrategyReportCell } from '../cells';
import type { AlertFlagProps } from '../cells/AlertCell';
import { AlertFiled } from '../cells/AlertCell/AlertCell';
import CampaignStatusCell from '../cells/CampaignStatusCell';
import CountryCell from '../cells/CountryCell';
import NameCell from '../cells/NameCell';
import { EntityType } from '../hooks/makePageDrawerPlugin';
import type { CampaignModel } from '../modelConverters';
import { CampaignColumnHeaders, CampaignColumnTooltips } from './types';
import type { CustomColumnConfig } from './utils';
import { formatStartEndDateString, microCurrencyToDollarAmount, stringToDateTimeString } from './utils';

const [, element] = bem('alert-cell');

export const getCampaignAlertFlags = ({
  id,
  hasZeroDeliveryRisk,
  hasMisalignedSchedule,
}: CampaignModel): AlertFlagProps[] => {
  return [
    {
      id,
      field: AlertFiled.ZeroDelivery,
      isShown: !!hasZeroDeliveryRisk,
      hoverMessage:
        'This campaign contains 1+ Line Item(s) that have not delivered any impressions in the last 12 hours.',
    },
    {
      id,
      field: AlertFiled.MisalignedSchedule,
      isShown: !!hasMisalignedSchedule,
      icon: <IconCalendar width={18} height={18} />,
      hoverMessage: 'This campaign contains 1+ Line Item(s) with schedule conflict alert.',
      className: element('red-icon'),
    },
  ];
};

const getMSTRReportLinkColumn = (): CustomColumnConfig<CampaignModel> => {
  return {
    id: CampaignColumn.MicroStrategyReport,
    Header: 'Reports',
    Cell: MicroStrategyReportCell,
    width: resizingData.getData(85, TraffickingTableName.campaigns, 'MicroStrategyReport'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    className: 'mstr-cell',
  };
};

const getCampaignsColumns = (hasAccessToChangeLog?: boolean): CustomColumnConfig<CampaignModel>[] => [
  {
    id: CampaignColumn.Alert,
    Header: CampaignColumnHeaders.Alert,
    Cell: (cell: Cell<CampaignModel>) => <AlertCell flags={getCampaignAlertFlags(cell.row.original)} />,
    width: resizingData.getData(90, TraffickingTableName.campaigns, 'Alert'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Alert],
  },
  {
    id: CampaignColumn.Name,
    accessor: 'name',
    Header: CampaignColumnHeaders.CampaignName,
    width: resizingData.getData(350, TraffickingTableName.campaigns, 'Name'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    Cell: ({ row: { original }, openTraffickingPageDrawer }) => (
      <NameCell
        rowOriginal={original}
        entityType={EntityType.CAMPAIGN}
        openTraffickingPageDrawer={hasAccessToChangeLog ? openTraffickingPageDrawer : () => {}}
      />
    ),
    className: stickyBlock('name'),
    disableHiding: true,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Name],
  },
  ...(envConfig.REACT_APP_FEATURE_ENABLE_MSTR_REPORT_LINK === 'true' ? [getMSTRReportLinkColumn()] : []),
  {
    id: CampaignColumn.TrackingId,
    accessor: 'trackingId',
    Header: CampaignColumnHeaders.TrackingId,
    width: resizingData.getData(175, TraffickingTableName.campaigns, 'TrackingId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.TrackingId],
  },
  {
    id: CampaignColumn.Brand,
    accessor: (c) => {
      return c.crmLink?.name;
    },
    Header: CampaignColumnHeaders.Brand,
    width: resizingData.getData(350, TraffickingTableName.campaigns, 'brand'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Brand],
  },
  {
    id: CampaignColumn.Country,
    accessor: ({ lineItemList }) => lineItemList || [],
    Header: CampaignColumnHeaders.Country,
    width: resizingData.getData(190, TraffickingTableName.lineItems, 'Country'),
    Cell: (cell: Cell<CampaignModel>) => <CountryCell lineItemList={cell.value} />,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Country],
  },
  {
    id: CampaignColumn.OrderType,
    accessor: (c) => c.orderType?.displayName,
    Header: CampaignColumnHeaders.OrderType,
    width: resizingData.getData(170, TraffickingTableName.campaigns, 'OrderType'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.OrderType],
  },
  {
    id: CampaignColumn.OrderOmsLinkId,
    accessor: (c) => c.orderOmsLink?.id,
    Header: CampaignColumnHeaders.OperativeSalesOrderId,
    width: resizingData.getData(250, TraffickingTableName.campaigns, 'OrderOmsLinkId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.OrderOmsLinkId],
  },
  {
    id: CampaignColumn.TraffickerEmail,
    accessor: 'traffickerEmail',
    Header: CampaignColumnHeaders.AccountManager,
    Cell: CampaignLeadCell,
    isEditable: true,
    width: resizingData.getData(210, TraffickingTableName.campaigns, 'TraffickerEmail'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.TraffickerEmail],
  },
  {
    id: CampaignColumn.OrderSalespersonEmail,
    accessor: (campaign) => (campaign.salespersonEmail ? fromEmailToName(campaign.salespersonEmail) : ''),
    Header: CampaignColumnHeaders.AccountExecutive,
    width: resizingData.getData(210, TraffickingTableName.campaigns, 'OrderSalespersonEmail'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.OrderSalespersonEmail],
  },
  {
    id: CampaignColumn.Start,
    accessor: (c) => c.startDate,
    Header: CampaignColumnHeaders.StartDate,
    Cell: formatStartEndDateString,
    width: resizingData.getData(160, TraffickingTableName.campaigns, 'Start'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Start],
  },
  {
    id: CampaignColumn.End,
    accessor: (c) => c.endDate,
    Header: CampaignColumnHeaders.EndDate,
    Cell: formatStartEndDateString,
    width: resizingData.getData(150, TraffickingTableName.campaigns, 'End'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.End],
  },
  {
    id: CampaignColumn.Advertiser,
    accessor: (c) => c.advertiser,
    Header: CampaignColumnHeaders.Advertiser,
    width: resizingData.getData(330, TraffickingTableName.campaigns, 'advertiser'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Advertiser],
  },
  {
    id: CampaignColumn.IndustryName,
    accessor: (c) => c.industryName,
    Header: CampaignColumnHeaders.Industry,
    width: resizingData.getData(330, TraffickingTableName.campaigns, 'industryName'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.IndustryName],
  },
  {
    id: CampaignColumn.Agency,
    accessor: (c) => c.agency,
    Header: CampaignColumnHeaders.Agency,
    width: resizingData.getData(330, TraffickingTableName.campaigns, 'agency'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Agency],
  },
  {
    id: CampaignColumn.CampaignID,
    accessor: 'id',
    Header: CampaignColumnHeaders.CampaignId,
    width: resizingData.getData(320, TraffickingTableName.campaigns, 'campaignID'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.CampaignID],
  },
  {
    id: CampaignColumn.CreatedAt,
    accessor: 'createdAt',
    Header: CampaignColumnHeaders.CreatedDate,
    Cell: stringToDateTimeString,
    width: resizingData.getData(190, TraffickingTableName.campaigns, 'CreatedAt'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.CreatedAt],
  },
  {
    id: CampaignColumn.UpdatedAt,
    accessor: 'updatedAt',
    Header: CampaignColumnHeaders.UpdatedDate,
    Cell: stringToDateTimeString,
    width: resizingData.getData(185, TraffickingTableName.campaigns, 'UpdatedAt'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.UpdatedAt],
  },
  {
    id: CampaignColumn.AdAccount,
    accessor: (c) => c.adAccount?.name,
    Header: CampaignColumnHeaders.AdAccount,
    width: resizingData.getData(320, TraffickingTableName.campaigns, 'adAccount'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.AdAccount],
  },
  {
    id: CampaignColumn.AdAccountId,
    accessor: (c) => c.adAccount?.id,
    Header: CampaignColumnHeaders.AdAccountId,
    width: resizingData.getData(324, TraffickingTableName.campaigns, 'AdAccountId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.AdAccountId],
  },
  {
    id: CampaignColumn.BrandId,
    accessor: (c) => c.crmLink?.id,
    Header: CampaignColumnHeaders.BrandId,
    width: resizingData.getData(200, TraffickingTableName.campaigns, 'BrandId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.BrandId],
  },
  {
    id: CampaignColumn.CampaignBudget,
    accessor: 'budget',
    Header: CampaignColumnHeaders.CampaignBudget,
    Cell: microCurrencyToDollarAmount,
    width: resizingData.getData(180, TraffickingTableName.campaigns, 'campaignBudget'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    className: 'price-cell',
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.CampaignBudget],
  },
  {
    id: CampaignColumn.Status,
    accessor: (c) => c?.status,
    Cell: CampaignStatusCell,
    Header: CampaignColumnHeaders.CampaignStatus,
    width: resizingData.getData(180, TraffickingTableName.campaigns, 'Status'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    tooltipMessage: CampaignColumnTooltips[CampaignColumn.Status],
  },
];

export default getCampaignsColumns;
