import type { ChangeEvent } from 'react';
import React, { memo, useCallback, useMemo } from 'react';

import ControlledCheckbox from '../../../../../../../../../common/ControlledCheckbox';
import type { DuplicateLineItem } from '../../../../../DuplicateAdsStepperProvider/types';
import { pickerElement } from '../../constants';

interface TableHeaderProps {
  lineItems: DuplicateLineItem[];
  handleUpdateLineItemsSelection: (selected: boolean, specificLineItemIds?: string[]) => void;
}

export const CheckboxLineItemHeaderCell: React.FC<TableHeaderProps> = ({
  handleUpdateLineItemsSelection,
  lineItems,
}) => {
  const { disabled, checked, indeterminate } = useMemo(() => {
    let selectableRowsInCurrentPage = 0;
    let selectedRowsInCurrentPage = 0;
    lineItems.forEach((lineItem) => {
      lineItem.selected && selectedRowsInCurrentPage++;
      !lineItem.conflicted && selectableRowsInCurrentPage++;
    });

    const disabled = selectableRowsInCurrentPage === 0;
    const checked = selectableRowsInCurrentPage === selectedRowsInCurrentPage && !disabled;
    const indeterminate = !checked && !disabled && selectedRowsInCurrentPage !== 0;

    return { disabled, checked, indeterminate };
  }, [lineItems]);

  const onAllSelectionChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        handleUpdateLineItemsSelection(e.target.checked);
      }
    },
    [disabled, handleUpdateLineItemsSelection]
  );

  return (
    <ControlledCheckbox
      onChange={onAllSelectionChanged}
      disabled={disabled}
      checked={checked}
      indeterminate={indeterminate}
      className={pickerElement('checkbox')}
      data-testid="line-item-header-controlled-checkbox"
    />
  );
};

export default memo(CheckboxLineItemHeaderCell);
