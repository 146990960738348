export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** JSONDataType includes all possible JSON data types. */
  JSONDataType: any;
  /** Void returns null. Useful for operations that are not expected to return any result. */
  Void: any;
};

/** AccentRGBInput contains a creative's soon-to-be associated RGB values */
export type AccentRgbInputV5 = {
  r: Scalars['Int'];
  g: Scalars['Int'];
  b: Scalars['Int'];
};

/** AccentRGB contains a creative's associated RGB values */
export type AccentRgbv5 = {
  __typename?: 'AccentRGBV5';
  r: Scalars['Int'];
  g: Scalars['Int'];
  b: Scalars['Int'];
};

export const ActiveDayTypeV5 = {
  Sunday: 'SUNDAY',
  Monday: 'MONDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY',
  Thursday: 'THURSDAY',
  Friday: 'FRIDAY',
  Saturday: 'SATURDAY',
} as const;

export type ActiveDayTypeV5 = typeof ActiveDayTypeV5[keyof typeof ActiveDayTypeV5];
/**
 * An ad account is a hard-budget enforcement object with the highest level of invoice aggregation. It is associated with a marketer organization and controls user roles and permissions, contains brand, industry, time zone, and currency settings as well as audience and creative library associations.
 * Media planners create campaigns on behalf of ad accounts.
 */
export type AdAccountV4 = {
  __typename?: 'AdAccountV4';
  /** The ad account GUID in DAAPI. */
  id: Maybe<Scalars['ID']>;
  /** The ad account type. */
  accessType: Maybe<Scalars['String']>;
  /** The ad account status */
  status: Maybe<Scalars['String']>;
  /** The reason why the ad account was disabled. */
  disabledReason: Maybe<Scalars['String']>;
  /** The ad account name. */
  name: Scalars['String'];
  /** The time zone associated with the ad account. */
  timezone: Scalars['String'];
  /** The ID and other information from the CRM system about agency office responsible for planning opportunities for the ad account. */
  planningAgencyOfficeCrmLink: Maybe<CrmLink>;
  /** The ID and other information from the CRM system about agency office responsible for buying opportunities for the ad account. */
  buyingAgencyOfficeCrmLink: Maybe<CrmLink>;
  /** The ID and other information from the CRM system about the billing account associated with the ad account. */
  billingAccountCrmLink: CrmLink;
  /** The currency associated with the ad account. */
  currency: Scalars['String'];
  /** The budget amount and type */
  budget: Maybe<BudgetV4>;
  /** The ad account fundint source */
  fundingSource: Maybe<Scalars['String']>;
  /** The billing terms to be used for the account's campaigns. */
  billingTerm: Scalars['String'];
  /** The associated organization's ID */
  organizationId: Maybe<Scalars['ID']>;
};

/**
 * An ad account is a hard-budget enforcement object with the highest level of invoice aggregation. It is associated with a marketer organization and controls user roles and permissions, contains brand, industry, time zone, and currency settings as well as audience and creative library associations.
 * Media planners create campaigns on behalf of ad accounts.
 */
export type AdAccountV5 = {
  __typename?: 'AdAccountV5';
  /** The ad account GUID in DAAPI. */
  id: Maybe<Scalars['ID']>;
  /** The ad account type. */
  access: Maybe<Scalars['String']>;
  /** The ad account status */
  status: Maybe<Scalars['String']>;
  /** The reason why the ad account was disabled. */
  disabledReason: Maybe<Scalars['String']>;
  /** The ad account name. */
  name: Scalars['String'];
  /** The time zone associated with the ad account. */
  timezone: Scalars['String'];
  /** The ID and other information from the CRM system about agency office responsible for planning opportunities for the ad account. */
  planningAgencyOfficeCrmLink: Maybe<CrmLink>;
  /** The ID and other information from the CRM system about agency office responsible for buying opportunities for the ad account. */
  buyingAgencyOfficeCrmLink: Maybe<CrmLink>;
  /** The ID and other information from the CRM system about the billing account associated with the ad account. */
  billingAccountCrmLink: CrmLink;
  /** The currency associated with the ad account. */
  currency: Scalars['String'];
  /** The budget amount and type */
  budget: Maybe<BudgetV5>;
  /** The ad account fundint source */
  fundingSource: Maybe<Scalars['String']>;
  /** The billing terms to be used for the account's campaigns. */
  billingTerm: Maybe<Scalars['String']>;
  /** The associated organization's ID */
  organizationId: Maybe<Scalars['ID']>;
};

export type AdBrand = {
  __typename?: 'AdBrand';
  id: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type AdDateHour = {
  __typename?: 'AdDateHour';
  /** The 4-digit year. */
  year: Scalars['Int'];
  /** The month number. */
  month: Scalars['Int'];
  /** The day of the month. */
  day: Scalars['Int'];
  /** The hour of the day. The supported values include integers from 0 through 23, where 0 is the hour from midnight till 1 AM, 1 from 1 AM till 2 AM, and so on. */
  hour: Scalars['Int'];
};

export type AdDateHourInput = {
  year: Scalars['Int'];
  month: Scalars['Int'];
  day: Scalars['Int'];
  hour: Scalars['Int'];
};

export type AdEdgeV5 = Edge & {
  __typename?: 'AdEdgeV5';
  cursor: Scalars['String'];
  node: AdV5;
};

export type AdFilterOptionsV5 = {
  idList?: Maybe<Array<Scalars['ID']>>;
  /**
   * If selected ids list is not empty then query fetches ads by these ids and new ads if needed depends on pagination limit
   * In this case idList and excludeIdList query param will be ignored
   */
  selectedIdList?: Maybe<Array<Scalars['ID']>>;
  /** List of ads that shoud be excluded from response */
  excludeIdList?: Maybe<Array<Scalars['ID']>>;
  adAccountIdList?: Maybe<Array<Scalars['ID']>>;
  lineItemParentLineOmsLinkList?: Maybe<Array<Scalars['ID']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  campaignIdList?: Maybe<Array<Scalars['ID']>>;
  creativeIdList?: Maybe<Array<Scalars['ID']>>;
  lineItemIdList?: Maybe<Array<Scalars['ID']>>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  name?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  lineItemStatusList?: Maybe<Array<Scalars['String']>>;
  lineItemSubStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  includeCampaignName?: Maybe<Scalars['Boolean']>;
  includeLineItemName?: Maybe<Scalars['Boolean']>;
  includeCampaignReviewError?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  creativeName?: Maybe<Scalars['String']>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  yodaPacingStatusList?: Maybe<Array<Scalars['String']>>;
};

export type AdInputV5 = {
  name: Scalars['String'];
  status: AdStatusV5;
  type: AdTypeV5;
  review: AdReviewV5;
  rating?: Maybe<Scalars['String']>;
  lineItemId: Scalars['ID'];
  viewabilityVendorList?: Maybe<Array<ViewabilityVendor>>;
  adTagList?: Maybe<Array<AdTagInput>>;
  sequence?: Maybe<Scalars['Int']>;
  schedule?: Maybe<ScheduleInputV5>;
  frequencyCapList?: Maybe<Array<FrequencyCapInputV5>>;
  targetingTermValues?: Maybe<Array<TargetingTermValueInput>>;
  targetingRule?: Maybe<TargetingRuleInput>;
  weight?: Maybe<Scalars['Float']>;
  creativeId?: Maybe<Scalars['ID']>;
};

export const AdReviewV5 = {
  Draft: 'DRAFT',
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
} as const;

export type AdReviewV5 = typeof AdReviewV5[keyof typeof AdReviewV5];
export type AdRotation = {
  __typename?: 'AdRotation';
  adId: Maybe<Scalars['ID']>;
  weight: Scalars['Float'];
  order: Maybe<Scalars['Int']>;
  rotationId: Maybe<Scalars['ID']>;
  rotationName: Maybe<Scalars['String']>;
};

export type AdRotationInput = {
  adId: Scalars['ID'];
  weight: Scalars['Float'];
  order: Scalars['Int'];
};

export type AdSelectorOption = {
  __typename?: 'AdSelectorOption';
  index: Scalars['Int'];
  thumbnailId: Scalars['ID'];
  thumbnail: Maybe<DisplayAssetV5>;
  adSelectorVideoCreativeId: Scalars['ID'];
  caption: Scalars['String'];
};

export type AdSelectorOptionInput = {
  index: Scalars['Int'];
  thumbnailId: Scalars['ID'];
  adSelectorVideoCreativeId: Scalars['ID'];
  caption: Scalars['String'];
};

export type AdSelectorSlateCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  logoId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  randomOrder?: Maybe<Scalars['Boolean']>;
  voiceoverUrl?: Maybe<Scalars['String']>;
  optionList?: Maybe<Array<AdSelectorOptionInput>>;
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type AdSelectorSlateCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'AdSelectorSlateCreativeV5';
    logo: Maybe<CreativeV5Asset>;
    headline: Maybe<Scalars['String']>;
    randomOrder: Maybe<Scalars['Boolean']>;
    voiceoverUrl: Maybe<Scalars['String']>;
    optionList: Maybe<Array<AdSelectorOption>>;
    /**
     * Creative Manager stores only assets id
     * logoId is being used to handle mapping to BaseAssetV5 in logo
     */
    logoId: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type AdSelectorVideoCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  videoId?: Maybe<Scalars['String']>;
  option?: Maybe<AdSelectorOptionInput>;
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type AdSelectorVideoCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'AdSelectorVideoCreativeV5';
    video: Maybe<CreativeV5Asset>;
    /**
     * Creative Manager stores only assets id
     * videoId is being used to handle mapping to BaseAssetV5 in video
     */
    videoId: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type AdSequence = {
  __typename?: 'AdSequence';
  order: Scalars['Int'];
  adId: Scalars['String'];
  name: Maybe<Scalars['String']>;
  sequenceId: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type AdSequenceInput = {
  order: Scalars['Int'];
  adId: Scalars['String'];
  sequenceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export const AdSortOptionV5 = {
  Id: 'ID',
  Name: 'NAME',
  TrackingId: 'TRACKING_ID',
  CreatedAt: 'CREATED_AT',
  UpdatedAt: 'UPDATED_AT',
  Type: 'TYPE',
  Review: 'REVIEW',
  Status: 'STATUS',
  ScheduleStart: 'SCHEDULE_START',
  ScheduleEnd: 'SCHEDULE_END',
  ScheduleTimezoneOffset: 'SCHEDULE_TIMEZONE_OFFSET',
  LineItemName: 'LINE_ITEM_NAME',
  CampaignName: 'CAMPAIGN_NAME',
  ViewabilityVendorList: 'VIEWABILITY_VENDOR_LIST',
  Sequence: 'SEQUENCE',
  Weight: 'WEIGHT',
} as const;

export type AdSortOptionV5 = typeof AdSortOptionV5[keyof typeof AdSortOptionV5];
export type AdSortOptionsV5 = {
  sort?: Maybe<AdSortOptionV5>;
  order?: Maybe<OrderOption>;
};

export type AdSortOptionsV5Type = {
  __typename?: 'AdSortOptionsV5Type';
  sort: Maybe<AdSortOptionV5>;
  order: Maybe<OrderOption>;
};

export const AdStatusV5 = {
  Off: 'OFF',
  On: 'ON',
} as const;

export type AdStatusV5 = typeof AdStatusV5[keyof typeof AdStatusV5];
export type AdTableFilters = {
  __typename?: 'AdTableFilters';
  searchTerm: Maybe<Scalars['String']>;
  selectedRowIds: Maybe<Array<Scalars['String']>>;
  sortBy: Maybe<AdSortOptionsV5Type>;
};

export type AdTableFiltersInput = {
  searchTerm?: Maybe<Scalars['String']>;
  selectedRowIds?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<AdSortOptionsV5>;
};

export type AdTag = {
  __typename?: 'AdTag';
  id: Scalars['ID'];
  /** The tag type. */
  type: Scalars['String'];
  /** The event when the AdTag will capture impressions. */
  event: Scalars['String'];
  /** The URL used to capture the impression. */
  url: Scalars['String'];
  /** Indicates whether the AdTag URL has been verified. */
  isVerified: Maybe<Scalars['Boolean']>;
  /** Indicates whether it s possible to delete current tag due to its status. */
  unDeletable: Scalars['Boolean'];
  /** Indicates whether the AdTag is IAS */
  isIas: Maybe<Scalars['Boolean']>;
};

export type AdTagInput = {
  id?: Maybe<Scalars['String']>;
  type: AdTagType;
  event: EventType;
  url: Scalars['String'];
  isVerified: Scalars['Boolean'];
  isIas?: Maybe<Scalars['Boolean']>;
};

export const AdTagType = {
  /**
   * Only one BILLABLE AdTag is permitted on each Ad.
   * A BILLABLE AdTag must have a COMPLETE event.
   */
  Billable: 'BILLABLE',
  NonBillable: 'NON_BILLABLE',
  /**
   * For tracking click-through URLs to the advertiser's page.
   * Only one CLICK AdTag is permitted on each Ad.
   * A CLICK AdTag must have a CLICK event.
   */
  Click: 'CLICK',
} as const;

export type AdTagType = typeof AdTagType[keyof typeof AdTagType];
export const AdTypeV5 = {
  Unknown: 'UNKNOWN',
  AdSelector: 'AD_SELECTOR',
  AdSelectorSlate: 'AD_SELECTOR_SLATE',
  AdSelectorVideo: 'AD_SELECTOR_VIDEO',
  Brightline: 'BRIGHTLINE',
  CollectionLogo: 'COLLECTION_LOGO',
  CoverStoryLogo: 'COVER_STORY_LOGO',
  GatewayGo: 'GATEWAY_GO',
  GatewayShop: 'GATEWAY_SHOP',
  HubLogo: 'HUB_LOGO',
  Innovid: 'INNOVID',
  MarqueePromotedContent: 'MARQUEE_PROMOTED_CONTENT',
  MaxSelector: 'MAX_SELECTOR',
  Pause: 'PAUSE',
  PauseQr: 'PAUSE_QR',
  Slate: 'SLATE',
  Video: 'VIDEO',
  BeSelectorSlate: 'BE_SELECTOR_SLATE',
  BeSelectorVideo: 'BE_SELECTOR_VIDEO',
} as const;

export type AdTypeV5 = typeof AdTypeV5[keyof typeof AdTypeV5];
export type AdV5 = Node & {
  __typename?: 'AdV5';
  /** The GUID of the ad in DAAPI. */
  id: Scalars['ID'];
  /** The ad type. */
  type: Scalars['String'];
  /** The ad name in the CFS. */
  name: Scalars['String'];
  /** The ad review status in the CFS. */
  review: Scalars['String'];
  /** The ad rating in the CFS. */
  rating: Scalars['String'];
  /** The ad trafficking status in the CFS. */
  status: Scalars['String'];
  /** Is possible to delete ad. */
  unDeletable: Scalars['Boolean'];
  /** A list of third-party viewability vendors used for the ad. */
  viewabilityVendorList: Array<Scalars['String']>;
  /** The actual start date and time in UTC when the first impression was recorded for this ad. */
  actualStart: Maybe<Scalars['DateTime']>;
  /** The permitted creative types for this ad. */
  permittedCreativeTypeList: Maybe<Array<Scalars['String']>>;
  /** The start date of the ad. If the ad does not have a scheduled start date, defaults to associated line item's start date. */
  startDate: Maybe<Scalars['DateTime']>;
  /** The end date of the ad. If the ad does not have a scheduled end date, defaults to associated line item's end date. */
  endDate: Maybe<Scalars['DateTime']>;
  /** The index that indicates the order of the ad in the line item serving sequence with multiple ads. */
  sequence: Maybe<Scalars['Int']>;
  /** The percentage assigned to the ad, if there are multiple ads associated with the same line item, to optimize delivery toward the client's goal based on delivery progress and other factors for the ad. */
  weight: Maybe<Scalars['Float']>;
  /** The delivery schedule information specific to this ad. If different from the line item, the ad schedule overwrites the line item schedule. */
  schedule: Maybe<ScheduleV5>;
  /** The delivery frequency limit information specific to this ad. If different from the line item, TBD the frequency caps are AND-ed. */
  frequencyCapList: Array<FrequencyCapV5>;
  /** The information about the associated parent line item. */
  lineItem: Maybe<LineItemV5>;
  /** The targeting terms associated with an ad. */
  targetingTermValues: Array<TargetingTermValue>;
  /** The information about the beacons associated with the ad. */
  adTagList: Array<AdTag>;
  /** The rotation and weight with the ad */
  adRotation: Maybe<AdRotation>;
  /** The sequence with the ad */
  adSequence: Maybe<AdSequence>;
  /** The tracking id on the ad */
  trackingId: Maybe<Scalars['String']>;
  /** The information about the creative associated with the ad */
  creative: Maybe<BaseCreativeV5>;
  /** The timestamp for ad's creation. */
  createdAt: Scalars['DateTime'];
  /** The timestamp for ad's update */
  updatedAt: Maybe<Scalars['DateTime']>;
  /** Computed Field: flag that describes if associated lineItem has pacing-risk zero completions */
  hasZeroDeliveryRisk: Maybe<Scalars['Boolean']>;
  /** Flag that shows if ad has a misaligned schedule */
  hasMisalignedSchedule: Maybe<Scalars['Boolean']>;
  /** The publisher targeting - the owner of ads */
  displayPublisherTarget: Maybe<Array<Scalars['String']>>;
  /** The traffickerEmail associated with the ad */
  traffickerEmail: Maybe<Scalars['String']>;
  /** The brand associated with the ad */
  brand: Maybe<AdBrand>;
  /** Flag that describes if vast url for ad's creative was verified */
  isVastUrlVerified: Maybe<Scalars['Boolean']>;
  campaignReviewErrors: Array<CampaignReviewError>;
  customSchedule: Maybe<Scalars['Boolean']>;
  targetingRule: Maybe<TargetingRule>;
};

export type AdsPageV5 = Connection & {
  __typename?: 'AdsPageV5';
  edges: Array<AdEdgeV5>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type Agency = Node & {
  __typename?: 'Agency';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
  indexedAt: Maybe<Scalars['String']>;
};

export type AgencyEdge = Edge & {
  __typename?: 'AgencyEdge';
  cursor: Scalars['String'];
  node: Agency;
};

export type AgencyFilterOptions = {
  name?: Maybe<Scalars['String']>;
  crmLinkTypeList?: Maybe<Array<CrmLinkTypeV5>>;
};

export type AgencyPage = Connection & {
  __typename?: 'AgencyPage';
  edges: Array<AgencyEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type ApproveLineItemsAndCorrelatedAdsResultV5 = {
  __typename?: 'ApproveLineItemsAndCorrelatedAdsResultV5';
  ads: Array<AdV5>;
  lineItems: Array<LineItemV5>;
  errors: Maybe<Array<ResponseError>>;
};

export type AssetAudioDataV5 = {
  __typename?: 'AssetAudioDataV5';
  bitRate: Maybe<Scalars['String']>;
  channels: Maybe<Scalars['Int']>;
  codec: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Int']>;
  language: Maybe<Scalars['String']>;
  sampleRate: Maybe<Scalars['String']>;
  sampleFormat: Maybe<Scalars['String']>;
  trackIndex: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['String']>;
};

export type AssetAudioDataV5Input = {
  bitRate?: Maybe<Scalars['String']>;
  channels?: Maybe<Scalars['Int']>;
  codec?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  sampleFormat?: Maybe<Scalars['String']>;
  sampleRate?: Maybe<Scalars['String']>;
  trackIndex?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export const AssetBrandReview = {
  Pending: 'PENDING',
  Preview: 'PREVIEW',
  Review: 'REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type AssetBrandReview = typeof AssetBrandReview[keyof typeof AssetBrandReview];
export type AssetBrandV5 = {
  __typename?: 'AssetBrandV5';
  review: Maybe<AssetBrandReview>;
  crmLink: Maybe<CrmLink>;
};

export type AssetFiltersV5 = {
  __typename?: 'AssetFiltersV5';
  adTypeList: Maybe<Array<Scalars['String']>>;
  assetReview: Maybe<Array<Scalars['String']>>;
  assignee: Maybe<Array<Scalars['String']>>;
  bidder: Maybe<Array<Scalars['String']>>;
  brandList: Maybe<Array<Scalars['String']>>;
  contentPartner: Maybe<Array<Scalars['String']>>;
  countryCodes: Maybe<Array<Scalars['String']>>;
  createdAt: Maybe<AssetsDateRangeType>;
  creativeType: Maybe<Array<Scalars['String']>>;
  dsp: Maybe<Array<Scalars['String']>>;
  dxc: Maybe<Array<Scalars['String']>>;
  externalCreativeId: Maybe<Array<Scalars['String']>>;
  industry: Maybe<Array<Scalars['String']>>;
  language: Maybe<Array<Scalars['String']>>;
  publisherList: Maybe<Array<Scalars['String']>>;
  reviewedAt: Maybe<AssetsDateRangeType>;
  reviewedTasks: Maybe<ReviewedTasksTableFiltersV5>;
  source: Maybe<Array<Scalars['String']>>;
  spReviewList: Maybe<Array<Scalars['String']>>;
  transcodingStatus: Maybe<Array<Scalars['String']>>;
};

export type AssetInputV5 = {
  assetTagList?: Maybe<Array<AssetTagInputV5>>;
  comments?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  language?: Maybe<Language>;
  name: Scalars['String'];
  rating?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<AssetRejectionReasonV5>>;
  review?: Maybe<ReviewAssetStatusV5>;
  transcodeStatus?: Maybe<TranscodeStatusV5>;
  type: AssetTypeV5;
};

export type AssetMetaDataV5 = {
  __typename?: 'AssetMetaDataV5';
  audio: Maybe<AssetAudioDataV5>;
  duration: Maybe<Scalars['Int']>;
  format: Maybe<Scalars['String']>;
  id: Scalars['String'];
  size: Maybe<Scalars['Int']>;
  video: Maybe<AssetVideoDataV5>;
};

export type AssetMetaDataV5Input = {
  audio?: Maybe<AssetAudioDataV5Input>;
  duration?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  video?: Maybe<AssetVideoDataV5Input>;
};

export const AssetRejectionReasonV5 = {
  AdPoliciesViolation: 'AD_POLICIES_VIOLATION',
  AudioExtraChannels: 'AUDIO_EXTRA_CHANNELS',
  AudioMissing: 'AUDIO_MISSING',
  AudioWrongBitRate: 'AUDIO_WRONG_BIT_RATE',
  AudioWrongCodec: 'AUDIO_WRONG_CODEC',
  AvDurationMismatch: 'AV_DURATION_MISMATCH',
  AvSync: 'AV_SYNC',
  BrandMismatch: 'BRAND_MISMATCH',
  CompetitorCallout: 'COMPETITOR_CALLOUT',
  FileCorrupted: 'FILE_CORRUPTED',
  FileWrongFormat: 'FILE_WRONG_FORMAT',
  FileWrongSize: 'FILE_WRONG_SIZE',
  FramesBlended: 'FRAMES_BLENDED',
  FramesDuplicate: 'FRAMES_DUPLICATE',
  FramesInterlaced: 'FRAMES_INTERLACED',
  FramesWrongRate: 'FRAMES_WRONG_RATE',
  GlitchesOrArtifacts: 'GLITCHES_OR_ARTIFACTS',
  ImpliedInteractivity: 'IMPLIED_INTERACTIVITY',
  Other: 'OTHER',
  SlatePresent: 'SLATE_PRESENT',
  VideoExtraTracks: 'VIDEO_EXTRA_TRACKS',
  VideoWrongBitRate: 'VIDEO_WRONG_BIT_RATE',
  VideoWrongCodec: 'VIDEO_WRONG_CODEC',
  WrongAspectRatio: 'WRONG_ASPECT_RATIO',
  WrongLength: 'WRONG_LENGTH',
  WrongSamplingRate: 'WRONG_SAMPLING_RATE',
  WrongScanType: 'WRONG_SCAN_TYPE',
} as const;

export type AssetRejectionReasonV5 = typeof AssetRejectionReasonV5[keyof typeof AssetRejectionReasonV5];
export type AssetTagInputV5 = {
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AssetTagV5 = Node & {
  __typename?: 'AssetTagV5';
  id: Scalars['ID'];
  description: Maybe<Scalars['String']>;
  displayName: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type AssetTagV5BatchReviewInput = {
  id?: Maybe<Scalars['String']>;
  taggedDate?: Maybe<Scalars['DateTime']>;
};

export type AssetTagV5Input = {
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export const AssetTypeV5 = {
  Video: 'VIDEO',
  Display: 'DISPLAY',
  Text: 'TEXT',
} as const;

export type AssetTypeV5 = typeof AssetTypeV5[keyof typeof AssetTypeV5];
export type AssetVideoDataV5 = {
  __typename?: 'AssetVideoDataV5';
  bitRate: Maybe<Scalars['String']>;
  closedCaptions: Maybe<Scalars['Int']>;
  codec: Maybe<Scalars['String']>;
  colorspace: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  displayAspectRatio: Maybe<Scalars['Int']>;
  duration: Maybe<Scalars['Int']>;
  frameCount: Maybe<Scalars['String']>;
  frameRate: Maybe<Scalars['String']>;
  height: Maybe<Scalars['Int']>;
  language: Maybe<Scalars['String']>;
  pixelAspectRatio: Maybe<Scalars['Int']>;
  scanType: Maybe<Scalars['String']>;
  trackIndex: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Int']>;
};

export type AssetVideoDataV5Input = {
  bitRate?: Maybe<Scalars['String']>;
  closedCaptions?: Maybe<Scalars['Int']>;
  codec?: Maybe<Scalars['String']>;
  colorspace?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  displayAspectRatio?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  frameCount?: Maybe<Scalars['String']>;
  frameRate?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  pixelAspectRatio?: Maybe<Scalars['Int']>;
  scanType?: Maybe<Scalars['String']>;
  trackIndex?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type AssetsDateRangeInput = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type AssetsDateRangeType = {
  __typename?: 'AssetsDateRangeType';
  start: Maybe<Scalars['String']>;
  end: Maybe<Scalars['String']>;
};

export type AssetsEdgeV5 = Edge & {
  __typename?: 'AssetsEdgeV5';
  cursor: Scalars['String'];
  node: BaseAssetV5;
};

export type AssetsFiltersInputV5 = {
  adTypeList?: Maybe<Array<Scalars['String']>>;
  assetReview?: Maybe<Array<Scalars['String']>>;
  assignee?: Maybe<Array<Scalars['String']>>;
  bidder?: Maybe<Array<Scalars['String']>>;
  brandList?: Maybe<Array<Scalars['String']>>;
  contentPartner?: Maybe<Array<Scalars['String']>>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<AssetsDateRangeInput>;
  creativeType?: Maybe<Array<Scalars['String']>>;
  dsp?: Maybe<Array<Scalars['String']>>;
  dxc?: Maybe<Array<Scalars['String']>>;
  externalCreativeId?: Maybe<Array<Scalars['String']>>;
  industry?: Maybe<Array<Scalars['String']>>;
  language?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  reviewedAt?: Maybe<AssetsDateRangeInput>;
  reviewedTasks?: Maybe<ReviewedTasksTableFiltersInputV5>;
  source?: Maybe<Array<Scalars['String']>>;
  spReviewList?: Maybe<Array<Scalars['String']>>;
  transcodingStatus?: Maybe<Array<Scalars['String']>>;
};

export type AssetsPageV5 = Connection & {
  __typename?: 'AssetsPageV5';
  edges: Array<AssetsEdgeV5>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type AudioData = {
  __typename?: 'AudioData';
  codec: Maybe<Scalars['String']>;
  bitRate: Maybe<Scalars['String']>;
  channels: Maybe<Scalars['Float']>;
  duration: Maybe<Scalars['Float']>;
  language: Maybe<Scalars['String']>;
  sampleRate: Maybe<Scalars['String']>;
  trackIndex: Maybe<Scalars['Float']>;
  sampleFormat: Maybe<Scalars['String']>;
};

export type AuditAdV5 = {
  __typename?: 'AuditAdV5';
  id: Scalars['ID'];
  type: Scalars['String'];
  name: Scalars['String'];
  review: Scalars['String'];
  rating: Scalars['String'];
  status: Scalars['String'];
  unDeletable: Scalars['Boolean'];
  viewabilityVendorList: Array<Scalars['String']>;
  actualStart: Maybe<Scalars['DateTime']>;
  permittedCreativeTypeList: Maybe<Array<Scalars['String']>>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  sequence: Maybe<Scalars['Int']>;
  weight: Maybe<Scalars['Float']>;
  schedule: Maybe<ScheduleV5>;
  frequencyCapList: Array<FrequencyCapV5>;
  lineItem: Maybe<LineItemV5>;
  targetingTermValues: Maybe<Array<TargetingTermValue>>;
  adTagList: Array<AdTag>;
  adRotation: Maybe<AdRotation>;
  adSequence: Maybe<AdSequence>;
  trackingId: Maybe<Scalars['String']>;
  creative: Maybe<BaseCreativeV5>;
  createdAt: Scalars['DateTime'];
  updatedAt: Maybe<Scalars['DateTime']>;
  hasZeroDeliveryRisk: Maybe<Scalars['Boolean']>;
  hasMisalignedSchedule: Maybe<Scalars['Boolean']>;
  displayPublisherTarget: Maybe<Array<Scalars['String']>>;
  traffickerEmail: Maybe<Scalars['String']>;
  brand: Maybe<AdBrand>;
  isVastUrlVerified: Maybe<Scalars['Boolean']>;
  campaignReviewErrors: Array<CampaignReviewError>;
  country: Maybe<Scalars['String']>;
  targetingRule: Maybe<TargetingRule>;
};

export type AuditBaseCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'AuditBaseCreativeV5';
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rating: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type AuditLineItemV5 = LineItemV5Body & {
  __typename?: 'AuditLineItemV5';
  id: Scalars['ID'];
  pacingType: Scalars['String'];
  trackingId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tier: LineItemTierV5;
  priority: LineItemPriorityV5;
  review: Scalars['String'];
  status: Scalars['String'];
  subStatus: Maybe<LineItemSubStatusOptionV5>;
  parentLineOmsLink: Maybe<OmsLinkV5>;
  lineOmsLink: Maybe<OmsLinkV5>;
  adProduct: Scalars['String'];
  billableThirdParty: Maybe<Scalars['String']>;
  unitCost: Scalars['Float'];
  unitCostDto: Money;
  costMethod: Maybe<Scalars['String']>;
  replaceable: Scalars['Boolean'];
  secondRoundEligible: Scalars['Boolean'];
  dhxpBackfill: Scalars['Boolean'];
  creativeMaxSeconds: Maybe<Scalars['Int']>;
  exclusiveBlock: Maybe<ExclusiveBlockV5>;
  campaign: Maybe<CampaignV5>;
  lineItemSequenceId: Maybe<Scalars['String']>;
  revenue: Maybe<Scalars['Float']>;
  priorityValue: Maybe<Scalars['Int']>;
  schedule: Maybe<ScheduleV5>;
  frequencyCapList: Array<FrequencyCapV5>;
  deliveryBufferPercent: Maybe<Scalars['Float']>;
  frontLoadPercent: Maybe<Scalars['Float']>;
  deliveryGoal: Maybe<Scalars['Float']>;
  preemptible: Maybe<Scalars['Boolean']>;
  goal: Maybe<Scalars['Float']>;
  isFiller: Maybe<Scalars['Boolean']>;
  podBuyout: Maybe<Scalars['Boolean']>;
  industryExclusive: Maybe<Scalars['Boolean']>;
  metrics: Maybe<Metrics>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  hasZeroDeliveryRisk: Maybe<Scalars['Boolean']>;
  hasMisalignedSchedule: Maybe<Scalars['Boolean']>;
  targetingTermValues: Maybe<Array<TargetingTermValue>>;
  displayPublisherTarget: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru: Maybe<Scalars['Boolean']>;
  isAddedValue: Maybe<Scalars['Boolean']>;
  isMakegood: Maybe<Scalars['Boolean']>;
  orderLineItemDto: Maybe<OrderLineItemV5>;
  deliverableLineItemDto: Maybe<DeliverableLineItemV5>;
  magniteDealDto: Maybe<MagniteDealV5>;
  adList: Maybe<Array<Maybe<AuditRelatedLineItemAd>>>;
  metadata: Maybe<MetadataV5>;
  draxDeal: Maybe<DraxDealV5>;
  campaignReviewErrors: Array<CampaignReviewError>;
  isRotation: Maybe<Scalars['Boolean']>;
  isSequence: Maybe<Scalars['Boolean']>;
  maxCreativeMs: Maybe<Scalars['Float']>;
  country: Maybe<Scalars['String']>;
  targetingRule: Maybe<TargetingRule>;
  latestKnownThreshold: Maybe<LineItemThresholdV5>;
  pacingMetrics: Maybe<LineItemPacingMetrics>;
};

export type AuditRelatedLineItemAd = {
  __typename?: 'AuditRelatedLineItemAd';
  name: Scalars['String'];
  id: Scalars['ID'];
};

export type BaseAssetV5 = {
  /** The asset tags with values attached */
  assetTagList: Maybe<Array<AssetTagV5>>;
  /** The additional notes that detail the asset rejection reason and/or suggesting a course of action. */
  comments: Maybe<Scalars['String']>;
  /** The country codes for the asset */
  countryCodes: Maybe<Array<Maybe<CountryCode>>>;
  /** The UTC date and time when the asset record was created in the CFS. */
  createdAt: Maybe<Scalars['String']>;
  /** The creativeLibraryId as string */
  creativeLibraryId: Maybe<Scalars['String']>;
  /** Boolean if deleted */
  deleted: Maybe<Scalars['Boolean']>;
  /** The externalAssetDto as string */
  externalAssetDto: Maybe<Scalars['String']>;
  /** The filename as string */
  filename: Maybe<Scalars['String']>;
  /** The server-generated size of the asset file. */
  fileSize: Maybe<Scalars['Int']>;
  /** The GUID of the asset in DAAPI. */
  id: Scalars['ID'];
  /** The language as string */
  language: Maybe<Language>;
  /** The server-generated asset file S3 location hash. */
  md5: Scalars['String'];
  /** Metadata for the asset */
  mediaMetadata: Maybe<AssetMetaDataV5>;
  /** The asset name in the CFS. */
  name: Scalars['String'];
  /** The Fonzi outlet key */
  outletKey: Maybe<Scalars['String']>;
  publisher: Maybe<Scalars['String']>;
  /** The rating as string */
  rating: Maybe<Scalars['String']>;
  /** The reason for the asset rejection provided by Quality Control. */
  rejectionReasons: Maybe<Array<AssetRejectionReasonV5>>;
  /** The Quality Control review status of the asset. The "REJECTED" status value requires additional information to be provided in the rejection-reason and comments fields. */
  review: Scalars['String'];
  /** The server-generated URL that identifies the path to the asset in the AWS S3 bucket where it is stored. */
  s3Url: Maybe<Scalars['String']>;
  /** The transcodeFailureDetails as type TranscodingFailure */
  transcodeFailureDetails: Maybe<TranscodingFailure>;
  /** The transcodeStatus as enum TranscodeStatus */
  transcodeStatus: Maybe<TranscodeStatusV5>;
  /** The type of the asset. This field value determines the typeProperties object contents. */
  type: AssetTypeV5;
  /** The UTC date and time when the asset record was updated in the CFS. */
  updatedAt: Maybe<Scalars['DateTime']>;
  approvedBrand: Maybe<CrmLinkV5>;
  approvedPublishers: Maybe<Array<Scalars['String']>>;
};

export type BaseAssetV5Input = {
  assetTagList?: Maybe<Array<AssetTagV5Input>>;
  comments?: Maybe<Scalars['String']>;
  countryCodes: Array<Maybe<CountryCode>>;
  createdAt?: Maybe<Scalars['String']>;
  creativeLibraryId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  externalAssetDto?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: Maybe<Language>;
  mediaMetadata?: Maybe<AssetMetaDataV5Input>;
  md5?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rating?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<Scalars['String']>>;
  s3Url: Scalars['String'];
  transcodeFailureDetails?: Maybe<TranscodingFailureInput>;
  transcodeStatus?: Maybe<Scalars['String']>;
  type: AssetTypeV5;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BaseChange = {
  /**
   * The change's field name. Used for determining the oldValue and newValue
   * ChangeValue types.
   */
  fieldName: Scalars['String'];
};

export type BaseChangeValue = {
  action: ChangeAction;
};

export type BaseCreativeEdgeV5 = Edge & {
  __typename?: 'BaseCreativeEdgeV5';
  cursor: Scalars['String'];
  node: BaseCreativeV5;
};

export type BaseCreativeFilterOptionsV5 = {
  name?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  type?: Maybe<Array<CreativeV5Type>>;
  idList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BaseCreativePageV5 = Connection & {
  __typename?: 'BaseCreativePageV5';
  edges: Array<BaseCreativeEdgeV5>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type BaseCreativeV5 = {
  /** The GUID of the creative in DAAPI. */
  id: Scalars['ID'];
  /** The type of the creative. */
  type: CreativeV5Type;
  metadata: Maybe<CreativeMetadata>;
  /** The name of the creative in the CFS. */
  name: Scalars['String'];
  /** The status of the creative. */
  review: Maybe<Scalars['String']>;
  /** The rating of the creative. */
  rating: Maybe<Scalars['String']>;
  /** The creative rejection reason. */
  rejectionReason: Maybe<Scalars['String']>;
  /** The full list of rejection reasons  */
  rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
  /** The creative qrEmbedded */
  qrEmbedded: Maybe<Scalars['Boolean']>;
  /** The status of the creative. */
  status: Scalars['String'];
  /** The UTC date and time when the creative record was created in the CFS. */
  createdAt: Scalars['DateTime'];
  /** The UTC date and time when the creative record was updated in the CFS. */
  updatedAt: Maybe<Scalars['DateTime']>;
  /** The creative deletion status. */
  deleted: Scalars['Boolean'];
  /** The Creative Library ID */
  creativeLibraryId: Maybe<Scalars['String']>;
  /** The Creative Library asset tag list */
  assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
};

export type BaseDateRangeInputV5 = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type BaseDateRangeV5 = {
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
};

export type BaseTargetingValue = Node &
  TargetingValueBody & {
    __typename?: 'BaseTargetingValue';
    id: Scalars['ID'];
    name: Scalars['String'];
    displayName: Scalars['String'];
    primarySource: TargetingSource;
    additionalSource: Array<TargetingSource>;
    description: Maybe<Scalars['String']>;
    attributeMap: Maybe<TargetingAttributeMap>;
  };

export type BatchReviewAssetInputV5 = {
  assetTagList?: Maybe<Array<AssetTagV5BatchReviewInput>>;
  brandName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  countryCodes?: Maybe<Array<CountryCode>>;
  language?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<AssetRejectionReasonV5>>;
  review?: Maybe<ReviewAssetStatusV5>;
  type: AssetTypeV5;
};

export type BatchReviewAssetResponseV5 = {
  __typename?: 'BatchReviewAssetResponseV5';
  failed: Maybe<Array<BatchReviewAssetResult>>;
  successful: Maybe<Array<BatchReviewAssetResult>>;
};

export type BatchReviewAssetResult = {
  __typename?: 'BatchReviewAssetResult';
  id: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
};

export type Brand = Node & {
  __typename?: 'Brand';
  adomains: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  industry: Maybe<Scalars['String']>;
  industryId: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  sourceSystemId: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type BrandEdge = Edge & {
  __typename?: 'BrandEdge';
  cursor: Scalars['String'];
  node: Brand;
};

export type BrandFilterOptions = {
  idList?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
};

/** The Brand List object holds the brand id. */
export type BrandIdV5 = {
  /** The id associated with the brand. */
  id?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['String']>;
};

export type BrandsPage = Connection & {
  __typename?: 'BrandsPage';
  edges: Array<BrandEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type BrightlineCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  videoAssetIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  featureList: Array<DaapiFeatureInput>;
  url: Scalars['String'];
  template: Scalars['String'];
  vpaid: Scalars['Boolean'];
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type BrightlineCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'BrightlineCreativeV5';
    url: Maybe<Scalars['String']>;
    vpaid: Maybe<Scalars['Boolean']>;
    videoAssetList: Maybe<Array<CreativeV5Asset>>;
    /**
     * Creative Manager stores only assets id
     * videoAssetIdList is being used to handle mapping to BaseAssetV5 in videoAssetList
     */
    videoAssetIdList: Maybe<Array<Scalars['String']>>;
    template: Maybe<Scalars['String']>;
    featureList: Maybe<Array<Maybe<DaapiFeature>>>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export const BrightlineFeature = {
  EOverlay: 'E_OVERLAY',
  CtfsInfoGallery: 'CTFS_INFO_GALLERY',
  CtfsVideoGallery: 'CTFS_VIDEO_GALLERY',
  MultiFeature: 'MULTI_FEATURE',
  Rfi: 'RFI',
  StoreLocator: 'STORE_LOCATOR',
  TCommerce: 'T_COMMERCE',
} as const;

export type BrightlineFeature = typeof BrightlineFeature[keyof typeof BrightlineFeature];
export const BrightlineTemplate = {
  EOverlay: 'E_OVERLAY',
  CtfsInfoGallery: 'CTFS_INFO_GALLERY',
  CtfsVideoGallery: 'CTFS_VIDEO_GALLERY',
  MultiFeature: 'MULTI_FEATURE',
  Rfi: 'RFI',
  StoreLocator: 'STORE_LOCATOR',
  TCommerce: 'T_COMMERCE',
  MaxSelector: 'MAX_SELECTOR',
} as const;

export type BrightlineTemplate = typeof BrightlineTemplate[keyof typeof BrightlineTemplate];
export type BrightlineTypePropertiesInputV5 = {
  url: Scalars['String'];
  template: BrightlineTemplate;
  featureList: Array<BrightlineFeature>;
};

export type BrightlineTypePropertiesV5 = {
  __typename?: 'BrightlineTypePropertiesV5';
  url: Scalars['String'];
  template: BrightlineTemplate;
  featureList: Array<BrightlineFeature>;
};

export type BudgetV4 = {
  __typename?: 'BudgetV4';
  amount: Maybe<Scalars['Float']>;
  type: Maybe<Scalars['String']>;
};

export type BudgetV5 = {
  __typename?: 'BudgetV5';
  amount: Maybe<Scalars['Float']>;
  type: Maybe<Scalars['String']>;
};

export type BufferInput = {
  id: Scalars['String'];
  value: Scalars['Float'];
};

export const CrmLinkTypeV5 = {
  Unknown: 'UNKNOWN',
  SfdcBrand: 'SFDC_BRAND',
  SfdcAgency: 'SFDC_AGENCY',
  SfdcAgencyOffice: 'SFDC_AGENCY_OFFICE',
  SfdcPlanningAgencyOffice: 'SFDC_PLANNING_AGENCY_OFFICE',
  SfdcBuyingAgencyOffice: 'SFDC_BUYING_AGENCY_OFFICE',
  SfdcAdvertiser: 'SFDC_ADVERTISER',
  SfdcAdAccount: 'SFDC_AD_ACCOUNT',
} as const;

export type CrmLinkTypeV5 = typeof CrmLinkTypeV5[keyof typeof CrmLinkTypeV5];
export type CampaignEdgeV5 = Edge & {
  __typename?: 'CampaignEdgeV5';
  cursor: Scalars['String'];
  node: CampaignV5;
};

export type CampaignFilterOptionsV5 = {
  idList?: Maybe<Array<Scalars['ID']>>;
  /**
   * If selected ids list is not empty then query fetches campaigns by these ids and new campaigns if needed depends on pagination limit
   * In this case idList and excludeIdList query param will be ignored
   */
  selectedIdList?: Maybe<Array<Scalars['ID']>>;
  /** List of campaigns that shoud be excluded from response */
  excludeIdList?: Maybe<Array<Scalars['ID']>>;
  omsOrderIdList?: Maybe<Array<Scalars['ID']>>;
  adAccountIdList?: Maybe<Array<Scalars['ID']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  lineItemIdList?: Maybe<Array<Scalars['ID']>>;
  adIdList?: Maybe<Array<Scalars['ID']>>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  name?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  lineItemStatusList?: Maybe<Array<Scalars['String']>>;
  lineItemSubStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  yodaPacingStatusList?: Maybe<Array<Scalars['String']>>;
};

export type CampaignInputV5 = {
  traffickerEmail: Scalars['String'];
};

export type CampaignReviewError = {
  __typename?: 'CampaignReviewError';
  id: Scalars['String'];
  name: Scalars['String'];
  type: CampaignReviewErrorType;
  resolved: Maybe<Scalars['Boolean']>;
};

export const CampaignReviewErrorAction = {
  Create: 'CREATE',
  Delete: 'DELETE',
} as const;

export type CampaignReviewErrorAction = typeof CampaignReviewErrorAction[keyof typeof CampaignReviewErrorAction];
export type CampaignReviewErrorInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  relatedEntityId: Scalars['String'];
  traffickerEmail: Scalars['String'];
  type: CampaignReviewErrorType;
  action: CampaignReviewErrorAction;
  resolved?: Maybe<Scalars['Boolean']>;
};

export const CampaignReviewErrorType = {
  AdError: 'AD_ERROR',
  LineItemError: 'LINE_ITEM_ERROR',
  TagError: 'TAG_ERROR',
  TargetingError: 'TARGETING_ERROR',
} as const;

export type CampaignReviewErrorType = typeof CampaignReviewErrorType[keyof typeof CampaignReviewErrorType];
export const CampaignReviewStatus = {
  Draft: 'DRAFT',
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
} as const;

export type CampaignReviewStatus = typeof CampaignReviewStatus[keyof typeof CampaignReviewStatus];
export const CampaignSortOptionV5 = {
  Id: 'ID',
  Name: 'NAME',
  CreatedAt: 'CREATED_AT',
  UpdatedAt: 'UPDATED_AT',
  OrderOmsLinkId: 'ORDER_OMS_LINK_ID',
  AdAccountId: 'AD_ACCOUNT_ID',
  BrandId: 'BRAND_ID',
  TraffickerEmail: 'TRAFFICKER_EMAIL',
  Marketplace: 'MARKETPLACE',
  OrderType: 'ORDER_TYPE',
  NielsenRangeList: 'NIELSEN_RANGE_LIST',
  Origin: 'ORIGIN',
  Start: 'START',
  End: 'END',
  TrackingId: 'TRACKING_ID',
  OrderSalespersonEmail: 'ORDER_SALESPERSON_EMAIL',
} as const;

export type CampaignSortOptionV5 = typeof CampaignSortOptionV5[keyof typeof CampaignSortOptionV5];
export type CampaignSortOptionsV5 = {
  sort?: Maybe<CampaignSortOptionV5>;
  order?: Maybe<OrderOption>;
};

export type CampaignSortOptionsV5Type = {
  __typename?: 'CampaignSortOptionsV5Type';
  sort: Maybe<CampaignSortOptionV5>;
  order: Maybe<OrderOption>;
};

export const CampaignStatusV5 = {
  Ready: 'READY',
  Live: 'LIVE',
  Late: 'LATE',
  Ended: 'ENDED',
  DidNotRun: 'DID_NOT_RUN',
  Off: 'OFF',
  Paused: 'PAUSED',
  Cancelled: 'CANCELLED',
} as const;

export type CampaignStatusV5 = typeof CampaignStatusV5[keyof typeof CampaignStatusV5];
export type CampaignTableFilters = {
  __typename?: 'CampaignTableFilters';
  searchTerm: Maybe<Scalars['String']>;
  selectedRowIds: Maybe<Array<Scalars['String']>>;
  sortBy: Maybe<CampaignSortOptionsV5Type>;
};

export type CampaignTableFiltersInput = {
  searchTerm?: Maybe<Scalars['String']>;
  selectedRowIds?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<CampaignSortOptionsV5>;
};

export type CampaignTagV5 = {
  __typename?: 'CampaignTagV5';
  id: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  isVerified: Maybe<Scalars['Boolean']>;
};

/** A single-purpose execution of advertising to reach a client objective. To optimize and measure results for each advertising objective, a campaign may have multiple line items. */
export type CampaignV5 = Node & {
  __typename?: 'CampaignV5';
  /** The campaign UUID in DAAPI, which is returned upon a successful creation request. */
  id: Scalars['ID'];
  /** The campaign name in the CFS. */
  name: Scalars['String'];
  /** The marketer ad account with which the campaign is associated. */
  adAccount: Maybe<AdAccountV5>;
  /** The advertiser account with which the campaign is associated. */
  advertiser: Maybe<Scalars['String']>;
  /** The agency account with which the campaign is associated. */
  agency: Maybe<Scalars['String']>;
  /** The budget for this campaign. */
  budget: Maybe<Scalars['Float']>;
  /** The ID and other information from the CRM system about the ad account. */
  crmLink: Maybe<CrmLink>;
  /** A list of campaign tags that this campaign is tagged with, such as nielsen tag */
  campaignTagList: Maybe<Array<CampaignTagV5>>;
  /** Computed Field: flag that describes if associated lineItem has pacing-risk zero completions */
  hasZeroDeliveryRisk: Maybe<Scalars['Boolean']>;
  /** The line items associated with the campaign. */
  lineItemList: Maybe<Array<LineItemV5>>;
  /** The line items attached to campaign object */
  campaignLineItems: Array<CampaignV5LineItem>;
  /** The unique ID and other data from the order management system for the order associated with the campaign. */
  orderOmsLink: Maybe<OmsLinkV5>;
  /** The order type for legacy compatibility. */
  orderType: Maybe<Scalars['String']>;
  /** The tracking id of a campaign */
  trackingId: Maybe<Scalars['String']>;
  /** The email address of the campaign lead that trafficked the campaign. */
  traffickerEmail: Scalars['String'];
  /** Name of the account industry. */
  industryName: Maybe<Scalars['String']>;
  /** The order object from Order Manager */
  order: Maybe<OrderV5>;
  /** Computed Field: The start date and time of the earliest-starting line item, with the time starting at midnight based on the specified time zone (EST, CST, MST, PST, AKST, HST). */
  startDate: Maybe<Scalars['DateTime']>;
  /** Computed Field: The end date and time of the last-ending line item based on the specified time zone (EST, CST, MST, PST, AKST, or HST). */
  endDate: Maybe<Scalars['DateTime']>;
  /** The timestamp for the campaign's creation. */
  createdAt: Scalars['DateTime'];
  /** The timestamp for the last time the campaign was updated. */
  updatedAt: Scalars['DateTime'];
  /** Indicates whether the campaign has been deleted. */
  deleted: Maybe<Scalars['Boolean']>;
  /** Corresponding trafficker id */
  traffickerId: Maybe<Scalars['String']>;
  /** Computed Field: flag that describes if campaign contains 1+ Line Item(s) with schedule conflicts */
  hasMisalignedSchedule: Maybe<Scalars['Boolean']>;
  /** Represents Campaign status that is derived from Line Item status */
  status: Maybe<CampaignStatusV5>;
};

export type CampaignV5LineItem = {
  __typename?: 'CampaignV5LineItem';
  id: Scalars['ID'];
  country: Maybe<Scalars['String']>;
};

export type CampaignV5PatchResponse = {
  __typename?: 'CampaignV5PatchResponse';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  trackingId: Maybe<Scalars['String']>;
  orderOmsLink: Maybe<OmsLinkV5>;
  traffickerEmail: Scalars['String'];
  campaignTagList: Maybe<Array<CampaignTagV5>>;
  adAccount: Maybe<AdAccountV5>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deleted: Maybe<Scalars['Boolean']>;
};

export type CampaignsPageV5 = Connection & {
  __typename?: 'CampaignsPageV5';
  edges: Array<CampaignEdgeV5>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const ChangeAction = {
  /** The associated change value payload includes only additions to existing values */
  Addition: 'ADDITION',
  /** The associated change value payload includes only updates to existing values */
  Update: 'UPDATE',
  /** The associated change value payload includes only removals of existing values */
  Removal: 'REMOVAL',
} as const;

export type ChangeAction = typeof ChangeAction[keyof typeof ChangeAction];
export type ChangeLog = Connection & {
  __typename?: 'ChangeLog';
  edges: Array<ChangeLogEntryEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** A GraphQL representation of DAAPI audits for Mission Control's Change Log */
export type ChangeLogEntry = {
  __typename?: 'ChangeLogEntry';
  /** The objectId of the original Audit object */
  id: Scalars['ID'];
  /** The user that made the changes to the DAAPI Ad Object */
  modifiedBy: Maybe<Scalars['String']>;
  /** The date and time the change was made */
  modifiedDate: Scalars['DateTime'];
  /** A list of all changes made to the ad object at the modified date time */
  changeList: Array<BaseChange>;
  /** Current state of object which changelog we requested */
  object: ChangeLogUnionObject;
};

export type ChangeLogEntryEdge = Edge & {
  __typename?: 'ChangeLogEntryEdge';
  cursor: Scalars['String'];
  node: ChangeLogNode;
};

export type ChangeLogNode = Node & {
  __typename?: 'ChangeLogNode';
  /** The id of the original Audit object */
  id: Scalars['ID'];
  /** The audits for changelog */
  audits: Maybe<ChangeLogEntry>;
  /** The array of audits for creatives */
  creativeAudits: Maybe<Array<Maybe<ChangeLogEntry>>>;
  /** State object comparison with old vs new definition arrays */
  targetingChanges: Maybe<TargetingChangesMap>;
};

export type ChangeLogUnionObject = CampaignV5 | AuditAdV5 | AuditLineItemV5 | AuditBaseCreativeV5;

export const ChangeType = {
  UserAddedToRole: 'USER_ADDED_TO_ROLE',
  UserRemovedFromRole: 'USER_REMOVED_FROM_ROLE',
} as const;

export type ChangeType = typeof ChangeType[keyof typeof ChangeType];
export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type ContactCrmLink = {
  __typename?: 'ContactCrmLink';
  crmId: Scalars['String'];
  url: Scalars['String'];
  type: Scalars['String'];
};

export type ContentPartner = Node & {
  __typename?: 'ContentPartner';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type ContentPartnerEdge = Edge & {
  __typename?: 'ContentPartnerEdge';
  cursor: Scalars['String'];
  node: ContentPartner;
};

export type ContentPartnerPage = Connection & {
  __typename?: 'ContentPartnerPage';
  edges: Array<ContentPartnerEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type ContentPartnerV5 = {
  __typename?: 'ContentPartnerV5';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type ContentPartnersFilterOptions = {
  name?: Maybe<Scalars['String']>;
  idList?: Maybe<Array<Scalars['String']>>;
};

export const ContentPartnersSortEnum = {
  Name: 'NAME',
} as const;

export type ContentPartnersSortEnum = typeof ContentPartnersSortEnum[keyof typeof ContentPartnersSortEnum];
export type ContentPartnersSortOption = {
  order?: Maybe<OrderOption>;
  sort?: Maybe<ContentPartnersSortEnum>;
};

export const CountryCode = {
  Us: 'US',
  Ca: 'CA',
  Dk: 'DK',
  Fr: 'FR',
  De: 'DE',
  It: 'IT',
  No: 'NO',
  Es: 'ES',
  Se: 'SE',
  Ch: 'CH',
  Gb: 'GB',
  Tr: 'TR',
  Ar: 'AR',
  Br: 'BR',
  Cl: 'CL',
  Co: 'CO',
  Mx: 'MX',
  Nl: 'NL',
  Fi: 'FI',
  Pt: 'PT',
} as const;

export type CountryCode = typeof CountryCode[keyof typeof CountryCode];
export type CountryFilterOption = {
  valueId: Scalars['String'];
};

export type CreativeApprovals = {
  __typename?: 'CreativeApprovals';
  approvedAdUuids: Array<Scalars['String']>;
  adUuidsWithApprovedVastUrl: Array<Scalars['String']>;
};

export type CreativeMetadata = {
  __typename?: 'CreativeMetadata';
  countryCodes: Maybe<Array<CountryCode>>;
  createdAt: Scalars['DateTime'];
  creativeId: Scalars['ID'];
  deleted: Scalars['Boolean'];
  isLegalApproved: Scalars['Boolean'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type CreativeMetadataEdge = {
  __typename?: 'CreativeMetadataEdge';
  cursor: Scalars['String'];
  node: CreativeMetadata;
};

export type CreativeMetadataFilterOptions = {
  creativeIdList?: Maybe<Array<Scalars['String']>>;
};

export type CreativeMetadataPage = {
  __typename?: 'CreativeMetadataPage';
  edges: Array<CreativeMetadataEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type CreativeReviewTaskV5 = ReviewTaskV5 &
  Node & {
    __typename?: 'CreativeReviewTaskV5';
    id: Scalars['ID'];
    asset: Maybe<BaseAssetV5>;
    assetId: Scalars['String'];
    brandList: Maybe<Array<Maybe<CrmLink>>>;
    brands: Maybe<Array<Brand>>;
    createdAt: Scalars['DateTime'];
    creativeSubmittedUserId: Maybe<Scalars['String']>;
    derivedSpReview: Maybe<SpReviewV5>;
    displayName: Maybe<Scalars['String']>;
    reviewedAt: Maybe<Scalars['DateTime']>;
    reviewUserId: Maybe<Scalars['String']>;
    source: Maybe<Scalars['String']>;
    spReview: Maybe<SpReviewV5>;
    startedAt: Maybe<Scalars['DateTime']>;
    taskStatus: Maybe<ReviewTaskV5Status>;
    transcodeMetadata: Maybe<TranscodeMetadataV5>;
    type: Maybe<ReviewTaskV5Type>;
    updatedAt: Maybe<Scalars['DateTime']>;
    adTypeList: Maybe<Array<Maybe<AdTypeV5>>>;
    creative: Maybe<ReviewTaskV5Creative>;
    creativeId: Maybe<Scalars['ID']>;
    creativeType: Maybe<CreativeV5Type>;
    linkedReviewTasks: Maybe<Array<CreativeReviewTaskV5>>;
    fonziReviewTask: Maybe<Scalars['Boolean']>;
  };

export type CreativeTypePropertiesV5 =
  | BrightlineTypePropertiesV5
  | DisplayTypePropertiesV5
  | InnovidTypePropertiesV5
  | PauseTypePropertiesV5
  | SlateTypePropertiesV5
  | VastVideoTypePropertiesV5
  | VideoTypePropertiesV5;

/** Creative asset which contains: assetId and the asset associated with that ID */
export type CreativeV5Asset = {
  __typename?: 'CreativeV5Asset';
  id: Scalars['ID'];
  asset: Maybe<BaseAssetV5>;
};

export const CreativeV5Status = {
  Active: 'ACTIVE',
  Archived: 'ARCHIVED',
} as const;

export type CreativeV5Status = typeof CreativeV5Status[keyof typeof CreativeV5Status];
export const CreativeV5Type = {
  AdSelectorSlate: 'AD_SELECTOR_SLATE',
  AdSelectorVideo: 'AD_SELECTOR_VIDEO',
  Brightline: 'BRIGHTLINE',
  Drax: 'DRAX',
  Innovid: 'INNOVID',
  GatewayGo: 'GATEWAY_GO',
  Magnite: 'MAGNITE',
  Pause: 'PAUSE',
  Slate: 'SLATE',
  VastVideo: 'VAST_VIDEO',
  Video: 'VIDEO',
  InstreamDxc: 'INSTREAM_DXC',
} as const;

export type CreativeV5Type = typeof CreativeV5Type[keyof typeof CreativeV5Type];
/** The CrmLink object is a link object that holds the associated opportunity information from the CRM system. */
export type CrmLink = {
  __typename?: 'CrmLink';
  /** The ID in the CRM system of the opportunity associated with the campaign, for example, the 18-alphanumeric-character ID in Salesforce. */
  id: Maybe<Scalars['String']>;
  /** The name of the CRM data object. */
  name: Maybe<Scalars['String']>;
  /** The type of data from the CRM system. */
  type: Maybe<Scalars['String']>;
  /** The URL of the opportunity page in the CRM system. */
  url: Maybe<Scalars['String']>;
  /** The Industry */
  industry: Maybe<Scalars['String']>;
  /** The Industry ID */
  industryId: Maybe<Scalars['String']>;
};

export type CrmLinkV5 = {
  __typename?: 'CrmLinkV5';
  id: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  type: Maybe<CrmLinkTypeV5>;
};

export type CrmLinkV5Input = {
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<CrmLinkTypeV5>;
};

export const Currency = {
  Usd: 'USD',
  Ars: 'ARS',
  Brl: 'BRL',
  Clp: 'CLP',
  Cop: 'COP',
  Mxn: 'MXN',
} as const;

export type Currency = typeof Currency[keyof typeof Currency];
/** @deprecated -- CustomerBrand is for V4; Use "Brand" instead for V5 and beyond */
export type CustomerBrand = Node & {
  __typename?: 'CustomerBrand';
  /** The id of the brand. */
  id: Scalars['ID'];
  /** The name of the brand. */
  name: Maybe<Scalars['String']>;
  /** The industry name of the brand. */
  industry: Maybe<Scalars['String']>;
  /** The industry id of the brand. */
  industryId: Maybe<Scalars['String']>;
  /** The id of the brand's source system. */
  sourceSystemId: Maybe<Scalars['String']>;
  /** A list of domains for the brand. */
  adomains: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomerBrandDetail = {
  __typename?: 'CustomerBrandDetail';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type CustomerBrandDetails = {
  __typename?: 'CustomerBrandDetails';
  brand: Maybe<CustomerBrandDetail>;
};

export type CustomerBrandFilterOptions = {
  idList?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
};

export type CustomerBrands = {
  __typename?: 'CustomerBrands';
  /** The number of brands returned. */
  returnCount: Scalars['Int'];
  /** An array of CustomerBrand objects. */
  brands: Maybe<Array<Maybe<CustomerBrand>>>;
};

export type DaapiFeature = {
  __typename?: 'DAAPIFeature';
  type: Scalars['String'];
};

export type DaapiFeatureInput = {
  type: Scalars['String'];
};

export type DateFilter = {
  __typename?: 'DateFilter';
  label: Scalars['String'];
  value: Maybe<ScheduleFilterV5>;
};

export type DateFilterInput = {
  label: Scalars['String'];
  value?: Maybe<ScheduleFilterInputV5>;
};

export type DateRangeInputV5 = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  pacingShare?: Maybe<Scalars['Float']>;
};

export type DateRangeV5 = BaseDateRangeV5 & {
  __typename?: 'DateRangeV5';
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  pacingShare: Maybe<Scalars['Float']>;
};

export type DayPartInputV5 = {
  firstActiveHour?: Maybe<Scalars['Int']>;
  lastActiveHour?: Maybe<Scalars['Int']>;
  activeDayList: Array<ActiveDayTypeV5>;
};

export type DayPartV5 = {
  __typename?: 'DayPartV5';
  firstActiveHour: Maybe<Scalars['Int']>;
  lastActiveHour: Maybe<Scalars['Int']>;
  activeDayList: Array<ActiveDayTypeV5>;
};

export type DeliverableLineItemV5 = {
  __typename?: 'DeliverableLineItemV5';
  parentLineOmsLink: Maybe<OmsLinkV5>;
  operativeOmsLink: Maybe<OmsLinkV5>;
  lineOmsLink: Maybe<OmsLinkV5>;
  packageName: Scalars['String'];
  buyerName: Maybe<Scalars['String']>;
  buyerId: Maybe<Scalars['String']>;
  isBilledInFull: Maybe<Scalars['Boolean']>;
  nonBillableThirdPartyServers: Maybe<Array<Scalars['String']>>;
};

export type DeliveryBufferPercentV5 = {
  __typename?: 'DeliveryBufferPercentV5';
  value: Maybe<Scalars['Float']>;
  mutable: Scalars['Boolean'];
};

export const DemographicDimension = {
  Age: 'AGE',
  Gender: 'GENDER',
} as const;

export type DemographicDimension = typeof DemographicDimension[keyof typeof DemographicDimension];
export type DetectionResultV5 = {
  __typename?: 'DetectionResultV5';
  duplicatedFrameDetector: Maybe<DetectorV5>;
  videoQualityDetector: Maybe<DetectorV5>;
};

export type DetectorStatusV5 = {
  __typename?: 'DetectorStatusV5';
  duplicatedFrameDetector: Maybe<Scalars['String']>;
  videoQualityDetector: Maybe<Scalars['String']>;
};

export type DetectorV5 = {
  __typename?: 'DetectorV5';
  details: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
};

export type DisplayAssetV5 = BaseAssetV5 &
  Node & {
    __typename?: 'DisplayAssetV5';
    assetTagList: Maybe<Array<AssetTagV5>>;
    comments: Maybe<Scalars['String']>;
    countryCodes: Maybe<Array<Maybe<CountryCode>>>;
    createdAt: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    deleted: Maybe<Scalars['Boolean']>;
    externalAssetDto: Maybe<Scalars['String']>;
    filename: Maybe<Scalars['String']>;
    fileSize: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    language: Maybe<Language>;
    publisher: Maybe<Scalars['String']>;
    md5: Scalars['String'];
    mediaMetadata: Maybe<AssetMetaDataV5>;
    name: Scalars['String'];
    outletKey: Maybe<Scalars['String']>;
    rating: Maybe<Scalars['String']>;
    rejectionReasons: Maybe<Array<AssetRejectionReasonV5>>;
    review: Scalars['String'];
    s3Url: Maybe<Scalars['String']>;
    transcodeFailureDetails: Maybe<TranscodingFailure>;
    transcodeStatus: Maybe<TranscodeStatusV5>;
    type: AssetTypeV5;
    updatedAt: Maybe<Scalars['DateTime']>;
    approvedBrand: Maybe<CrmLinkV5>;
    approvedPublishers: Maybe<Array<Scalars['String']>>;
    height: Maybe<Scalars['Int']>;
    kinkoKey: Maybe<Scalars['String']>;
    width: Maybe<Scalars['Int']>;
  };

export type DisplayTypePropertiesInputV5 = {
  image: IdInputV5;
  altText: Scalars['String'];
  accent?: Maybe<AccentRgbInputV5>;
};

export type DisplayTypePropertiesV5 = {
  __typename?: 'DisplayTypePropertiesV5';
  image: CreativeV5Asset;
  altText: Scalars['String'];
  accent: Maybe<AccentRgbv5>;
};

export type DraxCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  url: Scalars['String'];
  vpaid: Scalars['Boolean'];
  creativeLibraryId?: Maybe<Scalars['String']>;
  videoAssetIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DraxCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'DraxCreativeV5';
    url: Maybe<Scalars['String']>;
    vpaid: Maybe<Scalars['Boolean']>;
    videoAssetList: Maybe<Array<CreativeV5Asset>>;
    /**
     * Creative Manager stores only assets id
     * videoAssetIdList is being used to handle mapping to BaseAssetV5 in videoAssetList
     */
    videoAssetIdList: Maybe<Array<Scalars['String']>>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type DraxDealV5 = {
  __typename?: 'DraxDealV5';
  packageId: Maybe<Scalars['ID']>;
  packageName: Maybe<Scalars['String']>;
  dealId: Maybe<Scalars['ID']>;
  dealName: Maybe<Scalars['String']>;
  dspId: Maybe<Scalars['ID']>;
};

export type DspsAssetEdgeV5 = Edge & {
  __typename?: 'DspsAssetEdgeV5';
  cursor: Scalars['String'];
  node: DspsAssetV5;
};

export type DspsAssetFilterOptionsInputV5 = {
  bidder?: Maybe<DspsBidderInput>;
};

export type DspsAssetV5 = Node & {
  __typename?: 'DspsAssetV5';
  id: Scalars['ID'];
  dspName: Scalars['String'];
  dspCode: Scalars['String'];
  dspUuid: Scalars['String'];
  bidderUuid: Scalars['String'];
  abbreviation: Scalars['String'];
};

export type DspsAssetsPageV5 = {
  __typename?: 'DspsAssetsPageV5';
  edges: Array<DspsAssetEdgeV5>;
  pageInfo: Maybe<PageInfo>;
  total: Scalars['Int'];
};

export const DspsBidderInput = {
  Magnite: 'MAGNITE',
  DspDirect: 'DSP_DIRECT',
} as const;

export type DspsBidderInput = typeof DspsBidderInput[keyof typeof DspsBidderInput];
export type DxcCreativeV5 = {
  id: Scalars['ID'];
  type: CreativeV5Type;
  metadata: Maybe<CreativeMetadata>;
  name: Scalars['String'];
  review: Maybe<Scalars['String']>;
  rejectionReason: Maybe<Scalars['String']>;
  rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
  qrEmbedded: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Maybe<Scalars['DateTime']>;
  deleted: Scalars['Boolean'];
  rating: Maybe<Scalars['String']>;
  creativeLibraryId: Maybe<Scalars['String']>;
  assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  dmlTemplate: Scalars['String'];
  experienceId: Scalars['ID'];
  experienceType: Scalars['String'];
  dxcExecutableList: Array<DxcExecutable>;
  templateAssetIdList: Array<Scalars['String']>;
  templateAssetList: Array<CreativeV5Asset>;
};

export type DxcExecutable = {
  id: Scalars['ID'];
  subClass: Maybe<Scalars['String']>;
};

export type DxcExternalAssetV5 = ExternalAssetV5 &
  Node & {
    __typename?: 'DxcExternalAssetV5';
    id: Scalars['ID'];
    brand: Maybe<CrmLinkV5>;
    timing: Maybe<ExternalAssetV5Timing>;
    type: Maybe<ExternalAssetV5Type>;
    url: Maybe<Scalars['String']>;
  };

export type Edge = {
  cursor: Scalars['String'];
  node: Node;
};

export type EmailUmpDxcExecutable = DxcExecutable &
  UmpDxcExecutable & {
    __typename?: 'EmailUmpDxcExecutable';
    id: Scalars['ID'];
    messageConfigId: Scalars['ID'];
    subjectLine: Scalars['String'];
    bodyDescription: Scalars['String'];
    callToActionUrl: Scalars['String'];
    senderDetails: Scalars['String'];
    offerImageUrl: Scalars['String'];
    callToActionText: Scalars['String'];
    subClass: Scalars['String'];
  };

export const EntityFilterOptionV4 = {
  MarketerOrganization: 'MARKETER_ORGANIZATION',
  PublisherOrganization: 'PUBLISHER_ORGANIZATION',
  AdAccount: 'AD_ACCOUNT',
} as const;

export type EntityFilterOptionV4 = typeof EntityFilterOptionV4[keyof typeof EntityFilterOptionV4];
export const EntityType = {
  Campaign: 'CAMPAIGN',
  LineItem: 'LINE_ITEM',
  Ad: 'AD',
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];
export const EventType = {
  Start: 'START',
  Firstquartile: 'FIRSTQUARTILE',
  Midpoint: 'MIDPOINT',
  Thirdquartile: 'THIRDQUARTILE',
  Complete: 'COMPLETE',
  Click: 'CLICK',
} as const;

export type EventType = typeof EventType[keyof typeof EventType];
export type ExclusiveBlockV5 = {
  __typename?: 'ExclusiveBlockV5';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  lineItemIdList: Array<Scalars['String']>;
  blockedIndustryList: Maybe<Array<Industry>>;
  targetingTermValues: Maybe<Array<TargetingTermValue>>;
};

export type ExternalAssetReviewTaskV5 = ReviewTaskV5 &
  Node & {
    __typename?: 'ExternalAssetReviewTaskV5';
    id: Scalars['ID'];
    asset: Maybe<BaseAssetV5>;
    assetId: Scalars['String'];
    brandList: Maybe<Array<Maybe<CrmLink>>>;
    brands: Maybe<Array<Brand>>;
    createdAt: Scalars['DateTime'];
    creativeSubmittedUserId: Maybe<Scalars['String']>;
    derivedSpReview: Maybe<SpReviewV5>;
    displayName: Maybe<Scalars['String']>;
    reviewedAt: Maybe<Scalars['DateTime']>;
    reviewUserId: Maybe<Scalars['String']>;
    source: Maybe<Scalars['String']>;
    spReview: Maybe<SpReviewV5>;
    startedAt: Maybe<Scalars['DateTime']>;
    taskStatus: Maybe<ReviewTaskV5Status>;
    transcodeMetadata: Maybe<TranscodeMetadataV5>;
    type: Maybe<ReviewTaskV5Type>;
    updatedAt: Maybe<Scalars['DateTime']>;
    externalAssetId: Maybe<Scalars['ID']>;
    externalAsset: Maybe<ExternalAssetV5>;
    fonziReviewTask: Maybe<Scalars['Boolean']>;
  };

export type ExternalAssetV5 = {
  id: Scalars['ID'];
  brand: Maybe<CrmLinkV5>;
  timing: Maybe<ExternalAssetV5Timing>;
  type: Maybe<ExternalAssetV5Type>;
  url: Maybe<Scalars['String']>;
};

export type ExternalAssetV5ChildAsset = {
  assetTagList?: Maybe<Array<AssetTagV5Input>>;
  comments?: Maybe<Scalars['String']>;
  countryCodes: Array<Maybe<CountryCode>>;
  createdAt?: Maybe<Scalars['String']>;
  creativeLibraryId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  externalAssetDto?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: Maybe<Language>;
  md5?: Maybe<Scalars['String']>;
  mediaMetadata?: Maybe<AssetMetaDataV5Input>;
  name: Scalars['String'];
  rating?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<Scalars['String']>>;
  review?: Maybe<Scalars['String']>;
  s3Url: Scalars['String'];
  transcodeFailureDetails?: Maybe<TranscodingFailureInput>;
  transcodeStatus?: Maybe<Scalars['String']>;
  type: AssetTypeV5;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExternalAssetV5FilterOptionsInput = {
  type?: Maybe<Scalars['String']>;
  sourceAssetId?: Maybe<Scalars['String']>;
};

export type ExternalAssetV5Input = {
  type: ExternalAssetV5Type;
  url: Scalars['String'];
  timing: ExternalAssetV5Timing;
  brand?: Maybe<CrmLinkV5Input>;
  countryCodes: Array<Maybe<CountryCode>>;
  sourceAdId?: Maybe<Scalars['String']>;
  mediaKey?: Maybe<Scalars['String']>;
  source?: Maybe<ExternalAssetV5Source>;
  bidderId?: Maybe<Scalars['String']>;
  dspId?: Maybe<Scalars['String']>;
  asset: BaseAssetV5Input;
  sourceAssetId?: Maybe<Scalars['String']>;
};

export type ExternalAssetV5SortOptionsInput = {
  order?: Maybe<OrderOption>;
  sort?: Maybe<Scalars['String']>;
};

export const ExternalAssetV5Source = {
  Freewheel: 'FREEWHEEL',
  Google: 'GOOGLE',
  GoogleOAndO: 'GOOGLE_O_AND_O',
} as const;

export type ExternalAssetV5Source = typeof ExternalAssetV5Source[keyof typeof ExternalAssetV5Source];
export const ExternalAssetV5Timing = {
  Dynamic: 'DYNAMIC',
  PreIngest: 'PRE_INGEST',
} as const;

export type ExternalAssetV5Timing = typeof ExternalAssetV5Timing[keyof typeof ExternalAssetV5Timing];
export const ExternalAssetV5Type = {
  Import: 'IMPORT',
  Partner: 'PARTNER',
  Programmatic: 'PROGRAMMATIC',
  SelfServe: 'SELF_SERVE',
  Dxc: 'DXC',
} as const;

export type ExternalAssetV5Type = typeof ExternalAssetV5Type[keyof typeof ExternalAssetV5Type];
export type ExternalAssetsAssetInput = {
  __typename?: 'ExternalAssetsAssetInput';
  language: Maybe<Language>;
  countryCodes: Array<Maybe<CountryCode>>;
};

export type ExternalAssetsEdgeV5 = Edge & {
  __typename?: 'ExternalAssetsEdgeV5';
  cursor: Scalars['String'];
  node: ExternalAssetV5;
};

export type ExternalAssetsPageV5 = Connection & {
  __typename?: 'ExternalAssetsPageV5';
  edges: Array<ExternalAssetsEdgeV5>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type FeeLineItem = {
  __typename?: 'FeeLineItem';
  childLineOmsLink: OmsLinkV5;
  fee: Scalars['Float'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export const FrequencyCapDurationUnitV5 = {
  Unknown: 'UNKNOWN',
  Minute: 'MINUTE',
  Hour: 'HOUR',
  Day: 'DAY',
} as const;

export type FrequencyCapDurationUnitV5 = typeof FrequencyCapDurationUnitV5[keyof typeof FrequencyCapDurationUnitV5];
export type FrequencyCapInputV5 = {
  type: FrequencyCapTypeV5;
  limit: Scalars['Int'];
  enabled: Scalars['Boolean'];
  duration: Scalars['Int'];
  durationUnit: FrequencyCapDurationUnitV5;
};

export type FrequencyCapTagInputV5 = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type FrequencyCapTagV5 = {
  __typename?: 'FrequencyCapTagV5';
  id: Scalars['String'];
  name: Scalars['String'];
};

export const FrequencyCapTypeV5 = {
  Brand: 'BRAND',
  Ad: 'AD',
  LineItem: 'LINE_ITEM',
  Tag: 'TAG',
} as const;

export type FrequencyCapTypeV5 = typeof FrequencyCapTypeV5[keyof typeof FrequencyCapTypeV5];
/**
 * The FrequencyCap object holds information about the ad frequency delivery limits, which are defined as the number of ads shown to a given user per number of minutes, days, or weeks. Frequency caps can be set at the line item and ad levels.
 * For example, a 2x60 frequency cap indicates that a maximum of 2 ads can be served within 60 minutes, while a 4x24 frequency cap indicates that a maximum of 4 ads can be served within 24 hours.
 */
export type FrequencyCapV5 = {
  __typename?: 'FrequencyCapV5';
  /** The frequency cap type. */
  type: FrequencyCapTypeV5;
  /** The maximum number of ads of the same industry or brand that can be served with this frequency cap, for example, 2 in the 2x60 frequency cap. */
  limit: Scalars['Int'];
  /** The duration for the frequency cap */
  duration: Maybe<Scalars['Int']>;
  /** The frequency cap unit. */
  durationUnit: FrequencyCapDurationUnitV5;
  /** enabled Flag */
  enabled: Maybe<Scalars['Boolean']>;
};

export type FrontLoadPercentV5 = {
  __typename?: 'FrontLoadPercentV5';
  value: Maybe<Scalars['Float']>;
  mutable: Scalars['Boolean'];
};

export type GatewayGoCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  offerImageId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  livingRoomCopy?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailBody?: Maybe<Scalars['String']>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
  destinationUrl?: Maybe<Scalars['String']>;
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type GatewayGoCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'GatewayGoCreativeV5';
    offerImage: Maybe<CreativeV5Asset>;
    video: Maybe<CreativeV5Asset>;
    livingRoomCopy: Maybe<Scalars['String']>;
    emailSubject: Maybe<Scalars['String']>;
    emailBody: Maybe<Scalars['String']>;
    pushTitle: Maybe<Scalars['String']>;
    pushBody: Maybe<Scalars['String']>;
    destinationUrl: Maybe<Scalars['String']>;
    /**
     * Creative Manager stores only assets id
     * offerImageId and videoId is being used to handle mapping to BaseAssetV5 in offerImage and video
     */
    offerImageId: Maybe<Scalars['String']>;
    videoId: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export const GeneralPacingStatus = {
  Delivered: 'DELIVERED',
  UnderDelivered: 'UNDER_DELIVERED',
  Cancelled: 'CANCELLED',
  DidNotRun: 'DID_NOT_RUN',
  UnderPacing: 'UNDER_PACING',
  OverPacing: 'OVER_PACING',
  OnPace: 'ON_PACE',
  NotEven: 'NOT_EVEN',
} as const;

export type GeneralPacingStatus = typeof GeneralPacingStatus[keyof typeof GeneralPacingStatus];
export type GetChangelogFilterOptions = {
  dateRange?: Maybe<BaseDateRangeInputV5>;
};

export type GetRolesFilterOptions = {
  name?: Maybe<Scalars['String']>;
};

export type GetUserFilterOptions = {
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<SearchTerms>;
  excludeIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  idList?: Maybe<Array<Maybe<Scalars['String']>>>;
  roleName?: Maybe<Scalars['String']>;
  updatedFromMs?: Maybe<Scalars['String']>;
  updatedToMs?: Maybe<Scalars['String']>;
};

export type GetUserRoleChangeLogsFilterOptions = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  groupingDurationInMinutes?: Maybe<Scalars['Int']>;
  lastKey?: Maybe<Scalars['String']>;
  lastEvaluatedDate?: Maybe<Scalars['DateTime']>;
  size?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type GroupingDateRange = {
  __typename?: 'GroupingDateRange';
  fromDate: Maybe<Scalars['DateTime']>;
  toDate: Maybe<Scalars['DateTime']>;
};

/** IDInput is a reusable input type for an input field object with a single ID value */
export type IdInputV5 = {
  id: Scalars['ID'];
};

export type ImportExternalAssetV5 = ExternalAssetV5 &
  Node & {
    __typename?: 'ImportExternalAssetV5';
    id: Scalars['ID'];
    brand: Maybe<CrmLinkV5>;
    timing: Maybe<ExternalAssetV5Timing>;
    type: Maybe<ExternalAssetV5Type>;
    url: Maybe<Scalars['String']>;
  };

export type IndustriesFilterOptions = {
  name?: Maybe<Scalars['String']>;
  idList?: Maybe<Array<Scalars['String']>>;
};

export const IndustriesSortEnum = {
  Name: 'NAME',
  Industry: 'INDUSTRY',
} as const;

export type IndustriesSortEnum = typeof IndustriesSortEnum[keyof typeof IndustriesSortEnum];
export type IndustriesSortOption = {
  order?: Maybe<OrderOption>;
  sort?: Maybe<IndustriesSortEnum>;
};

export type Industry = Node & {
  __typename?: 'Industry';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type IndustryEdge = Edge & {
  __typename?: 'IndustryEdge';
  cursor: Scalars['String'];
  node: Industry;
};

export type IndustryFilterOptions = {
  name?: Maybe<Scalars['String']>;
};

export type IndustryPage = Connection & {
  __typename?: 'IndustryPage';
  edges: Array<IndustryEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const IngestionJobStatus = {
  Pending: 'PENDING',
  VastFetching: 'VAST_FETCHING',
  Ingesting: 'INGESTING',
  Complete: 'COMPLETE',
  Aborted: 'ABORTED',
  NoJobs: 'NO_JOBS',
} as const;

export type IngestionJobStatus = typeof IngestionJobStatus[keyof typeof IngestionJobStatus];
export const IngestionStatus = {
  Ingesting: 'INGESTING',
  IngestionComplete: 'INGESTION_COMPLETE',
  AssetCreationFailed: 'ASSET_CREATION_FAILED',
  AssetBindingFailed: 'ASSET_BINDING_FAILED',
  InvalidTrial: 'INVALID_TRIAL',
  DuplicatedAsset: 'DUPLICATED_ASSET',
  VastParsingErrorMandatoryElementMissing: 'VAST_PARSING_ERROR_MANDATORY_ELEMENT_MISSING',
  VastParsingErrorVpaidIssue: 'VAST_PARSING_ERROR_VPAID_ISSUE',
  VastParsingErrorTrackingIssue: 'VAST_PARSING_ERROR_TRACKING_ISSUE',
  VastParsingErrorCompanionIssue: 'VAST_PARSING_ERROR_COMPANION_ISSUE',
  VastParsingErrorMediaFileIssue: 'VAST_PARSING_ERROR_MEDIA_FILE_ISSUE',
  DownloadFailed: 'DOWNLOAD_FAILED',
  AssetValidationErrorInvalidCodec: 'ASSET_VALIDATION_ERROR_INVALID_CODEC',
  AssetValidationErrorIncorrectFramerate: 'ASSET_VALIDATION_ERROR_INCORRECT_FRAMERATE',
  AssetValidationErrorVideoTrackIssue: 'ASSET_VALIDATION_ERROR_VIDEO_TRACK_ISSUE',
  AssetValidationErrorAudioTrackIssue: 'ASSET_VALIDATION_ERROR_AUDIO_TRACK_ISSUE',
} as const;

export type IngestionStatus = typeof IngestionStatus[keyof typeof IngestionStatus];
export type IngestionTrace = Node & {
  __typename?: 'IngestionTrace';
  id: Scalars['ID'];
  vastUrl: Scalars['String'];
  jobStatus: IngestionJobStatus;
  trials: Array<Maybe<IngestionTrial>>;
  updatedAt: Scalars['DateTime'];
};

export type IngestionTraceEdge = Edge & {
  __typename?: 'IngestionTraceEdge';
  cursor: Scalars['String'];
  node: IngestionTrace;
};

export type IngestionTracesPage = Connection & {
  __typename?: 'IngestionTracesPage';
  edges: Array<IngestionTraceEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type IngestionTrial = {
  __typename?: 'IngestionTrial';
  assetKey: Maybe<Scalars['String']>;
  assetId: Maybe<Scalars['ID']>;
  mediaFileUrl: Maybe<Scalars['String']>;
  s3Url: Maybe<Scalars['String']>;
  trialStatus: IngestionStatus;
  detail: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InnovidCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  videoAssetIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  featureList: Array<DaapiFeatureInput>;
  url: Scalars['String'];
  template: Scalars['String'];
  vpaid: Scalars['Boolean'];
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type InnovidCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'InnovidCreativeV5';
    url: Maybe<Scalars['String']>;
    vpaid: Maybe<Scalars['Boolean']>;
    videoAssetList: Maybe<Array<CreativeV5Asset>>;
    /**
     * Creative Manager stores only assets id
     * videoAssetIdList is being used to handle mapping to BaseAssetV5 in videoAssetList
     */
    videoAssetIdList: Maybe<Array<Scalars['String']>>;
    template: Maybe<Scalars['String']>;
    featureList: Maybe<Array<Maybe<DaapiFeature>>>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export const InnovidFeature = {
  GalleryImage: 'GALLERY_IMAGE',
  GalleryVideo: 'GALLERY_VIDEO',
  LocalizationDynamicGeo: 'LOCALIZATION_DYNAMIC_GEO',
  SmsTv_2Mobile: 'SMS_TV_2_MOBILE',
  Weather: 'WEATHER',
  QrCode: 'QR_CODE',
  DynamicRemote: 'DYNAMIC_REMOTE',
} as const;

export type InnovidFeature = typeof InnovidFeature[keyof typeof InnovidFeature];
export const InnovidTemplate = {
  Canvas: 'CANVAS',
  Endcard: 'ENDCARD',
  Expandable: 'EXPANDABLE',
  Overlay: 'OVERLAY',
  TestInnovid: 'TEST_INNOVID',
} as const;

export type InnovidTemplate = typeof InnovidTemplate[keyof typeof InnovidTemplate];
export type InnovidTypePropertiesInputV5 = {
  url: Scalars['String'];
  template: InnovidTemplate;
  featureList: Array<InnovidFeature>;
};

export type InnovidTypePropertiesV5 = {
  __typename?: 'InnovidTypePropertiesV5';
  url: Scalars['String'];
  template: InnovidTemplate;
  featureList: Array<InnovidFeature>;
};

export type InstreamDxcCreativeV5 = DxcCreativeV5 &
  BaseCreativeV5 &
  Node & {
    __typename?: 'InstreamDxcCreativeV5';
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
    dmlTemplate: Scalars['String'];
    experienceId: Scalars['ID'];
    experienceType: Scalars['String'];
    dxcExecutableList: Array<DxcExecutable>;
    templateAssetList: Array<CreativeV5Asset>;
    templateAssetIdList: Array<Scalars['String']>;
    video: Maybe<CreativeV5Asset>;
    /**
     * Creative Manager stores only asset id
     * videoAssetId is being used to handle mapping to BaseAssetV5 in video
     */
    videoAssetId: Scalars['String'];
  };

export type KervDxcExecutable = DxcExecutable & {
  __typename?: 'KervDxcExecutable';
  id: Scalars['ID'];
  kervContentId: Scalars['String'];
  subClass: Scalars['String'];
};

export type LabelV5 = {
  __typename?: 'LabelV5';
  value: Maybe<Scalars['String']>;
};

export type LabelV5Input = {
  value?: Maybe<Scalars['String']>;
};

export const Language = {
  English: 'ENGLISH',
  Spanish: 'SPANISH',
  Danish: 'DANISH',
  French: 'FRENCH',
  German: 'GERMAN',
  Italian: 'ITALIAN',
  Norwegian: 'NORWEGIAN',
  Swedish: 'SWEDISH',
  Portuguese: 'PORTUGUESE',
  Dutch: 'DUTCH',
  Finnish: 'FINNISH',
  Turkish: 'TURKISH',
} as const;

export type Language = typeof Language[keyof typeof Language];
export type LatestIngestionStatus = {
  __typename?: 'LatestIngestionStatus';
  jobStatus: IngestionJobStatus;
  assetStatus: Array<IngestionTrial>;
};

export type LegacyCompanionV5 = {
  __typename?: 'LegacyCompanionV5';
  placementId: Maybe<Scalars['Int']>;
  creativeId: Maybe<Scalars['Int']>;
};

export const LineItemAdProductV5 = {
  Unknown: 'UNKNOWN',
  AdSelector: 'AD_SELECTOR',
  AdSelectorSlate: 'AD_SELECTOR_SLATE',
  AdSelectorVideo: 'AD_SELECTOR_VIDEO',
  BingeAdFree: 'BINGE_AD_FREE',
  BingeReward: 'BINGE_REWARD',
  BingeSlate: 'BINGE_SLATE',
  BingeTeaser: 'BINGE_TEASER',
  BingeVideo: 'BINGE_VIDEO',
  Brightline: 'BRIGHTLINE',
  CollectionLogo: 'COLLECTION_LOGO',
  CoverStoryLogo: 'COVER_STORY_LOGO',
  GatewayGo: 'GATEWAY_GO',
  GatewayShop: 'GATEWAY_SHOP',
  HubLogo: 'HUB_LOGO',
  Innovid: 'INNOVID',
  Marquee: 'MARQUEE',
  MaxSelector: 'MAX_SELECTOR',
  Pause: 'PAUSE',
  Slate: 'SLATE',
  Video: 'VIDEO',
  HouseVideo: 'HOUSE_VIDEO',
  BeSelector: 'BE_SELECTOR',
} as const;

export type LineItemAdProductV5 = typeof LineItemAdProductV5[keyof typeof LineItemAdProductV5];
export type LineItemEdgeV5 = Edge & {
  __typename?: 'LineItemEdgeV5';
  cursor: Scalars['String'];
  node: LineItemV5;
};

export type LineItemFilterOptionsV5 = {
  idList?: Maybe<Array<Scalars['ID']>>;
  /**
   * If selected ids list is not empty then query fetches line items by these ids and new line items if needed depends on pagination limit
   * In this case idList and excludeIdList query param will be ignored
   */
  selectedIdList?: Maybe<Array<Scalars['ID']>>;
  /** List of line items that should be excluded from response */
  excludeIdList?: Maybe<Array<Scalars['ID']>>;
  adAccountIdList?: Maybe<Array<Scalars['ID']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  campaignIdList?: Maybe<Array<Scalars['ID']>>;
  adIdList?: Maybe<Array<Scalars['ID']>>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  adPotentialTypeList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  name?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  statusList?: Maybe<Array<Scalars['String']>>;
  subStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  includeCampaignName?: Maybe<Scalars['Boolean']>;
  includeCampaignReviewError?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  yodaPacingStatusList?: Maybe<Array<Scalars['String']>>;
};

export type LineItemPacingMetrics = {
  __typename?: 'LineItemPacingMetrics';
  yodaPacingStatus: Maybe<LineItemYodaPacingStatus>;
  generalPacingStatus: Maybe<GeneralPacingStatus>;
  yodaThreshold: Maybe<Scalars['Int']>;
};

export type LineItemPacingTypeInputV5 = {
  id: Scalars['ID'];
  pacingType: Scalars['String'];
};

export type LineItemPriorityInputV5 = {
  name?: Maybe<Scalars['String']>;
};

export type LineItemPriorityV5 = {
  __typename?: 'LineItemPriorityV5';
  name: Scalars['String'];
  rank: Scalars['Int'];
};

export const LineItemSortOptionV5 = {
  Id: 'ID',
  Name: 'NAME',
  TrackingId: 'TRACKING_ID',
  CreatedAt: 'CREATED_AT',
  UpdatedAt: 'UPDATED_AT',
  ScheduleTimezoneOffset: 'SCHEDULE_TIMEZONE_OFFSET',
  ScheduleStart: 'SCHEDULE_START',
  ScheduleEnd: 'SCHEDULE_END',
  Review: 'REVIEW',
  Status: 'STATUS',
  Priority: 'PRIORITY',
  Tier: 'TIER',
  AdProduct: 'AD_PRODUCT',
  UnitCost: 'UNIT_COST',
  ParentOmsLinkId: 'PARENT_OMS_LINK_ID',
  LineOmsLinkId: 'LINE_OMS_LINK_ID',
  CostMethod: 'COST_METHOD',
  BillableThirdParty: 'BILLABLE_THIRD_PARTY',
  CreativeMaxSeconds: 'CREATIVE_MAX_SECONDS',
  DeliveryBufferPercent: 'DELIVERY_BUFFER_PERCENT',
  FrontLoadPercent: 'FRONT_LOAD_PERCENT',
  Goal: 'GOAL',
  DeliveryGoal: 'DELIVERY_GOAL',
  Revenue: 'REVENUE',
  CampaignName: 'CAMPAIGN_NAME',
  DeliveryType: 'DELIVERY_TYPE',
  MetricsCompletions: 'METRICS_COMPLETIONS',
  MetricsCompletionsAtRisk: 'METRICS_COMPLETIONS_AT_RISK',
  MetricsRevenueAtRisk: 'METRICS_REVENUE_AT_RISK',
  MetricsPercentCompletions: 'METRICS_PERCENT_COMPLETIONS',
  MetricsPercentPacing: 'METRICS_PERCENT_PACING',
  MetricsPacingStatus: 'METRICS_PACING_STATUS',
  MetricsUnderPacingRevenue: 'METRICS_UNDER_PACING_REVENUE',
  MetricsPercentExpected: 'METRICS_PERCENT_EXPECTED',
  MetricsRemainingExpectedCompletionsAcc: 'METRICS_REMAINING_EXPECTED_COMPLETIONS_ACC',
  DeliverableLineItemPackageName: 'DELIVERABLE_LINE_ITEM_PACKAGE_NAME',
  DeliverableLineItemBuyerId: 'DELIVERABLE_LINE_ITEM_BUYER_ID',
  DeliverableLineItemBuyerName: 'DELIVERABLE_LINE_ITEM_BUYER_NAME',
  MagniteDealDealName: 'MAGNITE_DEAL_DEAL_NAME',
  ActualStartDate: 'ACTUAL_START_DATE',
  CampaignTraffickerEmail: 'CAMPAIGN_TRAFFICKER_EMAIL',
  CampaignOrderSalespersonEmail: 'CAMPAIGN_ORDER_SALESPERSON_EMAIL',
  Currency: 'CURRENCY',
  LatestKnownThreshold: 'LATEST_KNOWN_THRESHOLD',
  MagniteDealDspName: 'MAGNITE_DEAL_DSP_NAME',
  GeneralPacingStatus: 'GENERAL_PACING_STATUS',
  YodaPacingStatus: 'YODA_PACING_STATUS',
  YodaThreshold: 'YODA_THRESHOLD',
} as const;

export type LineItemSortOptionV5 = typeof LineItemSortOptionV5[keyof typeof LineItemSortOptionV5];
export type LineItemSortOptionsV5 = {
  sort?: Maybe<LineItemSortOptionV5>;
  order?: Maybe<OrderOption>;
};

export type LineItemSortOptionsV5Type = {
  __typename?: 'LineItemSortOptionsV5Type';
  sort: Maybe<LineItemSortOptionV5>;
  order: Maybe<OrderOption>;
};

export const LineItemStatusV5 = {
  Off: 'OFF',
  On: 'ON',
  Paused: 'PAUSED',
  Cancelled: 'CANCELLED',
} as const;

export type LineItemStatusV5 = typeof LineItemStatusV5[keyof typeof LineItemStatusV5];
export const LineItemSubStatusOptionV5 = {
  Ready: 'READY',
  Live: 'LIVE',
  Late: 'LATE',
  Ended: 'ENDED',
  DidNotRun: 'DID_NOT_RUN',
} as const;

export type LineItemSubStatusOptionV5 = typeof LineItemSubStatusOptionV5[keyof typeof LineItemSubStatusOptionV5];
export type LineItemTableFilters = {
  __typename?: 'LineItemTableFilters';
  searchTerm: Maybe<Scalars['String']>;
  selectedRowIds: Maybe<Array<Scalars['String']>>;
  sortBy: Maybe<LineItemSortOptionsV5Type>;
};

export type LineItemTableFiltersInput = {
  searchTerm?: Maybe<Scalars['String']>;
  selectedRowIds?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<LineItemSortOptionsV5>;
};

export type LineItemTargetingValueBody = {
  __typename?: 'LineItemTargetingValueBody';
  lineItemId: Scalars['ID'];
  targetingTermValues: Array<TargetingTermValue>;
};

export type LineItemThresholdV5 = {
  __typename?: 'LineItemThresholdV5';
  publisher: Scalars['String'];
  percentage: Maybe<Scalars['Int']>;
};

export type LineItemTierInputV5 = {
  name?: Maybe<Scalars['String']>;
};

export type LineItemTierV5 = {
  __typename?: 'LineItemTierV5';
  huluCommitment: Scalars['String'];
  name: Scalars['String'];
  rank: Scalars['Int'];
};

export type LineItemV5 = LineItemV5Body &
  Node & {
    __typename?: 'LineItemV5';
    id: Scalars['ID'];
    pacingType: Scalars['String'];
    trackingId: Maybe<Scalars['String']>;
    name: Scalars['String'];
    tier: LineItemTierV5;
    priority: LineItemPriorityV5;
    review: Scalars['String'];
    status: Scalars['String'];
    subStatus: Maybe<LineItemSubStatusOptionV5>;
    parentLineOmsLink: Maybe<OmsLinkV5>;
    lineOmsLink: Maybe<OmsLinkV5>;
    childLineOmsLink: Maybe<OmsLinkV5>;
    adProduct: Scalars['String'];
    billableThirdParty: Maybe<Scalars['String']>;
    /** @deprecated Use unitCostDto */
    unitCost: Scalars['Float'];
    unitCostDto: Money;
    costMethod: Maybe<Scalars['String']>;
    replaceable: Scalars['Boolean'];
    secondRoundEligible: Scalars['Boolean'];
    dhxpBackfill: Scalars['Boolean'];
    creativeMaxSeconds: Maybe<Scalars['Int']>;
    exclusiveBlock: Maybe<ExclusiveBlockV5>;
    campaign: Maybe<CampaignV5>;
    lineItemSequenceId: Maybe<Scalars['String']>;
    revenue: Maybe<Scalars['Float']>;
    priorityValue: Maybe<Scalars['Int']>;
    schedule: Maybe<ScheduleV5>;
    frequencyCapList: Array<FrequencyCapV5>;
    deliveryBufferPercent: Maybe<Scalars['Float']>;
    frontLoadPercent: Maybe<Scalars['Float']>;
    deliveryGoal: Maybe<Scalars['Float']>;
    preemptible: Maybe<Scalars['Boolean']>;
    goal: Maybe<Scalars['Float']>;
    isFiller: Maybe<Scalars['Boolean']>;
    podBuyout: Maybe<Scalars['Boolean']>;
    industryExclusive: Maybe<Scalars['Boolean']>;
    metrics: Maybe<Metrics>;
    startDate: Maybe<Scalars['DateTime']>;
    endDate: Maybe<Scalars['DateTime']>;
    createdAt: Maybe<Scalars['DateTime']>;
    updatedAt: Maybe<Scalars['DateTime']>;
    hasZeroDeliveryRisk: Maybe<Scalars['Boolean']>;
    hasMisalignedSchedule: Maybe<Scalars['Boolean']>;
    targetingTermValues: Maybe<Array<TargetingTermValue>>;
    displayPublisherTarget: Maybe<Array<Scalars['String']>>;
    targetingRule: Maybe<TargetingRule>;
    isCoppaOrCaru: Maybe<Scalars['Boolean']>;
    orderLineItemDto: Maybe<OrderLineItemV5>;
    deliverableLineItemDto: Maybe<DeliverableLineItemV5>;
    magniteDealDto: Maybe<MagniteDealV5>;
    metadata: Maybe<MetadataV5>;
    isMakegood: Maybe<Scalars['Boolean']>;
    isAddedValue: Maybe<Scalars['Boolean']>;
    draxDeal: Maybe<DraxDealV5>;
    campaignReviewErrors: Array<CampaignReviewError>;
    maxCreativeMs: Maybe<Scalars['Float']>;
    country: Maybe<Scalars['String']>;
    latestKnownThreshold: Maybe<LineItemThresholdV5>;
    pacingMetrics: Maybe<LineItemPacingMetrics>;
    inventoryBlockId: Maybe<Scalars['String']>;
  };

/** A line item is the primary unit of execution for an order, which represents a specific inventory purchase and the required conditions for ad delivery. It is a measurable ad delivery for a cost with a set of applicable targeting rules, schedule, frequency caps, delivery goals, and other options. */
export type LineItemV5Body = {
  /** The line item GUID in DAAPI. */
  id: Scalars['ID'];
  /** The line item pacing type . */
  pacingType: Scalars['String'];
  /** The tracking id for a line item */
  trackingId: Maybe<Scalars['String']>;
  /** The line item name in the CFS. */
  name: Scalars['String'];
  /**
   * The line item delivery tier in the CFS.
   * modified
   */
  tier: LineItemTierV5;
  /** The line item priority. */
  priority: LineItemPriorityV5;
  /** The line item review status in the CFS. */
  review: Scalars['String'];
  /** The line item control status in the CFS. */
  status: Scalars['String'];
  /** The line item control sub status in the CFS. */
  subStatus: Maybe<LineItemSubStatusOptionV5>;
  /** The associated parent line item ID in the order management system, for example, Operative 1. */
  parentLineOmsLink: Maybe<OmsLinkV5>;
  /** The associated child line item ID in the order management system, for example, Operative 1. */
  lineOmsLink: Maybe<OmsLinkV5>;
  /** The ad product associated with the line item. */
  adProduct: Scalars['String'];
  /** The third-party server used for measurement and billing. */
  billableThirdParty: Maybe<Scalars['String']>;
  /**
   * The cost in micro-currency per billable event based on the method specified in the costMethod field.
   * @deprecated Use unitCostDto
   */
  unitCost: Scalars['Float'];
  /** The cost in micro-currency per billable event based on the method specified in the costMethod field and currency type.  */
  unitCostDto: Money;
  /** The cost method used for the line item. The supported values include: "FLAT" and "FIXED_CPM" */
  costMethod: Maybe<Scalars['String']>;
  /** The line item replaceable */
  replaceable: Scalars['Boolean'];
  /** The line item secondRoundElibible */
  secondRoundEligible: Scalars['Boolean'];
  /** The line item dxhpBackfill */
  dhxpBackfill: Maybe<Scalars['Boolean']>;
  /** The maximum duration of the creative in seconds. */
  creativeMaxSeconds: Maybe<Scalars['Int']>;
  /** The Exclusive Block */
  exclusiveBlock: Maybe<ExclusiveBlockV5>;
  /** The information about the campaign associated with the line item. */
  campaign: Maybe<CampaignV5>;
  /** The line item sequence id. */
  lineItemSequenceId: Maybe<Scalars['String']>;
  /** [ODS] Data model TBD. */
  revenue: Maybe<Scalars['Float']>;
  /** The line item priority value calculated based on priority and deliveryTier. */
  priorityValue: Maybe<Scalars['Int']>;
  /** The line item delivery schedule information, including day parting. */
  schedule: Maybe<ScheduleV5>;
  /** The line item delivery frequency limits based on its industry and/or brand. */
  frequencyCapList: Array<FrequencyCapV5>;
  /** Delivery Buffer percent */
  deliveryBufferPercent: Maybe<Scalars['Float']>;
  /** Front load percent */
  frontLoadPercent: Maybe<Scalars['Float']>;
  /** Delivery Goal */
  deliveryGoal: Maybe<Scalars['Float']>;
  /** preemptible flag */
  preemptible: Maybe<Scalars['Boolean']>;
  /** Goal */
  goal: Maybe<Scalars['Float']>;
  /** filler flag */
  isFiller: Maybe<Scalars['Boolean']>;
  /** Pod buy out flag */
  podBuyout: Maybe<Scalars['Boolean']>;
  /** Industry Exclusive flag */
  industryExclusive: Maybe<Scalars['Boolean']>;
  /** [ODS] */
  metrics: Maybe<Metrics>;
  /** The start date and hour for the entire line item. */
  startDate: Maybe<Scalars['DateTime']>;
  /** The end date and hour for the entire line item. */
  endDate: Maybe<Scalars['DateTime']>;
  /** The timestamp for the line item's creation. */
  createdAt: Maybe<Scalars['DateTime']>;
  /** The timestamp for the last time the line item was updated. */
  updatedAt: Maybe<Scalars['DateTime']>;
  /** Computed Field: flag that describes if lineItem has pacing-risk zero completions */
  hasZeroDeliveryRisk: Maybe<Scalars['Boolean']>;
  /** Computed Field: flag that describes if line item has an ad with a misaligned schedule */
  hasMisalignedSchedule: Maybe<Scalars['Boolean']>;
  /** The targeting terms associated with an ad. */
  targetingTermValues: Maybe<Array<TargetingTermValue>>;
  /** The publisher targeting - the owner of ads */
  displayPublisherTarget: Maybe<Array<Scalars['String']>>;
  /** Is Coppa Or Caru */
  isCoppaOrCaru: Maybe<Scalars['Boolean']>;
  /** Order Line Item */
  orderLineItemDto: Maybe<OrderLineItemV5>;
  /** Deliverable Line Item */
  deliverableLineItemDto: Maybe<DeliverableLineItemV5>;
  /** The Magnite Deal object created for PG Orders from Order Manager */
  magniteDealDto: Maybe<MagniteDealV5>;
  /** Line Item metadata details */
  metadata: Maybe<MetadataV5>;
  /** Line Item metadata details */
  isMakegood: Maybe<Scalars['Boolean']>;
  /** Line Item metadata details */
  isAddedValue: Maybe<Scalars['Boolean']>;
  draxDeal: Maybe<DraxDealV5>;
  maxCreativeMs: Maybe<Scalars['Float']>;
  /** Country */
  country: Maybe<Scalars['String']>;
  targetingRule: Maybe<TargetingRule>;
  /** Latest known YODA threshold up to line item ending */
  latestKnownThreshold: Maybe<LineItemThresholdV5>;
  /** Pacing Metrics */
  pacingMetrics: Maybe<LineItemPacingMetrics>;
};

export const LineItemYodaPacingStatus = {
  Delivered: 'DELIVERED',
  UnderDelivered: 'UNDER_DELIVERED',
  UnderPacing: 'UNDER_PACING',
  OverPacing: 'OVER_PACING',
  OnPace: 'ON_PACE',
  NotEven: 'NOT_EVEN',
} as const;

export type LineItemYodaPacingStatus = typeof LineItemYodaPacingStatus[keyof typeof LineItemYodaPacingStatus];
export type LineItemsPageV5 = Connection & {
  __typename?: 'LineItemsPageV5';
  edges: Array<LineItemEdgeV5>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type MagniteCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  url: Scalars['String'];
  vpaid: Scalars['Boolean'];
  videoAssetIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  adSourceId: Scalars['String'];
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type MagniteCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'MagniteCreativeV5';
    url: Maybe<Scalars['String']>;
    vpaid: Maybe<Scalars['Boolean']>;
    videoAssetList: Maybe<Array<CreativeV5Asset>>;
    /**
     * Creative Manager stores only assets id
     * videoAssetIdList is being used to handle mapping to BaseAssetV5 in videoAssetList
     */
    videoAssetIdList: Maybe<Array<Scalars['String']>>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    adSourceId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type MagniteDealV5 = {
  __typename?: 'MagniteDealV5';
  adSourceId: Scalars['String'];
  adSourceName: Scalars['String'];
  dealId: Scalars['String'];
  dealName: Scalars['String'];
  dspName: Maybe<Scalars['String']>;
};

export type MediaMetadata = {
  __typename?: 'MediaMetadata';
  id: Scalars['String'];
  format: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Float']>;
  size: Maybe<Scalars['Float']>;
  video: Maybe<VideoData>;
  audio: Maybe<AudioData>;
};

export type MetadataV5 = {
  __typename?: 'MetadataV5';
  actualStartDate: Maybe<Scalars['String']>;
};

export type Metrics = {
  __typename?: 'Metrics';
  completionsAtRisk: Maybe<Scalars['Float']>;
  revenueAtRisk: Maybe<Scalars['Float']>;
  percentCompletions: Maybe<Scalars['Float']>;
  percentPacing: Maybe<Scalars['Float']>;
  pacingStatus: Maybe<PacingStatus>;
  underPacingRevenue: Maybe<Scalars['Float']>;
  percentExpected: Maybe<Scalars['Float']>;
  remainingExpectedCompletionsAcc: Maybe<Scalars['Float']>;
  completions: Maybe<Scalars['Float']>;
  pacingRiskList: Array<Maybe<Scalars['String']>>;
};

/** Represents the user who last modified a DAAPI Object - Obtained from DAAPI Audit */
export type ModifiedByData = {
  __typename?: 'ModifiedByData';
  /** The objectId of the original Audit object */
  objectId: Scalars['String'];
  /** The user that made the change to the DAAPI Object */
  modifiedBy: Scalars['String'];
  /** The date and time the change was made */
  modifiedDate: Scalars['DateTime'];
};

export type Money = {
  __typename?: 'Money';
  currency: Currency;
  micros: Scalars['Float'];
};

/** Ad Rating */
export type MultiEditAdRatingInput = {
  id: Scalars['String'];
  value: Scalars['String'];
};

/** Ad Schedule */
export type MultiEditAdScheduleInputV5 = {
  id: Scalars['String'];
  value: MultiEditScheduleValueV5;
};

/** Ad Status */
export type MultiEditAdStatusInputV5 = {
  id: Scalars['String'];
  value: AdStatusV5;
};

/** Ad Tag */
export type MultiEditAdTagInput = {
  id: Scalars['String'];
  value: TagInput;
};

/** All types that are related to bulk Ads editing */
export type MultiEditAdsInputV5 = {
  rating?: Maybe<Array<MultiEditAdRatingInput>>;
  schedule?: Maybe<Array<MultiEditAdScheduleInputV5>>;
  status?: Maybe<Array<MultiEditAdStatusInputV5>>;
  targeting?: Maybe<Array<MultiEditTargetingInput>>;
  tags?: Maybe<Array<MultiEditAdTagInput>>;
};

/**  Buffer  */
export type MultiEditBufferInput = {
  buffer?: Maybe<Array<BufferInput>>;
  frontload?: Maybe<Array<BufferInput>>;
};

/**  Frequency  */
export type MultiEditFrequencyCapInputV5 = {
  id: Scalars['String'];
  value: Array<FrequencyCapInputV5>;
};

/**  All types that related to multi editing line items  */
export type MultiEditLineItemsInputV5 = {
  status?: Maybe<Array<MultiEditStatusInputV5>>;
  buffer?: Maybe<MultiEditBufferInput>;
  frequency?: Maybe<Array<MultiEditFrequencyCapInputV5>>;
  priority?: Maybe<Array<MultiEditPriorityInput>>;
  tier?: Maybe<Array<MultiEditTierInput>>;
  targeting?: Maybe<Array<MultiEditTargetingInput>>;
};

/**  Priority  */
export type MultiEditPriorityInput = {
  id: Scalars['String'];
  value: PriorityNameInput;
};

/** Ad Schedule Value With Optional Fields */
export type MultiEditScheduleValueV5 = {
  timezone: Scalars['String'];
  deliveryInViewerTime?: Maybe<Scalars['Boolean']>;
  dateRangeList?: Maybe<Array<DateRangeInputV5>>;
  dayPartList?: Maybe<Array<DayPartInputV5>>;
};

/**  Status  */
export type MultiEditStatusInputV5 = {
  id: Scalars['String'];
  value: LineItemStatusV5;
};

/**  Targeting  */
export type MultiEditTargetingInput = {
  id: Scalars['String'];
  value: Array<TargetingTermValueInput>;
};

/**  Tier  */
export type MultiEditTierInput = {
  id: Scalars['String'];
  value: TierNameInput;
};

/**
 * A Change whose value is a list, or collection, of elements that can be added,
 * removed, or updated. This may eventually be replaced by field-specific Change
 * types.
 */
export type MultiValueChange = BaseChange & {
  __typename?: 'MultiValueChange';
  fieldName: Scalars['String'];
  oldValues: Array<SimpleChangeValue>;
  newValues: Array<SimpleChangeValue>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAdSelectorSlateCreativeV5: Maybe<AdSelectorSlateCreativeV5>;
  createAdSelectorVideoCreativeV5: Maybe<AdSelectorVideoCreativeV5>;
  createBrightlineCreativeV5: Maybe<BrightlineCreativeV5>;
  createDraxCreativeV5: Maybe<DraxCreativeV5>;
  createInnovidCreativeV5: Maybe<InnovidCreativeV5>;
  createGatewayGoCreativeV5: Maybe<GatewayGoCreativeV5>;
  createMagniteCreativeV5: Maybe<MagniteCreativeV5>;
  createPauseCreativeV5: Maybe<PauseCreativeV5>;
  createSlateCreativeV5: Maybe<SlateCreativeV5>;
  createVastVideoCreativeV5: Maybe<VastVideoCreativeV5>;
  createVideoCreativeV5: Maybe<VideoCreativeV5>;
  createAdV5: Maybe<AdV5>;
  updateAdV5: AdV5;
  updateLineItemV5: Maybe<LineItemV5>;
  updateCampaignV5: Maybe<CampaignV5PatchResponse>;
  updateAdSelectorSlateCreativeV5: Maybe<AdSelectorSlateCreativeV5>;
  updateAdSelectorVideoCreativeV5: Maybe<AdSelectorVideoCreativeV5>;
  updateBrightlineCreativeV5: Maybe<BrightlineCreativeV5>;
  updateDraxCreativeV5: Maybe<DraxCreativeV5>;
  updateInnovidCreativeV5: Maybe<InnovidCreativeV5>;
  updateGatewayGoCreativeV5: Maybe<GatewayGoCreativeV5>;
  updateMagniteCreativeV5: Maybe<MagniteCreativeV5>;
  updatePauseCreativeV5: Maybe<PauseCreativeV5>;
  updateSlateCreativeV5: Maybe<SlateCreativeV5>;
  updateVastVideoCreativeV5: Maybe<VastVideoCreativeV5>;
  updateVideoCreativeV5: Maybe<VideoCreativeV5>;
  updateCreativeMetadataByCreativeIdV5: Maybe<CreativeMetadata>;
  createTargetingTermValuesForLineItem: Array<TargetingTermValue>;
  updateTargetingTermValuesForLineItem: Maybe<Array<TargetingTermValue>>;
  deleteTargetingTermValuesForLineItem: Maybe<Scalars['Void']>;
  createTargetingTermValuesForAd: Array<TargetingTermValue>;
  updateTargetingTermValuesForAd: Maybe<Array<TargetingTermValue>>;
  deleteTargetingTermValuesForAd: Maybe<Scalars['Void']>;
  approveAdsV5: Array<AdV5>;
  overrideAdsReviewV5: Array<AdV5>;
  deleteAdsV5: Maybe<Scalars['Void']>;
  approveLineItemsV5: Array<LineItemV5>;
  approveLineItemsAndCorrelatedAdsV5: ApproveLineItemsAndCorrelatedAdsResultV5;
  sendLineItemsOnReviewV5: Array<LineItemV5>;
  updateLineItemPacingTypeV5: LineItemV5;
  sendCampaignsOnReviewV5: Array<LineItemV5>;
  updateEntriesVerification: VerificationResponse;
  updateRotationsV5: Array<Rotation>;
  updateRotationNameV5: Array<Rotation>;
  deleteRotationsV5: Maybe<Scalars['Void']>;
  createSequenceV5: Sequence;
  updateSequenceV5: Sequence;
  deleteSequenceV5: Maybe<Scalars['Void']>;
  bulkCreateSequencesV5: Array<Sequence>;
  bulkUpdateSequencesV5: Array<Sequence>;
  bulkDeleteSequencesV5: Maybe<Scalars['Void']>;
  setAssetPublishingErrorV5: Maybe<Scalars['Void']>;
  startAssetIngestion: PreIngestResult;
  deleteAssetIngestion: Maybe<Scalars['Void']>;
  deleteAsset: Maybe<Scalars['Void']>;
  updateReviewAssetV5: BaseAssetV5;
  updateReviewTasksV5: Array<ReviewTaskV5>;
  patchReviewTasksV5: PatchReviewTasksResponseV5;
  upsertUsersWithRole: UpsertUsersWithRoleResult;
  removeRoleFromUser: RemoveRoleFromUserResult;
  saveAssetsFilters: ShareableLink;
  saveTraffickingFilters: ShareableLink;
  createAdsBatchV5: Array<AdV5>;
  updateCampaignReviewErrors: UpdatedCampaignReviewError;
  resolveCampaignReviewError: Array<CampaignReviewError>;
  multiEditAdsV5: Array<AdV5>;
  multiEditLineItemsV5: Array<LineItemV5>;
  createTargetingPreset: TargetingPreset;
  updateTargetingPreset: TargetingPreset;
  updateExternalAssetV5: ExternalAssetV5;
  deleteCreativesAssetByIDV5: Maybe<Scalars['Void']>;
  updateBatchReviewAssets: BatchReviewAssetResponseV5;
};

export type MutationCreateAdSelectorSlateCreativeV5Args = {
  input: AdSelectorSlateCreativeInputV5;
};

export type MutationCreateAdSelectorVideoCreativeV5Args = {
  input: AdSelectorVideoCreativeInputV5;
};

export type MutationCreateBrightlineCreativeV5Args = {
  input: BrightlineCreativeInputV5;
};

export type MutationCreateDraxCreativeV5Args = {
  input: DraxCreativeInputV5;
};

export type MutationCreateInnovidCreativeV5Args = {
  input: InnovidCreativeInputV5;
};

export type MutationCreateGatewayGoCreativeV5Args = {
  input: GatewayGoCreativeInputV5;
};

export type MutationCreateMagniteCreativeV5Args = {
  input: MagniteCreativeInputV5;
};

export type MutationCreatePauseCreativeV5Args = {
  input: PauseCreativeInputV5;
};

export type MutationCreateSlateCreativeV5Args = {
  input: SlateCreativeInputV5;
};

export type MutationCreateVastVideoCreativeV5Args = {
  input: VastVideoCreativeInputV5;
};

export type MutationCreateVideoCreativeV5Args = {
  input: VideoCreativeInputV5;
};

export type MutationCreateAdV5Args = {
  input: AdInputV5;
};

export type MutationUpdateAdV5Args = {
  id: Scalars['ID'];
  input: UpdateAdInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
};

export type MutationUpdateLineItemV5Args = {
  id: Scalars['ID'];
  input: UpdateLineItemInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
};

export type MutationUpdateCampaignV5Args = {
  id: Scalars['ID'];
  input: CampaignInputV5;
};

export type MutationUpdateAdSelectorSlateCreativeV5Args = {
  id: Scalars['ID'];
  input: AdSelectorSlateCreativeInputV5;
};

export type MutationUpdateAdSelectorVideoCreativeV5Args = {
  id: Scalars['ID'];
  input: AdSelectorVideoCreativeInputV5;
};

export type MutationUpdateBrightlineCreativeV5Args = {
  id: Scalars['ID'];
  input: BrightlineCreativeInputV5;
};

export type MutationUpdateDraxCreativeV5Args = {
  id: Scalars['ID'];
  input: DraxCreativeInputV5;
};

export type MutationUpdateInnovidCreativeV5Args = {
  id: Scalars['ID'];
  input: InnovidCreativeInputV5;
};

export type MutationUpdateGatewayGoCreativeV5Args = {
  id: Scalars['ID'];
  input: GatewayGoCreativeInputV5;
};

export type MutationUpdateMagniteCreativeV5Args = {
  id: Scalars['ID'];
  input: MagniteCreativeInputV5;
};

export type MutationUpdatePauseCreativeV5Args = {
  id: Scalars['ID'];
  input: PauseCreativeInputV5;
};

export type MutationUpdateSlateCreativeV5Args = {
  id: Scalars['ID'];
  input: SlateCreativeInputV5;
};

export type MutationUpdateVastVideoCreativeV5Args = {
  id: Scalars['ID'];
  input: VastVideoCreativeInputV5;
};

export type MutationUpdateVideoCreativeV5Args = {
  id: Scalars['ID'];
  input: VideoCreativeInputV5;
};

export type MutationUpdateCreativeMetadataByCreativeIdV5Args = {
  creativeId: Scalars['ID'];
  countryCodes: Array<Scalars['String']>;
  isLegalApproved: Scalars['Boolean'];
};

export type MutationCreateTargetingTermValuesForLineItemArgs = {
  lineItemId: Scalars['ID'];
  termValues: Array<TargetingTermValueInput>;
};

export type MutationUpdateTargetingTermValuesForLineItemArgs = {
  lineItemId: Scalars['ID'];
  termValues: Array<TargetingTermValueInput>;
};

export type MutationDeleteTargetingTermValuesForLineItemArgs = {
  lineItemId: Scalars['ID'];
};

export type MutationCreateTargetingTermValuesForAdArgs = {
  adId: Scalars['ID'];
  termValues: Array<TargetingTermValueInput>;
};

export type MutationUpdateTargetingTermValuesForAdArgs = {
  adId: Scalars['ID'];
  termValues: Array<TargetingTermValueInput>;
};

export type MutationDeleteTargetingTermValuesForAdArgs = {
  adId: Scalars['ID'];
};

export type MutationApproveAdsV5Args = {
  input: Array<Scalars['ID']>;
};

export type MutationOverrideAdsReviewV5Args = {
  input: Array<Scalars['ID']>;
};

export type MutationDeleteAdsV5Args = {
  input: Array<Scalars['ID']>;
};

export type MutationApproveLineItemsV5Args = {
  input: Array<Scalars['ID']>;
};

export type MutationApproveLineItemsAndCorrelatedAdsV5Args = {
  input: Array<Scalars['ID']>;
};

export type MutationSendLineItemsOnReviewV5Args = {
  input: Array<Scalars['ID']>;
};

export type MutationUpdateLineItemPacingTypeV5Args = {
  input: LineItemPacingTypeInputV5;
};

export type MutationSendCampaignsOnReviewV5Args = {
  input: Array<Scalars['ID']>;
};

export type MutationUpdateEntriesVerificationArgs = {
  input: Array<UpdateEntriesVerificationInput>;
};

export type MutationUpdateRotationsV5Args = {
  input: RotationsInput;
};

export type MutationUpdateRotationNameV5Args = {
  input: RotationNameInput;
};

export type MutationDeleteRotationsV5Args = {
  lineItemId: Scalars['ID'];
};

export type MutationCreateSequenceV5Args = {
  input: SequenceInput;
};

export type MutationUpdateSequenceV5Args = {
  input: SequenceInput;
};

export type MutationDeleteSequenceV5Args = {
  sequenceId: Scalars['String'];
};

export type MutationBulkCreateSequencesV5Args = {
  inputs: Array<SequenceInput>;
};

export type MutationBulkUpdateSequencesV5Args = {
  inputs: Array<SequenceInput>;
};

export type MutationBulkDeleteSequencesV5Args = {
  sequenceIds: Array<Scalars['ID']>;
};

export type MutationSetAssetPublishingErrorV5Args = {
  assetId: Scalars['ID'];
};

export type MutationStartAssetIngestionArgs = {
  creativeId: Scalars['ID'];
  vastUrl: Scalars['String'];
};

export type MutationDeleteAssetIngestionArgs = {
  assetId: Scalars['ID'];
};

export type MutationDeleteAssetArgs = {
  assetId: Scalars['ID'];
};

export type MutationUpdateReviewAssetV5Args = {
  assetId: Scalars['ID'];
  input: AssetInputV5;
};

export type MutationUpdateReviewTasksV5Args = {
  reviewTasks: Array<ReviewTaskBodyInputV5>;
};

export type MutationPatchReviewTasksV5Args = {
  input: PatchReviewTasksV5Input;
};

export type MutationUpsertUsersWithRoleArgs = {
  options: UpsertUsersWithRoleOptions;
};

export type MutationRemoveRoleFromUserArgs = {
  options: RemoveRoleFromUserOptions;
};

export type MutationSaveAssetsFiltersArgs = {
  input: AssetsFiltersInputV5;
};

export type MutationSaveTraffickingFiltersArgs = {
  input: TraffickingFiltersInput;
};

export type MutationCreateAdsBatchV5Args = {
  ads: Array<AdInputV5>;
};

export type MutationUpdateCampaignReviewErrorsArgs = {
  input: Array<CampaignReviewErrorInput>;
};

export type MutationResolveCampaignReviewErrorArgs = {
  input: Array<ResolveCampaignReviewErrorInput>;
};

export type MutationMultiEditAdsV5Args = {
  input: MultiEditAdsInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
};

export type MutationMultiEditLineItemsV5Args = {
  input: MultiEditLineItemsInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
};

export type MutationCreateTargetingPresetArgs = {
  input: TargetingPresetInput;
};

export type MutationUpdateTargetingPresetArgs = {
  targetingPresetId: Scalars['ID'];
  input: TargetingPresetUpdateInput;
};

export type MutationUpdateExternalAssetV5Args = {
  externalAssetId: Scalars['String'];
  externalAssetInput: ExternalAssetV5Input;
};

export type MutationDeleteCreativesAssetByIdv5Args = {
  creativeId: Scalars['String'];
  assetId: Scalars['String'];
};

export type MutationUpdateBatchReviewAssetsArgs = {
  idList: Array<Scalars['String']>;
  asset: BatchReviewAssetInputV5;
  appendAssetTagComplianceList?: Maybe<Array<AssetTagV5BatchReviewInput>>;
  appendCountryCodes?: Maybe<Scalars['Boolean']>;
  isExternal: Scalars['Boolean'];
};

export type Node = {
  id: Scalars['ID'];
};

export type OmsLinkInputV5 = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export const OmsLinkTypeV5 = {
  Unknown: 'UNKNOWN',
  HuluOp1Order: 'HULU_OP1_ORDER',
  HuluOp1ChildLine: 'HULU_OP1_CHILD_LINE',
  HuluOp1ParentLine: 'HULU_OP1_PARENT_LINE',
  DamOrder: 'DAM_ORDER',
  DamOrderLineItem: 'DAM_ORDER_LINE_ITEM',
  DcmOrder: 'DCM_ORDER',
  DcmOrderLineItem: 'DCM_ORDER_LINE_ITEM',
} as const;

export type OmsLinkTypeV5 = typeof OmsLinkTypeV5[keyof typeof OmsLinkTypeV5];
/** The OmsLink object is a link object that contains the campaign and line item information from the order management system. */
export type OmsLinkV5 = {
  __typename?: 'OmsLinkV5';
  /** The ID in the CRM system of the opportunity associated with the campaign, for example, the 18-alphanumeric-character ID in Salesforce. */
  id: Scalars['String'];
  /** The type of data from the CRM system. */
  type: OmsLinkTypeV5;
};

export type OperativePresetTargetings = {
  __typename?: 'OperativePresetTargetings';
  fieldName: Scalars['String'];
};

export type OrderFee = {
  __typename?: 'OrderFee';
  name: Scalars['String'];
  parentLineOmsLink: OmsLinkV5;
  feeLineItemList: Array<FeeLineItem>;
};

export type OrderFilter = {
  __typename?: 'OrderFilter';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type OrderFilterInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type OrderLineItemV5 = {
  __typename?: 'OrderLineItemV5';
  parentLineOmsLink: Maybe<OmsLinkV5>;
  packageName: Maybe<Scalars['String']>;
  childLineOmsLink: Maybe<OmsLinkV5>;
  isMakeGood: Maybe<Scalars['Boolean']>;
  buyerName: Maybe<Scalars['String']>;
  buyerId: Maybe<Scalars['String']>;
};

export const OrderOption = {
  Desc: 'DESC',
  Asc: 'ASC',
} as const;

export type OrderOption = typeof OrderOption[keyof typeof OrderOption];
export type OrderV5 = {
  __typename?: 'OrderV5';
  id: Scalars['String'];
  orderOmsLink: OmsLinkV5;
  nielsenRange: Maybe<Scalars['String']>;
  orderType: Scalars['String'];
  marketplace: Scalars['String'];
  salesOrigin: Scalars['String'];
  spend: Scalars['Float'];
  salespersonEmail: Scalars['String'];
  opportunityId: Maybe<Scalars['String']>;
  operativeOmsLink: Maybe<OmsLinkV5>;
  deliverableLineItemList: Array<DeliverableLineItemV5>;
  feeList: Maybe<Array<OrderFee>>;
};

export const PacingStatus = {
  UnderPacing: 'UNDER_PACING',
  OnPacing: 'ON_PACING',
  OverPacing: 'OVER_PACING',
} as const;

export type PacingStatus = typeof PacingStatus[keyof typeof PacingStatus];
export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
  endCursor: Maybe<Scalars['String']>;
};

export type PaginationOptions = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerExternalAssetV5 = ExternalAssetV5 &
  Node & {
    __typename?: 'PartnerExternalAssetV5';
    id: Scalars['ID'];
    brand: Maybe<CrmLinkV5>;
    timing: Maybe<ExternalAssetV5Timing>;
    type: Maybe<ExternalAssetV5Type>;
    url: Maybe<Scalars['String']>;
    mediaKey: Maybe<Scalars['String']>;
    source: Maybe<ExternalAssetV5Source>;
    sourceAdId: Maybe<Scalars['String']>;
    sourceAdTitle: Maybe<Scalars['String']>;
    sourceCreativeId: Maybe<Scalars['String']>;
    contentPartnerId: Maybe<Scalars['String']>;
    trackingId: Maybe<Scalars['Int']>;
    contentPartner: Maybe<ContentPartnerV5>;
  };

export type PatchReviewTasksResponseV5 = {
  __typename?: 'PatchReviewTasksResponseV5';
  successful: Array<ReviewTaskV5>;
  failed: Array<ReviewTaskPatchFailedV5>;
};

export type PatchReviewTasksV5Input = {
  idList?: Maybe<Array<Scalars['ID']>>;
  assetIdList?: Maybe<Array<Scalars['ID']>>;
  reviewTask: ReviewTaskPatchFieldsV5;
};

export type PauseCreativeInputV5 = {
  name: Scalars['String'];
  type: CreativeV5Type;
  imageId?: Maybe<Scalars['String']>;
  altText: Scalars['String'];
  accent: AccentRgbInputV5;
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type PauseCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'PauseCreativeV5';
    altText: Maybe<Scalars['String']>;
    image: Maybe<CreativeV5Asset>;
    /**
     * Creative Manager stores only asset id
     * imageId is being used to handle mapping to BaseAssetV5 in image
     */
    imageId: Maybe<Scalars['String']>;
    accent: Maybe<AccentRgbv5>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type PauseTypePropertiesInputV5 = {
  image: IdInputV5;
  altText: Scalars['String'];
  accent: AccentRgbInputV5;
};

export type PauseTypePropertiesV5 = {
  __typename?: 'PauseTypePropertiesV5';
  image: CreativeV5Asset;
  altText: Scalars['String'];
  accent: Maybe<AccentRgbv5>;
};

export type Permissions = {
  __typename?: 'Permissions';
  action: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  serviceNamespace: Maybe<Scalars['String']>;
};

export type PopulateTargetingValuesInput = {
  dimension: Scalars['String'];
  valueSet: Array<Scalars['String']>;
};

/** The Ingestion Trace object holds information about a triggered ingestion. */
export type PreIngestResult = {
  __typename?: 'PreIngestResult';
  /** The ingestion tracing ID in DAAPI. */
  tracingId: Maybe<Scalars['String']>;
  duplicate: Maybe<Scalars['Boolean']>;
};

export const PresetType = {
  Preset: 'PRESET',
  InventoryPreset: 'INVENTORY_PRESET',
  OperativePreset: 'OPERATIVE_PRESET',
} as const;

export type PresetType = typeof PresetType[keyof typeof PresetType];
export type PriorityNameInput = {
  name: Scalars['String'];
  rank?: Maybe<Scalars['Int']>;
};

export type ProgrammaticExternalAssetDeal = {
  __typename?: 'ProgrammaticExternalAssetDeal';
  dealId: Scalars['ID'];
};

export type ProgrammaticExternalAssetV5 = ExternalAssetV5 &
  Node & {
    __typename?: 'ProgrammaticExternalAssetV5';
    id: Scalars['ID'];
    brand: Maybe<CrmLinkV5>;
    timing: Maybe<ExternalAssetV5Timing>;
    type: Maybe<ExternalAssetV5Type>;
    url: Maybe<Scalars['String']>;
    bidderId: Maybe<Scalars['String']>;
    dspId: Maybe<Scalars['String']>;
    mediaKey: Maybe<Scalars['String']>;
    sourceAssetId: Maybe<Scalars['String']>;
  };

export type PublisherFilterOption = {
  valueIds: Array<Scalars['String']>;
};

export type PushUmpDxcExecutable = DxcExecutable &
  UmpDxcExecutable & {
    __typename?: 'PushUmpDxcExecutable';
    id: Scalars['ID'];
    messageConfigId: Scalars['ID'];
    subjectLine: Scalars['String'];
    bodyDescription: Scalars['String'];
    callToActionUrl: Scalars['String'];
    subClass: Scalars['String'];
  };

export type QrDxcExecutable = DxcExecutable & {
  __typename?: 'QrDxcExecutable';
  id: Scalars['ID'];
  destinationUrl: Scalars['String'];
  subClass: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  campaignsV5: CampaignsPageV5;
  creativeMetadata: CreativeMetadataPage;
  lineItemsV5: LineItemsPageV5;
  adsV5: AdsPageV5;
  lineItemPrioritiesV5: Array<LineItemPriorityV5>;
  searchCreativesV5: BaseCreativePageV5;
  searchAssetsV5: AssetsPageV5;
  lookupsExternalAssetsV5: ExternalAssetsPageV5;
  lookupsDspsAssetsV5: DspsAssetsPageV5;
  lookupDspsAssetByIdV5: Maybe<DspsAssetV5>;
  transcodingAsset: Maybe<TranscodingAsset>;
  getAssetIngestionTracesByCreativeIDs: IngestionTracesPage;
  getAssetLatestIngestionStatus: LatestIngestionStatus;
  getAssetByIDV5: BaseAssetV5;
  getAssetsByIDsV5: Array<BaseAssetV5>;
  getCreativeByIDV5: BaseCreativeV5;
  getCreativeLibraryIdByOrganizationIDV5: Scalars['ID'];
  getReviewTasksV5: ReviewTaskPageV5;
  /** @deprecated Use getUsersWithRole */
  getUsersByRoleV4: UserPageV4;
  getUsersWithRoleV4: UserPageV4;
  getUsers: Array<UserV4>;
  getRoles: Array<RoleV4>;
  getChangeLogByAdObjectID: ChangeLog;
  getLatestApprovalFromAudits: Array<ModifiedByData>;
  getUserRoleChangeLogs: UserRoleChangeLogPage;
  getTargetingTermValuesByLineItemIDV5: Maybe<Array<TargetingTermValue>>;
  getTargetingTermValuesByLineItemIDsV5: Array<LineItemTargetingValueBody>;
  getTargetingTermValuesByAdIDV5: Maybe<Array<TargetingTermValue>>;
  getTargetingCategories: Array<TargetingCategory>;
  getTargetingDimensionsByCategory: Array<TargetingDimension>;
  getTargetingValuesByCategoryAndDimensionID: TargetingValuePage;
  getTargetingValuesByDimensionAndValueID: Array<TargetingValue>;
  getTargetingProviderValuesByCategory: Array<TargetingValue>;
  getRotationsV5: Array<Rotation>;
  getAdTypesV5: Array<AdTypeV5>;
  getLineItemAdProductV5: Array<LineItemAdProductV5>;
  getAdRatingsV5: Array<Scalars['String']>;
  getAdPermittedTimezones: Array<Scalars['String']>;
  getSequenceByIdV5: Sequence;
  getTranscodingFailure: Maybe<TranscodingFailure>;
  getMediaMetadataByAssetIdV5: Maybe<MediaMetadata>;
  getOrderTypesV5: Array<Scalars['String']>;
  ordersV5: Array<Maybe<OrderV5>>;
  getTraffickingFilters: TraffickingFilters;
  getAssetsFiltersV5: AssetFiltersV5;
  getTraffickerEmails: TraffickerEmailsPage;
  getSalespersonEmails: Array<Scalars['String']>;
  getCreativeApprovalsV5: CreativeApprovals;
  getTargetingPresets: TargetingPresetPage;
  getTargetingPresetByUUID: Maybe<TargetingPreset>;
  /** @deprecated Use brands */
  customerManagerBrands: Maybe<CustomerBrands>;
  brands: Maybe<BrandsPage>;
  /** @deprecated Use brands */
  getCustomerBrandByIdV4: Maybe<CustomerBrandDetails>;
  getLineItemTierNamesV5: Array<Scalars['String']>;
  getCreativeReviewsStatusV5: Array<Scalars['String']>;
  getCreativeTypesV5: Array<CreativeV5Type>;
  getLineItemBillableThirdPartiesV5: Array<Scalars['String']>;
  getLineItemPriorityValuesV5: Array<Scalars['String']>;
  getPublisherListV4: Array<Scalars['String']>;
  getAgencies: AgencyPage;
  /** @deprecated Use getIndustries */
  getIndustriesV5: IndustryPage;
  getIndustries: IndustryPage;
  getContentPartnersV5: ContentPartnerPage;
  getCurrencyTypes: Array<Scalars['String']>;
  populateTargetingValues: Array<TargetingRuleWithPopulatedValues>;
  getO1Targetings: Maybe<Array<OperativePresetTargetings>>;
};

export type QueryCampaignsV5Args = {
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<CampaignSortOptionsV5>;
  filterOptions?: Maybe<CampaignFilterOptionsV5>;
};

export type QueryCreativeMetadataArgs = {
  filterOptions?: Maybe<CreativeMetadataFilterOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
};

export type QueryLineItemsV5Args = {
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<LineItemSortOptionsV5>;
  filterOptions?: Maybe<LineItemFilterOptionsV5>;
};

export type QueryAdsV5Args = {
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<AdSortOptionsV5>;
  filterOptions?: Maybe<AdFilterOptionsV5>;
};

export type QuerySearchCreativesV5Args = {
  filterOptions?: Maybe<BaseCreativeFilterOptionsV5>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<SearchCreativeSortOptions>;
};

export type QuerySearchAssetsV5Args = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Array<AssetTypeV5>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type QueryLookupsExternalAssetsV5Args = {
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<ExternalAssetV5SortOptionsInput>;
  filterOptions?: Maybe<ExternalAssetV5FilterOptionsInput>;
};

export type QueryLookupsDspsAssetsV5Args = {
  filterOptions?: Maybe<DspsAssetFilterOptionsInputV5>;
};

export type QueryLookupDspsAssetByIdV5Args = {
  dspUuid: Scalars['String'];
};

export type QueryTranscodingAssetArgs = {
  assetId: Scalars['ID'];
};

export type QueryGetAssetIngestionTracesByCreativeIDsArgs = {
  creativeIds: Array<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type QueryGetAssetLatestIngestionStatusArgs = {
  creativeId: Scalars['String'];
};

export type QueryGetAssetByIdv5Args = {
  assetId: Scalars['String'];
};

export type QueryGetAssetsByIDsV5Args = {
  assetIdList: Array<Scalars['String']>;
};

export type QueryGetCreativeByIdv5Args = {
  creativeId: Scalars['String'];
};

export type QueryGetCreativeLibraryIdByOrganizationIdv5Args = {
  organizationId: Scalars['String'];
};

export type QueryGetReviewTasksV5Args = {
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<ReviewTaskV5SortOptionsInput>;
  filterOptions?: Maybe<ReviewTaskV5FilterOptions>;
};

export type QueryGetUsersByRoleV4Args = {
  roleName?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityFilterOptionV4>;
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<UserFilterOptions>;
  sortOptions?: Maybe<UserV4SortOptions>;
};

export type QueryGetUsersWithRoleV4Args = {
  roleName?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityFilterOptionV4>;
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<UserFilterOptions>;
  sortOptions?: Maybe<UserV4SortOptions>;
};

export type QueryGetUsersArgs = {
  filterOptions?: Maybe<GetUserFilterOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
};

export type QueryGetRolesArgs = {
  filterOptions?: Maybe<GetRolesFilterOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
};

export type QueryGetChangeLogByAdObjectIdArgs = {
  id: Scalars['ID'];
  entityType?: Maybe<EntityType>;
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<GetChangelogFilterOptions>;
};

export type QueryGetLatestApprovalFromAuditsArgs = {
  idList: Array<Scalars['String']>;
};

export type QueryGetUserRoleChangeLogsArgs = {
  filterOptions?: Maybe<GetUserRoleChangeLogsFilterOptions>;
};

export type QueryGetTargetingTermValuesByLineItemIdv5Args = {
  lineItemId: Scalars['String'];
};

export type QueryGetTargetingTermValuesByLineItemIDsV5Args = {
  lineItemIds: Array<Scalars['String']>;
};

export type QueryGetTargetingTermValuesByAdIdv5Args = {
  adId: Scalars['String'];
};

export type QueryGetTargetingCategoriesArgs = {
  includePreset?: Maybe<Scalars['Boolean']>;
};

export type QueryGetTargetingDimensionsByCategoryArgs = {
  categoryGuid: Scalars['String'];
};

export type QueryGetTargetingValuesByCategoryAndDimensionIdArgs = {
  categoryGuid: Scalars['String'];
  dimensionGuid: Scalars['String'];
  originSource?: Maybe<Scalars['String']>;
  filterOptions?: Maybe<TargetingValueFilterOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
  countryOption?: Maybe<CountryFilterOption>;
  publisherOption?: Maybe<PublisherFilterOption>;
};

export type QueryGetTargetingValuesByDimensionAndValueIdArgs = {
  dimensionGuid: Scalars['String'];
  valueId: Scalars['String'];
};

export type QueryGetTargetingProviderValuesByCategoryArgs = {
  categoryId: Scalars['String'];
  paginationOptions?: Maybe<PaginationOptions>;
};

export type QueryGetRotationsV5Args = {
  lineItemId: Scalars['ID'];
};

export type QueryGetSequenceByIdV5Args = {
  sequenceId: Scalars['String'];
};

export type QueryGetTranscodingFailureArgs = {
  assetId: Scalars['String'];
};

export type QueryGetMediaMetadataByAssetIdV5Args = {
  assetId: Scalars['String'];
};

export type QueryGetTraffickingFiltersArgs = {
  shareableId: Scalars['String'];
};

export type QueryGetAssetsFiltersV5Args = {
  assetShareableId: Scalars['String'];
};

export type QueryGetTraffickerEmailsArgs = {
  search?: Maybe<Scalars['String']>;
  paginationOptions?: Maybe<PaginationOptions>;
};

export type QueryGetCreativeApprovalsV5Args = {
  creativeId: Scalars['String'];
};

export type QueryGetTargetingPresetsArgs = {
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<TargetingPresetFilterOptions>;
  sortOptions?: Maybe<TargetingPresetSortOptions>;
};

export type QueryGetTargetingPresetByUuidArgs = {
  uuid: Scalars['String'];
};

export type QueryCustomerManagerBrandsArgs = {
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<CustomerBrandFilterOptions>;
};

export type QueryBrandsArgs = {
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<BrandFilterOptions>;
};

export type QueryGetCustomerBrandByIdV4Args = {
  brandId: Scalars['String'];
};

export type QueryGetAgenciesArgs = {
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<AgencyFilterOptions>;
};

export type QueryGetIndustriesV5Args = {
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<IndustryFilterOptions>;
};

export type QueryGetIndustriesArgs = {
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<IndustriesFilterOptions>;
  sortOptions?: Maybe<IndustriesSortOption>;
};

export type QueryGetContentPartnersV5Args = {
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<ContentPartnersFilterOptions>;
  sortOptions?: Maybe<ContentPartnersSortOption>;
};

export type QueryPopulateTargetingValuesArgs = {
  termList: Array<PopulateTargetingValuesInput>;
};

export type QueryGetO1TargetingsArgs = {
  onlyExportable?: Maybe<Scalars['Boolean']>;
};

export type RangeList = {
  __typename?: 'RangeList';
  lower: Maybe<Scalars['Int']>;
  upper: Maybe<Scalars['Int']>;
};

export type RangeListInput = {
  lower?: Maybe<Scalars['Int']>;
  upper?: Maybe<Scalars['Int']>;
};

export const RejectionReasonV5 = {
  Prohibited: 'PROHIBITED',
  AdPolicies: 'AD_POLICIES',
  Specs: 'SPECS',
  SetupError: 'SETUP_ERROR',
  BrandMismatch: 'BRAND_MISMATCH',
  MultipleIssues: 'MULTIPLE_ISSUES',
  InvalidUrl: 'INVALID_URL',
} as const;

export type RejectionReasonV5 = typeof RejectionReasonV5[keyof typeof RejectionReasonV5];
export type RemoteAsset = {
  __typename?: 'RemoteAsset';
  /** TBD The URL to the location of the media partner's VAST asset. */
  url: Scalars['String'];
  /** The reason for the remote asset rejection. */
  rejectionReason: Maybe<Scalars['String']>;
};

export type RemoveRoleFromUserFailedRequest = {
  __typename?: 'RemoveRoleFromUserFailedRequest';
  email: Scalars['String'];
  error: Maybe<Scalars['JSONDataType']>;
};

export type RemoveRoleFromUserOptions = {
  roleName: Scalars['String'];
  entityType: EntityFilterOptionV4;
  entityId: Scalars['String'];
  requests: Array<RemoveRoleFromUserRequest>;
};

export type RemoveRoleFromUserRequest = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type RemoveRoleFromUserResult = {
  __typename?: 'RemoveRoleFromUserResult';
  failedRequests: Array<RemoveRoleFromUserFailedRequest>;
  successfulRequests: Array<UserV4>;
};

export type ResolveCampaignReviewErrorInput = {
  id: Scalars['String'];
  resolved: Scalars['Boolean'];
};

export type ResponseError = {
  __typename?: 'ResponseError';
  id: Scalars['String'];
  message: Scalars['String'];
  stack: Maybe<Scalars['String']>;
};

export const Review = {
  Draft: 'DRAFT',
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type Review = typeof Review[keyof typeof Review];
export const ReviewAssetStatusV5 = {
  Pending: 'PENDING',
  Preview: 'PREVIEW',
  Review: 'REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type ReviewAssetStatusV5 = typeof ReviewAssetStatusV5[keyof typeof ReviewAssetStatusV5];
export type ReviewTaskAsset = {
  name?: Maybe<Scalars['String']>;
  transcodeStatusList?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewList?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  languageList?: Maybe<Array<Maybe<Scalars['String']>>>;
  publisherList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReviewTaskBodyInputV5 = {
  id: Scalars['ID'];
  assetId?: Maybe<Scalars['String']>;
  creativeId?: Maybe<Scalars['String']>;
  reviewUserId?: Maybe<Scalars['String']>;
  spReview?: Maybe<SpReviewV5>;
  taskStatus?: Maybe<ReviewTaskV5Status>;
};

export type ReviewTaskEdgeV5 = Edge & {
  __typename?: 'ReviewTaskEdgeV5';
  cursor: Scalars['String'];
  node: ReviewTaskV5;
};

export type ReviewTaskPageV5 = Connection & {
  __typename?: 'ReviewTaskPageV5';
  edges: Array<ReviewTaskEdgeV5>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type ReviewTaskPatchFailedV5 = {
  __typename?: 'ReviewTaskPatchFailedV5';
  id: Scalars['ID'];
  assetId: Scalars['ID'];
  message: Scalars['String'];
};

export type ReviewTaskPatchFieldsV5 = {
  reviewUserId?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<ReviewTaskV5Status>;
};

export type ReviewTaskV5 = {
  id: Scalars['ID'];
  asset: Maybe<BaseAssetV5>;
  assetId: Scalars['String'];
  brandList: Maybe<Array<Maybe<CrmLink>>>;
  brands: Maybe<Array<Brand>>;
  createdAt: Scalars['DateTime'];
  creativeSubmittedUserId: Maybe<Scalars['String']>;
  derivedSpReview: Maybe<SpReviewV5>;
  displayName: Maybe<Scalars['String']>;
  reviewedAt: Maybe<Scalars['DateTime']>;
  reviewUserId: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
  spReview: Maybe<SpReviewV5>;
  startedAt: Maybe<Scalars['DateTime']>;
  taskStatus: Maybe<ReviewTaskV5Status>;
  transcodeMetadata: Maybe<TranscodeMetadataV5>;
  type: Maybe<ReviewTaskV5Type>;
  updatedAt: Maybe<Scalars['DateTime']>;
  fonziReviewTask: Maybe<Scalars['Boolean']>;
};

export type ReviewTaskV5Brand = {
  idList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReviewTaskV5BrandList = {
  id?: Maybe<BrandIdV5>;
};

export type ReviewTaskV5Creative = {
  __typename?: 'ReviewTaskV5Creative';
  id: Scalars['String'];
  name: Scalars['String'];
  type: CreativeV5Type;
  review: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ReviewTaskV5CreativeInput = {
  urlList?: Maybe<Array<Scalars['String']>>;
};

export type ReviewTaskV5ExternalAsset = {
  dspIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  bidderIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  mediaKeyList?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceAssetIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceAdIdList?: Maybe<Array<Scalars['String']>>;
  timingList?: Maybe<Array<Scalars['String']>>;
  contentPartnerIdList?: Maybe<Array<Scalars['String']>>;
};

export type ReviewTaskV5FilterOptions = {
  adTypeList?: Maybe<Array<Scalars['String']>>;
  asset?: Maybe<ReviewTaskAsset>;
  assetIdList?: Maybe<Array<Scalars['String']>>;
  brandList?: Maybe<Array<Maybe<BrandIdV5>>>;
  createdAtAfter?: Maybe<Scalars['String']>;
  createdAtBefore?: Maybe<Scalars['String']>;
  createdUserIdList?: Maybe<Array<Scalars['String']>>;
  creative?: Maybe<ReviewTaskV5CreativeInput>;
  creativeIdList?: Maybe<Array<Scalars['String']>>;
  creativeSubmittedUserIdList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  displayNameList?: Maybe<Array<Scalars['String']>>;
  externalAsset?: Maybe<ReviewTaskV5ExternalAsset>;
  idList?: Maybe<Array<Scalars['String']>>;
  industry?: Maybe<Scalars['String']>;
  reviewedAtAfter?: Maybe<Scalars['String']>;
  reviewedAtBefore?: Maybe<Scalars['String']>;
  reviewUserIdList?: Maybe<Array<Scalars['String']>>;
  searchTerm?: Maybe<Scalars['String']>;
  selectedIdList?: Maybe<Array<Scalars['String']>>;
  sourceList?: Maybe<Array<Maybe<Scalars['String']>>>;
  spReviewList?: Maybe<Array<Scalars['String']>>;
  startedAtAfter?: Maybe<Scalars['String']>;
  startedAtBefore?: Maybe<Scalars['String']>;
  taskStatusList?: Maybe<Array<Scalars['String']>>;
  updatedAtAfter?: Maybe<Scalars['String']>;
  updatedAtBefore?: Maybe<Scalars['String']>;
};

export const ReviewTaskV5SortOptions = {
  AdTypeList: 'AD_TYPE_LIST',
  AssetCountryCodes: 'ASSET_COUNTRY_CODES',
  AssetCreatedAt: 'ASSET_CREATED_AT',
  AssetId: 'ASSET_ID',
  AssetLanguage: 'ASSET_LANGUAGE',
  AssetName: 'ASSET_NAME',
  AssetReview: 'ASSET_REVIEW',
  Assignee: 'ASSIGNEE',
  Brand: 'BRAND',
  ContentPartner: 'CONTENT_PARTNER',
  CreatedAt: 'CREATED_AT',
  CreativeType: 'CREATIVE_TYPE',
  DisplayName: 'DISPLAY_NAME',
  DuplicateFrame: 'DUPLICATE_FRAME',
  ExternalAssetSourceAdId: 'EXTERNAL_ASSET_SOURCE_AD_ID',
  ExternalAssetSourceAssetId: 'EXTERNAL_ASSET_SOURCE_ASSET_ID',
  Industry: 'INDUSTRY',
  ReviewedAt: 'REVIEWED_AT',
  ReviewTaskId: 'REVIEW_TASK_ID',
  Source: 'SOURCE',
  SpReview: 'SP_REVIEW',
  StartedAt: 'STARTED_AT',
  TaskStatus: 'TASK_STATUS',
  TranscodingStatus: 'TRANSCODING_STATUS',
  UpdatedAt: 'UPDATED_AT',
  VideoQuality: 'VIDEO_QUALITY',
} as const;

export type ReviewTaskV5SortOptions = typeof ReviewTaskV5SortOptions[keyof typeof ReviewTaskV5SortOptions];
export type ReviewTaskV5SortOptionsInput = {
  order?: Maybe<OrderOption>;
  sort?: Maybe<ReviewTaskV5SortOptions>;
};

export type ReviewTaskV5SortOptionsType = {
  __typename?: 'ReviewTaskV5SortOptionsType';
  order: Maybe<OrderOption>;
  sort: Maybe<ReviewTaskV5SortOptions>;
};

export const ReviewTaskV5Status = {
  Completed: 'COMPLETED',
  Failed: 'FAILED',
  New: 'NEW',
  Pending: 'PENDING',
} as const;

export type ReviewTaskV5Status = typeof ReviewTaskV5Status[keyof typeof ReviewTaskV5Status];
export const ReviewTaskV5Type = {
  CreativeReview: 'CREATIVE_REVIEW',
  ExternalAssetReview: 'EXTERNAL_ASSET_REVIEW',
} as const;

export type ReviewTaskV5Type = typeof ReviewTaskV5Type[keyof typeof ReviewTaskV5Type];
export type ReviewedTasksTableFiltersInputV5 = {
  searchTerm?: Maybe<Scalars['String']>;
  selectedRowIds?: Maybe<Array<Scalars['String']>>;
  sortBy?: Maybe<ReviewTaskV5SortOptionsInput>;
};

export type ReviewedTasksTableFiltersV5 = {
  __typename?: 'ReviewedTasksTableFiltersV5';
  searchTerm: Maybe<Scalars['String']>;
  selectedRowIds: Maybe<Array<Scalars['String']>>;
  sortBy: Maybe<ReviewTaskV5SortOptionsType>;
};

export type RoleMappingV4 = {
  __typename?: 'RoleMappingV4';
  entityId: Scalars['ID'];
  entityType: EntityFilterOptionV4;
  role: RoleV4;
};

export type RoleV4 = {
  __typename?: 'RoleV4';
  name: Scalars['String'];
  permissions: Maybe<Array<Maybe<Permissions>>>;
};

export type Rotation = {
  __typename?: 'Rotation';
  rotationId: Scalars['ID'];
  name: Scalars['String'];
  order: Maybe<Scalars['Int']>;
  adRotationList: Array<AdRotation>;
};

export type RotationInput = {
  rotationId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  adRotationList: Array<AdRotationInput>;
};

export type RotationNameInput = {
  rotationId: Scalars['ID'];
  name: Scalars['String'];
};

export type RotationsInput = {
  lineItemId: Scalars['ID'];
  rotationRequestList: Array<RotationInput>;
};

export const SpReviewV5 = {
  Approved: 'APPROVED',
  Denied: 'DENIED',
  Locked: 'LOCKED',
  Unlocked: 'UNLOCKED',
} as const;

export type SpReviewV5 = typeof SpReviewV5[keyof typeof SpReviewV5];
export type ScheduleFilterInputV5 = {
  timezone: Scalars['String'];
  start: AdDateHourInput;
  end: AdDateHourInput;
  status: ScheduleFilterStatusV5;
};

export const ScheduleFilterStatusV5 = {
  Starting: 'STARTING',
  Running: 'RUNNING',
  Ending: 'ENDING',
} as const;

export type ScheduleFilterStatusV5 = typeof ScheduleFilterStatusV5[keyof typeof ScheduleFilterStatusV5];
export type ScheduleFilterV5 = {
  __typename?: 'ScheduleFilterV5';
  timezone: Scalars['String'];
  start: AdDateHour;
  end: AdDateHour;
  status: ScheduleFilterStatusV5;
};

export type ScheduleInputV5 = {
  timezone: Scalars['String'];
  deliveryInViewerTime: Scalars['Boolean'];
  dateRangeList: Array<DateRangeInputV5>;
  dayPartList?: Maybe<Array<DayPartInputV5>>;
};

/** The Schedule object holds information about the line item delivery schedule, including day parting. */
export type ScheduleV5 = {
  __typename?: 'ScheduleV5';
  /** The timezone associated with the ad or line item. */
  timezone: Scalars['String'];
  /** The timezone associated with the ad or line item. */
  deliveryInViewerTime: Scalars['Boolean'];
  /** The date ranges when the ad or line item will be delivered */
  dateRangeList: Array<DateRangeV5>;
  /** The times and days of the week when the ad or line item will be delivered */
  dayPartList: Maybe<Array<DayPartV5>>;
};

export const SearchCreativeSortOption = {
  CreatedAt: 'CREATED_AT',
} as const;

export type SearchCreativeSortOption = typeof SearchCreativeSortOption[keyof typeof SearchCreativeSortOption];
export type SearchCreativeSortOptions = {
  sort: SearchCreativeSortOption;
  order?: Maybe<OrderOption>;
};

export type SearchTerms = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type SelfServeExternalAssetV5 = ExternalAssetV5 &
  Node & {
    __typename?: 'SelfServeExternalAssetV5';
    id: Scalars['ID'];
    brand: Maybe<CrmLinkV5>;
    timing: Maybe<ExternalAssetV5Timing>;
    type: Maybe<ExternalAssetV5Type>;
    url: Maybe<Scalars['String']>;
  };

export type Sequence = {
  __typename?: 'Sequence';
  id: Scalars['String'];
  type: Scalars['String'];
  name: Maybe<Scalars['String']>;
  adSequence: Maybe<Array<Maybe<AdSequence>>>;
  createdAt: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type SequenceInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  adSequence?: Maybe<Array<Maybe<AdSequenceInput>>>;
};

export type ShareableLink = {
  __typename?: 'ShareableLink';
  id: Scalars['String'];
};

export type SimpleChangeValue = BaseChangeValue & {
  __typename?: 'SimpleChangeValue';
  action: ChangeAction;
  payload: Maybe<Scalars['JSONDataType']>;
};

/** A Change whose value is a single element (String, Boolean, Object, etc...) */
export type SingleValueChange = BaseChange & {
  __typename?: 'SingleValueChange';
  fieldName: Scalars['String'];
  oldValue: SimpleChangeValue;
  newValue: SimpleChangeValue;
};

export type SlateCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  slateId?: Maybe<Scalars['String']>;
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type SlateCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'SlateCreativeV5';
    slate: Maybe<CreativeV5Asset>;
    /**
     * Creative Manager stores only asset id
     * slateId is being used to handle mapping to BaseAssetV5 in slate
     */
    slateId: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type SlateTypePropertiesInputV5 = {
  slate: IdInputV5;
};

export type SlateTypePropertiesV5 = {
  __typename?: 'SlateTypePropertiesV5';
  slate: CreativeV5Asset;
};

export type TagInput = {
  type: AdTagType;
  event: EventType;
  isIas?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  isVerified: Scalars['Boolean'];
  unDeletable?: Maybe<Scalars['Boolean']>;
};

export type TargetingAttributeMap = {
  __typename?: 'TargetingAttributeMap';
  publisher: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  premiereDateTime: Maybe<Scalars['String']>;
  salesChannel: Maybe<Scalars['String']>;
  sportId: Maybe<Scalars['String']>;
  sportName: Maybe<Scalars['String']>;
};

export type TargetingCategory = {
  __typename?: 'TargetingCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  primarySource: TargetingSource;
  additionalSource: Array<TargetingSource>;
  attributeMap: Maybe<TargetingAttributeMap>;
};

export type TargetingChangesMap = {
  __typename?: 'TargetingChangesMap';
  old: Maybe<TargetingChangesTermList>;
  new: Maybe<TargetingChangesTermList>;
};

export type TargetingChangesTermList = {
  __typename?: 'TargetingChangesTermList';
  termList: Maybe<Array<Maybe<TargetingRuleDefinitionV4>>>;
};

export type TargetingDimension = {
  __typename?: 'TargetingDimension';
  /** The id field is pulled from the DAAPI targeting dimension's dimension-id field. It must be a String */
  id: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  primarySource: TargetingSource;
  additionalSource: Array<TargetingSource>;
  attributeMap: Maybe<TargetingAttributeMap>;
};

/** Targeting preset is a set of targeting terms that can be used to target */
export type TargetingPreset = Node & {
  __typename?: 'TargetingPreset';
  /** Preset ID */
  id: Scalars['ID'];
  /** Preset name */
  name: Scalars['String'];
  /** Preset description */
  description: Maybe<Scalars['String']>;
  /** Is preset archived */
  archived: Scalars['Boolean'];
  /** Preset definition */
  definition: Array<TargetingTermWithBaseValue>;
  /** Preset creation time */
  createdAt: Scalars['DateTime'];
  /** Preset last update time */
  updatedAt: Maybe<Scalars['DateTime']>;
  /** Preset type */
  type: PresetType;
  /** Field Name */
  fieldName: Maybe<Scalars['String']>;
};

export type TargetingPresetEdge = Edge & {
  __typename?: 'TargetingPresetEdge';
  cursor: Scalars['String'];
  node: TargetingPreset;
};

export type TargetingPresetFilterOptions = {
  /** List of Preset IDs to filter results by */
  idList?: Maybe<Array<Scalars['String']>>;
  /** Filter by active or archived presets */
  archived?: Maybe<Scalars['Boolean']>;
  /** Filter to lookup presets that share a similar name */
  name?: Maybe<Scalars['String']>;
  /** Combination filter that will match on presets that include the searchTerm value in the Preset Name or presets whose ID matches the searchTerm value */
  searchTerm?: Maybe<Scalars['String']>;
  /** Filter by the preset type */
  type?: Maybe<PresetType>;
};

export type TargetingPresetInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  definition: Array<TargetingTermValueInput>;
  type: PresetType;
  fieldName?: Maybe<Scalars['String']>;
};

export type TargetingPresetPage = Connection & {
  __typename?: 'TargetingPresetPage';
  edges: Array<TargetingPresetEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export const TargetingPresetSortByOption = {
  UpdatedAt: 'updatedAt',
} as const;

export type TargetingPresetSortByOption = typeof TargetingPresetSortByOption[keyof typeof TargetingPresetSortByOption];
export type TargetingPresetSortOptions = {
  sortBy?: Maybe<TargetingPresetSortByOption>;
  sortOrder?: Maybe<OrderOption>;
};

export type TargetingPresetUpdateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  definition?: Maybe<Array<TargetingTermValueInput>>;
  archived?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PresetType>;
};

/** An object representing what the API will send back incase there are more records to be searched for. */
export type TargetingProviderLink = {
  __typename?: 'TargetingProviderLink';
  first: Maybe<Scalars['String']>;
  next: Maybe<Scalars['String']>;
  prev: Maybe<Scalars['String']>;
  last: Maybe<Scalars['String']>;
};

export type TargetingRule = {
  __typename?: 'TargetingRule';
  id: Maybe<Scalars['String']>;
  definition: Maybe<TargetingRuleDefinitionV4>;
};

export type TargetingRuleDefinitionV4 = {
  __typename?: 'TargetingRuleDefinitionV4';
  /** If true this definition is an exclusion */
  not: Maybe<Scalars['Boolean']>;
  /** Operation of lineitem or ad with AND OR logic */
  operation: Maybe<Scalars['String']>;
  /** Dimension of the definition ex. genre, publisher, country, city, state etc. */
  dimension: Maybe<Scalars['String']>;
  /**
   * An array of definitions related to the operations logical operator
   * Can be nested 2 levels deep
   */
  termList: Maybe<Array<Maybe<TargetingRuleDefinitionV4>>>;
  /**
   * Rule manager class ex.
   * com.disney.digital.ads.rule.manager.common.And
   * com.disney.digital.ads.rule.manager.common.Or
   * com.disney.digital.ads.rule.manager.common.Include
   */
  subClass: Maybe<Scalars['String']>;
  /**
   * An array containing targeting value strings related to a dimension
   * ex. dimension: genre   valueSet: action, comedy, etc.
   */
  valueSet: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * An array that defines the min and max of a range.
   * ex. dimension: age rangeList: [{lower: 0, upper: 16}, {lower: 20, upper: 35}] ages 0-16 and 20-35
   */
  rangeList: Maybe<Array<Maybe<RangeList>>>;
};

export type TargetingRuleDefinitionV4Input = {
  /** If true this definition is an exclusion */
  not?: Maybe<Scalars['Boolean']>;
  /** Operation of lineitem or ad with AND OR logic */
  operation?: Maybe<Scalars['String']>;
  /** Dimension of the definition ex. genre, publisher, country, city, state etc. */
  dimension?: Maybe<Scalars['String']>;
  /**
   * An array of definitions related to the operations logical operator
   * Can be nested 2 levels deep
   */
  termList?: Maybe<Array<Maybe<TargetingRuleDefinitionV4Input>>>;
  /**
   * Rule manager class ex.
   * com.disney.digital.ads.rule.manager.common.And
   * com.disney.digital.ads.rule.manager.common.Or
   * com.disney.digital.ads.rule.manager.common.Include
   */
  subClass?: Maybe<Scalars['String']>;
  /**
   * An array containing targeting value strings related to a dimension
   * ex. dimension: genre   valueSet: action, comedy, etc.
   */
  valueSet?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * An array that defines the min and max of a range.
   * ex. dimension: age rangeList: [{lower: 0, upper: 16}, {lower: 20, upper: 35}] ages 0-16 and 20-35
   */
  rangeList?: Maybe<Array<Maybe<RangeListInput>>>;
};

export type TargetingRuleInput = {
  id?: Maybe<Scalars['String']>;
  definition?: Maybe<TargetingRuleDefinitionV4Input>;
};

export type TargetingRuleWithPopulatedValues = {
  __typename?: 'TargetingRuleWithPopulatedValues';
  dimension: Scalars['String'];
  valueSet: Array<TargetingValue>;
};

export type TargetingSource = {
  __typename?: 'TargetingSource';
  id: Scalars['String'];
  name: Scalars['String'];
  system: Maybe<Scalars['String']>;
  source: Maybe<Scalars['String']>;
};

export type TargetingTermValue = TargetingTermValueBody & {
  __typename?: 'TargetingTermValue';
  category: Maybe<TargetingCategory>;
  dimension: Maybe<TargetingDimension>;
  value: Maybe<TargetingValue>;
  include: Maybe<Scalars['Boolean']>;
};

export type TargetingTermValueBody = {
  category: Maybe<TargetingCategory>;
  dimension: Maybe<TargetingDimension>;
  include: Maybe<Scalars['Boolean']>;
};

export type TargetingTermValueInput = {
  dimensionName: Scalars['String'];
  valueName: Scalars['String'];
  include: Scalars['Boolean'];
};

export type TargetingTermWithBaseValue = TargetingTermValueBody & {
  __typename?: 'TargetingTermWithBaseValue';
  category: Maybe<TargetingCategory>;
  dimension: Maybe<TargetingDimension>;
  value: Maybe<BaseTargetingValue>;
  include: Maybe<Scalars['Boolean']>;
};

export type TargetingValue = Node &
  TargetingValueBody & {
    __typename?: 'TargetingValue';
    id: Scalars['ID'];
    name: Scalars['String'];
    displayName: Scalars['String'];
    primarySource: TargetingSource;
    additionalSource: Array<TargetingSource>;
    description: Maybe<Scalars['String']>;
    definition: Maybe<Array<TargetingTermWithBaseValue>>;
    attributeMap: Maybe<TargetingAttributeMap>;
  };

export type TargetingValueBody = {
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  primarySource: TargetingSource;
  additionalSource: Array<TargetingSource>;
  description: Maybe<Scalars['String']>;
  attributeMap: Maybe<TargetingAttributeMap>;
};

export type TargetingValueEdge = Edge & {
  __typename?: 'TargetingValueEdge';
  cursor: Scalars['String'];
  node: TargetingValue;
};

export type TargetingValueFilterOptions = {
  searchTerm?: Maybe<Scalars['String']>;
};

export type TargetingValuePage = Connection & {
  __typename?: 'TargetingValuePage';
  edges: Array<TargetingValueEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type TextAssetV5 = BaseAssetV5 &
  Node & {
    __typename?: 'TextAssetV5';
    assetTagList: Maybe<Array<AssetTagV5>>;
    comments: Maybe<Scalars['String']>;
    countryCodes: Maybe<Array<Maybe<CountryCode>>>;
    createdAt: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    deleted: Maybe<Scalars['Boolean']>;
    externalAssetDto: Maybe<Scalars['String']>;
    filename: Maybe<Scalars['String']>;
    fileSize: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    language: Maybe<Language>;
    publisher: Maybe<Scalars['String']>;
    md5: Scalars['String'];
    mediaMetadata: Maybe<AssetMetaDataV5>;
    name: Scalars['String'];
    outletKey: Maybe<Scalars['String']>;
    rating: Maybe<Scalars['String']>;
    rejectionReasons: Maybe<Array<AssetRejectionReasonV5>>;
    review: Scalars['String'];
    s3Url: Maybe<Scalars['String']>;
    transcodeFailureDetails: Maybe<TranscodingFailure>;
    transcodeStatus: Maybe<TranscodeStatusV5>;
    type: AssetTypeV5;
    updatedAt: Maybe<Scalars['DateTime']>;
    approvedBrand: Maybe<CrmLinkV5>;
    approvedPublishers: Maybe<Array<Scalars['String']>>;
    text: Scalars['String'];
  };

export type TierNameInput = {
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  huluCommitment?: Maybe<Scalars['String']>;
};

export type TraffickerEmail = Node & {
  __typename?: 'TraffickerEmail';
  id: Scalars['ID'];
  email: Scalars['String'];
};

export type TraffickerEmailEdge = Edge & {
  __typename?: 'TraffickerEmailEdge';
  cursor: Scalars['String'];
  node: TraffickerEmail;
};

export type TraffickerEmailsPage = Connection & {
  __typename?: 'TraffickerEmailsPage';
  edges: Array<TraffickerEmailEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type TraffickingFilters = {
  __typename?: 'TraffickingFilters';
  productType: Maybe<Array<Scalars['String']>>;
  dateFilter: Maybe<DateFilter>;
  accountManager: Maybe<Array<UserFilter>>;
  accountExecutive: Maybe<Array<UserFilter>>;
  pacingRiskList: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule: Maybe<Scalars['Boolean']>;
  generalPacingStatusList: Maybe<Array<Scalars['String']>>;
  adStatusList: Maybe<Array<Scalars['String']>>;
  reviewStatusList: Maybe<Array<Scalars['String']>>;
  orderTypeList: Maybe<Array<Scalars['String']>>;
  lineItemStatusList: Maybe<Array<Scalars['String']>>;
  tierNameList: Maybe<Array<Scalars['String']>>;
  priorityValueList: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList: Maybe<Array<Scalars['String']>>;
  publisherList: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru: Maybe<Array<Scalars['String']>>;
  isMakegood: Maybe<Array<Scalars['String']>>;
  isAddedValue: Maybe<Array<Scalars['String']>>;
  adRatingList: Maybe<Array<Scalars['String']>>;
  adTypeList: Maybe<Array<Scalars['String']>>;
  creativeTypeList: Maybe<Array<Scalars['String']>>;
  creativeReviewList: Maybe<Array<Scalars['String']>>;
  brandIdList: Maybe<Array<OrderFilter>>;
  agencyIdList: Maybe<Array<OrderFilter>>;
  industryIdList: Maybe<Array<OrderFilter>>;
  isRotation: Maybe<Array<Scalars['String']>>;
  isSequence: Maybe<Array<Scalars['String']>>;
  currencyList: Maybe<Array<Scalars['String']>>;
  selectedTab: Maybe<Scalars['String']>;
  campaigns: Maybe<CampaignTableFilters>;
  lineItems: Maybe<LineItemTableFilters>;
  ads: Maybe<AdTableFilters>;
  countryList: Maybe<Array<Scalars['String']>>;
};

export type TraffickingFiltersInput = {
  productType?: Maybe<Array<Scalars['String']>>;
  dateFilter?: Maybe<DateFilterInput>;
  accountManager?: Maybe<Array<UserFilterInput>>;
  accountExecutive?: Maybe<Array<UserFilterInput>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  lineItemStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  brandIdList?: Maybe<Array<OrderFilterInput>>;
  agencyIdList?: Maybe<Array<OrderFilterInput>>;
  industryIdList?: Maybe<Array<OrderFilterInput>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  selectedTab: Scalars['String'];
  campaigns?: Maybe<CampaignTableFiltersInput>;
  lineItems?: Maybe<LineItemTableFiltersInput>;
  ads?: Maybe<AdTableFiltersInput>;
  countryList?: Maybe<Array<Scalars['String']>>;
};

export type TranscodeMetadataV5 = {
  __typename?: 'TranscodeMetadataV5';
  id: Maybe<Scalars['ID']>;
  detectionResult: Maybe<DetectionResultV5>;
  detectorStatus: Maybe<DetectorStatusV5>;
  qcPlaylist: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export const TranscodeStatusV5 = {
  Pending: 'PENDING',
  PreProcessing: 'PRE_PROCESSING',
  PreProcessingComplete: 'PRE_PROCESSING_COMPLETE',
  PreProcessingError: 'PRE_PROCESSING_ERROR',
  PreProcessingAbort: 'PRE_PROCESSING_ABORT',
  Publishing: 'PUBLISHING',
  PublishingComplete: 'PUBLISHING_COMPLETE',
  PublishingError: 'PUBLISHING_ERROR',
  PublishingAbort: 'PUBLISHING_ABORT',
  Streamable: 'STREAMABLE',
  StreamableChecking: 'STREAMABLE_CHECKING',
  StreamableCheckingError: 'STREAMABLE_CHECKING_ERROR',
  StreamableAbort: 'STREAMABLE_ABORT',
  StreamableError: 'STREAMABLE_ERROR',
} as const;

export type TranscodeStatusV5 = typeof TranscodeStatusV5[keyof typeof TranscodeStatusV5];
export type TranscodingAsset = {
  __typename?: 'TranscodingAsset';
  assetRecord: Maybe<TranscodingAssetRecord>;
  currentState: Maybe<Scalars['String']>;
  detectionResults: Maybe<TranscodingAssetDetectionResults>;
  trackInfo: Maybe<Array<Maybe<TranscodingAssetTrackInfo>>>;
};

export type TranscodingAssetDetectionResult = {
  __typename?: 'TranscodingAssetDetectionResult';
  id: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  details: Maybe<Scalars['String']>;
  detectionLevel: Maybe<Scalars['String']>;
};

export type TranscodingAssetDetectionResults = {
  __typename?: 'TranscodingAssetDetectionResults';
  assetId: Maybe<Scalars['ID']>;
  detectionResult: Maybe<Array<Maybe<TranscodingAssetDetectionResult>>>;
  qcPlaylist: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
};

export type TranscodingAssetRecord = {
  __typename?: 'TranscodingAssetRecord';
  assetId: Maybe<Scalars['ID']>;
  createAt: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  masterPlayList: Maybe<Scalars['String']>;
  mediaId: Maybe<Scalars['ID']>;
  processInfo: Maybe<Scalars['String']>;
  processor: Maybe<Scalars['Int']>;
  qcManifest: Maybe<Scalars['String']>;
  transcodeState: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  updateAt: Maybe<Scalars['String']>;
};

export type TranscodingAssetTrackInfo = {
  __typename?: 'TranscodingAssetTrackInfo';
  createAt: Maybe<Scalars['String']>;
  processor: Maybe<Scalars['Int']>;
  transcodingStatus: Maybe<Scalars['String']>;
};

export type TranscodingFailure = {
  __typename?: 'TranscodingFailure';
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  timestamp: Maybe<Scalars['String']>;
};

export type TranscodingFailureInput = {
  timestamp?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type UmpDxcExecutable = {
  id: Scalars['ID'];
  messageConfigId: Scalars['ID'];
  subjectLine: Scalars['String'];
  bodyDescription: Scalars['String'];
  callToActionUrl: Scalars['String'];
  subClass: Scalars['String'];
};

export type UpdateAdInputV5 = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<AdStatusV5>;
  rating?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  dateRangeList?: Maybe<Array<DateRangeInputV5>>;
  dayPartList?: Maybe<Array<DayPartInputV5>>;
  creativeId?: Maybe<Scalars['ID']>;
  frequencyCapList?: Maybe<Array<FrequencyCapInputV5>>;
  viewabilityVendorList?: Maybe<Array<ViewabilityVendor>>;
  adTagList?: Maybe<Array<AdTagInput>>;
  targetingTermValues?: Maybe<Array<TargetingTermValueInput>>;
  deliveryInViewerTime?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
};

export type UpdateEntriesVerificationInput = {
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  type: VerificationEntryType;
};

export type UpdateLineItemInputV5 = {
  status?: Maybe<Scalars['String']>;
  subStatus?: Maybe<Scalars['String']>;
  deliveryBufferPercent?: Maybe<Scalars['Float']>;
  frontLoadPercent?: Maybe<Scalars['Float']>;
  frequencyCapList?: Maybe<Array<FrequencyCapInputV5>>;
  targetingTermValues?: Maybe<Array<TargetingTermValueInput>>;
  priority?: Maybe<LineItemPriorityInputV5>;
  tier?: Maybe<LineItemTierInputV5>;
  targetingRule?: Maybe<TargetingRuleInput>;
};

export type UpdatedCampaignReviewError = {
  __typename?: 'UpdatedCampaignReviewError';
  createdErrors: Array<CampaignReviewError>;
  deletedErrorIds: Array<Maybe<Scalars['String']>>;
};

export type UpsertUsersWithRoleFailedRequest = {
  __typename?: 'UpsertUsersWithRoleFailedRequest';
  email: Scalars['String'];
  error: Maybe<Scalars['JSONDataType']>;
};

export type UpsertUsersWithRoleOptions = {
  roleName: Scalars['String'];
  entityType: EntityFilterOptionV4;
  entityId: Scalars['String'];
  requests: Array<UpsertUsersWithRoleRequest>;
};

export type UpsertUsersWithRoleRequest = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpsertUsersWithRoleResult = {
  __typename?: 'UpsertUsersWithRoleResult';
  failedRequests: Array<UpsertUsersWithRoleFailedRequest>;
  successfulRequests: Array<UserV4>;
};

export type UserEdgeV4 = Edge & {
  __typename?: 'UserEdgeV4';
  cursor: Scalars['String'];
  node: UserV4;
};

export type UserFilter = {
  __typename?: 'UserFilter';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UserFilterInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type UserFilterOptions = {
  selectedIdList?: Maybe<Array<Scalars['ID']>>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type UserPageV4 = Connection & {
  __typename?: 'UserPageV4';
  edges: Array<UserEdgeV4>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type UserRoleChange = {
  __typename?: 'UserRoleChange';
  changeType: Maybe<ChangeType>;
  userEmail: Maybe<Scalars['String']>;
  modifiedDate: Maybe<Scalars['String']>;
};

export type UserRoleChangeIntermediateEntry = {
  __typename?: 'UserRoleChangeIntermediateEntry';
  modifiedBy: Maybe<Scalars['String']>;
  modifiedDate: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
  changeType: Maybe<ChangeType>;
  userEmail: Maybe<Scalars['String']>;
};

export type UserRoleChangeLogEdge = Edge & {
  __typename?: 'UserRoleChangeLogEdge';
  cursor: Scalars['String'];
  node: UserRoleChangeLogGroup;
};

export type UserRoleChangeLogGroup = Node & {
  __typename?: 'UserRoleChangeLogGroup';
  id: Scalars['ID'];
  key: Maybe<UserRoleChangeLogKey>;
  changes: Maybe<Array<Maybe<UserRoleChange>>>;
};

export type UserRoleChangeLogKey = {
  __typename?: 'UserRoleChangeLogKey';
  modifiedBy: Maybe<Scalars['String']>;
  fromDate: Maybe<Scalars['DateTime']>;
  toDate: Maybe<Scalars['DateTime']>;
  role: Maybe<Scalars['String']>;
};

export type UserRoleChangeLogPage = Connection & {
  __typename?: 'UserRoleChangeLogPage';
  edges: Array<UserRoleChangeLogEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type UserV4 = Node & {
  __typename?: 'UserV4';
  /** The user id. */
  id: Scalars['ID'];
  /** The user email. */
  email: Scalars['String'];
  /** The user first name. */
  firstName: Scalars['String'];
  /** The user last name. */
  lastName: Scalars['String'];
  /** The ID and other information from the CRM system about the contact associated with the ad account. */
  contactCrmLink: Maybe<ContactCrmLink>;
  /** The user title. */
  title: Maybe<Scalars['String']>;
  /** The user phone. */
  phone: Maybe<Scalars['String']>;
  /** The user roles V4. */
  roles: Maybe<Array<RoleMappingV4>>;
  /** The ad account types. */
  adAccountTypes: Maybe<Array<Scalars['String']>>;
};

export type UserV4SortOptions = {
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<OrderOption>;
};

export type VastVideoCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'VASTVideoCreativeV5';
    url: Maybe<Scalars['String']>;
    /** @deprecated April 24 UML update */
    vpaid: Maybe<Scalars['Boolean']>;
    videoAssetList: Maybe<Array<CreativeV5Asset>>;
    /**
     * Creative Manager stores only assets id
     * videoAssetIdList is being used to handle mapping to BaseAssetV5 in videoAssetList
     */
    videoAssetIdList: Maybe<Array<Scalars['String']>>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type VastVideoTypePropertiesV5 = {
  __typename?: 'VASTVideoTypePropertiesV5';
  url: Scalars['String'];
};

export type VastVideoCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  review?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  vpaid: Scalars['Boolean'];
  videoAssetIdList?: Maybe<Array<Maybe<Scalars['String']>>>;
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type VastVideoTypePropertiesInputV5 = {
  url: Scalars['String'];
};

export const VerificationEntryType = {
  Click: 'CLICK',
  VastUrl: 'VAST_URL',
  Billable: 'BILLABLE',
  NonBillable: 'NON_BILLABLE',
} as const;

export type VerificationEntryType = typeof VerificationEntryType[keyof typeof VerificationEntryType];
export type VerificationResponse = {
  __typename?: 'VerificationResponse';
  verifiedEntries: Maybe<Array<VerifiedEntry>>;
  errors: Maybe<Array<ResponseError>>;
};

/** VerifiedEntry is a entry which fields are updated after verification */
export type VerifiedEntry = AdV5 | AdTag;

export type VideoAssetV5 = BaseAssetV5 &
  Node & {
    __typename?: 'VideoAssetV5';
    assetTagList: Maybe<Array<AssetTagV5>>;
    comments: Maybe<Scalars['String']>;
    countryCodes: Maybe<Array<Maybe<CountryCode>>>;
    createdAt: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    deleted: Maybe<Scalars['Boolean']>;
    externalAssetDto: Maybe<Scalars['String']>;
    filename: Maybe<Scalars['String']>;
    fileSize: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    language: Maybe<Language>;
    publisher: Maybe<Scalars['String']>;
    md5: Scalars['String'];
    mediaMetadata: Maybe<AssetMetaDataV5>;
    name: Scalars['String'];
    outletKey: Maybe<Scalars['String']>;
    rating: Maybe<Scalars['String']>;
    rejectionReasons: Maybe<Array<AssetRejectionReasonV5>>;
    review: Scalars['String'];
    s3Url: Maybe<Scalars['String']>;
    transcodeFailureDetails: Maybe<TranscodingFailure>;
    transcodeStatus: Maybe<TranscodeStatusV5>;
    type: AssetTypeV5;
    updatedAt: Maybe<Scalars['DateTime']>;
    approvedBrand: Maybe<CrmLinkV5>;
    approvedPublishers: Maybe<Array<Scalars['String']>>;
    duration: Maybe<Scalars['Int']>;
  };

export type VideoCreativeInputV5 = {
  name: Scalars['String'];
  type?: Maybe<CreativeV5Type>;
  videoAssetId?: Maybe<Scalars['String']>;
  creativeLibraryId?: Maybe<Scalars['String']>;
};

export type VideoCreativeV5 = BaseCreativeV5 &
  Node & {
    __typename?: 'VideoCreativeV5';
    video: Maybe<CreativeV5Asset>;
    /**
     * Creative Manager stores only asset id
     * videoAssetId is being used to handle mapping to BaseAssetV5 in video
     */
    videoAssetId: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: CreativeV5Type;
    metadata: Maybe<CreativeMetadata>;
    name: Scalars['String'];
    review: Maybe<Scalars['String']>;
    rejectionReason: Maybe<Scalars['String']>;
    rejectionReasonDetails: Maybe<Array<Scalars['String']>>;
    qrEmbedded: Maybe<Scalars['Boolean']>;
    status: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Maybe<Scalars['DateTime']>;
    deleted: Scalars['Boolean'];
    rating: Maybe<Scalars['String']>;
    creativeLibraryId: Maybe<Scalars['String']>;
    assetTagList: Maybe<Array<Maybe<AssetTagV5>>>;
  };

export type VideoData = {
  __typename?: 'VideoData';
  codec: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Float']>;
  height: Maybe<Scalars['Float']>;
  bitRate: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['Float']>;
  language: Maybe<Scalars['String']>;
  scanType: Maybe<Scalars['String']>;
  colorspace: Maybe<Scalars['String']>;
  frameRate: Maybe<Scalars['String']>;
  frameCount: Maybe<Scalars['String']>;
  trackIndex: Maybe<Scalars['Float']>;
  closedCaptions: Maybe<Scalars['Float']>;
  pixelAspectRatio: Maybe<Scalars['String']>;
  displayAspectRatio: Maybe<Scalars['String']>;
};

export type VideoTypePropertiesInputV5 = {
  video: IdInputV5;
};

export type VideoTypePropertiesV5 = {
  __typename?: 'VideoTypePropertiesV5';
  video: CreativeV5Asset;
};

export const ViewabilityVendor = {
  Moat: 'MOAT',
  Ias: 'IAS',
  Iab: 'IAB',
  Doubleverify: 'DOUBLEVERIFY',
} as const;

export type ViewabilityVendor = typeof ViewabilityVendor[keyof typeof ViewabilityVendor];

/**
 * The WeightedPace object holds the pacing percentage and schedule expiration date for the line item.
 * For example, a weighted pacing may look like shown below if 30% is set to be delivered until the 10th of the month and 50% until the 20th of the month.
 */
export type WeightedPace = {
  __typename?: 'WeightedPace';
  /** The pacing percentage assigned to the line item. */
  weight: Scalars['Float'];
  /**
   * The pacing schedule expiration date and time.
   * If the specified time period includes dark days, the line item delivery will be spread equally across the non-dark days.
   */
  until: AdDateHour;
};

export type WeightedPaceInput = {
  weight: Scalars['Float'];
  until: AdDateHourInput;
};

export type TargetingRuleDefinitionFragment = { __typename?: 'TargetingRule' } & {
  definition: Maybe<
    { __typename?: 'TargetingRuleDefinitionV4' } & Pick<TargetingRuleDefinitionV4, 'not' | 'subClass'> & {
        termList: Maybe<
          Array<
            Maybe<
              { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                TargetingRuleDefinitionV4,
                'not' | 'dimension' | 'subClass' | 'valueSet'
              > & {
                  termList: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                          TargetingRuleDefinitionV4,
                          'not' | 'dimension' | 'subClass' | 'valueSet'
                        > & {
                            termList: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                                    TargetingRuleDefinitionV4,
                                    'not' | 'dimension' | 'subClass' | 'valueSet'
                                  > & {
                                      termList: Maybe<
                                        Array<
                                          Maybe<
                                            { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                                              TargetingRuleDefinitionV4,
                                              'not' | 'dimension' | 'subClass' | 'valueSet'
                                            > & {
                                                termList: Maybe<
                                                  Array<
                                                    Maybe<
                                                      { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                                                        TargetingRuleDefinitionV4,
                                                        'not' | 'dimension' | 'subClass' | 'valueSet'
                                                      > & {
                                                          termList: Maybe<
                                                            Array<
                                                              Maybe<
                                                                { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                                                                  TargetingRuleDefinitionV4,
                                                                  'not' | 'dimension' | 'subClass' | 'valueSet'
                                                                > & {
                                                                    termList: Maybe<
                                                                      Array<
                                                                        Maybe<
                                                                          {
                                                                            __typename?: 'TargetingRuleDefinitionV4';
                                                                          } & Pick<
                                                                            TargetingRuleDefinitionV4,
                                                                            | 'not'
                                                                            | 'dimension'
                                                                            | 'subClass'
                                                                            | 'valueSet'
                                                                          > & {
                                                                              termList: Maybe<
                                                                                Array<
                                                                                  Maybe<
                                                                                    {
                                                                                      __typename?: 'TargetingRuleDefinitionV4';
                                                                                    } & Pick<
                                                                                      TargetingRuleDefinitionV4,
                                                                                      | 'not'
                                                                                      | 'dimension'
                                                                                      | 'subClass'
                                                                                      | 'valueSet'
                                                                                    > & {
                                                                                        termList: Maybe<
                                                                                          Array<
                                                                                            Maybe<
                                                                                              {
                                                                                                __typename?: 'TargetingRuleDefinitionV4';
                                                                                              } & Pick<
                                                                                                TargetingRuleDefinitionV4,
                                                                                                | 'not'
                                                                                                | 'dimension'
                                                                                                | 'subClass'
                                                                                                | 'valueSet'
                                                                                              > & {
                                                                                                  termList: Maybe<
                                                                                                    Array<
                                                                                                      Maybe<
                                                                                                        {
                                                                                                          __typename?: 'TargetingRuleDefinitionV4';
                                                                                                        } & Pick<
                                                                                                          TargetingRuleDefinitionV4,
                                                                                                          | 'not'
                                                                                                          | 'dimension'
                                                                                                          | 'subClass'
                                                                                                          | 'valueSet'
                                                                                                        >
                                                                                                      >
                                                                                                    >
                                                                                                  >;
                                                                                                }
                                                                                            >
                                                                                          >
                                                                                        >;
                                                                                      }
                                                                                  >
                                                                                >
                                                                              >;
                                                                            }
                                                                        >
                                                                      >
                                                                    >;
                                                                  }
                                                              >
                                                            >
                                                          >;
                                                        }
                                                    >
                                                  >
                                                >;
                                              }
                                          >
                                        >
                                      >;
                                    }
                                >
                              >
                            >;
                          }
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type TargetingTermValuesFragment = { __typename?: 'TargetingTermValue' } & Pick<
  TargetingTermValue,
  'include'
> & {
    category: Maybe<
      { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'> & {
          primarySource: { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>;
          additionalSource: Array<{ __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>>;
        }
    >;
    dimension: Maybe<
      { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'> & {
          primarySource: { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>;
          additionalSource: Array<{ __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>>;
        }
    >;
    value: Maybe<
      { __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName' | 'description'> & {
          definition: Maybe<
            Array<
              { __typename?: 'TargetingTermWithBaseValue' } & {
                category: Maybe<
                  { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>
                >;
                dimension: Maybe<
                  { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'>
                >;
                value: Maybe<
                  { __typename?: 'BaseTargetingValue' } & Pick<
                    BaseTargetingValue,
                    'id' | 'name' | 'displayName' | 'description'
                  >
                >;
              }
            >
          >;
          primarySource: { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>;
          additionalSource: Array<{ __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>>;
        }
    >;
  };

export type Get_Ads_With_Creative_By_Parent_Line_Oms_LinkQueryVariables = Exact<{
  lineItemParentLineOmsLinkList: Array<Scalars['ID']>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeName?: Maybe<Scalars['String']>;
}>;

export type Get_Ads_With_Creative_By_Parent_Line_Oms_LinkQuery = { __typename?: 'Query' } & {
  adsV5: { __typename?: 'AdsPageV5' } & {
    edges: Array<
      { __typename?: 'AdEdgeV5' } & {
        node: { __typename?: 'AdV5' } & Pick<AdV5, 'id'> & {
            creative: Maybe<
              | ({ __typename?: 'AdSelectorSlateCreativeV5' } & Pick<AdSelectorSlateCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'AdSelectorVideoCreativeV5' } & Pick<AdSelectorVideoCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'AuditBaseCreativeV5' } & Pick<AuditBaseCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'BrightlineCreativeV5' } & Pick<BrightlineCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'DraxCreativeV5' } & Pick<DraxCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'GatewayGoCreativeV5' } & Pick<GatewayGoCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'InnovidCreativeV5' } & Pick<InnovidCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'InstreamDxcCreativeV5' } & Pick<InstreamDxcCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'MagniteCreativeV5' } & Pick<MagniteCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'PauseCreativeV5' } & Pick<PauseCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'SlateCreativeV5' } & Pick<SlateCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'VASTVideoCreativeV5' } & Pick<VastVideoCreativeV5, 'id' | 'name'>)
              | ({ __typename?: 'VideoCreativeV5' } & Pick<VideoCreativeV5, 'id' | 'name'>)
            >;
          };
      }
    >;
  };
};

export type DeleteCreativesAssetByIdv5MutationVariables = Exact<{
  creativeId: Scalars['String'];
  assetId: Scalars['String'];
}>;

export type DeleteCreativesAssetByIdv5Mutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCreativesAssetByIDV5'
>;

export type SetAssetPublishingErrorMutationVariables = Exact<{
  assetId: Scalars['ID'];
}>;

export type SetAssetPublishingErrorMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'setAssetPublishingErrorV5'>;

export type StartAssetIngestionMutationVariables = Exact<{
  creativeId: Scalars['ID'];
  vastUrl: Scalars['String'];
}>;

export type StartAssetIngestionMutation = { __typename?: 'Mutation' } & {
  startAssetIngestion: { __typename?: 'PreIngestResult' } & Pick<PreIngestResult, 'tracingId' | 'duplicate'>;
};

export type GetCreativeApprovalsQueryVariables = Exact<{
  creativeId: Scalars['String'];
}>;

export type GetCreativeApprovalsQuery = { __typename?: 'Query' } & {
  getCreativeApprovalsV5: { __typename?: 'CreativeApprovals' } & Pick<
    CreativeApprovals,
    'adUuidsWithApprovedVastUrl' | 'approvedAdUuids'
  >;
};

export type GetCreativeDataQueryVariables = Exact<{
  creativeId: Scalars['String'];
  assetIdList: Array<Scalars['String']>;
  filterOptions?: Maybe<ReviewTaskV5FilterOptions>;
}>;

export type GetCreativeDataQuery = { __typename?: 'Query' } & {
  getAssetLatestIngestionStatus: { __typename?: 'LatestIngestionStatus' } & Pick<LatestIngestionStatus, 'jobStatus'> & {
      assetStatus: Array<
        { __typename?: 'IngestionTrial' } & Pick<
          IngestionTrial,
          'assetId' | 'trialStatus' | 'detail' | 's3Url' | 'updatedAt'
        >
      >;
    };
  getReviewTasksV5: { __typename?: 'ReviewTaskPageV5' } & Pick<ReviewTaskPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'ReviewTaskEdgeV5' } & {
          node:
            | ({ __typename?: 'CreativeReviewTaskV5' } & Pick<CreativeReviewTaskV5, 'assetId' | 'spReview'>)
            | ({ __typename?: 'ExternalAssetReviewTaskV5' } & Pick<ExternalAssetReviewTaskV5, 'assetId' | 'spReview'>);
        }
      >;
    };
  getAssetsByIDsV5: Array<
    | ({ __typename?: 'DisplayAssetV5' } & CreativeAsset_DisplayAssetV5_Fragment)
    | ({ __typename?: 'TextAssetV5' } & CreativeAsset_TextAssetV5_Fragment)
    | ({ __typename?: 'VideoAssetV5' } & CreativeAsset_VideoAssetV5_Fragment)
  >;
};

export type GetVideoCreativeDataQueryVariables = Exact<{
  creativeId: Scalars['String'];
  assetIdList: Array<Scalars['String']>;
  filterOptions?: Maybe<ReviewTaskV5FilterOptions>;
}>;

export type GetVideoCreativeDataQuery = { __typename?: 'Query' } & {
  getAssetLatestIngestionStatus: { __typename?: 'LatestIngestionStatus' } & Pick<LatestIngestionStatus, 'jobStatus'> & {
      assetStatus: Array<
        { __typename?: 'IngestionTrial' } & Pick<
          IngestionTrial,
          'assetId' | 'trialStatus' | 'detail' | 's3Url' | 'updatedAt'
        >
      >;
    };
  getReviewTasksV5: { __typename?: 'ReviewTaskPageV5' } & {
    edges: Array<
      { __typename?: 'ReviewTaskEdgeV5' } & {
        node:
          | ({ __typename?: 'CreativeReviewTaskV5' } & Pick<CreativeReviewTaskV5, 'assetId' | 'spReview'>)
          | ({ __typename?: 'ExternalAssetReviewTaskV5' } & Pick<ExternalAssetReviewTaskV5, 'assetId' | 'spReview'>);
      }
    >;
  };
  getAssetsByIDsV5: Array<
    | ({ __typename?: 'DisplayAssetV5' } & Pick<
        DisplayAssetV5,
        | 'id'
        | 'review'
        | 'rejectionReasons'
        | 'name'
        | 'type'
        | 's3Url'
        | 'createdAt'
        | 'updatedAt'
        | 'md5'
        | 'fileSize'
        | 'transcodeStatus'
      > & {
          assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
          transcodeFailureDetails: Maybe<
            { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
          >;
        } & AssetImporterAssetV5_DisplayAssetV5_Fragment)
    | ({ __typename?: 'TextAssetV5' } & Pick<
        TextAssetV5,
        | 'id'
        | 'review'
        | 'rejectionReasons'
        | 'name'
        | 'type'
        | 's3Url'
        | 'createdAt'
        | 'updatedAt'
        | 'md5'
        | 'fileSize'
        | 'transcodeStatus'
      > & {
          assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
          transcodeFailureDetails: Maybe<
            { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
          >;
        } & AssetImporterAssetV5_TextAssetV5_Fragment)
    | ({ __typename?: 'VideoAssetV5' } & Pick<
        VideoAssetV5,
        | 'duration'
        | 'id'
        | 'review'
        | 'rejectionReasons'
        | 'name'
        | 'type'
        | 's3Url'
        | 'createdAt'
        | 'updatedAt'
        | 'md5'
        | 'fileSize'
        | 'transcodeStatus'
      > & {
          mediaMetadata: Maybe<{ __typename?: 'AssetMetaDataV5' } & Pick<AssetMetaDataV5, 'format'>>;
          assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
          transcodeFailureDetails: Maybe<
            { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
          >;
        } & AssetImporterAssetV5_VideoAssetV5_Fragment)
  >;
};

export type GetCreativeByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetCreativeByIdQuery = { __typename?: 'Query' } & {
  getCreativeByIDV5:
    | ({ __typename?: 'AdSelectorSlateCreativeV5' } & Creatives_AdSelectorSlateCreativeV5_Fragment)
    | ({ __typename?: 'AdSelectorVideoCreativeV5' } & Creatives_AdSelectorVideoCreativeV5_Fragment)
    | ({ __typename?: 'AuditBaseCreativeV5' } & Creatives_AuditBaseCreativeV5_Fragment)
    | ({ __typename?: 'BrightlineCreativeV5' } & Creatives_BrightlineCreativeV5_Fragment)
    | ({ __typename?: 'DraxCreativeV5' } & Creatives_DraxCreativeV5_Fragment)
    | ({ __typename?: 'GatewayGoCreativeV5' } & Creatives_GatewayGoCreativeV5_Fragment)
    | ({ __typename?: 'InnovidCreativeV5' } & Creatives_InnovidCreativeV5_Fragment)
    | ({ __typename?: 'InstreamDxcCreativeV5' } & Creatives_InstreamDxcCreativeV5_Fragment)
    | ({ __typename?: 'MagniteCreativeV5' } & Creatives_MagniteCreativeV5_Fragment)
    | ({ __typename?: 'PauseCreativeV5' } & Creatives_PauseCreativeV5_Fragment)
    | ({ __typename?: 'SlateCreativeV5' } & Creatives_SlateCreativeV5_Fragment)
    | ({ __typename?: 'VASTVideoCreativeV5' } & Creatives_VastVideoCreativeV5_Fragment)
    | ({ __typename?: 'VideoCreativeV5' } & Creatives_VideoCreativeV5_Fragment);
};

export type SearchCreativesQueryVariables = Exact<{
  filterOptions?: Maybe<BaseCreativeFilterOptionsV5>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<SearchCreativeSortOptions>;
}>;

export type SearchCreativesQuery = { __typename?: 'Query' } & {
  searchCreatives: { __typename?: 'BaseCreativePageV5' } & {
    edges: Array<
      { __typename?: 'BaseCreativeEdgeV5' } & {
        node:
          | ({ __typename?: 'AdSelectorSlateCreativeV5' } & Pick<AdSelectorSlateCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'AdSelectorVideoCreativeV5' } & Pick<AdSelectorVideoCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'AuditBaseCreativeV5' } & Pick<AuditBaseCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'BrightlineCreativeV5' } & Pick<BrightlineCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'DraxCreativeV5' } & Pick<DraxCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'GatewayGoCreativeV5' } & Pick<GatewayGoCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'InnovidCreativeV5' } & Pick<InnovidCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'InstreamDxcCreativeV5' } & Pick<InstreamDxcCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'MagniteCreativeV5' } & Pick<MagniteCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'PauseCreativeV5' } & Pick<PauseCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'SlateCreativeV5' } & Pick<SlateCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'VASTVideoCreativeV5' } & Pick<VastVideoCreativeV5, 'id' | 'name'>)
          | ({ __typename?: 'VideoCreativeV5' } & Pick<VideoCreativeV5, 'id' | 'name'>);
      }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
    >;
  };
};

export type AdsPageAdFragment = { __typename: 'AdV5' } & Pick<
  AdV5,
  | 'id'
  | 'name'
  | 'status'
  | 'review'
  | 'rating'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'startDate'
  | 'endDate'
  | 'viewabilityVendorList'
  | 'actualStart'
  | 'unDeletable'
  | 'sequence'
> & {
    creative: Maybe<
      | ({ __typename?: 'AdSelectorSlateCreativeV5' } & Creatives_AdSelectorSlateCreativeV5_Fragment)
      | ({ __typename?: 'AdSelectorVideoCreativeV5' } & Creatives_AdSelectorVideoCreativeV5_Fragment)
      | ({ __typename?: 'AuditBaseCreativeV5' } & Creatives_AuditBaseCreativeV5_Fragment)
      | ({ __typename?: 'BrightlineCreativeV5' } & Creatives_BrightlineCreativeV5_Fragment)
      | ({ __typename?: 'DraxCreativeV5' } & Creatives_DraxCreativeV5_Fragment)
      | ({ __typename?: 'GatewayGoCreativeV5' } & Creatives_GatewayGoCreativeV5_Fragment)
      | ({ __typename?: 'InnovidCreativeV5' } & Creatives_InnovidCreativeV5_Fragment)
      | ({ __typename?: 'InstreamDxcCreativeV5' } & Creatives_InstreamDxcCreativeV5_Fragment)
      | ({ __typename?: 'MagniteCreativeV5' } & Creatives_MagniteCreativeV5_Fragment)
      | ({ __typename?: 'PauseCreativeV5' } & Creatives_PauseCreativeV5_Fragment)
      | ({ __typename?: 'SlateCreativeV5' } & Creatives_SlateCreativeV5_Fragment)
      | ({ __typename?: 'VASTVideoCreativeV5' } & Creatives_VastVideoCreativeV5_Fragment)
      | ({ __typename?: 'VideoCreativeV5' } & Creatives_VideoCreativeV5_Fragment)
    >;
    adRotation: Maybe<{ __typename?: 'AdRotation' } & Pick<AdRotation, 'rotationId' | 'rotationName' | 'weight'>>;
    adSequence: Maybe<
      { __typename?: 'AdSequence' } & Pick<AdSequence, 'order' | 'adId' | 'name' | 'sequenceId' | 'updatedAt'>
    >;
    adTagList: Array<
      { __typename?: 'AdTag' } & Pick<AdTag, 'id' | 'type' | 'event' | 'url' | 'isVerified' | 'unDeletable' | 'isIas'>
    >;
    frequencyCapList: Array<
      { __typename?: 'FrequencyCapV5' } & Pick<
        FrequencyCapV5,
        'type' | 'limit' | 'duration' | 'durationUnit' | 'enabled'
      >
    >;
    targetingTermValues: Array<
      { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
          category: Maybe<
            { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>
          >;
          dimension: Maybe<
            { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'>
          >;
          value: Maybe<
            { __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName' | 'description'>
          >;
        }
    >;
    targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
    lineItem: Maybe<
      { __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id'> & {
          schedule: Maybe<
            { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                dateRangeList: Array<
                  { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                >;
                dayPartList: Maybe<
                  Array<
                    { __typename?: 'DayPartV5' } & Pick<
                      DayPartV5,
                      'firstActiveHour' | 'lastActiveHour' | 'activeDayList'
                    >
                  >
                >;
              }
          >;
          parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
        }
    >;
    schedule: Maybe<
      { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'deliveryInViewerTime' | 'timezone'> & {
          dateRangeList: Array<
            { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
          >;
          dayPartList: Maybe<
            Array<
              { __typename?: 'DayPartV5' } & Pick<DayPartV5, 'firstActiveHour' | 'lastActiveHour' | 'activeDayList'>
            >
          >;
        }
    >;
  };

type AssetImporterAssetV5_DisplayAssetV5_Fragment = { __typename?: 'DisplayAssetV5' } & Pick<
  DisplayAssetV5,
  | 'height'
  | 'kinkoKey'
  | 'width'
  | 'comments'
  | 'countryCodes'
  | 'createdAt'
  | 'filename'
  | 'fileSize'
  | 'id'
  | 'md5'
  | 'name'
  | 'publisher'
  | 'rejectionReasons'
  | 'review'
  | 's3Url'
  | 'type'
  | 'updatedAt'
> & {
    assetTagList: Maybe<
      Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'description' | 'displayName' | 'value'>>
    >;
  };

type AssetImporterAssetV5_TextAssetV5_Fragment = { __typename?: 'TextAssetV5' } & Pick<
  TextAssetV5,
  | 'text'
  | 'comments'
  | 'countryCodes'
  | 'createdAt'
  | 'filename'
  | 'fileSize'
  | 'id'
  | 'md5'
  | 'name'
  | 'publisher'
  | 'rejectionReasons'
  | 'review'
  | 's3Url'
  | 'type'
  | 'updatedAt'
> & {
    assetTagList: Maybe<
      Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'description' | 'displayName' | 'value'>>
    >;
  };

type AssetImporterAssetV5_VideoAssetV5_Fragment = { __typename?: 'VideoAssetV5' } & Pick<
  VideoAssetV5,
  | 'countryCodes'
  | 'duration'
  | 'rating'
  | 'comments'
  | 'createdAt'
  | 'filename'
  | 'fileSize'
  | 'id'
  | 'md5'
  | 'name'
  | 'publisher'
  | 'rejectionReasons'
  | 'review'
  | 's3Url'
  | 'type'
  | 'updatedAt'
> & {
    assetTagList: Maybe<
      Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'description' | 'displayName' | 'value'>>
    >;
  };

export type AssetImporterAssetV5Fragment =
  | AssetImporterAssetV5_DisplayAssetV5_Fragment
  | AssetImporterAssetV5_TextAssetV5_Fragment
  | AssetImporterAssetV5_VideoAssetV5_Fragment;

export type CreativeBuilderAssetV5Fragment = { __typename: 'CreativeV5Asset' } & {
  asset: Maybe<
    | ({ __typename?: 'DisplayAssetV5' } & AssetImporterAssetV5_DisplayAssetV5_Fragment)
    | ({ __typename?: 'TextAssetV5' } & AssetImporterAssetV5_TextAssetV5_Fragment)
    | ({ __typename?: 'VideoAssetV5' } & AssetImporterAssetV5_VideoAssetV5_Fragment)
  >;
};

type CreativeAsset_DisplayAssetV5_Fragment = { __typename?: 'DisplayAssetV5' } & Pick<
  DisplayAssetV5,
  | 'id'
  | 'review'
  | 'rejectionReasons'
  | 'name'
  | 'type'
  | 's3Url'
  | 'createdAt'
  | 'updatedAt'
  | 'md5'
  | 'fileSize'
  | 'transcodeStatus'
> & {
    assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
    transcodeFailureDetails: Maybe<
      { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
    >;
  } & AssetImporterAssetV5_DisplayAssetV5_Fragment;

type CreativeAsset_TextAssetV5_Fragment = { __typename?: 'TextAssetV5' } & Pick<
  TextAssetV5,
  | 'id'
  | 'review'
  | 'rejectionReasons'
  | 'name'
  | 'type'
  | 's3Url'
  | 'createdAt'
  | 'updatedAt'
  | 'md5'
  | 'fileSize'
  | 'transcodeStatus'
> & {
    assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
    transcodeFailureDetails: Maybe<
      { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
    >;
  } & AssetImporterAssetV5_TextAssetV5_Fragment;

type CreativeAsset_VideoAssetV5_Fragment = { __typename?: 'VideoAssetV5' } & Pick<
  VideoAssetV5,
  | 'duration'
  | 'id'
  | 'review'
  | 'rejectionReasons'
  | 'name'
  | 'type'
  | 's3Url'
  | 'createdAt'
  | 'updatedAt'
  | 'md5'
  | 'fileSize'
  | 'transcodeStatus'
> & {
    mediaMetadata: Maybe<{ __typename?: 'AssetMetaDataV5' } & Pick<AssetMetaDataV5, 'format'>>;
    assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
    transcodeFailureDetails: Maybe<
      { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
    >;
  } & AssetImporterAssetV5_VideoAssetV5_Fragment;

export type CreativeAssetFragment =
  | CreativeAsset_DisplayAssetV5_Fragment
  | CreativeAsset_TextAssetV5_Fragment
  | CreativeAsset_VideoAssetV5_Fragment;

export type CreativeMetadataFragment = { __typename?: 'CreativeMetadata' } & Pick<
  CreativeMetadata,
  'creativeId' | 'createdAt' | 'updatedAt' | 'countryCodes' | 'deleted' | 'isLegalApproved'
>;

type Creatives_AdSelectorSlateCreativeV5_Fragment = { __typename: 'AdSelectorSlateCreativeV5' } & Pick<
  AdSelectorSlateCreativeV5,
  | 'headline'
  | 'randomOrder'
  | 'voiceoverUrl'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    logo: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    optionList: Maybe<
      Array<
        { __typename?: 'AdSelectorOption' } & Pick<
          AdSelectorOption,
          'index' | 'thumbnailId' | 'adSelectorVideoCreativeId' | 'caption'
        > & { thumbnail: Maybe<{ __typename?: 'DisplayAssetV5' } & CreativeAsset_DisplayAssetV5_Fragment> }
      >
    >;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_AdSelectorVideoCreativeV5_Fragment = { __typename: 'AdSelectorVideoCreativeV5' } & Pick<
  AdSelectorVideoCreativeV5,
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    video: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_AuditBaseCreativeV5_Fragment = { __typename: 'AuditBaseCreativeV5' } & Pick<
  AuditBaseCreativeV5,
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_BrightlineCreativeV5_Fragment = { __typename: 'BrightlineCreativeV5' } & Pick<
  BrightlineCreativeV5,
  | 'url'
  | 'vpaid'
  | 'template'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    videoAssetList: Maybe<Array<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>>;
    featureList: Maybe<Array<Maybe<{ __typename?: 'DAAPIFeature' } & Pick<DaapiFeature, 'type'>>>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_DraxCreativeV5_Fragment = { __typename: 'DraxCreativeV5' } & Pick<
  DraxCreativeV5,
  | 'url'
  | 'vpaid'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    videoAssetList: Maybe<Array<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_GatewayGoCreativeV5_Fragment = { __typename: 'GatewayGoCreativeV5' } & Pick<
  GatewayGoCreativeV5,
  | 'livingRoomCopy'
  | 'emailSubject'
  | 'emailBody'
  | 'pushTitle'
  | 'pushBody'
  | 'destinationUrl'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    offerImage: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    video: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_InnovidCreativeV5_Fragment = { __typename: 'InnovidCreativeV5' } & Pick<
  InnovidCreativeV5,
  | 'url'
  | 'vpaid'
  | 'template'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    videoAssetList: Maybe<Array<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>>;
    featureList: Maybe<Array<Maybe<{ __typename?: 'DAAPIFeature' } & Pick<DaapiFeature, 'type'>>>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_InstreamDxcCreativeV5_Fragment = { __typename: 'InstreamDxcCreativeV5' } & Pick<
  InstreamDxcCreativeV5,
  | 'experienceId'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    video: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    templateAssetList: Array<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_MagniteCreativeV5_Fragment = { __typename: 'MagniteCreativeV5' } & Pick<
  MagniteCreativeV5,
  | 'url'
  | 'vpaid'
  | 'adSourceId'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    videoAssetList: Maybe<Array<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_PauseCreativeV5_Fragment = { __typename: 'PauseCreativeV5' } & Pick<
  PauseCreativeV5,
  | 'altText'
  | 'status'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    image: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    accent: Maybe<{ __typename?: 'AccentRGBV5' } & Pick<AccentRgbv5, 'r' | 'g' | 'b'>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_SlateCreativeV5_Fragment = { __typename: 'SlateCreativeV5' } & Pick<
  SlateCreativeV5,
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    slate: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_VastVideoCreativeV5_Fragment = { __typename: 'VASTVideoCreativeV5' } & Pick<
  VastVideoCreativeV5,
  | 'url'
  | 'vpaid'
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    videoAssetList: Maybe<
      Array<
        { __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'> & {
            asset: Maybe<
              | ({ __typename?: 'DisplayAssetV5' } & Pick<DisplayAssetV5, 'name' | 's3Url'>)
              | ({ __typename?: 'TextAssetV5' } & Pick<TextAssetV5, 'name' | 's3Url'>)
              | ({ __typename?: 'VideoAssetV5' } & Pick<VideoAssetV5, 'name' | 's3Url'>)
            >;
          }
      >
    >;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

type Creatives_VideoCreativeV5_Fragment = { __typename: 'VideoCreativeV5' } & Pick<
  VideoCreativeV5,
  | 'id'
  | 'creativeLibraryId'
  | 'name'
  | 'rating'
  | 'review'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'type'
  | 'rejectionReason'
  | 'rejectionReasonDetails'
> & {
    video: Maybe<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>;
    assetTagList: Maybe<
      Array<Maybe<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'id' | 'value' | 'description' | 'displayName'>>>
    >;
  };

export type CreativesFragment =
  | Creatives_AdSelectorSlateCreativeV5_Fragment
  | Creatives_AdSelectorVideoCreativeV5_Fragment
  | Creatives_AuditBaseCreativeV5_Fragment
  | Creatives_BrightlineCreativeV5_Fragment
  | Creatives_DraxCreativeV5_Fragment
  | Creatives_GatewayGoCreativeV5_Fragment
  | Creatives_InnovidCreativeV5_Fragment
  | Creatives_InstreamDxcCreativeV5_Fragment
  | Creatives_MagniteCreativeV5_Fragment
  | Creatives_PauseCreativeV5_Fragment
  | Creatives_SlateCreativeV5_Fragment
  | Creatives_VastVideoCreativeV5_Fragment
  | Creatives_VideoCreativeV5_Fragment;

export type RotationsFragment = { __typename?: 'Rotation' } & Pick<Rotation, 'rotationId' | 'name' | 'order'> & {
    adRotationList: Array<
      { __typename?: 'AdRotation' } & Pick<AdRotation, 'adId' | 'weight' | 'order' | 'rotationId' | 'rotationName'>
    >;
  };

export type GetAssetByIdQueryVariables = Exact<{
  assetID: Scalars['String'];
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<ReviewTaskV5FilterOptions>;
  hasCreative: Scalars['Boolean'];
  fetchMediaMetadata: Scalars['Boolean'];
}>;

export type GetAssetByIdQuery = { __typename?: 'Query' } & {
  getAssetByIDV5:
    | ({ __typename?: 'DisplayAssetV5' } & Pick<
        DisplayAssetV5,
        'name' | 'id' | 'rejectionReasons' | 'review' | 'transcodeStatus' | 'filename' | 'countryCodes'
      > & {
          assetTagList: Maybe<
            Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'description' | 'id' | 'value' | 'displayName'>>
          >;
        })
    | ({ __typename?: 'TextAssetV5' } & Pick<
        TextAssetV5,
        'name' | 'id' | 'rejectionReasons' | 'review' | 'transcodeStatus' | 'filename' | 'countryCodes'
      > & {
          assetTagList: Maybe<
            Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'description' | 'id' | 'value' | 'displayName'>>
          >;
        })
    | ({ __typename?: 'VideoAssetV5' } & Pick<
        VideoAssetV5,
        'name' | 'id' | 'rejectionReasons' | 'review' | 'transcodeStatus' | 'filename' | 'countryCodes'
      > & {
          assetTagList: Maybe<
            Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'description' | 'id' | 'value' | 'displayName'>>
          >;
        });
  getMediaMetadataByAssetIdV5: Maybe<{ __typename?: 'MediaMetadata' } & Pick<MediaMetadata, 'format'>>;
  getReviewTasksV5: Maybe<
    { __typename?: 'ReviewTaskPageV5' } & {
      edges: Array<
        { __typename?: 'ReviewTaskEdgeV5' } & {
          node:
            | ({ __typename?: 'CreativeReviewTaskV5' } & Pick<CreativeReviewTaskV5, 'id' | 'spReview'>)
            | ({ __typename?: 'ExternalAssetReviewTaskV5' } & Pick<ExternalAssetReviewTaskV5, 'id' | 'spReview'>);
        }
      >;
    }
  >;
};

export type GetCreativeLibraryIdByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type GetCreativeLibraryIdByOrganizationIdQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getCreativeLibraryIdByOrganizationIDV5'
>;

export type GetTranscodingFailureQueryVariables = Exact<{
  assetId: Scalars['String'];
}>;

export type GetTranscodingFailureQuery = { __typename?: 'Query' } & {
  getTranscodingFailure: Maybe<
    { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'message' | 'id' | 'name' | 'status'>
  >;
};

export type PopulateTargetingValuesQueryVariables = Exact<{
  termList: Array<PopulateTargetingValuesInput>;
}>;

export type PopulateTargetingValuesQuery = { __typename?: 'Query' } & {
  populateTargetingValues: Array<
    { __typename?: 'TargetingRuleWithPopulatedValues' } & Pick<TargetingRuleWithPopulatedValues, 'dimension'> & {
        valueSet: Array<{ __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'displayName'>>;
      }
  >;
};

export type AuditTargetingTermValuesFragment = { __typename?: 'TargetingTermValue' } & Pick<
  TargetingTermValue,
  'include'
> & {
    category: Maybe<{ __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>>;
    dimension: Maybe<{ __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'>>;
    value: Maybe<{ __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName'>>;
  };

export type AuditScheduleV5Fragment = { __typename?: 'ScheduleV5' } & Pick<
  ScheduleV5,
  'timezone' | 'deliveryInViewerTime'
> & {
    dayPartList: Maybe<
      Array<{ __typename?: 'DayPartV5' } & Pick<DayPartV5, 'activeDayList' | 'firstActiveHour' | 'lastActiveHour'>>
    >;
    dateRangeList: Array<{ __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>>;
  };

export type AuditLineItemV5Fragment = { __typename?: 'AuditLineItemV5' } & {
  schedule: Maybe<{ __typename?: 'ScheduleV5' } & AuditScheduleV5Fragment>;
  adList: Maybe<Array<Maybe<{ __typename?: 'AuditRelatedLineItemAd' } & Pick<AuditRelatedLineItemAd, 'id' | 'name'>>>>;
  lineItemTargetingTermValues: Maybe<Array<{ __typename?: 'TargetingTermValue' } & AuditTargetingTermValuesFragment>>;
};

export type AuditAdV5Fragment = { __typename?: 'AuditAdV5' } & {
  schedule: Maybe<{ __typename?: 'ScheduleV5' } & AuditScheduleV5Fragment>;
  adTargetingTermValues: Maybe<Array<{ __typename?: 'TargetingTermValue' } & AuditTargetingTermValuesFragment>>;
};

type SingleValueChange_MultiValueChange_Fragment = { __typename: 'MultiValueChange' };

type SingleValueChange_SingleValueChange_Fragment = { __typename: 'SingleValueChange' } & {
  oldValue: { __typename?: 'SimpleChangeValue' } & Pick<SimpleChangeValue, 'action' | 'payload'>;
  newValue: { __typename?: 'SimpleChangeValue' } & Pick<SimpleChangeValue, 'action' | 'payload'>;
};

export type SingleValueChangeFragment =
  | SingleValueChange_MultiValueChange_Fragment
  | SingleValueChange_SingleValueChange_Fragment;

export type GetChangeLogByAdObjectIdQueryVariables = Exact<{
  id: Scalars['ID'];
  entityType?: Maybe<EntityType>;
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<GetChangelogFilterOptions>;
}>;

export type GetChangeLogByAdObjectIdQuery = { __typename?: 'Query' } & {
  getChangeLogByAdObjectID: { __typename?: 'ChangeLog' } & Pick<ChangeLog, 'total'> & {
      edges: Array<
        { __typename?: 'ChangeLogEntryEdge' } & {
          node: { __typename?: 'ChangeLogNode' } & {
            audits: Maybe<
              { __typename?: 'ChangeLogEntry' } & Pick<ChangeLogEntry, 'modifiedDate' | 'modifiedBy'> & {
                  changeList: Array<
                    | ({ __typename?: 'MultiValueChange' } & Pick<MultiValueChange, 'fieldName'> &
                        SingleValueChange_MultiValueChange_Fragment)
                    | ({ __typename?: 'SingleValueChange' } & Pick<SingleValueChange, 'fieldName'> &
                        SingleValueChange_SingleValueChange_Fragment)
                  >;
                  object:
                    | { __typename?: 'CampaignV5' }
                    | ({ __typename?: 'AuditAdV5' } & AuditAdV5Fragment)
                    | ({ __typename?: 'AuditLineItemV5' } & AuditLineItemV5Fragment)
                    | { __typename?: 'AuditBaseCreativeV5' };
                }
            >;
            creativeAudits: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ChangeLogEntry' } & Pick<ChangeLogEntry, 'modifiedDate' | 'modifiedBy'> & {
                      changeList: Array<
                        | ({ __typename?: 'MultiValueChange' } & Pick<MultiValueChange, 'fieldName'> &
                            SingleValueChange_MultiValueChange_Fragment)
                        | ({ __typename?: 'SingleValueChange' } & Pick<SingleValueChange, 'fieldName'> &
                            SingleValueChange_SingleValueChange_Fragment)
                      >;
                      object:
                        | { __typename?: 'CampaignV5' }
                        | ({ __typename?: 'AuditAdV5' } & AuditAdV5Fragment)
                        | ({ __typename?: 'AuditLineItemV5' } & AuditLineItemV5Fragment)
                        | { __typename?: 'AuditBaseCreativeV5' };
                    }
                >
              >
            >;
            targetingChanges: Maybe<
              { __typename?: 'TargetingChangesMap' } & {
                old: Maybe<
                  { __typename?: 'TargetingChangesTermList' } & {
                    termList: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                            TargetingRuleDefinitionV4,
                            'not' | 'subClass' | 'operation' | 'dimension' | 'valueSet'
                          > & {
                              rangeList: Maybe<
                                Array<Maybe<{ __typename?: 'RangeList' } & Pick<RangeList, 'lower' | 'upper'>>>
                              >;
                            }
                        >
                      >
                    >;
                  }
                >;
                new: Maybe<
                  { __typename?: 'TargetingChangesTermList' } & {
                    termList: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'TargetingRuleDefinitionV4' } & Pick<
                            TargetingRuleDefinitionV4,
                            'not' | 'subClass' | 'operation' | 'dimension' | 'valueSet'
                          > & {
                              rangeList: Maybe<
                                Array<Maybe<{ __typename?: 'RangeList' } & Pick<RangeList, 'lower' | 'upper'>>>
                              >;
                            }
                        >
                      >
                    >;
                  }
                >;
              }
            >;
          };
        }
      >;
    };
};

export type SearchAssetsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Array<AssetTypeV5>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type SearchAssetsQuery = { __typename?: 'Query' } & {
  searchAssetsV5: { __typename?: 'AssetsPageV5' } & {
    edges: Array<
      { __typename?: 'AssetsEdgeV5' } & {
        node:
          | ({ __typename?: 'DisplayAssetV5' } & AssetImporterAssetV5_DisplayAssetV5_Fragment)
          | ({ __typename?: 'TextAssetV5' } & AssetImporterAssetV5_TextAssetV5_Fragment)
          | ({ __typename?: 'VideoAssetV5' } & AssetImporterAssetV5_VideoAssetV5_Fragment);
      }
    >;
  };
};

export type GetReviewModalStatusChangeLogQueryVariables = Exact<{
  id: Scalars['ID'];
  entityType?: Maybe<EntityType>;
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<GetChangelogFilterOptions>;
}>;

export type GetReviewModalStatusChangeLogQuery = { __typename?: 'Query' } & {
  getChangeLogByAdObjectID: { __typename?: 'ChangeLog' } & Pick<ChangeLog, 'total'> & {
      edges: Array<
        { __typename?: 'ChangeLogEntryEdge' } & {
          node: { __typename?: 'ChangeLogNode' } & {
            audits: Maybe<
              { __typename?: 'ChangeLogEntry' } & Pick<ChangeLogEntry, 'modifiedDate' | 'modifiedBy'> & {
                  changeList: Array<
                    | ({ __typename: 'MultiValueChange' } & Pick<MultiValueChange, 'fieldName'>)
                    | ({ __typename: 'SingleValueChange' } & Pick<SingleValueChange, 'fieldName'> & {
                          oldValue: { __typename?: 'SimpleChangeValue' } & Pick<
                            SimpleChangeValue,
                            'action' | 'payload'
                          >;
                          newValue: { __typename?: 'SimpleChangeValue' } & Pick<
                            SimpleChangeValue,
                            'action' | 'payload'
                          >;
                        })
                  >;
                }
            >;
          };
        }
      >;
    };
};

export type GetTargetingPresetsQueryVariables = Exact<{
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<TargetingPresetFilterOptions>;
  sortOptions?: Maybe<TargetingPresetSortOptions>;
}>;

export type GetTargetingPresetsQuery = { __typename?: 'Query' } & {
  getTargetingPresets: { __typename?: 'TargetingPresetPage' } & Pick<TargetingPresetPage, 'total'> & {
      edges: Array<
        { __typename?: 'TargetingPresetEdge' } & {
          node: { __typename?: 'TargetingPreset' } & Pick<
            TargetingPreset,
            'archived' | 'name' | 'updatedAt' | 'id' | 'description'
          >;
        }
      >;
    };
};

export type GetTargetingPresetsWithTargetingTermsQueryVariables = Exact<{
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<TargetingPresetFilterOptions>;
}>;

export type GetTargetingPresetsWithTargetingTermsQuery = { __typename?: 'Query' } & {
  getTargetingPresets: { __typename?: 'TargetingPresetPage' } & Pick<TargetingPresetPage, 'total'> & {
      edges: Array<
        { __typename?: 'TargetingPresetEdge' } & {
          node: { __typename?: 'TargetingPreset' } & Pick<
            TargetingPreset,
            'archived' | 'name' | 'updatedAt' | 'id' | 'description'
          > & {
              definition: Array<
                { __typename?: 'TargetingTermWithBaseValue' } & {
                  category: Maybe<{ __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'displayName'>>;
                  dimension: Maybe<{ __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'displayName'>>;
                  value: Maybe<{ __typename?: 'BaseTargetingValue' } & Pick<BaseTargetingValue, 'displayName'>>;
                }
              >;
            };
        }
      >;
    };
};

export type Get_Ad_Permitted_TimezonesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Ad_Permitted_TimezonesQuery = { __typename?: 'Query' } & Pick<Query, 'getAdPermittedTimezones'>;

export type Get_Ad_RatingsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Ad_RatingsQuery = { __typename?: 'Query' } & Pick<Query, 'getAdRatingsV5'>;

export type Get_Ad_TypesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Ad_TypesQuery = { __typename?: 'Query' } & Pick<Query, 'getAdTypesV5'>;

export type Get_AgenciesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  crmLinkTypeList?: Maybe<Array<CrmLinkTypeV5>>;
}>;

export type Get_AgenciesQuery = { __typename?: 'Query' } & {
  getAgencies: { __typename?: 'AgencyPage' } & {
    edges: Array<
      { __typename?: 'AgencyEdge' } & {
        node: { __typename?: 'Agency' } & Pick<Agency, 'id' | 'url' | 'type' | 'name' | 'indexedAt'>;
      }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
  };
};

export type Get_BrandsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_BrandsQuery = { __typename?: 'Query' } & {
  brands: Maybe<
    { __typename?: 'BrandsPage' } & {
      edges: Array<
        { __typename?: 'BrandEdge' } & {
          node: { __typename?: 'Brand' } & Pick<
            Brand,
            'id' | 'name' | 'url' | 'type' | 'industry' | 'industryId' | 'sourceSystemId' | 'adomains'
          >;
        }
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
    }
  >;
};

export type Get_Creative_Review_StatusesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Creative_Review_StatusesQuery = { __typename?: 'Query' } & Pick<Query, 'getCreativeReviewsStatusV5'>;

export type Get_Creative_TypesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Creative_TypesQuery = { __typename?: 'Query' } & Pick<Query, 'getCreativeTypesV5'>;

export type Get_Industries_V5QueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_Industries_V5Query = { __typename?: 'Query' } & {
  getIndustriesV5: { __typename?: 'IndustryPage' } & {
    edges: Array<
      { __typename?: 'IndustryEdge' } & { node: { __typename?: 'Industry' } & Pick<Industry, 'id' | 'name'> }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
  };
};

export type Get_Line_Item_Billable_Third_PartiesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Line_Item_Billable_Third_PartiesQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getLineItemBillableThirdPartiesV5'
>;

export type Get_Line_Priority_ValuesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Line_Priority_ValuesQuery = { __typename?: 'Query' } & Pick<Query, 'getLineItemPriorityValuesV5'>;

export type Get_Line_Item_Tier_NamesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Line_Item_Tier_NamesQuery = { __typename?: 'Query' } & Pick<Query, 'getLineItemTierNamesV5'>;

export type Get_Order_TypesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Order_TypesQuery = { __typename?: 'Query' } & { getOrderTypes: Query['getOrderTypesV5'] };

export type Get_Trafficking_FiltersQueryVariables = Exact<{
  shareableId: Scalars['String'];
}>;

export type Get_Trafficking_FiltersQuery = { __typename?: 'Query' } & {
  getTraffickingFilters: { __typename?: 'TraffickingFilters' } & Pick<
    TraffickingFilters,
    | 'productType'
    | 'pacingRiskList'
    | 'hasMisalignedSchedule'
    | 'generalPacingStatusList'
    | 'reviewStatusList'
    | 'adStatusList'
    | 'orderTypeList'
    | 'lineItemStatusList'
    | 'tierNameList'
    | 'priorityValueList'
    | 'billableThirdPartyList'
    | 'publisherList'
    | 'adRatingList'
    | 'adTypeList'
    | 'creativeTypeList'
    | 'creativeReviewList'
    | 'currencyList'
    | 'isCoppaOrCaru'
    | 'isMakegood'
    | 'isAddedValue'
    | 'isRotation'
    | 'isSequence'
    | 'selectedTab'
    | 'countryList'
  > & {
      dateFilter: Maybe<
        { __typename?: 'DateFilter' } & Pick<DateFilter, 'label'> & {
            value: Maybe<
              { __typename?: 'ScheduleFilterV5' } & Pick<ScheduleFilterV5, 'status' | 'timezone'> & {
                  end: { __typename?: 'AdDateHour' } & Pick<AdDateHour, 'day' | 'hour' | 'month' | 'year'>;
                  start: { __typename?: 'AdDateHour' } & Pick<AdDateHour, 'day' | 'hour' | 'month' | 'year'>;
                }
            >;
          }
      >;
      accountManager: Maybe<Array<{ __typename?: 'UserFilter' } & Pick<UserFilter, 'label' | 'value'>>>;
      accountExecutive: Maybe<Array<{ __typename?: 'UserFilter' } & Pick<UserFilter, 'label' | 'value'>>>;
      brandIdList: Maybe<Array<{ __typename?: 'OrderFilter' } & Pick<OrderFilter, 'label' | 'value'>>>;
      industryIdList: Maybe<Array<{ __typename?: 'OrderFilter' } & Pick<OrderFilter, 'label' | 'value'>>>;
      agencyIdList: Maybe<Array<{ __typename?: 'OrderFilter' } & Pick<OrderFilter, 'label' | 'value'>>>;
      campaigns: Maybe<
        { __typename?: 'CampaignTableFilters' } & Pick<CampaignTableFilters, 'searchTerm' | 'selectedRowIds'> & {
            sortBy: Maybe<
              { __typename?: 'CampaignSortOptionsV5Type' } & Pick<CampaignSortOptionsV5Type, 'sort' | 'order'>
            >;
          }
      >;
      lineItems: Maybe<
        { __typename?: 'LineItemTableFilters' } & Pick<LineItemTableFilters, 'searchTerm' | 'selectedRowIds'> & {
            sortBy: Maybe<
              { __typename?: 'LineItemSortOptionsV5Type' } & Pick<LineItemSortOptionsV5Type, 'sort' | 'order'>
            >;
          }
      >;
      ads: Maybe<
        { __typename?: 'AdTableFilters' } & Pick<AdTableFilters, 'searchTerm' | 'selectedRowIds'> & {
            sortBy: Maybe<{ __typename?: 'AdSortOptionsV5Type' } & Pick<AdSortOptionsV5Type, 'sort' | 'order'>>;
          }
      >;
    };
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = { __typename?: 'Query' } & {
  getRoles: Array<
    { __typename?: 'RoleV4' } & Pick<RoleV4, 'name'> & {
        permissions: Maybe<
          Array<Maybe<{ __typename?: 'Permissions' } & Pick<Permissions, 'name' | 'serviceNamespace'>>>
        >;
      }
  >;
};

export type CreateAdsPageAdMutationVariables = Exact<{
  adInput: AdInputV5;
}>;

export type CreateAdsPageAdMutation = { __typename?: 'Mutation' } & {
  createAd: Maybe<{ __typename?: 'AdV5' } & AdsPageAdFragment>;
};

export type CreateAdsPageAdWithAssetMutationVariables = Exact<{
  adInput: AdInputV5;
  assetId: Scalars['ID'];
  assetInput: AssetInputV5;
}>;

export type CreateAdsPageAdWithAssetMutation = { __typename?: 'Mutation' } & {
  createAd: Maybe<{ __typename?: 'AdV5' } & AdsPageAdFragment>;
  updateReviewAssetV5:
    | ({ __typename?: 'DisplayAssetV5' } & Pick<DisplayAssetV5, 'id'>)
    | ({ __typename?: 'TextAssetV5' } & Pick<TextAssetV5, 'id'>)
    | ({ __typename?: 'VideoAssetV5' } & Pick<VideoAssetV5, 'id'>);
};

export type CreateAdWithBatchAssetsMutationVariables = Exact<{
  adInput: AdInputV5;
  idList: Array<Scalars['String']>;
  assetInput: BatchReviewAssetInputV5;
}>;

export type CreateAdWithBatchAssetsMutation = { __typename?: 'Mutation' } & {
  createAd: Maybe<{ __typename?: 'AdV5' } & AdsPageAdFragment>;
  updateBatchReviewAssets: { __typename?: 'BatchReviewAssetResponseV5' } & {
    successful: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id'>>>;
    failed: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id' | 'message'>>>;
  };
};

export type CreateAdWithGatewayAssetsMutationVariables = Exact<{
  adInput: AdInputV5;
  displayAssetId: Scalars['String'];
  displayAssetInput: BatchReviewAssetInputV5;
  videoAssetId: Scalars['String'];
  videoAssetInput: BatchReviewAssetInputV5;
}>;

export type CreateAdWithGatewayAssetsMutation = { __typename?: 'Mutation' } & {
  createAd: Maybe<{ __typename?: 'AdV5' } & AdsPageAdFragment>;
  updateDisplayAsset: { __typename?: 'BatchReviewAssetResponseV5' } & {
    successful: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id'>>>;
    failed: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id' | 'message'>>>;
  };
  updateVideoAsset: { __typename?: 'BatchReviewAssetResponseV5' } & {
    successful: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id'>>>;
    failed: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id' | 'message'>>>;
  };
};

export type UpdateAdsPageAdMutationVariables = Exact<{
  adId: Scalars['ID'];
  updateAdInput: UpdateAdInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
}>;

export type UpdateAdsPageAdMutation = { __typename?: 'Mutation' } & {
  updateAd: { __typename?: 'AdV5' } & {
    targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
  } & AdsPageAdFragment;
};

export type UpdateAdsPageWithAssetMutationVariables = Exact<{
  adId: Scalars['ID'];
  updateAdInput: UpdateAdInputV5;
  assetId: Scalars['ID'];
  assetInput: AssetInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
}>;

export type UpdateAdsPageWithAssetMutation = { __typename?: 'Mutation' } & {
  updateAd: { __typename?: 'AdV5' } & {
    targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
  } & AdsPageAdFragment;
  updateReviewAssetV5:
    | ({ __typename?: 'DisplayAssetV5' } & Pick<DisplayAssetV5, 'id'>)
    | ({ __typename?: 'TextAssetV5' } & Pick<TextAssetV5, 'id'>)
    | ({ __typename?: 'VideoAssetV5' } & Pick<VideoAssetV5, 'id'>);
};

export type UpdateAdWithBatchAssetsMutationVariables = Exact<{
  adId: Scalars['ID'];
  updateAdInput: UpdateAdInputV5;
  assetIdList: Array<Scalars['String']>;
  assetInput: BatchReviewAssetInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
}>;

export type UpdateAdWithBatchAssetsMutation = { __typename?: 'Mutation' } & {
  updateAd: { __typename?: 'AdV5' } & {
    targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
  } & AdsPageAdFragment;
  updateBatchReviewAssets: { __typename?: 'BatchReviewAssetResponseV5' } & {
    successful: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id'>>>;
    failed: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id' | 'message'>>>;
  };
};

export type UpdateAdWithGatewayAssetsMutationVariables = Exact<{
  adId: Scalars['ID'];
  updateAdInput: UpdateAdInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
  displayAssetId: Scalars['String'];
  displayAssetInput: BatchReviewAssetInputV5;
  videoAssetId: Scalars['String'];
  videoAssetInput: BatchReviewAssetInputV5;
}>;

export type UpdateAdWithGatewayAssetsMutation = { __typename?: 'Mutation' } & {
  updateAd: { __typename?: 'AdV5' } & {
    targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
  } & AdsPageAdFragment;
  updateDisplayAsset: { __typename?: 'BatchReviewAssetResponseV5' } & {
    successful: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id'>>>;
    failed: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id' | 'message'>>>;
  };
  updateVideoAsset: { __typename?: 'BatchReviewAssetResponseV5' } & {
    successful: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id'>>>;
    failed: Maybe<Array<{ __typename?: 'BatchReviewAssetResult' } & Pick<BatchReviewAssetResult, 'id' | 'message'>>>;
  };
};

export type CreateAdSelectorSlateCreativeMutationVariables = Exact<{
  creativeInput: AdSelectorSlateCreativeInputV5;
}>;

export type CreateAdSelectorSlateCreativeMutation = { __typename?: 'Mutation' } & {
  createAdSelectorSlateCreativeV5: Maybe<
    { __typename?: 'AdSelectorSlateCreativeV5' } & Pick<AdSelectorSlateCreativeV5, 'id'>
  >;
};

export type CreateAdSelectorVideoCreativeMutationVariables = Exact<{
  creativeInput: AdSelectorVideoCreativeInputV5;
}>;

export type CreateAdSelectorVideoCreativeMutation = { __typename?: 'Mutation' } & {
  createAdSelectorVideoCreativeV5: Maybe<
    { __typename?: 'AdSelectorVideoCreativeV5' } & Pick<AdSelectorVideoCreativeV5, 'id'>
  >;
};

export type CreateVideoCreativeMutationVariables = Exact<{
  creativeInput: VideoCreativeInputV5;
}>;

export type CreateVideoCreativeMutation = { __typename?: 'Mutation' } & {
  createVideoCreativeV5: Maybe<{ __typename?: 'VideoCreativeV5' } & Pick<VideoCreativeV5, 'id'>>;
};

export type CreateSlateCreativeMutationVariables = Exact<{
  creativeInput: SlateCreativeInputV5;
}>;

export type CreateSlateCreativeMutation = { __typename?: 'Mutation' } & {
  createSlateCreativeV5: Maybe<{ __typename?: 'SlateCreativeV5' } & Pick<SlateCreativeV5, 'id'>>;
};

export type CreatePauseCreativeMutationVariables = Exact<{
  creativeInput: PauseCreativeInputV5;
}>;

export type CreatePauseCreativeMutation = { __typename?: 'Mutation' } & {
  createPauseCreativeV5: Maybe<{ __typename?: 'PauseCreativeV5' } & Pick<PauseCreativeV5, 'id'>>;
};

export type CreateBrightlineCreativeMutationVariables = Exact<{
  creativeInput: BrightlineCreativeInputV5;
}>;

export type CreateBrightlineCreativeMutation = { __typename?: 'Mutation' } & {
  createBrightlineCreativeV5: Maybe<{ __typename?: 'BrightlineCreativeV5' } & Pick<BrightlineCreativeV5, 'id'>>;
};

export type CreateDraxCreativeV5MutationVariables = Exact<{
  creativeInput: DraxCreativeInputV5;
}>;

export type CreateDraxCreativeV5Mutation = { __typename?: 'Mutation' } & {
  createDraxCreativeV5: Maybe<{ __typename?: 'DraxCreativeV5' } & Pick<DraxCreativeV5, 'id'>>;
};

export type CreateInnovidCreativeMutationVariables = Exact<{
  creativeInput: InnovidCreativeInputV5;
}>;

export type CreateInnovidCreativeMutation = { __typename?: 'Mutation' } & {
  createInnovidCreativeV5: Maybe<{ __typename?: 'InnovidCreativeV5' } & Pick<InnovidCreativeV5, 'id'>>;
};

export type CreateGatewayGoCreativeMutationVariables = Exact<{
  creativeInput: GatewayGoCreativeInputV5;
}>;

export type CreateGatewayGoCreativeMutation = { __typename?: 'Mutation' } & {
  createGatewayGoCreativeV5: Maybe<{ __typename?: 'GatewayGoCreativeV5' } & Pick<GatewayGoCreativeV5, 'id'>>;
};

export type CreateVastVideoCreativeMutationVariables = Exact<{
  creativeInput: VastVideoCreativeInputV5;
}>;

export type CreateVastVideoCreativeMutation = { __typename?: 'Mutation' } & {
  createVastVideoCreativeV5: Maybe<{ __typename?: 'VASTVideoCreativeV5' } & Pick<VastVideoCreativeV5, 'id'>>;
};

export type UpdateAdSelectorSlateCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: AdSelectorSlateCreativeInputV5;
}>;

export type UpdateAdSelectorSlateCreativeMutation = { __typename?: 'Mutation' } & {
  updateAdSelectorSlateCreativeV5: Maybe<
    { __typename?: 'AdSelectorSlateCreativeV5' } & Pick<AdSelectorSlateCreativeV5, 'id'>
  >;
};

export type UpdateAdSelectorVideoCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: AdSelectorVideoCreativeInputV5;
}>;

export type UpdateAdSelectorVideoCreativeMutation = { __typename?: 'Mutation' } & {
  updateAdSelectorVideoCreativeV5: Maybe<
    { __typename?: 'AdSelectorVideoCreativeV5' } & Pick<AdSelectorVideoCreativeV5, 'id'>
  >;
};

export type UpdateVideoCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: VideoCreativeInputV5;
}>;

export type UpdateVideoCreativeMutation = { __typename?: 'Mutation' } & {
  updateVideoCreativeV5: Maybe<{ __typename?: 'VideoCreativeV5' } & Pick<VideoCreativeV5, 'id'>>;
};

export type UpdateSlateCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: SlateCreativeInputV5;
}>;

export type UpdateSlateCreativeMutation = { __typename?: 'Mutation' } & {
  updateSlateCreativeV5: Maybe<{ __typename?: 'SlateCreativeV5' } & Pick<SlateCreativeV5, 'id'>>;
};

export type UpdatePauseCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: PauseCreativeInputV5;
}>;

export type UpdatePauseCreativeMutation = { __typename?: 'Mutation' } & {
  updatePauseCreativeV5: Maybe<{ __typename?: 'PauseCreativeV5' } & Pick<PauseCreativeV5, 'id'>>;
};

export type UpdateBrightlineCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: BrightlineCreativeInputV5;
}>;

export type UpdateBrightlineCreativeMutation = { __typename?: 'Mutation' } & {
  updateBrightlineCreativeV5: Maybe<{ __typename?: 'BrightlineCreativeV5' } & Pick<BrightlineCreativeV5, 'id'>>;
};

export type UpdateInnovidCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: InnovidCreativeInputV5;
}>;

export type UpdateInnovidCreativeMutation = { __typename?: 'Mutation' } & {
  updateInnovidCreativeV5: Maybe<{ __typename?: 'InnovidCreativeV5' } & Pick<InnovidCreativeV5, 'id'>>;
};

export type UpdateGatewayGoCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: GatewayGoCreativeInputV5;
}>;

export type UpdateGatewayGoCreativeMutation = { __typename?: 'Mutation' } & {
  updateGatewayGoCreativeV5: Maybe<{ __typename?: 'GatewayGoCreativeV5' } & Pick<GatewayGoCreativeV5, 'id'>>;
};

export type UpdateVastVideoCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: VastVideoCreativeInputV5;
}>;

export type UpdateVastVideoCreativeMutation = { __typename?: 'Mutation' } & {
  updateVastVideoCreativeV5: Maybe<{ __typename?: 'VASTVideoCreativeV5' } & Pick<VastVideoCreativeV5, 'id'>>;
};

export type UpdateMagniteCreativeMutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: MagniteCreativeInputV5;
}>;

export type UpdateMagniteCreativeMutation = { __typename?: 'Mutation' } & {
  updateMagniteCreativeV5: Maybe<{ __typename?: 'MagniteCreativeV5' } & Pick<MagniteCreativeV5, 'id'>>;
};

export type UpdateDraxCreativeV5MutationVariables = Exact<{
  id: Scalars['ID'];
  creativeInput: DraxCreativeInputV5;
}>;

export type UpdateDraxCreativeV5Mutation = { __typename?: 'Mutation' } & {
  updateDraxCreativeV5: Maybe<{ __typename?: 'DraxCreativeV5' } & Pick<DraxCreativeV5, 'id'>>;
};

export type UpdateCreativeMetadataByCreativeIdV5MutationVariables = Exact<{
  creativeId: Scalars['ID'];
  countryCodes: Array<Scalars['String']>;
  isLegalApproved: Scalars['Boolean'];
}>;

export type UpdateCreativeMetadataByCreativeIdV5Mutation = { __typename?: 'Mutation' } & {
  updateCreativeMetadataByCreativeIdV5: Maybe<{ __typename?: 'CreativeMetadata' } & CreativeMetadataFragment>;
};

export type UpdateRotationsMutationVariables = Exact<{
  rotationsInput: RotationsInput;
}>;

export type UpdateRotationsMutation = { __typename?: 'Mutation' } & {
  updateRotationsV5: Array<{ __typename?: 'Rotation' } & RotationsFragment>;
};

export type DeleteRotationsMutationVariables = Exact<{
  lineItemId: Scalars['ID'];
}>;

export type DeleteRotationsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteRotationsV5'>;

export type GetAssetsByIDsQueryVariables = Exact<{
  assetIdList: Array<Scalars['String']>;
  filterOptions?: Maybe<ReviewTaskV5FilterOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
  sortOptions?: Maybe<ReviewTaskV5SortOptionsInput>;
}>;

export type GetAssetsByIDsQuery = { __typename?: 'Query' } & {
  getAssetsByIDsV5: Array<
    | ({ __typename?: 'DisplayAssetV5' } & Pick<
        DisplayAssetV5,
        | 'id'
        | 'review'
        | 'rejectionReasons'
        | 'name'
        | 'type'
        | 's3Url'
        | 'createdAt'
        | 'updatedAt'
        | 'md5'
        | 'fileSize'
        | 'transcodeStatus'
      > & {
          assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
          transcodeFailureDetails: Maybe<
            { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
          >;
        } & AssetImporterAssetV5_DisplayAssetV5_Fragment)
    | ({ __typename?: 'TextAssetV5' } & Pick<
        TextAssetV5,
        | 'id'
        | 'review'
        | 'rejectionReasons'
        | 'name'
        | 'type'
        | 's3Url'
        | 'createdAt'
        | 'updatedAt'
        | 'md5'
        | 'fileSize'
        | 'transcodeStatus'
      > & {
          assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
          transcodeFailureDetails: Maybe<
            { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
          >;
        } & AssetImporterAssetV5_TextAssetV5_Fragment)
    | ({ __typename?: 'VideoAssetV5' } & Pick<
        VideoAssetV5,
        | 'duration'
        | 'id'
        | 'review'
        | 'rejectionReasons'
        | 'name'
        | 'type'
        | 's3Url'
        | 'createdAt'
        | 'updatedAt'
        | 'md5'
        | 'fileSize'
        | 'transcodeStatus'
      > & {
          assetTagList: Maybe<Array<{ __typename?: 'AssetTagV5' } & Pick<AssetTagV5, 'value'>>>;
          transcodeFailureDetails: Maybe<
            { __typename?: 'TranscodingFailure' } & Pick<TranscodingFailure, 'name' | 'message'>
          >;
        } & AssetImporterAssetV5_VideoAssetV5_Fragment)
  >;
  getReviewTasksV5: { __typename?: 'ReviewTaskPageV5' } & Pick<ReviewTaskPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'ReviewTaskEdgeV5' } & {
          node:
            | ({ __typename?: 'CreativeReviewTaskV5' } & Pick<CreativeReviewTaskV5, 'derivedSpReview'>)
            | ({ __typename?: 'ExternalAssetReviewTaskV5' } & Pick<ExternalAssetReviewTaskV5, 'derivedSpReview'>);
        }
      >;
    };
};

export type Creative_MetadataQueryVariables = Exact<{
  filterOptions?: Maybe<CreativeMetadataFilterOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type Creative_MetadataQuery = { __typename?: 'Query' } & {
  creativeMetadata: { __typename?: 'CreativeMetadataPage' } & {
    edges: Array<
      { __typename?: 'CreativeMetadataEdge' } & { node: { __typename?: 'CreativeMetadata' } & CreativeMetadataFragment }
    >;
  };
};

export type GetAssetIngestionTracesByCreativeIDsQueryVariables = Exact<{
  creativeIds: Array<Scalars['String']>;
}>;

export type GetAssetIngestionTracesByCreativeIDsQuery = { __typename?: 'Query' } & {
  getAssetIngestionTracesByCreativeIDs: { __typename?: 'IngestionTracesPage' } & {
    edges: Array<
      { __typename?: 'IngestionTraceEdge' } & {
        node: { __typename?: 'IngestionTrace' } & Pick<IngestionTrace, 'id'> & {
            trials: Array<Maybe<{ __typename?: 'IngestionTrial' } & Pick<IngestionTrial, 'trialStatus' | 'detail'>>>;
          };
      }
    >;
  };
};

export type GetRotationsListQueryVariables = Exact<{
  lineItemId: Scalars['ID'];
}>;

export type GetRotationsListQuery = { __typename?: 'Query' } & {
  getRotationsV5: Array<{ __typename?: 'Rotation' } & RotationsFragment>;
};

export type GetSavedAdsQueryVariables = Exact<{
  lineItemIds: Array<Scalars['ID']>;
  lineItemId: Scalars['String'];
}>;

export type GetSavedAdsQuery = { __typename?: 'Query' } & {
  lineItems: { __typename?: 'LineItemsPageV5' } & {
    edges: Array<
      { __typename?: 'LineItemEdgeV5' } & {
        node: { __typename?: 'LineItemV5' } & Pick<
          LineItemV5,
          'isCoppaOrCaru' | 'startDate' | 'endDate' | 'displayPublisherTarget'
        > & {
            schedule: Maybe<
              { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone'> & {
                  dateRangeList: Array<
                    { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                  >;
                }
            >;
            targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
            metrics: Maybe<{ __typename?: 'Metrics' } & Pick<Metrics, 'completions'>>;
            metadata: Maybe<{ __typename?: 'MetadataV5' } & Pick<MetadataV5, 'actualStartDate'>>;
            draxDeal: Maybe<{ __typename?: 'DraxDealV5' } & Pick<DraxDealV5, 'dealId'>>;
            campaign: Maybe<
              { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'name' | 'id' | 'startDate'> & {
                  campaignTagList: Maybe<
                    Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url'>>
                  >;
                }
            >;
          };
      }
    >;
  };
  ads: { __typename?: 'AdsPageV5' } & {
    edges: Array<{ __typename?: 'AdEdgeV5' } & { node: { __typename?: 'AdV5' } & AdsPageAdFragment }>;
  };
  getTargetingTermValuesByLineItemIDV5: Maybe<
    Array<
      { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
          category: Maybe<
            { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>
          >;
          dimension: Maybe<
            { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'>
          >;
          value: Maybe<{ __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName'>>;
        }
    >
  >;
};

export type GetTargetingCategoriesQueryVariables = Exact<{
  includeTargetingPresets?: Maybe<Scalars['Boolean']>;
}>;

export type GetTargetingCategoriesQuery = { __typename?: 'Query' } & {
  getTargetingCategories: Array<
    { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'> & {
        primarySource: { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'source' | 'system'>;
        additionalSource: Array<
          { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'source' | 'system'>
        >;
      }
  >;
};

export type GetTargetingDimensionsByCategoryQueryVariables = Exact<{
  categoryGuid: Scalars['String'];
}>;

export type GetTargetingDimensionsByCategoryQuery = { __typename?: 'Query' } & {
  getTargetingDimensionsByCategory: Array<
    { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'> & {
        primarySource: { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'source' | 'system'>;
        additionalSource: Array<
          { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'source' | 'system'>
        >;
      }
  >;
};

export type GetTargetingValuesByCategoryAndDimensionIdQueryVariables = Exact<{
  categoryGuid: Scalars['String'];
  dimensionGuid: Scalars['String'];
  filterOptions?: Maybe<TargetingValueFilterOptions>;
  paginationOptions?: Maybe<PaginationOptions>;
  countryOption?: Maybe<CountryFilterOption>;
  publisherOption?: Maybe<PublisherFilterOption>;
}>;

export type GetTargetingValuesByCategoryAndDimensionIdQuery = { __typename?: 'Query' } & {
  getTargetingValuesByCategoryAndDimensionID: { __typename?: 'TargetingValuePage' } & {
    edges: Array<
      { __typename?: 'TargetingValueEdge' } & {
        node: { __typename?: 'TargetingValue' } & Pick<
          TargetingValue,
          'id' | 'name' | 'displayName' | 'description'
        > & {
            definition: Maybe<
              Array<
                { __typename?: 'TargetingTermWithBaseValue' } & {
                  category: Maybe<
                    { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>
                  >;
                  value: Maybe<
                    { __typename?: 'BaseTargetingValue' } & Pick<
                      BaseTargetingValue,
                      'id' | 'name' | 'displayName' | 'description'
                    >
                  >;
                  dimension: Maybe<
                    { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'>
                  >;
                }
              >
            >;
            primarySource: { __typename?: 'TargetingSource' } & Pick<
              TargetingSource,
              'id' | 'name' | 'source' | 'system'
            >;
            additionalSource: Array<
              { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'source' | 'system'>
            >;
          };
      }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
  };
};

export type GetTargetingValuesByDimensionAndValueIdQueryVariables = Exact<{
  dimensionGuid: Scalars['String'];
  valueId: Scalars['String'];
}>;

export type GetTargetingValuesByDimensionAndValueIdQuery = { __typename?: 'Query' } & {
  getTargetingValuesByDimensionAndValueID: Array<
    { __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName'> & {
        primarySource: { __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>;
        additionalSource: Array<{ __typename?: 'TargetingSource' } & Pick<TargetingSource, 'id' | 'name' | 'system'>>;
      }
  >;
};

export type GetAssetsByIdQueryVariables = Exact<{
  assetIds: Array<Scalars['String']>;
}>;

export type GetAssetsByIdQuery = { __typename?: 'Query' } & {
  getAssetsByIDsV5: Array<
    | ({ __typename?: 'DisplayAssetV5' } & CreativeAsset_DisplayAssetV5_Fragment)
    | ({ __typename?: 'TextAssetV5' } & CreativeAsset_TextAssetV5_Fragment)
    | ({ __typename?: 'VideoAssetV5' } & CreativeAsset_VideoAssetV5_Fragment)
  >;
};

export type BulkCreateSequencesV5MutationVariables = Exact<{
  inputs: Array<SequenceInput>;
}>;

export type BulkCreateSequencesV5Mutation = { __typename?: 'Mutation' } & {
  bulkCreateSequencesV5: Array<
    { __typename?: 'Sequence' } & Pick<Sequence, 'id' | 'name' | 'type'> & {
        adSequence: Maybe<
          Array<
            Maybe<
              { __typename?: 'AdSequence' } & Pick<AdSequence, 'order' | 'adId' | 'name' | 'sequenceId' | 'updatedAt'>
            >
          >
        >;
      }
  >;
};

export type BulkDeleteSequencesV5MutationVariables = Exact<{
  inputs: Array<Scalars['ID']>;
}>;

export type BulkDeleteSequencesV5Mutation = { __typename?: 'Mutation' } & {
  bulkDeleteSequencesV5: Mutation['bulkDeleteSequencesV5'];
};

export type BulkUpdateSequencesV5MutationVariables = Exact<{
  inputs: Array<SequenceInput>;
}>;

export type BulkUpdateSequencesV5Mutation = { __typename?: 'Mutation' } & {
  bulkUpdateSequencesV5: Array<
    { __typename?: 'Sequence' } & Pick<Sequence, 'id' | 'name' | 'type'> & {
        adSequence: Maybe<
          Array<
            Maybe<
              { __typename?: 'AdSequence' } & Pick<AdSequence, 'order' | 'adId' | 'name' | 'sequenceId' | 'updatedAt'>
            >
          >
        >;
      }
  >;
};

export type Get_Ads_By_Parent_Line_Oms_Link_Id_ListQueryVariables = Exact<{
  lineItemParentLineOmsLinkList: Array<Scalars['ID']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;

export type Get_Ads_By_Parent_Line_Oms_Link_Id_ListQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & Pick<AdsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'AdEdgeV5' } & {
          node: { __typename?: 'AdV5' } & Pick<
            AdV5,
            'id' | 'name' | 'startDate' | 'endDate' | 'trackingId' | 'type'
          > & {
              lineItem: Maybe<
                { __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id' | 'name'> & {
                    parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
                  }
              >;
              adRotation: Maybe<{ __typename?: 'AdRotation' } & Pick<AdRotation, 'rotationId'>>;
              adSequence: Maybe<
                { __typename?: 'AdSequence' } & Pick<AdSequence, 'adId' | 'name' | 'order' | 'sequenceId' | 'updatedAt'>
              >;
            };
        }
      >;
    };
};

export type SendCampaignsOnReviewMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type SendCampaignsOnReviewMutation = { __typename?: 'Mutation' } & {
  sendCampaignsOnReviewV5: Array<{ __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id' | 'review'>>;
};

export type SendLineItemsOnReviewMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type SendLineItemsOnReviewMutation = { __typename?: 'Mutation' } & {
  sendLineItemsOnReviewV5: Array<{ __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id' | 'review'>>;
};

export type GetDrawerTargetingAdQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type GetDrawerTargetingAdQuery = { __typename?: 'Query' } & {
  adsV5: { __typename?: 'AdsPageV5' } & {
    edges: Array<
      { __typename?: 'AdEdgeV5' } & {
        node: { __typename?: 'AdV5' } & Pick<AdV5, 'id' | 'displayPublisherTarget'> & {
            lineItem: Maybe<
              { __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id'> & {
                  targetingTermValues: Maybe<
                    Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>
                  >;
                }
            >;
            targetingTermValues: Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>;
            targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
          };
      }
    >;
  };
};

export type GetDrawerTargetingLineItemQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type GetDrawerTargetingLineItemQuery = { __typename?: 'Query' } & {
  lineItemsV5: { __typename?: 'LineItemsPageV5' } & {
    edges: Array<
      { __typename?: 'LineItemEdgeV5' } & {
        node: { __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id' | 'displayPublisherTarget'> & {
            targetingTermValues: Maybe<Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>>;
            targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
          };
      }
    >;
  };
};

export type UpdateAdTargetingValuesMutationVariables = Exact<{
  id: Scalars['ID'];
  newTargetingValues: UpdateAdInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
}>;

export type UpdateAdTargetingValuesMutation = { __typename?: 'Mutation' } & {
  updateAdV5: { __typename?: 'AdV5' } & {
    targetingTermValues: Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>;
    targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
  };
};

export type UpdateLineItemTargetingValuesMutationVariables = Exact<{
  id: Scalars['ID'];
  newTargetingValues: UpdateLineItemInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
}>;

export type UpdateLineItemTargetingValuesMutation = { __typename?: 'Mutation' } & {
  updateLineItem: Maybe<
    { __typename?: 'LineItemV5' } & {
      targetingTermValues: Maybe<Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>>;
      targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
    }
  >;
};

export type CreateBatchAdsMutationVariables = Exact<{
  adsInput: Array<AdInputV5>;
}>;

export type CreateBatchAdsMutation = { __typename?: 'Mutation' } & {
  createAdsBatchV5: Array<{ __typename?: 'AdV5' } & Pick<AdV5, 'id'>>;
};

export type GetAdsPageAdByIdQueryVariables = Exact<{
  adId: Scalars['ID'];
  lineItemId: Scalars['String'];
}>;

export type GetAdsPageAdByIdQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & {
    edges: Array<{ __typename?: 'AdEdgeV5' } & { node: { __typename?: 'AdV5' } & AdsPageAdFragment }>;
  };
  getTargetingTermValuesByLineItemIDV5: Maybe<
    Array<
      { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
          category: Maybe<{ __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'name' | 'displayName'>>;
          dimension: Maybe<{ __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'name' | 'displayName'>>;
          value: Maybe<{ __typename?: 'TargetingValue' } & Pick<TargetingValue, 'name' | 'displayName'>>;
        }
    >
  >;
};

export type GetLineItemsForAdDuplicateQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  adAccountIdList?: Maybe<Array<Scalars['ID']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  adPotentialTypeList?: Maybe<Array<Scalars['String']>>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  statusList?: Maybe<Array<Scalars['String']>>;
  subStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  campaignIds: Array<Scalars['ID']>;
  paginationOptions: PaginationOptions;
}>;

export type GetLineItemsForAdDuplicateQuery = { __typename?: 'Query' } & {
  lineItemsV5: { __typename?: 'LineItemsPageV5' } & Pick<LineItemsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'LineItemEdgeV5' } & {
          node: { __typename?: 'LineItemV5' } & Pick<
            LineItemV5,
            'id' | 'name' | 'startDate' | 'endDate' | 'billableThirdParty' | 'isCoppaOrCaru' | 'adProduct'
          > & {
              schedule: Maybe<
                { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                    dateRangeList: Array<
                      { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                    >;
                    dayPartList: Maybe<
                      Array<
                        { __typename?: 'DayPartV5' } & Pick<
                          DayPartV5,
                          'activeDayList' | 'firstActiveHour' | 'lastActiveHour'
                        >
                      >
                    >;
                  }
              >;
              campaign: Maybe<
                { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'id'> & {
                    campaignTagList: Maybe<
                      Array<
                        { __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url' | 'isVerified'>
                      >
                    >;
                  }
              >;
            };
        }
      >;
    };
};

export type Get_Ads_For_ExportQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
  campaignIds?: Maybe<Array<Scalars['ID']>>;
  lineItemIds: Array<Scalars['ID']>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  lineItemStatusList?: Maybe<Array<Scalars['String']>>;
  lineItemSubStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  sort?: Maybe<AdSortOptionV5>;
  order?: Maybe<OrderOption>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_Ads_For_ExportQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & Pick<AdsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'AdEdgeV5' } & {
          node: { __typename: 'AdV5' } & Pick<
            AdV5,
            | 'id'
            | 'name'
            | 'type'
            | 'review'
            | 'rating'
            | 'status'
            | 'startDate'
            | 'endDate'
            | 'unDeletable'
            | 'createdAt'
            | 'updatedAt'
            | 'viewabilityVendorList'
            | 'sequence'
            | 'trackingId'
            | 'displayPublisherTarget'
            | 'hasMisalignedSchedule'
          > & {
              schedule: Maybe<
                { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                    dateRangeList: Array<
                      { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                    >;
                    dayPartList: Maybe<
                      Array<
                        { __typename?: 'DayPartV5' } & Pick<
                          DayPartV5,
                          'activeDayList' | 'firstActiveHour' | 'lastActiveHour'
                        >
                      >
                    >;
                  }
              >;
              frequencyCapList: Array<
                { __typename?: 'FrequencyCapV5' } & Pick<
                  FrequencyCapV5,
                  'type' | 'limit' | 'duration' | 'durationUnit' | 'enabled'
                >
              >;
              creative: Maybe<
                | ({ __typename?: 'AdSelectorSlateCreativeV5' } & Pick<
                    AdSelectorSlateCreativeV5,
                    'id' | 'review' | 'type'
                  > & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'AdSelectorVideoCreativeV5' } & Pick<
                    AdSelectorVideoCreativeV5,
                    'id' | 'review' | 'type'
                  > & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'AuditBaseCreativeV5' } & Pick<AuditBaseCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'BrightlineCreativeV5' } & Pick<BrightlineCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'DraxCreativeV5' } & Pick<DraxCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'GatewayGoCreativeV5' } & Pick<GatewayGoCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'InnovidCreativeV5' } & Pick<InnovidCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'InstreamDxcCreativeV5' } & Pick<InstreamDxcCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'MagniteCreativeV5' } & Pick<MagniteCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'PauseCreativeV5' } & Pick<PauseCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'SlateCreativeV5' } & Pick<SlateCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'VASTVideoCreativeV5' } & Pick<VastVideoCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
                | ({ __typename?: 'VideoCreativeV5' } & Pick<VideoCreativeV5, 'id' | 'review' | 'type'> & {
                      metadata: Maybe<
                        { __typename?: 'CreativeMetadata' } & Pick<
                          CreativeMetadata,
                          'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
                        >
                      >;
                    })
              >;
              lineItem: Maybe<
                { __typename?: 'LineItemV5' } & Pick<
                  LineItemV5,
                  | 'startDate'
                  | 'endDate'
                  | 'id'
                  | 'name'
                  | 'adProduct'
                  | 'inventoryBlockId'
                  | 'displayPublisherTarget'
                  | 'billableThirdParty'
                  | 'isCoppaOrCaru'
                  | 'country'
                > & {
                    parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
                    campaign: Maybe<
                      { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'name' | 'id' | 'startDate'> & {
                          adAccount: Maybe<
                            { __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'timezone' | 'organizationId'>
                          >;
                          campaignTagList: Maybe<
                            Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url'>>
                          >;
                          crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
                        }
                    >;
                    schedule: Maybe<
                      { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                          dateRangeList: Array<
                            { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                          >;
                          dayPartList: Maybe<
                            Array<
                              { __typename?: 'DayPartV5' } & Pick<
                                DayPartV5,
                                'firstActiveHour' | 'lastActiveHour' | 'activeDayList'
                              >
                            >
                          >;
                        }
                    >;
                  }
              >;
              adRotation: Maybe<
                { __typename?: 'AdRotation' } & Pick<AdRotation, 'rotationName' | 'rotationId' | 'weight'>
              >;
              adSequence: Maybe<
                { __typename?: 'AdSequence' } & Pick<AdSequence, 'adId' | 'sequenceId' | 'order' | 'name' | 'updatedAt'>
              >;
              adTagList: Array<
                { __typename?: 'AdTag' } & Pick<
                  AdTag,
                  'id' | 'type' | 'event' | 'url' | 'isVerified' | 'unDeletable' | 'isIas'
                >
              >;
            };
        }
      >;
    };
};

export type Get_Campaigns_For_ExportQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']>>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  lineItemStatusList?: Maybe<Array<Scalars['String']>>;
  lineItemSubStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  sort?: Maybe<CampaignSortOptionV5>;
  order?: Maybe<OrderOption>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_Campaigns_For_ExportQuery = { __typename?: 'Query' } & {
  campaigns: { __typename?: 'CampaignsPageV5' } & Pick<CampaignsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'CampaignEdgeV5' } & {
          node: { __typename: 'CampaignV5' } & Pick<
            CampaignV5,
            | 'id'
            | 'name'
            | 'advertiser'
            | 'agency'
            | 'budget'
            | 'hasZeroDeliveryRisk'
            | 'hasMisalignedSchedule'
            | 'orderType'
            | 'trackingId'
            | 'traffickerEmail'
            | 'startDate'
            | 'endDate'
            | 'createdAt'
            | 'updatedAt'
            | 'industryName'
            | 'status'
          > & {
              adAccount: Maybe<
                { __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'name' | 'timezone' | 'organizationId'>
              >;
              crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
              campaignTagList: Maybe<
                Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url' | 'isVerified'>>
              >;
              orderOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
              order: Maybe<{ __typename?: 'OrderV5' } & Pick<OrderV5, 'salespersonEmail'>>;
              campaignLineItems: Array<
                { __typename?: 'CampaignV5LineItem' } & Pick<CampaignV5LineItem, 'id' | 'country'>
              >;
            };
        }
      >;
    };
};

export type Get_Line_Items_For_ExportQueryVariables = Exact<{
  adProductList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  ids: Array<Scalars['ID']>;
  campaignIds: Array<Scalars['ID']>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  statusList?: Maybe<Array<Scalars['String']>>;
  subStatusList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  sort?: Maybe<LineItemSortOptionV5>;
  order?: Maybe<OrderOption>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_Line_Items_For_ExportQuery = { __typename?: 'Query' } & {
  lineItems: { __typename?: 'LineItemsPageV5' } & Pick<LineItemsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'LineItemEdgeV5' } & {
          node: { __typename: 'LineItemV5' } & Pick<
            LineItemV5,
            | 'id'
            | 'pacingType'
            | 'trackingId'
            | 'name'
            | 'review'
            | 'status'
            | 'subStatus'
            | 'adProduct'
            | 'billableThirdParty'
            | 'unitCost'
            | 'costMethod'
            | 'secondRoundEligible'
            | 'dhxpBackfill'
            | 'creativeMaxSeconds'
            | 'lineItemSequenceId'
            | 'revenue'
            | 'priorityValue'
            | 'deliveryBufferPercent'
            | 'frontLoadPercent'
            | 'preemptible'
            | 'goal'
            | 'isFiller'
            | 'podBuyout'
            | 'industryExclusive'
            | 'startDate'
            | 'endDate'
            | 'createdAt'
            | 'updatedAt'
            | 'hasZeroDeliveryRisk'
            | 'hasMisalignedSchedule'
            | 'deliveryGoal'
            | 'displayPublisherTarget'
            | 'isCoppaOrCaru'
            | 'isMakegood'
            | 'isAddedValue'
            | 'maxCreativeMs'
            | 'country'
            | 'inventoryBlockId'
          > & {
              pacingMetrics: Maybe<
                { __typename?: 'LineItemPacingMetrics' } & Pick<
                  LineItemPacingMetrics,
                  'yodaPacingStatus' | 'generalPacingStatus' | 'yodaThreshold'
                >
              >;
              tier: { __typename?: 'LineItemTierV5' } & Pick<LineItemTierV5, 'huluCommitment' | 'name' | 'rank'>;
              priority: { __typename?: 'LineItemPriorityV5' } & Pick<LineItemPriorityV5, 'name' | 'rank'>;
              parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
              lineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
              unitCostDto: { __typename?: 'Money' } & Pick<Money, 'currency' | 'micros'>;
              exclusiveBlock: Maybe<
                { __typename?: 'ExclusiveBlockV5' } & Pick<ExclusiveBlockV5, 'id' | 'name' | 'lineItemIdList'>
              >;
              draxDeal: Maybe<
                { __typename?: 'DraxDealV5' } & Pick<
                  DraxDealV5,
                  'packageId' | 'packageName' | 'dealId' | 'dealName' | 'dspId'
                >
              >;
              campaign: Maybe<
                { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'id' | 'name' | 'traffickerEmail' | 'startDate'> & {
                    order: Maybe<{ __typename?: 'OrderV5' } & Pick<OrderV5, 'salespersonEmail'>>;
                    adAccount: Maybe<
                      { __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'timezone' | 'organizationId'>
                    >;
                    campaignTagList: Maybe<
                      Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url'>>
                    >;
                    crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
                  }
              >;
              schedule: Maybe<
                { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                    dateRangeList: Array<
                      { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                    >;
                    dayPartList: Maybe<
                      Array<
                        { __typename?: 'DayPartV5' } & Pick<
                          DayPartV5,
                          'firstActiveHour' | 'lastActiveHour' | 'activeDayList'
                        >
                      >
                    >;
                  }
              >;
              frequencyCapList: Array<
                { __typename?: 'FrequencyCapV5' } & Pick<
                  FrequencyCapV5,
                  'type' | 'limit' | 'duration' | 'durationUnit' | 'enabled'
                >
              >;
              metrics: Maybe<
                { __typename?: 'Metrics' } & Pick<
                  Metrics,
                  | 'pacingStatus'
                  | 'percentExpected'
                  | 'percentPacing'
                  | 'percentCompletions'
                  | 'remainingExpectedCompletionsAcc'
                  | 'revenueAtRisk'
                  | 'completions'
                  | 'underPacingRevenue'
                  | 'completionsAtRisk'
                >
              >;
              orderLineItemDto: Maybe<
                { __typename?: 'OrderLineItemV5' } & Pick<
                  OrderLineItemV5,
                  'packageName' | 'isMakeGood' | 'buyerName' | 'buyerId'
                > & {
                    parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id' | 'type'>>;
                    childLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id' | 'type'>>;
                  }
              >;
              magniteDealDto: Maybe<
                { __typename?: 'MagniteDealV5' } & Pick<
                  MagniteDealV5,
                  'dealId' | 'dealName' | 'adSourceId' | 'adSourceName' | 'dspName'
                >
              >;
              metadata: Maybe<{ __typename?: 'MetadataV5' } & Pick<MetadataV5, 'actualStartDate'>>;
            };
        }
      >;
    };
};

export type ApproveLineItemsAndCorrelatedAdsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type ApproveLineItemsAndCorrelatedAdsMutation = { __typename?: 'Mutation' } & {
  approveLineItemsAndCorrelatedAdsV5: { __typename?: 'ApproveLineItemsAndCorrelatedAdsResultV5' } & {
    ads: Array<{ __typename?: 'AdV5' } & Pick<AdV5, 'id' | 'review' | 'status'>>;
    lineItems: Array<{ __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id' | 'review' | 'status'>>;
    errors: Maybe<Array<{ __typename?: 'ResponseError' } & Pick<ResponseError, 'id' | 'message'>>>;
  };
};

export type OverrideAdsReviewMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type OverrideAdsReviewMutation = { __typename?: 'Mutation' } & {
  overrideAdsReviewV5: Array<{ __typename?: 'AdV5' } & Pick<AdV5, 'id' | 'review' | 'status'>>;
};

export type Get_Ad_ProductQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Ad_ProductQuery = { __typename?: 'Query' } & Pick<Query, 'getLineItemAdProductV5'>;

export type Get_Currency_TypesQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Currency_TypesQuery = { __typename?: 'Query' } & Pick<Query, 'getCurrencyTypes'>;

export type Get_Salesperson_EmailsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Salesperson_EmailsQuery = { __typename?: 'Query' } & Pick<Query, 'getSalespersonEmails'>;

export type Get_Trafficker_EmailsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  paginationOptions?: Maybe<PaginationOptions>;
}>;

export type Get_Trafficker_EmailsQuery = { __typename?: 'Query' } & {
  getTraffickerEmails: { __typename?: 'TraffickerEmailsPage' } & Pick<TraffickerEmailsPage, 'total'> & {
      edges: Array<
        { __typename?: 'TraffickerEmailEdge' } & {
          node: { __typename?: 'TraffickerEmail' } & Pick<TraffickerEmail, 'id' | 'email'>;
        }
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export type Get_Users_By_RoleQueryVariables = Exact<{
  roleName: Scalars['String'];
  entityType: EntityFilterOptionV4;
  entityId: Scalars['String'];
  paginationOptions?: Maybe<PaginationOptions>;
  filterOptions?: Maybe<UserFilterOptions>;
}>;

export type Get_Users_By_RoleQuery = { __typename?: 'Query' } & {
  getUsersByRoleV4: { __typename?: 'UserPageV4' } & Pick<UserPageV4, 'total'> & {
      edges: Array<
        { __typename?: 'UserEdgeV4' } & {
          node: { __typename?: 'UserV4' } & Pick<
            UserV4,
            'id' | 'email' | 'firstName' | 'lastName' | 'title' | 'phone' | 'adAccountTypes'
          > & {
              contactCrmLink: Maybe<{ __typename?: 'ContactCrmLink' } & Pick<ContactCrmLink, 'crmId' | 'type' | 'url'>>;
              roles: Maybe<
                Array<
                  { __typename?: 'RoleMappingV4' } & Pick<RoleMappingV4, 'entityId' | 'entityType'> & {
                      role: { __typename?: 'RoleV4' } & Pick<RoleV4, 'name'>;
                    }
                >
              >;
            };
        }
      >;
    };
};

export type SaveTraffickingFiltersMutationVariables = Exact<{
  input: TraffickingFiltersInput;
}>;

export type SaveTraffickingFiltersMutation = { __typename?: 'Mutation' } & {
  saveTraffickingFilters: { __typename?: 'ShareableLink' } & Pick<ShareableLink, 'id'>;
};

export type AdTraffickingColumnFragment = { __typename: 'AdV5' } & Pick<
  AdV5,
  | 'id'
  | 'name'
  | 'type'
  | 'review'
  | 'rating'
  | 'status'
  | 'startDate'
  | 'endDate'
  | 'unDeletable'
  | 'createdAt'
  | 'updatedAt'
  | 'viewabilityVendorList'
  | 'sequence'
  | 'trackingId'
  | 'displayPublisherTarget'
  | 'hasMisalignedSchedule'
> & {
    schedule: Maybe<
      { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
          dateRangeList: Array<
            { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
          >;
          dayPartList: Maybe<
            Array<
              { __typename?: 'DayPartV5' } & Pick<DayPartV5, 'activeDayList' | 'firstActiveHour' | 'lastActiveHour'>
            >
          >;
        }
    >;
    frequencyCapList: Array<
      { __typename?: 'FrequencyCapV5' } & Pick<
        FrequencyCapV5,
        'type' | 'limit' | 'duration' | 'durationUnit' | 'enabled'
      >
    >;
    creative: Maybe<
      | ({ __typename?: 'AdSelectorSlateCreativeV5' } & Pick<AdSelectorSlateCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'AdSelectorVideoCreativeV5' } & Pick<AdSelectorVideoCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'AuditBaseCreativeV5' } & Pick<AuditBaseCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'BrightlineCreativeV5' } & Pick<BrightlineCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'DraxCreativeV5' } & Pick<DraxCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'GatewayGoCreativeV5' } & Pick<GatewayGoCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'InnovidCreativeV5' } & Pick<InnovidCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'InstreamDxcCreativeV5' } & Pick<InstreamDxcCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'MagniteCreativeV5' } & Pick<MagniteCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'PauseCreativeV5' } & Pick<PauseCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'SlateCreativeV5' } & Pick<SlateCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'VASTVideoCreativeV5' } & Pick<VastVideoCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
      | ({ __typename?: 'VideoCreativeV5' } & Pick<VideoCreativeV5, 'id' | 'review' | 'type'> & {
            metadata: Maybe<
              { __typename?: 'CreativeMetadata' } & Pick<
                CreativeMetadata,
                'countryCodes' | 'createdAt' | 'creativeId' | 'deleted' | 'isLegalApproved' | 'updatedAt'
              >
            >;
          })
    >;
    lineItem: Maybe<
      { __typename?: 'LineItemV5' } & Pick<
        LineItemV5,
        | 'startDate'
        | 'endDate'
        | 'id'
        | 'name'
        | 'adProduct'
        | 'inventoryBlockId'
        | 'displayPublisherTarget'
        | 'billableThirdParty'
        | 'isCoppaOrCaru'
        | 'country'
      > & {
          exclusiveBlock: Maybe<
            { __typename?: 'ExclusiveBlockV5' } & Pick<ExclusiveBlockV5, 'id' | 'name' | 'lineItemIdList'> & {
                blockedIndustryList: Maybe<Array<{ __typename?: 'Industry' } & Pick<Industry, 'id' | 'name'>>>;
                targetingTermValues: Maybe<
                  Array<
                    { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
                        category: Maybe<
                          { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>
                        >;
                        dimension: Maybe<
                          { __typename?: 'TargetingDimension' } & Pick<
                            TargetingDimension,
                            'id' | 'name' | 'displayName'
                          >
                        >;
                        value: Maybe<
                          { __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName'>
                        >;
                      }
                  >
                >;
              }
          >;
          parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
          campaign: Maybe<
            { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'name' | 'id' | 'startDate'> & {
                adAccount: Maybe<
                  { __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'timezone' | 'organizationId'>
                >;
                campaignTagList: Maybe<
                  Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url'>>
                >;
                crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
              }
          >;
          schedule: Maybe<
            { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                dateRangeList: Array<
                  { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                >;
                dayPartList: Maybe<
                  Array<
                    { __typename?: 'DayPartV5' } & Pick<
                      DayPartV5,
                      'firstActiveHour' | 'lastActiveHour' | 'activeDayList'
                    >
                  >
                >;
              }
          >;
        }
    >;
    adRotation: Maybe<{ __typename?: 'AdRotation' } & Pick<AdRotation, 'rotationName' | 'rotationId' | 'weight'>>;
    adSequence: Maybe<
      { __typename?: 'AdSequence' } & Pick<AdSequence, 'adId' | 'sequenceId' | 'order' | 'name' | 'updatedAt'>
    >;
    adTagList: Array<
      { __typename?: 'AdTag' } & Pick<AdTag, 'id' | 'type' | 'event' | 'url' | 'isVerified' | 'unDeletable' | 'isIas'>
    >;
  };

export type AdsRotationsFragment = { __typename?: 'AdV5' } & Pick<
  AdV5,
  'id' | 'name' | 'weight' | 'startDate' | 'endDate'
> & {
    adRotation: Maybe<
      { __typename?: 'AdRotation' } & Pick<AdRotation, 'weight' | 'rotationName' | 'adId' | 'rotationId'>
    >;
    targetingTermValues: Array<
      { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
          category: Maybe<
            { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>
          >;
          dimension: Maybe<
            { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'>
          >;
          value: Maybe<{ __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName'>>;
        }
    >;
    schedule: Maybe<
      { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
          dateRangeList: Array<
            { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
          >;
          dayPartList: Maybe<
            Array<
              { __typename?: 'DayPartV5' } & Pick<DayPartV5, 'lastActiveHour' | 'firstActiveHour' | 'activeDayList'>
            >
          >;
        }
    >;
    lineItem: Maybe<
      { __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id'> & {
          schedule: Maybe<
            { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                dateRangeList: Array<
                  { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                >;
                dayPartList: Maybe<
                  Array<
                    { __typename?: 'DayPartV5' } & Pick<
                      DayPartV5,
                      'firstActiveHour' | 'lastActiveHour' | 'activeDayList'
                    >
                  >
                >;
              }
          >;
        }
    >;
  };

export type CampaignTraffickingColumnsFragment = { __typename: 'CampaignV5' } & Pick<
  CampaignV5,
  | 'id'
  | 'name'
  | 'advertiser'
  | 'agency'
  | 'budget'
  | 'hasZeroDeliveryRisk'
  | 'hasMisalignedSchedule'
  | 'orderType'
  | 'trackingId'
  | 'traffickerEmail'
  | 'startDate'
  | 'endDate'
  | 'createdAt'
  | 'updatedAt'
  | 'industryName'
  | 'status'
> & {
    adAccount: Maybe<{ __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'name' | 'timezone' | 'organizationId'>>;
    crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
    campaignTagList: Maybe<
      Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url' | 'isVerified'>>
    >;
    orderOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
    order: Maybe<{ __typename?: 'OrderV5' } & Pick<OrderV5, 'salespersonEmail'>>;
    lineItemList: Maybe<Array<{ __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id' | 'country'>>>;
  };

export type CampaignV5PatchResponseFragment = { __typename: 'CampaignV5PatchResponse' } & Pick<
  CampaignV5PatchResponse,
  'id' | 'name' | 'trackingId' | 'traffickerEmail' | 'createdAt' | 'updatedAt'
> & {
    adAccount: Maybe<{ __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'name' | 'timezone' | 'organizationId'>>;
    campaignTagList: Maybe<
      Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url' | 'isVerified'>>
    >;
    orderOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
  };

export type LineItemTraffickingColumnsFragment = { __typename: 'LineItemV5' } & Pick<
  LineItemV5,
  | 'id'
  | 'pacingType'
  | 'trackingId'
  | 'name'
  | 'review'
  | 'status'
  | 'subStatus'
  | 'adProduct'
  | 'billableThirdParty'
  | 'unitCost'
  | 'costMethod'
  | 'secondRoundEligible'
  | 'dhxpBackfill'
  | 'creativeMaxSeconds'
  | 'lineItemSequenceId'
  | 'revenue'
  | 'priorityValue'
  | 'deliveryBufferPercent'
  | 'frontLoadPercent'
  | 'preemptible'
  | 'goal'
  | 'isFiller'
  | 'podBuyout'
  | 'industryExclusive'
  | 'startDate'
  | 'endDate'
  | 'createdAt'
  | 'updatedAt'
  | 'hasZeroDeliveryRisk'
  | 'hasMisalignedSchedule'
  | 'deliveryGoal'
  | 'displayPublisherTarget'
  | 'isCoppaOrCaru'
  | 'isMakegood'
  | 'isAddedValue'
  | 'maxCreativeMs'
  | 'country'
  | 'inventoryBlockId'
> & {
    pacingMetrics: Maybe<
      { __typename?: 'LineItemPacingMetrics' } & Pick<
        LineItemPacingMetrics,
        'yodaPacingStatus' | 'generalPacingStatus' | 'yodaThreshold'
      >
    >;
    tier: { __typename?: 'LineItemTierV5' } & Pick<LineItemTierV5, 'huluCommitment' | 'name' | 'rank'>;
    priority: { __typename?: 'LineItemPriorityV5' } & Pick<LineItemPriorityV5, 'name' | 'rank'>;
    parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
    lineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
    unitCostDto: { __typename?: 'Money' } & Pick<Money, 'currency' | 'micros'>;
    exclusiveBlock: Maybe<
      { __typename?: 'ExclusiveBlockV5' } & Pick<ExclusiveBlockV5, 'id' | 'name' | 'lineItemIdList'> & {
          blockedIndustryList: Maybe<Array<{ __typename?: 'Industry' } & Pick<Industry, 'id' | 'name'>>>;
          targetingTermValues: Maybe<
            Array<
              { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
                  category: Maybe<
                    { __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'id' | 'name' | 'displayName'>
                  >;
                  dimension: Maybe<
                    { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'id' | 'name' | 'displayName'>
                  >;
                  value: Maybe<{ __typename?: 'TargetingValue' } & Pick<TargetingValue, 'id' | 'name' | 'displayName'>>;
                }
            >
          >;
        }
    >;
    draxDeal: Maybe<
      { __typename?: 'DraxDealV5' } & Pick<DraxDealV5, 'packageId' | 'packageName' | 'dealId' | 'dealName' | 'dspId'>
    >;
    campaign: Maybe<
      { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'id' | 'name' | 'traffickerEmail' | 'startDate'> & {
          order: Maybe<{ __typename?: 'OrderV5' } & Pick<OrderV5, 'salespersonEmail'>>;
          adAccount: Maybe<{ __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'timezone' | 'organizationId'>>;
          campaignTagList: Maybe<Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url'>>>;
          crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
        }
    >;
    schedule: Maybe<
      { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
          dateRangeList: Array<
            { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
          >;
          dayPartList: Maybe<
            Array<
              { __typename?: 'DayPartV5' } & Pick<DayPartV5, 'firstActiveHour' | 'lastActiveHour' | 'activeDayList'>
            >
          >;
        }
    >;
    frequencyCapList: Array<
      { __typename?: 'FrequencyCapV5' } & Pick<
        FrequencyCapV5,
        'type' | 'limit' | 'duration' | 'durationUnit' | 'enabled'
      >
    >;
    metrics: Maybe<
      { __typename?: 'Metrics' } & Pick<
        Metrics,
        | 'pacingStatus'
        | 'percentExpected'
        | 'percentPacing'
        | 'percentCompletions'
        | 'remainingExpectedCompletionsAcc'
        | 'revenueAtRisk'
        | 'completions'
        | 'underPacingRevenue'
        | 'completionsAtRisk'
      >
    >;
    orderLineItemDto: Maybe<
      { __typename?: 'OrderLineItemV5' } & Pick<
        OrderLineItemV5,
        'packageName' | 'isMakeGood' | 'buyerName' | 'buyerId'
      > & {
          parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id' | 'type'>>;
          childLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id' | 'type'>>;
        }
    >;
    magniteDealDto: Maybe<
      { __typename?: 'MagniteDealV5' } & Pick<
        MagniteDealV5,
        'dealId' | 'dealName' | 'adSourceId' | 'adSourceName' | 'dspName'
      >
    >;
    metadata: Maybe<{ __typename?: 'MetadataV5' } & Pick<MetadataV5, 'actualStartDate'>>;
  };

export type UpdateAdMutationVariables = Exact<{
  id: Scalars['ID'];
  updateAdInput: UpdateAdInputV5;
}>;

export type UpdateAdMutation = { __typename?: 'Mutation' } & {
  updateAd: { __typename?: 'AdV5' } & AdTraffickingColumnFragment;
};

export type DeleteAdsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type DeleteAdsMutation = { __typename?: 'Mutation' } & { deleteAds: Mutation['deleteAdsV5'] };

export type UpdateCampaignMutationVariables = Exact<{
  id: Scalars['ID'];
  campaignInput: CampaignInputV5;
}>;

export type UpdateCampaignMutation = { __typename?: 'Mutation' } & {
  updateCampaign: Maybe<{ __typename?: 'CampaignV5PatchResponse' } & CampaignV5PatchResponseFragment>;
};

export type UpdateLineItemMutationVariables = Exact<{
  id: Scalars['ID'];
  updateLineItemInput: UpdateLineItemInputV5;
}>;

export type UpdateLineItemMutation = { __typename?: 'Mutation' } & {
  updateLineItem: Maybe<{ __typename?: 'LineItemV5' } & LineItemTraffickingColumnsFragment>;
};

export type UpdatePacingTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  pacingType: Scalars['String'];
}>;

export type UpdatePacingTypeMutation = { __typename?: 'Mutation' } & {
  updateLineItemPacingType: { __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id' | 'pacingType'>;
};

export type UpdateRotationNameMutationVariables = Exact<{
  rotationId: Scalars['ID'];
  name: Scalars['String'];
}>;

export type UpdateRotationNameMutation = { __typename?: 'Mutation' } & {
  updateRotationName: Array<{ __typename?: 'Rotation' } & RotationsFragment>;
};

export type DeleteSequenceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteSequenceMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteSequenceV5'>;

export type UpdateSequenceMutationVariables = Exact<{
  input: SequenceInput;
}>;

export type UpdateSequenceMutation = { __typename?: 'Mutation' } & {
  updateSequenceV5: { __typename?: 'Sequence' } & Pick<Sequence, 'id' | 'type'> & {
      adSequence: Maybe<
        Array<Maybe<{ __typename?: 'AdSequence' } & Pick<AdSequence, 'order' | 'sequenceId' | 'adId'>>>
      >;
    };
};

export type CreateSequenceMutationVariables = Exact<{
  input: SequenceInput;
}>;

export type CreateSequenceMutation = { __typename?: 'Mutation' } & {
  createSequenceV5: { __typename?: 'Sequence' } & Pick<Sequence, 'id' | 'type'> & {
      adSequence: Maybe<
        Array<Maybe<{ __typename?: 'AdSequence' } & Pick<AdSequence, 'order' | 'sequenceId' | 'adId'>>>
      >;
    };
};

export type Get_CampaignsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']>>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  lineItemStatusList?: Maybe<Array<Scalars['String']>>;
  lineItemSubStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  sort?: Maybe<CampaignSortOptionV5>;
  order?: Maybe<OrderOption>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_CampaignsQuery = { __typename?: 'Query' } & {
  campaigns: { __typename?: 'CampaignsPageV5' } & Pick<CampaignsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'CampaignEdgeV5' } & { node: { __typename?: 'CampaignV5' } & CampaignTraffickingColumnsFragment }
      >;
    };
};

export type Get_AdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
  campaignIds?: Maybe<Array<Scalars['ID']>>;
  lineItemIds: Array<Scalars['ID']>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  lineItemStatusList?: Maybe<Array<Scalars['String']>>;
  lineItemSubStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  sort?: Maybe<AdSortOptionV5>;
  order?: Maybe<OrderOption>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_AdsQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & Pick<AdsPageV5, 'total'> & {
      edges: Array<{ __typename?: 'AdEdgeV5' } & { node: { __typename?: 'AdV5' } & AdTraffickingColumnFragment }>;
    };
};

export type Get_Ads_IdsQueryVariables = Exact<{
  campaignsIds?: Maybe<Array<Scalars['ID']>>;
  lineItemsIds?: Maybe<Array<Scalars['ID']>>;
  limit: Scalars['Int'];
}>;

export type Get_Ads_IdsQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & {
    edges: Array<{ __typename?: 'AdEdgeV5' } & { node: { __typename?: 'AdV5' } & Pick<AdV5, 'id'> }>;
  };
};

export type GetAdsRotationsQueryVariables = Exact<{
  lineItemIds: Array<Scalars['ID']>;
}>;

export type GetAdsRotationsQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & {
    edges: Array<{ __typename?: 'AdEdgeV5' } & { node: { __typename?: 'AdV5' } & AdsRotationsFragment }>;
  };
};

export type GetAdsWithTargetingsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type GetAdsWithTargetingsQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & Pick<AdsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'AdEdgeV5' } & {
          node: { __typename?: 'AdV5' } & {
            targetingTermValues: Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>;
            lineItem: Maybe<
              { __typename?: 'LineItemV5' } & Pick<
                LineItemV5,
                | 'startDate'
                | 'endDate'
                | 'id'
                | 'name'
                | 'adProduct'
                | 'displayPublisherTarget'
                | 'billableThirdParty'
                | 'isCoppaOrCaru'
                | 'country'
              > & {
                  exclusiveBlock: Maybe<
                    { __typename?: 'ExclusiveBlockV5' } & Pick<ExclusiveBlockV5, 'id' | 'name' | 'lineItemIdList'> & {
                        blockedIndustryList: Maybe<Array<{ __typename?: 'Industry' } & Pick<Industry, 'id' | 'name'>>>;
                        targetingTermValues: Maybe<
                          Array<
                            { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
                                category: Maybe<
                                  { __typename?: 'TargetingCategory' } & Pick<
                                    TargetingCategory,
                                    'id' | 'name' | 'displayName'
                                  >
                                >;
                                dimension: Maybe<
                                  { __typename?: 'TargetingDimension' } & Pick<
                                    TargetingDimension,
                                    'id' | 'name' | 'displayName'
                                  >
                                >;
                                value: Maybe<
                                  { __typename?: 'TargetingValue' } & Pick<
                                    TargetingValue,
                                    'id' | 'name' | 'displayName'
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >;
                  parentLineOmsLink: Maybe<{ __typename?: 'OmsLinkV5' } & Pick<OmsLinkV5, 'id'>>;
                  campaign: Maybe<
                    { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'name' | 'id' | 'startDate'> & {
                        adAccount: Maybe<
                          { __typename?: 'AdAccountV5' } & Pick<AdAccountV5, 'id' | 'timezone' | 'organizationId'>
                        >;
                        campaignTagList: Maybe<
                          Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url'>>
                        >;
                        crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
                      }
                  >;
                  schedule: Maybe<
                    { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                        dateRangeList: Array<
                          { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                        >;
                        dayPartList: Maybe<
                          Array<
                            { __typename?: 'DayPartV5' } & Pick<
                              DayPartV5,
                              'firstActiveHour' | 'lastActiveHour' | 'activeDayList'
                            >
                          >
                        >;
                      }
                  >;
                  targetingTermValues: Maybe<
                    Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>
                  >;
                }
            >;
          } & AdTraffickingColumnFragment;
        }
      >;
    };
};

export type LineItemPrioritiesQueryVariables = Exact<{ [key: string]: never }>;

export type LineItemPrioritiesQuery = { __typename?: 'Query' } & {
  lineItemPrioritiesV5: Array<{ __typename?: 'LineItemPriorityV5' } & Pick<LineItemPriorityV5, 'name' | 'rank'>>;
};

export type Get_Lineitems_By_Campaignid_ListQueryVariables = Exact<{
  adProductList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  ids: Array<Scalars['ID']>;
  campaignIds: Array<Scalars['ID']>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  statusList?: Maybe<Array<Scalars['String']>>;
  subStatusList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  currencyList?: Maybe<Array<Scalars['String']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  countryList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  sort?: Maybe<LineItemSortOptionV5>;
  order?: Maybe<OrderOption>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;

export type Get_Lineitems_By_Campaignid_ListQuery = { __typename?: 'Query' } & {
  lineItems: { __typename?: 'LineItemsPageV5' } & Pick<LineItemsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'LineItemEdgeV5' } & { node: { __typename?: 'LineItemV5' } & LineItemTraffickingColumnsFragment }
      >;
    };
};

export type Get_Line_Items_IdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
  limit: Scalars['Int'];
}>;

export type Get_Line_Items_IdsQuery = { __typename?: 'Query' } & {
  lineItems: { __typename?: 'LineItemsPageV5' } & {
    edges: Array<{ __typename?: 'LineItemEdgeV5' } & { node: { __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id'> }>;
  };
};

export type GetLineItemsWithTargetingsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type GetLineItemsWithTargetingsQuery = { __typename?: 'Query' } & {
  lineItems: { __typename?: 'LineItemsPageV5' } & Pick<LineItemsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'LineItemEdgeV5' } & {
          node: { __typename?: 'LineItemV5' } & {
            targetingTermValues: Maybe<Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>>;
            targetingRule: Maybe<{ __typename?: 'TargetingRule' } & TargetingRuleDefinitionFragment>;
          } & LineItemTraffickingColumnsFragment;
        }
      >;
    };
};

export type GetLineItemsToDuplicateQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  adAccountIdList?: Maybe<Array<Scalars['ID']>>;
  accountExecutiveList?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<ScheduleFilterInputV5>;
  adProductList?: Maybe<Array<Scalars['String']>>;
  adPotentialTypeList?: Maybe<Array<Scalars['String']>>;
  generalPacingStatusList?: Maybe<Array<Scalars['String']>>;
  reviewStatusList?: Maybe<Array<Scalars['String']>>;
  pacingRiskList?: Maybe<Array<Scalars['String']>>;
  hasMisalignedSchedule?: Maybe<Scalars['Boolean']>;
  adStatusList?: Maybe<Array<Scalars['String']>>;
  traffickerEmailList?: Maybe<Array<Scalars['String']>>;
  orderTypeList?: Maybe<Array<Scalars['String']>>;
  statusList?: Maybe<Array<Scalars['String']>>;
  subStatusList?: Maybe<Array<Scalars['String']>>;
  tierNameList?: Maybe<Array<Scalars['String']>>;
  priorityValueList?: Maybe<Array<Scalars['String']>>;
  billableThirdPartyList?: Maybe<Array<Scalars['String']>>;
  publisherList?: Maybe<Array<Scalars['String']>>;
  adRatingList?: Maybe<Array<Scalars['String']>>;
  adTypeList?: Maybe<Array<Scalars['String']>>;
  creativeTypeList?: Maybe<Array<Scalars['String']>>;
  creativeReviewList?: Maybe<Array<Scalars['String']>>;
  isCoppaOrCaru?: Maybe<Scalars['Boolean']>;
  isMakegood?: Maybe<Scalars['Boolean']>;
  isAddedValue?: Maybe<Scalars['Boolean']>;
  brandIdList?: Maybe<Array<Scalars['ID']>>;
  agencyIdList?: Maybe<Array<Scalars['ID']>>;
  industryIdList?: Maybe<Array<Scalars['ID']>>;
  isRotation?: Maybe<Scalars['Boolean']>;
  isSequence?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  campaignIds: Array<Scalars['ID']>;
  paginationOptions: PaginationOptions;
}>;

export type GetLineItemsToDuplicateQuery = { __typename?: 'Query' } & {
  lineItemsV5: { __typename?: 'LineItemsPageV5' } & Pick<LineItemsPageV5, 'total'> & {
      edges: Array<
        { __typename?: 'LineItemEdgeV5' } & {
          node: { __typename?: 'LineItemV5' } & Pick<
            LineItemV5,
            'id' | 'name' | 'startDate' | 'endDate' | 'billableThirdParty' | 'isCoppaOrCaru' | 'adProduct'
          > & {
              schedule: Maybe<
                { __typename?: 'ScheduleV5' } & Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'> & {
                    dateRangeList: Array<
                      { __typename?: 'DateRangeV5' } & Pick<DateRangeV5, 'startDate' | 'endDate' | 'pacingShare'>
                    >;
                    dayPartList: Maybe<
                      Array<
                        { __typename?: 'DayPartV5' } & Pick<
                          DayPartV5,
                          'activeDayList' | 'firstActiveHour' | 'lastActiveHour'
                        >
                      >
                    >;
                  }
              >;
              campaign: Maybe<
                { __typename?: 'CampaignV5' } & Pick<CampaignV5, 'id'> & {
                    campaignTagList: Maybe<
                      Array<
                        { __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url' | 'isVerified'>
                      >
                    >;
                  }
              >;
            };
        }
      >;
    };
};

export type GetAdsPageAdsByIDsQueryVariables = Exact<{
  adIds: Array<Scalars['ID']>;
  lineItemIds: Array<Scalars['String']>;
  adsLimit: Scalars['Int'];
}>;

export type GetAdsPageAdsByIDsQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & {
    edges: Array<{ __typename?: 'AdEdgeV5' } & { node: { __typename?: 'AdV5' } & AdsPageAdFragment }>;
  };
  getTargetingTermValuesByLineItemIDsV5: Array<
    { __typename?: 'LineItemTargetingValueBody' } & Pick<LineItemTargetingValueBody, 'lineItemId'> & {
        targetingTermValues: Array<
          { __typename?: 'TargetingTermValue' } & Pick<TargetingTermValue, 'include'> & {
              category: Maybe<{ __typename?: 'TargetingCategory' } & Pick<TargetingCategory, 'name' | 'displayName'>>;
              dimension: Maybe<
                { __typename?: 'TargetingDimension' } & Pick<TargetingDimension, 'name' | 'displayName'>
              >;
              value: Maybe<{ __typename?: 'TargetingValue' } & Pick<TargetingValue, 'name' | 'displayName'>>;
            }
        >;
      }
  >;
};

export type Get_Ads_By_Line_Item_IdsQueryVariables = Exact<{
  lineItemIds: Array<Scalars['ID']>;
}>;

export type Get_Ads_By_Line_Item_IdsQuery = { __typename?: 'Query' } & {
  ads: { __typename?: 'AdsPageV5' } & {
    edges: Array<
      { __typename?: 'AdEdgeV5' } & {
        node: { __typename?: 'AdV5' } & Pick<AdV5, 'status'> & {
            lineItem: Maybe<{ __typename?: 'LineItemV5' } & Pick<LineItemV5, 'id'>>;
          };
      }
    >;
  };
};

export type MultiEditLineItemMutationVariables = Exact<{
  input: MultiEditLineItemsInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
}>;

export type MultiEditLineItemMutation = { __typename?: 'Mutation' } & {
  multiEditLineItem: Array<{ __typename: 'LineItemV5' } & LineItemTraffickingColumnsFragment>;
};

export type MultiEditAdsMutationVariables = Exact<{
  input: MultiEditAdsInputV5;
  targetingRule?: Maybe<TargetingRuleInput>;
}>;

export type MultiEditAdsMutation = { __typename?: 'Mutation' } & {
  multiEditAds: Array<{ __typename: 'AdV5' } & AdTraffickingColumnFragment>;
};
