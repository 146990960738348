import { ChangeAction } from '../../apis/graphql';

export enum ChangesPrefixes {
  AdRotation = 'ad-rotation',
}

export enum NestedArrayChangesProperties {
  AdList = 'ad-list',
}

export const ROWS_TO_IGNORE = [
  'updated-at',
  'created-at',
  'permitted-creative-type-list',
  'ad-rotation.ad-id',
  'ad-rotation.order',
];

export const ROWS_TO_IGNORE_DUPLICATE = ['id'];

export const changeActionToTitleActionMap: Record<ChangeAction, string> = {
  [ChangeAction.Addition]: 'created',
  [ChangeAction.Removal]: 'removed',
  [ChangeAction.Update]: 'data changed',
};

export const arrayChangesTitles: Record<string, string> = {
  adList: 'Ad',
};

export const NOTIFY_ON_NETWORK_STATUS_CHANGE = true;
export const LIMIT_AUDITS = 25;
export const DEFAULT_AMOUNT_OF_LOGS_TO_SHOW = 12;
export const START_OF_DAY_TIME = 'T00:00:00Z';
export const END_OF_DAY_TIME = 'T23:59:59Z';
