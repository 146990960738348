import './TargetingTable.scss';

import { isEqual as _isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { CellProps, Column } from 'react-table';
import { useFlexLayout, useSortBy, useTable } from 'react-table';

import type { TargetingTermValue } from '../../models';
import bem from '../../utils/bem';
import { genericMemo } from '../../utils/genericMemo';
import type { ListHelpers } from '../../utils/listHelpers';
import { workWithLocalStorage } from '../../utils/storage/localStorage';
import { getTargetingKey } from '../AdForm/Targeting/util/getTargetingKey';
import SortArrow from '../SortArrow';
import { SortState } from '../SortArrow/constants';
import { DIMENSION_INCLUDE_EXCLUDE_LIST } from '../TargetingDropdowns/constants';
import { ToggleSection } from '../ToggleSection';
import { LOCAL_STORAGE_EXPANDABLE_ROWS_STATE, MIN_COLLAPSIBLE_ROW_GROUP_SIZE } from './constants';
import TargetingTableActionCell from './TargetingTableActionCell';
import { assignIdFromUnsortedData, changeSortState, sortTargetingTableRowGroups } from './utils';
import { buildRowGroups, generateRow, generateToggleRow, getExpandCollapseText } from './utils/helpers';

export type TargetingTableRowGroup = {
  groupType?: string;
  entityName?: string;
  targetingTermValues: TargetingTermValue[];
};

type ListHelpersPartial = Pick<ListHelpers<TargetingTermValue>, 'removeAt' | 'replaceAt' | 'removeAll'>;

export interface TargetingTableProps {
  data: TargetingTableRowGroup[];
  listHelpers?: ListHelpersPartial;
  editMode?: boolean;
  isAd?: boolean;
  isReadOnly?: boolean;
  isTableToggleable?: boolean;
  isTableDataFiltered?: boolean;
  filteredTargetingValues?: Set<string>;
}

const [block, element] = bem('targeting-table');

function TargetingValueCell({ value: cellValue }: CellProps<TargetingTermValue>): JSX.Element {
  if (!cellValue || !cellValue?.displayName) return <>No Value found</>;
  return cellValue.displayName;
}

function getAgeMinMax(values: string[]): string {
  const valFilter: number[] = values.filter((val) => !Number.isNaN(val)).map((val) => parseInt(val, 10));
  const lower: number = Math.min(...valFilter);
  const upper: number | undefined = values.length > 1 ? Math.max(...valFilter) : undefined;

  return `${lower}${upper ? ' - ' + upper : ''}`;
}

function TargetingTable({
  data,
  listHelpers,
  editMode = true,
  isAd,
  isReadOnly = true,
  isTableToggleable = true,
  isTableDataFiltered = false,
  filteredTargetingValues,
}: TargetingTableProps): JSX.Element {
  const [expandedRows, setExpandedRows] = useState<boolean[]>(new Array(data.length).fill(true));
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const [tableSort, setTableSort] = useState(SortState.UNSORTED);
  const sortedData = useMemo(() => sortTargetingTableRowGroups(data, tableSort), [data, tableSort]);
  const targetingTermValuesData = useMemo(
    () =>
      sortedData.reduce(
        (allTermValues: TargetingTermValue[], { targetingTermValues }) => [...allTermValues, ...targetingTermValues],
        []
      ),
    [sortedData]
  );

  const handleExpandCollapseClick = (): void => {
    setExpandedGroups((prevExpandedGroups) => {
      const allExpanded = Object.values(prevExpandedGroups).every((isExpanded) => isExpanded === true);

      const newExpandedGroups = Object.keys(prevExpandedGroups).reduce<Record<string, boolean>>((acc, group) => {
        acc[group] = !allExpanded;
        return acc;
      }, {});

      return newExpandedGroups;
    });
  };
  const changeToggleByDimension = useCallback(
    (value: boolean, row: TargetingTermValue, subGroupType: string): void => {
      const type: string = row.dimension.name;
      const listGroup = sortedData.find((group) => group.groupType === subGroupType);

      if (!DIMENSION_INCLUDE_EXCLUDE_LIST.includes(row.dimension.id)) {
        // update all include values of the same dimensions
        listGroup?.targetingTermValues.forEach((rowToUpdate, idx) => {
          const currType: string = rowToUpdate.dimension.name;
          if (currType === type) {
            listHelpers?.replaceAt(idx, { ...rowToUpdate, include: value });
          }
        });
      } else {
        // match on row key instead of row object. row instance may change with filtering
        const rowIndex =
          listGroup?.targetingTermValues?.findIndex(
            (targetingTerm) => getTargetingKey(row) === getTargetingKey(targetingTerm)
          ) ?? 0;

        listHelpers?.replaceAt(rowIndex, { ...row, include: value });
      }
    },
    [listHelpers, sortedData]
  );

  const columns: Column<TargetingTermValue>[] = useMemo(
    (): Column<TargetingTermValue>[] => {
      const actionColumn: Column<TargetingTermValue> = {
        Header: (): React.JSX.Element => {
          return (
            <button className={element('expand-collapse-text')} onClick={handleExpandCollapseClick}>
              {getExpandCollapseText(expandedGroups)}
            </button>
          );
        },
        accessor: 'include',
        Cell: (props): JSX.Element => {
          return (
            <TargetingTableActionCell
              editMode={editMode}
              listHelpers={listHelpers as ListHelpersPartial}
              isLineItemTargetingTermValue={props.isLineItemTargetingTermValue}
              changeToggleByDimension={changeToggleByDimension}
              {...props}
            />
          );
        },
        disableSortBy: true,
        maxWidth: 100,
      };

      return [
        {
          Header: 'Category',
          accessor: (a): string => a?.category?.displayName || 'No Category found',
          maxWidth: 100,
        },
        {
          Header: 'Type',
          accessor: (a): string => a?.dimension?.displayName || 'No Dimension found',
          disableSortBy: true,
          maxWidth: 80,
        },
        {
          Header: 'Value',
          accessor: 'value',
          Cell: TargetingValueCell,
          disableSortBy: true,
          maxWidth: 140,
        },
        ...(isReadOnly && listHelpers ? [actionColumn] : []),
      ];
    },
    // eslint-disable-next-line
    [editMode, listHelpers, expandedGroups, changeToggleByDimension]
  );

  const tableInstance = useTable<TargetingTermValue>(
    {
      columns,
      data: targetingTermValuesData,
      autoResetHiddenColumns: false,
      manualSortBy: true,
    },
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setTableSort(SortState.UNSORTED);
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const handleClearSelections = useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      listHelpers?.removeAll();
    },
    [listHelpers]
  );

  let completeGroupedData: Record<string, boolean> = {};
  const hasInitializedRef = useRef(false);

  useEffect(() => {
    if ((!hasInitializedRef.current && data.length > 0) || editMode) {
      if (!_isEqual(completeGroupedData, expandedGroups)) {
        setExpandedGroups(completeGroupedData);
      }
      hasInitializedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let nThRowForGeneratingKey = 0;

  return (
    <table {...getTableProps()} cellSpacing={0} cellPadding={0} className={block()}>
      <thead>
        <tr {...headerGroups[0].getHeaderGroupProps()}>
          {headerGroups[0].headers.map((column, headerIndex) => (
            <th
              tabIndex={-1}
              {...column.getHeaderProps(column.getSortByToggleProps())}
              key={`table-head-${headerIndex}`}
            >
              <div className={element('header-cell')}>
                {column.render('Header')}
                {column.canSort && (
                  <SortArrow
                    isSorted={tableSort !== SortState.UNSORTED}
                    isDescending={tableSort === SortState.SORTED_DESC}
                    onClick={(): void => setTableSort(changeSortState(tableSort))}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()} key="targeting-table-body">
        {sortedData.map(({ targetingTermValues, groupType, entityName }, rowGroupIndex) => {
          const handleToggle = (): void => {
            setExpandedRows((prevExpandedRows) => {
              const updatedExpandedRows = [...prevExpandedRows];
              updatedExpandedRows[rowGroupIndex] = !prevExpandedRows[rowGroupIndex];
              return updatedExpandedRows;
            });
          };
          const ageValArr: string[] = targetingTermValues
            .filter((entry) => entry.dimension.id === 'age')
            .map((entry) => entry.value.id);
          const sliceRowIndex = rowGroupIndex ? sortedData[rowGroupIndex - 1].targetingTermValues.length : 0;

          const isShowAction =
            targetingTermValues.length > 0 &&
            editMode &&
            ((!isAd && groupType === 'Line Item Targeting') || (isAd && groupType === 'Ad Targeting'));

          const TableToggleWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => (
            <td className={element('toggle-cell')}>
              <ToggleSection
                title={
                  <div className={element('section-title')}>
                    <div>
                      {groupType}
                      <span>
                        {entityName ? ` - ${entityName}` : ' '} ({targetingTermValues.length})
                      </span>
                    </div>
                  </div>
                }
                isExpanded={expandedRows[rowGroupIndex]}
                handleToggle={handleToggle}
                key={`toggle-section-${rowGroupIndex}`}
                toggleClassName={`${element('toggle')}`}
                action={
                  isShowAction && (
                    <button className={element('clear')} title="Clear Selections" onClick={handleClearSelections}>
                      Clear Selections
                    </button>
                  )
                }
              >
                {children}
              </ToggleSection>
            </td>
          );

          const targetingRows = rows.slice(sliceRowIndex, sliceRowIndex + targetingTermValues.length);
          const groupedRowData = buildRowGroups(targetingRows);
          let targetingTermsTable: React.JSX.Element[] = [];
          for (let group in groupedRowData) {
            let rowsToAdd: React.JSX.Element | React.JSX.Element[] = [];
            if (groupedRowData[group].items.length < MIN_COLLAPSIBLE_ROW_GROUP_SIZE) {
              // eslint-disable-next-line no-loop-func
              rowsToAdd = groupedRowData[group].items.reduce((accum, termValueRow, rowIndexWithinGroup) => {
                const rowKey = nThRowForGeneratingKey++;
                termValueRow.index = assignIdFromUnsortedData(data, groupType!, termValueRow);
                prepareRow(termValueRow);

                // don't display row if it's filtered out
                if (isTableDataFiltered && !filteredTargetingValues?.has(getTargetingKey(termValueRow.original))) {
                  return accum;
                }
                return [...accum, generateRow(termValueRow, rowKey, rowIndexWithinGroup, groupType, element)];
              }, [] as React.JSX.Element[]);
            } else {
              const adOrLineItemGroup = group + rowGroupIndex;
              const handleToggleGroup = (): void => {
                setExpandedGroups((prevExpandedGroups) => {
                  const updatedExpandedGroups = {
                    ...prevExpandedGroups,
                    [adOrLineItemGroup]: !prevExpandedGroups[adOrLineItemGroup],
                  };
                  return updatedExpandedGroups;
                });
              };

              let rowGroup: React.JSX.Element[] = [];
              // eslint-disable-next-line no-loop-func
              groupedRowData[group].items.forEach((termValueRow, rowIndexWithinGroup) => {
                termValueRow.index = assignIdFromUnsortedData(data, groupType!, termValueRow);
                prepareRow(termValueRow);
                const rowInGroup = generateRow(
                  termValueRow,
                  nThRowForGeneratingKey++,
                  rowIndexWithinGroup,
                  groupType,
                  element,
                  groupedRowData[group].items.length - 1,
                  expandedGroups[adOrLineItemGroup],
                  handleToggleGroup
                );

                // don't display row if it's filtered out
                if (isTableDataFiltered && !filteredTargetingValues?.has(getTargetingKey(termValueRow.original))) {
                  return;
                }
                rowGroup.push(rowInGroup);
              });

              // display rowgroup if it's not empty
              if (rowGroup.length) {
                rowsToAdd = generateToggleRow(rowGroup, expandedGroups[adOrLineItemGroup], element, handleToggleGroup);
                completeGroupedData[adOrLineItemGroup] =
                  expandedGroups[adOrLineItemGroup] ||
                  (workWithLocalStorage.getData(LOCAL_STORAGE_EXPANDABLE_ROWS_STATE) as boolean);
              }
            }
            targetingTermsTable = targetingTermsTable.concat(rowsToAdd);
          }

          if (targetingTermsTable.length === 0) {
            const emptyTableMsg = isTableDataFiltered ? 'No matching targeting' : 'No targeting added yet';
            targetingTermsTable = targetingTermsTable.concat(
              <span key="no-targeting" className={element('no-targeting')}>
                {emptyTableMsg}
              </span>
            );
          }

          const tdElem = isTableToggleable ? (
            <TableToggleWrapper key={`${rowGroupIndex}-toggle-wrapper`}>{targetingTermsTable}</TableToggleWrapper>
          ) : (
            targetingTermsTable
          );

          return (
            <tr className={element('toggle-section-row')} key={`${rowGroupIndex}-toggle-section-row`}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {ageValArr.length ? (
                  <div>{`NOTE: You selected Age values '${ageValArr.join(', ')}' your value will be '${getAgeMinMax(
                    ageValArr
                  )}'`}</div>
                ) : null}
                {tdElem}
              </div>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default genericMemo(TargetingTable);
