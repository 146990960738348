// this is a non-comprehensive list of element attributes that are focusable, we can add more as needed
// for full list, refer to https://allyjs.io/data-tables/focusable.html
// NOTE: the "data-focusable" attribute is unique to this application to add focusabilty for elements that don't have it by default (for example, div's)

export const FOCUSABLE_ELEMENT_SELECTOR =
  'a[href]:not([tabindex="-1"]), button:not([tabindex="-1"]), textarea:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), [data-focusable]';

export const baseModalContainerClassName = 'base-modal-container';

export const SELECT_ROW_TOOLTIP = 'Toggle Row Selected';

export const URL_REGEX = /^https:\/\/(?!\/)[^<>#|~^]*$/i;

export const TAG_VALIDATION_MESSAGE =
  'Tag must start with https:// and not consist of symbols such as ^, ~, >, <, #, |';
export const URL_VALIDATION_MESSAGE =
  'URL must start with https:// and not consist of symbols such as ^, ~, >, <, #, |';

export const LOCATION_BEFORE_LOGIN = 'locationBeforeLogin';
export const CLIPBOARD_SUCCESS_MESSAGE = 'Copied to clipboard';
export const CLIPBOARD_FAILURE_MESSAGE = 'Failed to copy to clipboard';
