import { useCallback, useEffect, useState } from 'react';

import type { DateRange as Range } from '../../../models';
import type { FilterVariables } from '../ChangeLogList/usePagination';
import { END_OF_DAY_TIME, LIMIT_AUDITS, START_OF_DAY_TIME } from '../constants';
import { convertLocalDateToUTC } from './utils';

const initialDateRange: Range = {
  startDate: '',
  endDate: null,
  pacingShare: null,
};

const initialPaginationOptions = {
  offset: 0,
  limit: LIMIT_AUDITS,
};

type Props = {
  id?: string;
  refetch: (variables?: FilterVariables) => void;
  setLoadingFilter: (isLoading: boolean) => void;
  toggleAll: (isLoading: boolean) => void;
};

export type FilterResult = {
  dateRange: Range;
  onDateRangeChange: (field: 'start' | 'end', value: string) => void;
  onFilterSubmit: () => void;
  onClearFilter: () => void;
};

export const useDateRangeFilter = ({ refetch, id, setLoadingFilter, toggleAll }: Props): FilterResult => {
  const [dateRange, setDateRange] = useState(initialDateRange);

  useEffect(() => {
    setDateRange(initialDateRange);
  }, [id]);

  const onDateRangeChange = useCallback(
    (field: 'start' | 'end', value: string) => {
      setDateRange((prev) => ({
        ...prev,
        [`${field}Date`]: value ? value : '',
        [`${field}DateTime`]: value ? value : '',
      }));
    },
    [setDateRange]
  );

  const onFilterSubmit = useCallback((): void => {
    setLoadingFilter(true);
    toggleAll(false);

    refetch({
      paginationOptions: initialPaginationOptions,
      filterOptions: {
        dateRange: {
          startDate: convertLocalDateToUTC(`${dateRange.startDate}${START_OF_DAY_TIME}`),
          endDate: dateRange.endDate ? convertLocalDateToUTC(`${dateRange.endDate}${END_OF_DAY_TIME}`) : null,
        },
      },
    });
  }, [dateRange, refetch, setLoadingFilter, toggleAll]);

  const onClearFilter = useCallback((): void => {
    setLoadingFilter(true);
    toggleAll(false);
    setDateRange(initialDateRange);

    refetch({
      paginationOptions: initialPaginationOptions,
      filterOptions: undefined,
    });
  }, [refetch, setLoadingFilter, toggleAll]);

  return { dateRange, onDateRangeChange, onFilterSubmit, onClearFilter };
};
