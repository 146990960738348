/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconCalendar } from '@hulu-react-style-components/icons';
import envConfig from '@hulu/env-config';
import * as React from 'react';
import type { Cell } from 'react-table';

import { getTimezoneWithGMT } from '../../../../common/SelectTimeZone/utils';
import { resizingData, stickyBlock } from '../../../../common/TraffickingTable/utils';
import { pacingTypes } from '../../../../configs';
import { TraffickingTableName } from '../../../../constants';
import bem from '../../../../utils/bem';
import { DEFAULT_COLUMN_MIN_WIDTH } from '../../constants';
import { LineItemColumn } from '../../constants/lineItems';
import {
  AlertCell,
  CreateAndEditAdCell,
  DeliveryForensicsCell,
  LineItemFrequencyCapCell,
  LineItemStatusCell,
  NameCell,
  PacingStatusCell,
  PacingTypeCell,
  PercentCell,
  PriorityNameCell,
  ReviewCell,
  TierNameCell,
} from '../cells';
import type { AlertFlagProps } from '../cells/AlertCell';
import { AlertFiled } from '../cells/AlertCell/AlertCell';
import InventoryBlockCell from '../cells/InventoryBlockCell';
import YodaPacingStatusCell from '../cells/YodaPacingStatusCell';
import { EntityType } from '../hooks/makePageDrawerPlugin';
import type { LineItemModel } from '../modelConverters';
import { CellType } from '../types';
import { LineItemColumnHeaders, LineItemColumnTooltips } from './types';
import type { CustomColumnConfig } from './utils';
import {
  boolToYes,
  formatStartEndDateString,
  formatUTCToDefaultTimezone,
  getAccountRelatedColumnAccessor,
  getIdFromTableCell,
  getLineItemThreshold,
  getMetricsPercentRelatedColumnAccessor,
  getMetricsRelatedColumnAccessor,
  getPublisherTargetingList,
  microCurrencyToDollarAmount,
  numberToDollarAmount,
  numberToPercentage,
  stringToDateTimeString,
} from './utils';

const [, element] = bem('alert-cell');

export const getLineItemsAlertFlags = ({
  id,
  hasMisalignedSchedule,
  hasZeroDeliveryRisk,
  exclusiveBlock,
}: LineItemModel): AlertFlagProps[] => {
  return [
    {
      id,
      field: AlertFiled.ZeroDelivery,
      isShown: !!hasZeroDeliveryRisk,
      hoverMessage: 'This line item has not delivered any impressions in the last 12 hours.',
    },
    {
      id,
      field: AlertFiled.MisalignedSchedule,
      isShown: !!hasMisalignedSchedule,
      icon: <IconCalendar width={18} height={18} />,
      hoverMessage: 'This line item has ads with a misaligned schedule.',
      className: element('red-icon'),
    },
    {
      id,
      field: AlertFiled.InventoryBlock,
      icon: <InventoryBlockCell exclusiveBlock={exclusiveBlock} />,
      isShown: !!exclusiveBlock,
      hoverMessage: 'This line item has an inventory block.',
      className: element('red-icon', 'clickable'),
    },
  ];
};

const commonBooleanCellConfig = (id: string) => ({
  Cell: boolToYes,
  width: resizingData.getData(190, TraffickingTableName.lineItems, id),
  minWidth: DEFAULT_COLUMN_MIN_WIDTH,
});

// NOTE: `Cell` property cannot return `undefined` or the page will crash
const getLineItemsColumns = (): CustomColumnConfig<LineItemModel>[] => {
  const columns: CustomColumnConfig<LineItemModel>[] = [
    {
      id: LineItemColumn.Alert,
      Header: LineItemColumnHeaders.Alert,
      Cell: (cell: Cell<LineItemModel>) => <AlertCell flags={getLineItemsAlertFlags(cell.row.original)} />,
      width: resizingData.getData(90, TraffickingTableName.lineItems, 'Alert'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Alert],
    },
    {
      id: LineItemColumn.Name,
      accessor: 'name',
      Header: LineItemColumnHeaders.LineItemName,
      Cell: ({ row: { original }, openTraffickingPageDrawer }) => (
        <NameCell
          rowOriginal={original}
          entityType={EntityType.LINE_ITEM}
          openTraffickingPageDrawer={openTraffickingPageDrawer}
        />
      ),
      disableHiding: true,
      width: resizingData.getData(360, TraffickingTableName.lineItems, 'Name'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: stickyBlock('name'),
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Name],
    },
    {
      id: LineItemColumn.CreateAd,
      Header: LineItemColumnHeaders.CreateAd,
      Cell: (cell: Cell<LineItemModel>) => <CreateAndEditAdCell cell={cell} cellType={CellType.create} />,
      width: resizingData.getData(115, TraffickingTableName.lineItems, 'CreateAd'),
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.CreateAd],
    },
    {
      id: LineItemColumn.Country,
      accessor: ({ country }) => country || '',
      Header: LineItemColumnHeaders.Country,
      width: resizingData.getData(115, TraffickingTableName.lineItems, 'Country'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Country],
    },
    {
      id: LineItemColumn.DeliverableLineItemPackageName,
      accessor: (li) => li.orderLineItemDto?.packageName,
      Header: LineItemColumnHeaders.OMSPackageName,
      width: resizingData.getData(300, TraffickingTableName.lineItems, 'DeliverableLineItemPackageName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.DeliverableLineItemPackageName],
    },
    {
      id: LineItemColumn.TrackingId,
      accessor: 'trackingId',
      Header: LineItemColumnHeaders.TrackingId,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'TrackingId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.TrackingId],
    },
    {
      id: LineItemColumn.ParentOmsLinkId,
      accessor: (li) => li.parentLineOmsLink?.id,
      Header: LineItemColumnHeaders.ParentOmsId,
      width: resizingData.getData(185, TraffickingTableName.lineItems, 'ParentOmsLinkId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.ParentOmsLinkId],
    },
    {
      id: LineItemColumn.Status,
      Header: LineItemColumnHeaders.Status,
      accessor: (li) => li.status,
      isEditable: true,
      Cell: LineItemStatusCell,
      width: resizingData.getData(140, TraffickingTableName.lineItems, 'Status'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Status],
    },
    {
      id: LineItemColumn.Review,
      accessor: (li) => li.review,
      Header: LineItemColumnHeaders.Review,
      Cell: ReviewCell,
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'Review'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Review],
    },
    {
      id: LineItemColumn.ScheduleStart,
      accessor: (li) => li.startDate,
      Header: LineItemColumnHeaders.StartDate,
      Cell: formatStartEndDateString,
      width: resizingData.getData(155, TraffickingTableName.lineItems, 'ScheduleStart'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.ScheduleStart],
    },
    {
      id: LineItemColumn.ActualStartDate,
      accessor: (li) => li.metadata?.actualStartDate || null,
      Header: LineItemColumnHeaders.ActualStartDate,
      Cell: formatUTCToDefaultTimezone,
      width: resizingData.getData(210, TraffickingTableName.lineItems, 'ActualStartDate'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.ActualStartDate],
    },
    {
      id: LineItemColumn.ScheduleEnd,
      accessor: (li) => li.endDate,
      Header: LineItemColumnHeaders.EndDate,
      Cell: formatStartEndDateString,
      width: resizingData.getData(155, TraffickingTableName.lineItems, 'ScheduleEnd'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.ScheduleEnd],
    },
    {
      id: LineItemColumn.DisplayPublisherTarget,
      accessor: 'displayPublisherTarget',
      Header: LineItemColumnHeaders.Publisher,
      Cell: getPublisherTargetingList,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'DisplayPublisherTarget'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.DisplayPublisherTarget],
    },
    {
      id: LineItemColumn.ScheduleTimezoneOffset,
      accessor: (li) => (li.schedule?.timezone ? getTimezoneWithGMT(li.schedule?.timezone) : ''),
      Header: LineItemColumnHeaders.TimeZone,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'ScheduleTimezoneOffset'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.ScheduleTimezoneOffset],
    },
    {
      id: LineItemColumn.GeneralPacingStatus,
      accessor: (li) => li.pacingMetrics?.generalPacingStatus || null,
      Header: LineItemColumnHeaders.GeneralPacingStatus,
      Cell: PacingStatusCell,
      width: resizingData.getData(245, TraffickingTableName.lineItems, 'GeneralPacingStatus'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.GeneralPacingStatus],
    },
    {
      id: LineItemColumn.Goal,
      accessor: (li) => (li.goal !== null ? li.goal.toLocaleString() : ''),
      Header: LineItemColumnHeaders.CompletionGoal,
      width: resizingData.getData(200, TraffickingTableName.lineItems, 'Goal'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Goal],
    },
    {
      id: LineItemColumn.MetricsCompletions,
      accessor: (li) => getMetricsRelatedColumnAccessor(li.metrics?.completions),
      Header: LineItemColumnHeaders.Completions,
      width: resizingData.getData(170, TraffickingTableName.lineItems, 'MetricsCompletions'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsCompletions],
    },
    {
      id: LineItemColumn.MetricsPercentPacing,
      accessor: (li) => getMetricsPercentRelatedColumnAccessor(li.metrics?.percentPacing),
      Header: LineItemColumnHeaders.PercentPacing,
      Cell: numberToPercentage,
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'MetricsPercentPacing'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsPercentPacing],
    },
    {
      id: LineItemColumn.MetricsPercentExpected,
      accessor: (li) => getMetricsPercentRelatedColumnAccessor(li.metrics?.percentExpected),
      Header: LineItemColumnHeaders.PercentExpected,
      Cell: numberToPercentage,
      width: resizingData.getData(165, TraffickingTableName.lineItems, 'MetricsPercentExpected'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsPercentExpected],
    },
    {
      id: LineItemColumn.MetricsPercentCompletions,
      accessor: (li) => getMetricsPercentRelatedColumnAccessor(li.metrics?.percentCompletions),
      Header: LineItemColumnHeaders.PercentDelivered,
      Cell: numberToPercentage,
      width: resizingData.getData(165, TraffickingTableName.lineItems, 'MetricsPercentCompletions'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsPercentCompletions],
    },
    {
      id: LineItemColumn.PacingType,
      accessor: (li) => ({ pacingType: li.pacingType, tierName: li.tier.name.key }),
      Header: LineItemColumnHeaders.PacingType,
      Cell: PacingTypeCell,
      isEditable: true,
      width: resizingData.getData(180, TraffickingTableName.lineItems, 'PacingType'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.PacingType],
    },
    {
      id: LineItemColumn.Tier,
      accessor: (li) => ({ pacingType: li.pacingType, tier: li.tier }),
      Header: LineItemColumnHeaders.TierName,
      Cell: TierNameCell,
      isEditable: true,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'Tier'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Tier],
    },
    {
      id: LineItemColumn.YodaThreshold,
      accessor: (li) => getLineItemThreshold(li?.pacingMetrics?.yodaThreshold ?? null),
      Header: LineItemColumnHeaders.YodaThreshold,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'YodaThreshold'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.YodaThreshold],
    },
    {
      id: LineItemColumn.YodaPacingStatus,
      accessor: (li) => li?.pacingMetrics?.yodaPacingStatus,
      Header: LineItemColumnHeaders.YodaPacingStatus,
      Cell: YodaPacingStatusCell,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'YodaPacingStatus'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.YodaPacingStatus],
    },
    {
      id: LineItemColumn.PriorityName,
      accessor: (li) => li.priority,
      Header: LineItemColumnHeaders.PriorityName,
      Cell: PriorityNameCell,
      isEditable: true,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'PriorityName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.PriorityName],
    },
    {
      id: LineItemColumn.PriorityValue,
      accessor: (li) => li.priorityValue,
      Header: LineItemColumnHeaders.PriorityValue,
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'PriorityValue'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.PriorityValue],
    },
    {
      id: LineItemColumn.AdProduct,
      accessor: (li) => li.adProduct.displayName,
      Header: LineItemColumnHeaders.AdProduct,
      width: resizingData.getData(250, TraffickingTableName.lineItems, 'AdProduct'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.AdProduct],
    },
    {
      id: LineItemColumn.FrequencyCapList,
      accessor: (li) => li.frequencyCapList,
      isEditable: true,
      Header: LineItemColumnHeaders.FrequencyCap,
      Cell: LineItemFrequencyCapCell,
      width: resizingData.getData(160, TraffickingTableName.lineItems, 'frequencyCapList'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.FrequencyCapList],
    },
    {
      id: LineItemColumn.DeliveryBufferPercent,
      accessor: (li) => li.deliveryBufferPercent,
      isEditable: true,
      Header: LineItemColumnHeaders.Buffer,
      Cell: (cell: Cell<LineItemModel>) => (
        <PercentCell
          value={cell.value}
          id={getIdFromTableCell(cell)}
          name="deliveryBufferPercent"
          lowerLimit={-99}
          upperLimit={800}
          showEmptyCell={cell.row.original.pacingType === pacingTypes.SOV}
        />
      ),
      width: resizingData.getData(125, TraffickingTableName.lineItems, 'DeliveryBufferPercent'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.DeliveryBufferPercent],
    },
    {
      id: LineItemColumn.FrontLoadPercent,
      accessor: (li) => li.frontLoadPercent,
      isEditable: true,
      Header: LineItemColumnHeaders.Frontload,
      Cell: (cell: Cell<LineItemModel>) => (
        <PercentCell
          value={cell.value}
          id={getIdFromTableCell(cell)}
          name="frontLoadPercent"
          lowerLimit={0}
          upperLimit={400}
          showEmptyCell={
            cell.row.original.pacingType === pacingTypes.SOV || cell.row.original.pacingType === pacingTypes.AFAP
          }
        />
      ),
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'FrontLoadPercent'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.FrontLoadPercent],
    },
    {
      id: LineItemColumn.DeliveryForensics,
      Header: LineItemColumnHeaders.DF,
      Cell: DeliveryForensicsCell,
      isEditable: true,
      width: resizingData.getData(80, TraffickingTableName.lineItems, 'deliveryForensics'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'df-cell',
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.DeliveryForensics],
    },
    {
      id: LineItemColumn.AccessorId,
      accessor: 'id',
      Header: LineItemColumnHeaders.LineItemId,
      width: resizingData.getData(330, TraffickingTableName.lineItems, 'accessorId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.AccessorId],
    },
    {
      id: LineItemColumn.MetricsRemainingExpectedCompletionsAcc,
      accessor: (li) => getMetricsRelatedColumnAccessor(li.metrics?.remainingExpectedCompletionsAcc),
      Header: LineItemColumnHeaders.CompletionsRemaining,
      width: resizingData.getData(250, TraffickingTableName.lineItems, 'MetricsRemainingExpectedCompletionsAcc'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsRemainingExpectedCompletionsAcc],
    },
    {
      id: LineItemColumn.MetricsCompletionsAtRisk,
      accessor: (li) => getMetricsRelatedColumnAccessor(li?.metrics?.completionsAtRisk),
      Header: LineItemColumnHeaders.CompletionsAtRisk,
      width: resizingData.getData(220, TraffickingTableName.lineItems, 'MetricsCompletionsAtRisk'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsCompletionsAtRisk],
    },
    {
      id: LineItemColumn.Revenue,
      accessor: 'revenue',
      Header: LineItemColumnHeaders.Revenue,
      Cell: numberToDollarAmount,
      width: resizingData.getData(145, TraffickingTableName.lineItems, 'Revenue'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Revenue],
    },
    {
      id: LineItemColumn.MetricsRevenueAtRisk,
      accessor: (li) => li.metrics?.revenueAtRisk || null,
      Header: LineItemColumnHeaders.RevenueAtRisk,
      Cell: numberToDollarAmount,
      width: resizingData.getData(190, TraffickingTableName.lineItems, 'MetricsRevenueAtRisk'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsRevenueAtRisk],
    },
    {
      id: LineItemColumn.BillableThirdParty,
      accessor: (li) => li.billableThirdParty?.displayName,
      Header: LineItemColumnHeaders.BillableThirdParty,
      width: resizingData.getData(225, TraffickingTableName.lineItems, 'BillableThirdParty'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.BillableThirdParty],
    },
    {
      id: LineItemColumn.UnitCost,
      accessor: ({ unitCostDto }) => unitCostDto?.micros || '',
      Header: LineItemColumnHeaders.CPM,
      Cell: microCurrencyToDollarAmount,
      width: resizingData.getData(120, TraffickingTableName.lineItems, 'UnitCost'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.UnitCost],
    },
    {
      id: LineItemColumn.MetricsUnderPacingRevenue,
      accessor: (li) => li.metrics?.underPacingRevenue || null,
      Header: LineItemColumnHeaders.UnderPacingRevenue,
      Cell: numberToDollarAmount,
      width: resizingData.getData(245, TraffickingTableName.lineItems, 'MetricsUnderPacingRevenue'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MetricsUnderPacingRevenue],
    },
    {
      id: LineItemColumn.LineOmsLinkId,
      accessor: (li) => li.lineOmsLink?.id,
      Header: LineItemColumnHeaders.LineOmsId,
      width: resizingData.getData(200, TraffickingTableName.lineItems, 'LineOmsLinkId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.LineOmsLinkId],
    },
    {
      id: LineItemColumn.CreatedAt,
      accessor: 'createdAt',
      Header: LineItemColumnHeaders.CreatedDate,
      Cell: stringToDateTimeString,
      width: resizingData.getData(190, TraffickingTableName.lineItems, 'CreatedAt'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.CreatedAt],
    },
    {
      id: LineItemColumn.UpdatedAt,
      accessor: 'updatedAt',
      Header: LineItemColumnHeaders.UpdatedDate,
      Cell: stringToDateTimeString,
      width: resizingData.getData(190, TraffickingTableName.lineItems, 'UpdatedAt'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.UpdatedAt],
    },
    {
      id: LineItemColumn.CreativeMaxSeconds,
      accessor: 'creativeMaxSeconds',
      Header: LineItemColumnHeaders.MaxDuration,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'CreativeMaxSeconds'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.CreativeMaxSeconds],
    },
    {
      id: LineItemColumn.CampaignName,
      accessor: (li) => li?.campaign?.name,
      Header: LineItemColumnHeaders.CampaignName,
      width: resizingData.getData(525, TraffickingTableName.lineItems, 'CampaignName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.CampaignName],
    },
    {
      id: LineItemColumn.DeliveryGoal,
      accessor: (li) => li.deliveryGoal?.toLocaleString(),
      Header: LineItemColumnHeaders.DeliveryGoal,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DeliveryGoal'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.DeliveryGoal],
    },
    {
      id: LineItemColumn.IsCoppaOrCaru,
      accessor: 'isCoppaOrCaru',
      Header: LineItemColumnHeaders.IsCoppaOrCaru,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.IsCoppaOrCaru],
      ...commonBooleanCellConfig('IsCoppaOrCaru'),
    },
    {
      id: LineItemColumn.IsAddedValue,
      accessor: 'isAddedValue',
      Header: LineItemColumnHeaders.IsAddedValue,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.IsAddedValue],
      ...commonBooleanCellConfig('IsAddedValue'),
    },
    {
      id: LineItemColumn.IsMakegood,
      accessor: 'isMakegood',
      Header: LineItemColumnHeaders.IsMakegood,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.IsMakegood],
      ...commonBooleanCellConfig('IsMakegood'),
    },
    {
      id: LineItemColumn.IsFiller,
      accessor: 'isFiller',
      Header: LineItemColumnHeaders.FillerAd,
      isColumnHiddenByDefault: true,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.IsFiller],
      ...commonBooleanCellConfig('isFiller'),
    },
    {
      id: LineItemColumn.DeliverableLineItemBuyerName,
      accessor: (li) => li.orderLineItemDto?.buyerName,
      Header: LineItemColumnHeaders.BuyerName,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DeliverableLineItemBuyerName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.DeliverableLineItemBuyerName],
    },
    {
      id: LineItemColumn.DeliverableLineItemBuyerId,
      accessor: (li) => li.orderLineItemDto?.buyerId,
      Header: LineItemColumnHeaders.BuyerSeatId,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DeliverableLineItemBuyerId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.DeliverableLineItemBuyerId],
    },
    {
      id: LineItemColumn.MagniteDealDspName,
      accessor: (li) => li.magniteDealDto?.dspName || '',
      Header: LineItemColumnHeaders.DSPName,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'MagniteDealDspName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MagniteDealDspName],
    },
    {
      id: LineItemColumn.MagniteDealDealName,
      accessor: (li) => li.magniteDealDto?.dealName,
      Header: LineItemColumnHeaders.DealId,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DealId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.MagniteDealDealName],
    },
    {
      id: LineItemColumn.CampaignTraffickerEmail,
      accessor: ({ campaign }) => getAccountRelatedColumnAccessor(campaign?.traffickerEmail),
      Header: LineItemColumnHeaders.AccountManager,
      width: resizingData.getData(220, TraffickingTableName.lineItems, 'CampaignTraffickerEmail'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.CampaignTraffickerEmail],
    },
    {
      id: LineItemColumn.CampaignOrderSalespersonEmail,
      accessor: ({ campaign }) => getAccountRelatedColumnAccessor(campaign?.order?.salespersonEmail),
      Header: LineItemColumnHeaders.AccountExecutive,
      width: resizingData.getData(220, TraffickingTableName.lineItems, 'CampaignOrderSalespersonEmail'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.CampaignOrderSalespersonEmail],
    },
    {
      id: LineItemColumn.Currency,
      accessor: ({ unitCostDto }) => unitCostDto?.currency || '',
      Header: LineItemColumnHeaders.CurrencyType,
      width: resizingData.getData(200, TraffickingTableName.lineItems, 'Currency'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Currency],
    },
  ];

  if (envConfig.REACT_APP_FEATURE_ENABLE_CREATE_SEQUENCE === 'true') {
    columns.push({
      id: LineItemColumn.Sequence,
      accessor: (li) => boolToYes({ value: !!li.lineItemSequenceId, isNoShown: true }),
      Header: LineItemColumnHeaders.Sequence,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'Sequence'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: LineItemColumnTooltips[LineItemColumn.Sequence],
    });
  }

  return columns;
};

export default getLineItemsColumns;
