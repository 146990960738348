import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';

import type { AssetRejectionReasonV5 } from '../../../../apis/graphql';
import type { AssetRejectionDisplayValues } from '../../../../configs';
import { assetRejectionReasonMap } from '../../../../configs';
import { enumToTitleCase } from '../../../../utils/formatting';
import { ToggleSection } from '../../../ToggleSection';
import { useAssetComments } from '../../hooks';

interface RejectionReasonToggleProps {
  rejectionReasons: string | AssetRejectionReasonV5[];
  toggles: boolean[];
  toggleAt: (index: number, value: boolean) => void;
  reasonIndex?: number;
  index?: number;
  assetId?: string;
}

export const NO_REJECTION_REASONS_LABEL = 'No rejection reasons provided.';
export const LOADING_COMMENTS_LABEL = 'Loading comments...';
export const ERROR_LOADING_COMMENTS_LABEL = 'Error loading comments.';
export const NO_COMMENTS_AVAILABLE_LABEL = 'No comments available for this asset.';

const [, modalElement] = bem('asset-rejection-reasons-modal');

function RejectionReasonToggle({
  rejectionReasons,
  toggles,
  toggleAt,
  index = 0,
  assetId,
}: RejectionReasonToggleProps): React.JSX.Element {
  const { changeLogComments, loading, error } = useAssetComments(assetId);

  // Flatten rejection reasons into a simple list
  const filteredRejectionReasons: [string, AssetRejectionDisplayValues][] = useMemo(() => {
    const entries = Object.entries(assetRejectionReasonMap);

    return entries.filter(([key]) => rejectionReasons.includes(key as AssetRejectionReasonV5));
  }, [rejectionReasons]);

  return (
    <>
      {filteredRejectionReasons.length > 0 ? (
        <div className={modalElement('rejection-reasons')}>
          <ul>
            {filteredRejectionReasons.map(([, value]: [string, AssetRejectionDisplayValues]) => (
              <li key={value.title} className={modalElement('issue-description')}>
                {enumToTitleCase(value.title.replace(/_/g, ' '))}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={modalElement('issue-description')}>{NO_REJECTION_REASONS_LABEL}</div>
      )}
      <ToggleSection
        handleToggle={(): void => toggleAt(index, toggles[index])}
        isExpanded={toggles[index] === undefined ? false : toggles[index]}
        key={`toggle-comments-${index}`}
        title={'Comments'}
      >
        {loading && <div className={modalElement('issue-description')}>{LOADING_COMMENTS_LABEL}</div>}
        {error && <div className={modalElement('issue-description')}>{ERROR_LOADING_COMMENTS_LABEL}</div>}
        {!loading && !error && changeLogComments.length === 0 && (
          <div className={modalElement('issue-description')}>{NO_COMMENTS_AVAILABLE_LABEL}</div>
        )}
        {changeLogComments.length > 0 &&
          changeLogComments.map((comment, idx) => (
            <div key={`${comment.date}_${idx}`} className={modalElement('comment')}>
              <div className={modalElement('comment-text')}>{comment.comment}</div>
              <div className={modalElement('comment-divider')} />
              <div className={modalElement('comment-date')}>{comment.date}</div>
            </div>
          ))}
      </ToggleSection>
    </>
  );
}

export default RejectionReasonToggle;
