import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';

import type { GetAdsWithTargetingsQuery, GetAdsWithTargetingsQueryVariables } from '../../../../../../apis/graphql';
import { getDataFromNodes } from '../../../../../../apis/graphql';
import { AlertType } from '../../../../../../common/Alert';
import { openToastAlert } from '../../../../../../common/ToastAlert/toastAlert';
import { GENERAL_ERROR_MESSAGE } from '../../../../../../constants';
import type { MultipleEditDrawerAdData } from '../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { GET_ADS_WITH_TARGETINGS } from '../../../../TraffickingPage/hooks/queries/getAdsWithTargetings';
import type { AdModel } from '../../../../TraffickingPage/modelConverters';
import type {
  AdWithTargetingsModel,
  AdWithTargetingsNode,
} from '../../../../TraffickingPage/modelConverters/adWithTargetingsConvertor';
import { adWithTargetingsNodeToModel } from '../../../../TraffickingPage/modelConverters/adWithTargetingsConvertor';
import type { MultipleEditDrawerProps } from '../../MultipleEditDrawer';
import MultipleEditDrawer from '../../MultipleEditDrawer';
import { getMultipleEditDrawerAdsInitialValues } from '../../utils';
import SharedMultipleEditLoader from '../SharedMultipleEditLoader/SharedMultipleEditLoader';

export type Props = MultipleEditDrawerProps & {
  data: MultipleEditDrawerAdData;
};

const MultipleAdDrawer = ({ data, isExpanded, isOpen, className, onClose, onToggleExpansion }: Props): JSX.Element => {
  const ids = data.entities.map((entity: AdModel) => entity.id);

  const { data: adData, loading, error } = useQuery<GetAdsWithTargetingsQuery, GetAdsWithTargetingsQueryVariables>(
    GET_ADS_WITH_TARGETINGS,
    {
      variables: {
        ids,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const adsWithTargetings = useMemo(() => {
    return getDataFromNodes<AdWithTargetingsNode, AdWithTargetingsModel>(adData, 'ads', adWithTargetingsNodeToModel)();
  }, [adData]);
  const initialValues = useMemo(() => getMultipleEditDrawerAdsInitialValues(adsWithTargetings), [adsWithTargetings]);

  useEffect(() => {
    if (error) {
      openToastAlert({
        alertType: AlertType.ERROR,
        message: GENERAL_ERROR_MESSAGE,
      });
    }
  }, [error]);

  if (loading) {
    return (
      <SharedMultipleEditLoader
        isOpen={isOpen}
        isExpanded={isExpanded}
        className={className}
        data={data}
        onClose={onClose}
        onToggleExpansion={onToggleExpansion}
      />
    );
  }

  return (
    <MultipleEditDrawer
      isOpen={isOpen}
      isExpanded={isExpanded}
      className={className}
      data={{
        ...data,
        entities: adsWithTargetings,
      }}
      onClose={onClose}
      onToggleExpansion={onToggleExpansion}
      initialValues={initialValues}
    />
  );
};

export default MultipleAdDrawer;
