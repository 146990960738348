import './RemoveCreativeButton.scss';

import type { ModalProps } from 'antd';
import { useFormikContext } from 'formik';
import { User } from 'mission-control-common-components';
import React from 'react';

import type { AdTypeV5 } from '../../../apis/graphql';
import Button from '../../../common/Button';
import type { PermissionsNames } from '../../../constants';
import bem from '../../../utils/bem';
import type { AdFormValues } from '../adFormik';
import { getInitialCreativeBuilderValues } from '../adFormik';
import type { CreativesBuilderValues } from '../CreativeBuilders/types';
import RemoveEntityButton from '../RemoveEntityButton';
import type { ButtonRendererFunction } from '../RemoveEntityButton/RemoveEntityButton';

export interface RemoveCreativeButtonProps {
  adType: AdTypeV5;
  creativeName: string;
  readonly?: boolean;
}

const [block] = bem('remove-creative-button');

function RemoveCreativeButton({ creativeName, adType, readonly }: RemoveCreativeButtonProps): JSX.Element {
  const {
    values: { organizationCreativeLibraryId },
  } = useFormikContext<AdFormValues>();

  const permissions = User.getPermissions<PermissionsNames>();
  const modalProps: ModalProps = {
    cancelText: 'NO, DO NOT REMOVE',
    okText: 'YES, REMOVE CREATIVE',
    title: `Remove Creative`,
  };

  const ButtonRenderer: ButtonRendererFunction = (props) => (
    <Button
      className={block()}
      title="REMOVE"
      variant="outlined"
      data-testid="remove-button"
      disabled={!permissions?.updateAd || readonly}
      {...props}
    >
      Remove
    </Button>
  );

  const modalMessage = (
    <span>
      Are you sure you want to remove the creative <strong> {creativeName}</strong> from this ad?
    </span>
  );

  return (
    <RemoveEntityButton<CreativesBuilderValues>
      formFieldName="creative"
      emptyValue={getInitialCreativeBuilderValues('selectOption', adType, undefined, organizationCreativeLibraryId)}
      modalProps={modalProps}
      ButtonRenderer={ButtonRenderer}
      modalMessage={modalMessage}
    />
  );
}

export default RemoveCreativeButton;
