import './TraffickingPage.scss';

import { IconDrag } from '@hulu-react-style-components/icons';
import { Tab, TabNavigation } from '@hulu/react-style-components';
import { lowerCase as _lowerCase } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import type { TableInstance } from 'react-table';

import ClearAllButton from '../../../common/Button/ClearAllButton';
import TraffickingTable from '../../../common/TraffickingTable';
import { draggedElementId } from '../../../common/TraffickingTable/constants';
import type { TraffickingTableName } from '../../../constants';
import { TRAFFICKING_SEARCH_PARAMS, TRAFFICKING_TABLE_NAMES } from '../../../constants';
import { useFiltersContext } from '../../../contexts/FilterContext';
import bem from '../../../utils/bem';
import SelectedItemsPill from '../SelectedItemsPill';
import TableActionBar from '../TableActionBar';
import TableFilterBar from '../TableFilterBar';
import TableNavigationActionButtons from '../TableNavigationActionButtons';
import TraffickingPageDrawer from '../TraffickingPageDrawer';
import { CLEAR_ALL_BUTTON_LABEL, CLEAR_SEARCH_RESULTS_AND_SELECTION_BUTTON_TOOLTIP } from './constants';
import { useTraffickerState } from './hooks';
import type { EntityModel } from './modelConverters';

const [block, element] = bem('trafficking-page');

const emptyTableMessages: { [key: string]: React.ReactNode } = {
  campaigns: 'No campaigns found matching your filters, selections and groupings.',
  lineItems: 'No line items found matching your filters, selections and groupings.',
  ads: (
    <div>
      <p>
        <strong>No ads found matching your filters, selections and groupings.</strong>
      </p>
      <p>To create a new ad, go to the Line Items tab, select a line item, and click Create Ad.</p>
    </div>
  ),
};

export default function TraffickingPage(): JSX.Element {
  const {
    drawerProps,
    tables,
    isClearAllBadgeVisible,
    clearAllSearchResultsAndSelections,
    applyCommonFilter,
  } = useTraffickerState();

  const { applyFilter, filters, applyTableFilter } = useFiltersContext();

  const selectTab = useCallback(
    (tabName: TraffickingTableName): void => applyFilter(TRAFFICKING_SEARCH_PARAMS.SELECTED_TAB, tabName),
    [applyFilter]
  );

  const actionButtonsConfig = useMemo(() => {
    return [
      {
        id: CLEAR_ALL_BUTTON_LABEL,
        tooltipClassName: element('tooltip'),
        tooltipLabel: CLEAR_SEARCH_RESULTS_AND_SELECTION_BUTTON_TOOLTIP,
        isShown: isClearAllBadgeVisible,
        component: <ClearAllButton label={CLEAR_ALL_BUTTON_LABEL} onClick={clearAllSearchResultsAndSelections} />,
      },
    ];
  }, [isClearAllBadgeVisible, clearAllSearchResultsAndSelections]);

  const tableNamesToTabs = (tableName: TraffickingTableName): JSX.Element => {
    const {
      error,
      loading,
      initialLoading,
      tableInstance,
      hasMore,
      total,
      onNext,
      keyboardOnNext,
      defaultLimit,
      setOffset,
      resetSelectedIdOffset,
      handleDeleteItems,
      handleToggleAllSelectedOff,
    } = tables[tableName];

    const { initialState, state } = tableInstance;
    const selectedRowsCount = Object.keys(initialState?.selectedRowIds || {}).length;

    const onPillClear = (e: React.MouseEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      state.selectedRowIds = {};
      resetSelectedIdOffset(tableName);

      applyTableFilter(tableName, 'selectedRowIds', []);
    };

    const handleDeleteItemsFromTable = (ids: string[]): void => handleDeleteItems(ids, handleToggleAllSelectedOff);

    const selectedLineItems = tables.lineItems.tableInstance.selectedFlatRows.map((item) => item.original);

    const selectedAds = tables.ads.tableInstance.selectedFlatRows.map((item) => item.original);

    return (
      <Tab
        key={tableName}
        title={tableName}
        element={
          <span tabIndex={0} onKeyDown={(e): boolean | void => e.key === 'Enter' && selectTab(tableName)} role="tab">
            {`${_lowerCase(tableName)}`}&nbsp;
            {!initialLoading && (
              <span className={'rows-count'} data-testid="rows-count">
                {total}
              </span>
            )}
            {selectedRowsCount > 0 && (
              <SelectedItemsPill selectedRowsCount={selectedRowsCount} onClearFunction={onPillClear} />
            )}
          </span>
        }
        className={element('table-content')}
      >
        <TableActionBar
          tableInstance={tableInstance as TableInstance<EntityModel>}
          tableName={tableName}
          total={total}
          setOffset={setOffset}
          selectedLineItems={selectedLineItems}
          selectedAds={selectedAds}
          isSequenceViewEnabled={tables[tableName].isSequenceViewEnabled}
          toggleIsSequenceViewEnabled={tables[tableName].toggleIsSequenceViewEnabled}
          handleDeleteItemsFromTable={handleDeleteItemsFromTable}
        />
        <TraffickingTable
          tableName={tableName}
          initialLoading={initialLoading}
          emptyMessage={emptyTableMessages[tableName]}
          errorMessage={error}
          loading={loading}
          tableInstance={tableInstance as TableInstance<EntityModel>}
          hasMore={hasMore}
          onNext={onNext}
          keyboardOnNext={keyboardOnNext}
          defaultLimit={defaultLimit}
          isColumnResizing={!!state.columnResizing.isResizingColumn}
          isSequenceViewEnabled={tables[tableName].isSequenceViewEnabled}
        />
      </Tab>
    );
  };

  return (
    <div className={`${block()} page`}>
      <IconDrag id={draggedElementId} className={`${element('drag-element')}`} />
      <div className={element('trafficker')}>
        <TableFilterBar applyCommonFilter={applyCommonFilter} />
        <div className={element('tab-navigation-wrapper')}>
          <TableNavigationActionButtons config={actionButtonsConfig} />
          <TabNavigation selectedTab={filters?.selectedTab!} onTabClick={selectTab}>
            {TRAFFICKING_TABLE_NAMES.map(tableNamesToTabs)}
          </TabNavigation>
        </div>
      </div>
      {drawerProps.isOpen && <TraffickingPageDrawer {...drawerProps} />}
    </div>
  );
}
