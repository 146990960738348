import './AdSectionsForm.scss';

import { Form, Formik } from 'formik';
import { bem } from 'mission-control-common-components';
import { useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import AdSideNav from '../../../../../../../common/AdForm/AdSideNav';
import type { AdFormSection } from '../../../../../../../common/AdForm/AdSideNav/AdSideNav';
import Alert, { AlertType } from '../../../../../../../common/Alert';
import type { TargetingTermValue } from '../../../../../../../models';
import type { AdModel } from '../../../../../TraffickingPage/modelConverters';
import { useDuplicateAdsStepperContext } from '../../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import { AD_FORM_VALUES_MAPPING_ERROR } from '../../constants';
import { AdPreviewForm } from '../AdPreviewForm';
import ApplyToAllSection from '../ApplyToAllSection/ApplyToAllSection';
import type { DuplicateAdFormValue } from '../types';
import { getAdsWithVisitedAd } from './utils';

type Props = {
  duplicateAdFormValue: DuplicateAdFormValue;
  allFormValues: DuplicateAdFormValue[];
  lineItem: AdModel['lineItem'];
  lineItemTargetingTermValues: TargetingTermValue[];
  loading: boolean;
};

const NOOP = () => {};

export enum AdPreviewFormLinks {
  CREATIVE = 'Creative',
  TAGS = 'Tags',
  TARGETING = 'Targeting',
  SCHEDULING = 'Scheduling',
  METADATA = 'Metadata',
}

const [block, element] = bem('ad-sections-form');

const AdSectionsForm = ({
  duplicateAdFormValue,
  allFormValues,
  lineItemTargetingTermValues,
  lineItem,
  loading,
}: Props): React.JSX.Element => {
  const { setAds, ads, setIsSelectedSectionsChanged } = useDuplicateAdsStepperContext();

  const adIndex = useMemo(() => ads.findIndex(({ id }) => duplicateAdFormValue.id === id), [
    duplicateAdFormValue.id,
    ads,
  ]);

  const toggleSection = useCallback(
    (sectionName: AdFormSection, state: boolean): void => {
      const newAds = [...ads];
      const updatedAd = newAds[adIndex];

      if (!state) {
        newAds[adIndex] = {
          ...newAds[adIndex],
          selectedSections: updatedAd.selectedSections.filter((section) => section !== sectionName),
        };
      } else {
        newAds[adIndex] = {
          ...newAds[adIndex],
          selectedSections: [...updatedAd.selectedSections, sectionName],
        };
      }

      setAds(newAds);
      setIsSelectedSectionsChanged(true);
    },
    [adIndex, ads, setAds, setIsSelectedSectionsChanged]
  );

  useEffectOnce(() => {
    if (adIndex > -1 && !ads[adIndex].isVisited) {
      setAds(getAdsWithVisitedAd(ads, adIndex, duplicateAdFormValue));
    }
  });

  if (adIndex < 0) {
    return (
      <div className={element('error-wrapper')}>
        <Alert className={element('error')} type={AlertType.ERROR} bodyText={AD_FORM_VALUES_MAPPING_ERROR} />
      </div>
    );
  }

  return (
    <div className={block()}>
      <ApplyToAllSection allFormValues={allFormValues} selectedAd={ads[adIndex]} />
      <div className={element('preview-wrapper')}>
        <Formik onSubmit={NOOP} initialValues={duplicateAdFormValue} enableReinitialize>
          <Form className={element('form')}>
            <AdSideNav containerId="ad-preview-form-id" links={Object.values(AdPreviewFormLinks)} />
            <AdPreviewForm
              lineItem={lineItem}
              disableSelectSections={loading}
              lineItemTargetingTermValues={lineItemTargetingTermValues}
              toggleSection={toggleSection}
            />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AdSectionsForm;
