import React, { useMemo } from 'react';

import { useDuplicateAdsStepperContext } from '../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import type { DuplicateAdTab } from '../../DuplicateAdsTabs';
import DuplicateAdsTabs from '../../DuplicateAdsTabs/DuplicateAdsTabs';
import MultipleDuplicateAdsContentWrapper from '../../MultipleDuplicateAdsContentWrapper/MultipleDuplicateAdsContentWrapper';
import { DUPLICATE_ADS_TITLE } from '../constants';
import MultipleDuplicateAdsStepperHeader from '../MultipleDuplicateAdsStepperHeader/MultipleDuplicateAdsStepperHeader';
import DuplicateAdAssigningToLineItem from './DuplicateAdAssigningToLineItem/DuplicateAdAssigningToLineItem';

const SetAdNamesStep = (): JSX.Element => {
  const { lineItems, ads } = useDuplicateAdsStepperContext();

  const adWithErrors = useMemo(() => {
    const adIds: string[] = [];

    ads.forEach((ad) => {
      const lineItemWithoutAdName = lineItems.find(
        (lineItem) =>
          lineItem.selected && !lineItem.conflicted && (!lineItem.adNames[ad.id] || !lineItem.adNames[ad.id].name)
      );

      if (lineItemWithoutAdName) {
        adIds.push(ad.id);
      }
    });

    return adIds;
  }, [ads, lineItems]);

  const tabs = useMemo(
    () =>
      ads.map(
        (ad): DuplicateAdTab => {
          const showFailedState = adWithErrors.includes(ad.id);

          return {
            id: ad.id,
            name: ad.name,
            showCheck: showFailedState,
            isFailed: showFailedState,
            component: (
              <DuplicateAdAssigningToLineItem
                campaignName={ad.lineItem?.campaign.name}
                lineItemName={ad.lineItem?.name}
                adId={ad.id}
                adType={ad.type.displayName}
                adName={ad.name}
              />
            ),
          };
        }
      ),
    [adWithErrors, ads]
  );

  return (
    <div>
      <MultipleDuplicateAdsStepperHeader confirmButtonDisabled={!!adWithErrors.length} title={DUPLICATE_ADS_TITLE} />
      <MultipleDuplicateAdsContentWrapper>
        <DuplicateAdsTabs tabs={tabs} />
      </MultipleDuplicateAdsContentWrapper>
    </div>
  );
};

export default SetAdNamesStep;
