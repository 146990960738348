import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import type { CreateBatchAdsMutation, CreateBatchAdsMutationVariables } from '../../../../../../../apis/graphql';
import type { DuplicateLineItem } from '../../../DuplicateAdsStepperProvider/types';
import { CREATE_ADS_BATCH } from '../mutations/batchCreateAds';
import type { DuplicateAdFormValue } from '../types';
import { getCreateBatchAdsInputs, onSavingAdsError, onSavingAdsSuccess } from './utils';

type UseSaveAdsResult = {
  saveAds: (
    selectedLineItems: DuplicateLineItem[],
    duplicateAdFromValues: DuplicateAdFormValue[],
    onCloseCallback: () => void
  ) => Promise<void>;
  loading: boolean;
};

export const useSaveAds = (): UseSaveAdsResult => {
  const [batchCreateAds, { loading }] = useMutation<CreateBatchAdsMutation, CreateBatchAdsMutationVariables>(
    CREATE_ADS_BATCH
  );

  const saveAds = useCallback(
    async (
      selectedLineItems: DuplicateLineItem[],
      duplicateAdFromValues: DuplicateAdFormValue[],
      onCloseCallback: () => void
    ): Promise<void> => {
      const adsInput = getCreateBatchAdsInputs(selectedLineItems, duplicateAdFromValues);

      await batchCreateAds({ variables: { adsInput } }).then(({ data, errors }) => {
        if (errors?.length) {
          onSavingAdsError(adsInput, errors);
        } else {
          if (data) onSavingAdsSuccess(data);

          onCloseCallback();
        }
      });
    },
    [batchCreateAds]
  );

  return {
    loading,
    saveAds,
  };
};
