import './MultipleDuplicateAdsStepperHeader.scss';

import { IconArrowLeft } from '@hulu-react-style-components/icons';
import React from 'react';

import Button from '../../../../../../common/Button';
import Tooltip from '../../../../../../common/Tooltip';
import bem from '../../../../../../utils/bem';
import { useDuplicateAdsStepperContext } from '../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';

const [block, element] = bem('multiple-duplicate-ad-stepper-header');

type Props = {
  onNext?: () => void;
  title: string;
  confirmButtonDisabled: boolean;
  confirmText?: string;
  loading?: boolean;
  tooltipText?: string;
};

const MultipleDuplicateAdsStepperHeader = ({
  title,
  onNext,
  confirmButtonDisabled,
  confirmText = 'Next',
  tooltipText,
  loading,
}: Props): React.JSX.Element => {
  const { onCloseStepper, step, goToNextStep, goToPrevStep } = useDuplicateAdsStepperContext();

  return (
    <div className={block()}>
      {step !== 0 && (
        <div className={element('arrow')}>
          <IconArrowLeft data-testid="backIcon" onClick={goToPrevStep} />
        </div>
      )}
      <div className={element('title')}>{title}</div>
      <div className={element('buttons-container')}>
        <Button size="medium" variant="outlined" className={element('close')} onClick={onCloseStepper}>
          Cancel
        </Button>
        <Tooltip
          id={'multiple-duplicate-ads-next-button'}
          message={tooltipText ?? ''}
          disable={!confirmButtonDisabled || loading}
          delayHide={100}
          delayShow={200}
        >
          <Button
            size="medium"
            disabled={confirmButtonDisabled}
            className={element('next-step')}
            data-testid="next-button"
            onClick={onNext ?? goToNextStep}
            loading={loading}
          >
            {confirmText}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default MultipleDuplicateAdsStepperHeader;
