import type { AssetType } from '../../configs';
import { FileUploaderType } from './enum';

export const INCOMPATIBLE_DIMENSIONS = 'Incompatible Dimensions';
export const INVALID_FILE_TYPE = 'Invalid File Type';

export const VIDEO_WRONG_CODEC = 'Video Wrong Codec';
export const VIDEO_WRONG_CODEC_MESSAGE = 'Please resubmit your Ad in Apple ProRes 422 or H.264 codecs.';

export const COMMON_INCOMPATIBLE_DIMENSIONS_MESSAGE =
  'Please use an asset with the approved width and height dimensions.';
export const INCOMPATIBLE_IMAGE_TYPE = 'Asset must be PNG.';
export const INCOMPATIBLE_JPG_IMAGE_TYPE = 'Asset must be PNG or JPG/JPEG.';
export const ACCEPTABLE_FILE_TYPES_MAP: Record<AssetType, string[]> = {
  DISPLAY: ['.jpg', '.jpeg', '.png'],
  VIDEO: ['.mov', '.mp4'],
};

export const IMAGE_TYPES: FileUploaderType[] = [
  FileUploaderType.imgJPEG,
  FileUploaderType.imgGIF,
  FileUploaderType.imgWEBP,
  FileUploaderType.imgSVG,
];
