import './ApplyToAllSection.scss';

import { bem } from 'mission-control-common-components';
import { useCallback } from 'react';

import { AdFormSection } from '../../../../../../../common/AdForm/AdSideNav/AdSideNav';
import { AlertType } from '../../../../../../../common/Alert';
import Button from '../../../../../../../common/Button';
import { openToastAlert } from '../../../../../../../common/ToastAlert/toastAlert';
import { useDuplicateAdsStepperContext } from '../../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import type { DuplicateAd } from '../../../DuplicateAdsStepperProvider/types';
import {
  APPLY_TO_ALL_APPLIED_TEXT,
  APPLY_TO_ALL_APPLIED_WITH_ERRORS_TEXT,
  APPLY_TO_ALL_LABEL,
  DEFAULT_APPLIED_NO_CRITERIA_TEXT,
} from '../../constants';
import type { DuplicateAdFormValue } from '../types';
import { getSelectedSections } from '../utils';

const [block, element] = bem('apply-to-all-section');

type Props = {
  allFormValues: DuplicateAdFormValue[];
  selectedAd: DuplicateAd;
};

const ApplyToAllSection = ({ allFormValues, selectedAd }: Props): React.JSX.Element => {
  const {
    ads,
    lastAppliedSectionsAdName,
    setAds,
    isSelectedSectionsChanged,
    setIsSelectedSectionsChanged,
    setLastAppliedSectionsAdName,
  } = useDuplicateAdsStepperContext();

  const onApplyToAll = useCallback((): void => {
    let isSomeAdsFailed = false;

    setAds(
      ads.map((ad) => {
        const foundValue = allFormValues.find(({ id }) => ad.id === id);

        const isFailed =
          !!foundValue?.schedulingConflictLineItemIds.length &&
          selectedAd.selectedSections.includes(AdFormSection.SCHEDULING);

        if (isFailed) {
          isSomeAdsFailed = true;
        }

        return {
          ...ad,
          selectedSections: getSelectedSections(
            !!foundValue?.schedulingConflictLineItemIds.length,
            selectedAd.selectedSections
          ),
          isVisited: !isFailed,
          isFailed,
        };
      })
    );
    setIsSelectedSectionsChanged(false);
    setLastAppliedSectionsAdName(selectedAd.name);

    openToastAlert({
      message: isSomeAdsFailed ? APPLY_TO_ALL_APPLIED_WITH_ERRORS_TEXT : APPLY_TO_ALL_APPLIED_TEXT,
      alertType: AlertType.SUCCESS,
    });
  }, [
    ads,
    allFormValues,
    selectedAd.name,
    selectedAd.selectedSections,
    setAds,
    setIsSelectedSectionsChanged,
    setLastAppliedSectionsAdName,
  ]);

  const currentlyAppliedText = lastAppliedSectionsAdName ? (
    <span className={element('highlighted-text')}>
      Currently, criteria selections from “{lastAppliedSectionsAdName}” are applied to all ads.
    </span>
  ) : (
    <span className={element('subtext')}>{DEFAULT_APPLIED_NO_CRITERIA_TEXT}</span>
  );

  return (
    <div className={block()} data-testid="apply-to-all-section">
      <div className={element('info-wrapper')}>
        <Button disabled={!isSelectedSectionsChanged} className={element('button')} size="small" onClick={onApplyToAll}>
          {APPLY_TO_ALL_LABEL}
        </Button>
        <span className={element('text')}>Apply criteria selections from “{selectedAd.name}” to all ads.</span>
        <span>{currentlyAppliedText}</span>
      </div>
      <hr />
    </div>
  );
};

export default ApplyToAllSection;
