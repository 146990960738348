import type { DeliveryTier, DisplayEnum, PacingType } from '../../../../../../../../../../configs';
import { deliveryTiers, pacingTypes } from '../../../../../../../../../../configs';
import type { Tier } from '../../../../../../../../../../models/tier';
import type { LineItemModel } from '../../../../../../../../TraffickingPage/modelConverters';

type Props = {
  tier: Tier | null;
  pacingType?: LineItemModel['pacingType'];
};

type PriorityPacingPair = {
  tier: DeliveryTier;
  pacing: DisplayEnum<PacingType>;
};

const sponsorshipPriorityPacing: PriorityPacingPair = {
  tier: deliveryTiers.sponsorship.key,
  pacing: pacingTypes.EVEN,
};

const guaranteedPriorityPacing: PriorityPacingPair = {
  tier: deliveryTiers.guaranteed.key,
  pacing: pacingTypes.EVEN,
};

const nonGuaranteedPriorityPacing: PriorityPacingPair = {
  tier: deliveryTiers.non_guaranteed.key,
  pacing: pacingTypes.YIELD,
};

const housePriorityPacing: PriorityPacingPair = {
  tier: deliveryTiers.house.key,
  pacing: pacingTypes.YIELD,
};

const testPriorityPacing: PriorityPacingPair = {
  tier: deliveryTiers.test.key,
  pacing: pacingTypes.EVEN,
};

const allPriorityPacing: PriorityPacingPair[] = [
  sponsorshipPriorityPacing,
  guaranteedPriorityPacing,
  nonGuaranteedPriorityPacing,
  housePriorityPacing,
  testPriorityPacing,
];

export const getNewPacingTypeValue = ({ tier, pacingType }: Props): DisplayEnum<PacingType> | null => {
  if (!pacingType || !tier || pacingType === pacingTypes.SOV) return null;

  const resultedPriorityPacingPair = allPriorityPacing.find((pacingPair) => {
    return pacingPair.tier === tier.name.key;
  });

  if (resultedPriorityPacingPair && resultedPriorityPacingPair.pacing !== pacingType) {
    return resultedPriorityPacingPair.pacing;
  }

  return null;
};
