import type { TargetingTermValue } from '../../../../../../../../../../models';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import type { UseGetAllConflictsResult } from '../../checkConflictsAndWarnings/getAllConflictsAndWarnings/useGetAllConflictsAndWarnings';
import {
  getPreparedInputForBuffer,
  getPreparedInputForFrequency,
  getPreparedInputForLineItemStatuses,
  getPreparedInputForPriority,
  getPreparedInputForTargeting,
} from '../index';

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
  isLineItem: boolean;
  existedTargeting?: Record<string, TargetingTermValue[]>;
} & Omit<UseGetAllConflictsResult, 'lineItemConflicts'>;

export type Result = {
  resultInput: {
    status?: ReturnType<typeof getPreparedInputForLineItemStatuses>['status'];
    buffer?: ReturnType<typeof getPreparedInputForBuffer>['buffer'];
    frequency?: ReturnType<typeof getPreparedInputForFrequency>['frequency'];
    priority?: ReturnType<typeof getPreparedInputForPriority>['priority'];
    tier?: ReturnType<typeof getPreparedInputForPriority>['tier'];
    targeting?: ReturnType<typeof getPreparedInputForTargeting>['targeting'];
  };
  hasInputForUpdate?: boolean | null | number;
};

export const getInputForUpdateLineItems = ({
  lineItemStatusErrors,
  values,
  isLineItem,
  bufferConflicts,
  targetingConflicts,
  existedTargeting,
  priorityConflicts,
}: Props): Result => {
  const resultInput: Result['resultInput'] = {
    ...getPreparedInputForLineItemStatuses({ statusErrors: lineItemStatusErrors, values }),
    ...getPreparedInputForBuffer({ bufferConflicts, values, isLineItem }),
    ...getPreparedInputForFrequency({ values, isLineItem }),
    ...getPreparedInputForPriority({ values, priorityConflicts, isLineItem }),
    ...getPreparedInputForTargeting({ values, targetingConflicts, isLineItem, existedTargeting }),
  };

  const hasInputForUpdate =
    resultInput.status?.length ||
    resultInput.buffer?.buffer?.length ||
    resultInput.buffer?.frontload?.length ||
    resultInput.frequency?.length ||
    resultInput.priority?.length ||
    resultInput.targeting?.length;

  return {
    resultInput,
    hasInputForUpdate: !!hasInputForUpdate,
  };
};
