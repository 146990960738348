import './HeaderIconTooltip.scss';

import React, { memo, useCallback, useState } from 'react';

import bem from '../../../utils/bem';
import Tooltip from '../../Tooltip';

export type HeaderIconTooltipProps = {
  tooltipMessage: string;
  tooltipId: string;
  isDisabled: boolean;
  children?: React.JSX.Element;
};

const [block, element] = bem('header-icon-tooltip');

const HeaderIconTooltip = ({
  tooltipMessage,
  tooltipId,
  isDisabled,
  children,
}: HeaderIconTooltipProps): React.JSX.Element => {
  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);

  const onShowTooltip = useCallback(() => setIsTooltipShown(true), []);
  const onHideTooltip = useCallback(() => setIsTooltipShown(false), []);

  return (
    <section className={block()}>
      {isTooltipShown ? (
        <Tooltip disable={isDisabled} id={tooltipId} message={tooltipMessage} className={element('tooltip')}>
          <span className={element('icon-wrapper')} onMouseLeave={onHideTooltip} data-testid="icon-wrapper">
            {children}
          </span>
        </Tooltip>
      ) : !isDisabled && tooltipMessage ? (
        <span
          className={element('icon-wrapper')}
          onMouseEnter={onShowTooltip}
          onMouseLeave={onHideTooltip}
          data-testid="icon-wrapper"
        >
          {children}
        </span>
      ) : (
        <>{children}</>
      )}
    </section>
  );
};

export default memo(HeaderIconTooltip);
