import './AdsPage.scss';

import { Formik } from 'formik';
import { uniqBy as _uniqBy } from 'lodash';
import React from 'react';

import type { AdsPageLineItem } from '../../../common/AdForm/types';
import DefaultPageHeader from '../../../common/DefaultPageHeader';
import Loader from '../../../common/Loader';
import type { AdType, Config } from '../../../configs';
import bem from '../../../utils/bem';
import AdForm from '../AdForm';
import AdList from '../AdList';
import { useAdForm } from '../hooks';

export interface AdsPageProps {
  lineItem: AdsPageLineItem;
  permittedAdTypes: Config<AdType>[];
  editAdId?: string;
}

const [block, element] = bem('ads-page');
const [, headerElement] = bem('default-page-header');

const AdsPage = ({ lineItem, permittedAdTypes, editAdId }: AdsPageProps): JSX.Element => {
  const {
    ads,
    lineItemTargetingTermValues,
    initialValues,
    errors: { fetchAdsError },
    loading,
    submitForm,
    lineItems,
    selectedAd,
  } = useAdForm(lineItem, permittedAdTypes[0].key);
  const parentLineItem = lineItems.length ? lineItems[0] : null;
  const uniqueAdsById = _uniqBy(ads, 'id');
  const isAdInitiallyWithBillableTag = selectedAd?.adTagList?.some((el) => el.type.key === 'BILLABLE');
  const lineItemCountry = lineItem?.targetingTermValues?.find((term) => term.dimension?.id === 'country')?.value?.id;

  return (
    <div className={`${block()} page`}>
      <DefaultPageHeader>
        <div>
          <h1 className={headerElement('campaign-name')}>{lineItem.campaign?.name}</h1>
          <h2 className={headerElement('line-item-name')}>{lineItem.name}</h2>
        </div>
      </DefaultPageHeader>
      {loading ? (
        <Loader className={element('initial-loader')} />
      ) : (
        <Formik initialValues={initialValues} onSubmit={submitForm}>
          {({ isSubmitting, submitForm }): JSX.Element => (
            <>
              <div className={element('body')}>
                <AdForm
                  permittedAdTypes={permittedAdTypes}
                  lineItemTargetingTermValues={lineItemTargetingTermValues}
                  isSubmitting={isSubmitting}
                  submitForm={submitForm}
                  parentLineItem={parentLineItem}
                  lineItemCountry={lineItemCountry}
                  selectedAd={selectedAd}
                  isAdInitiallyWithBillableTag={isAdInitiallyWithBillableTag}
                />
                <AdList ads={uniqueAdsById} lineItemId={lineItem.id} error={fetchAdsError} editAdId={editAdId} />
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AdsPage;
