import envConfig from '@hulu/env-config';

import App from '../App';
// using commonjs require as tslint breaks for launch-darkly
const { withLDProvider } = require('launchdarkly-react-client-sdk');

const idToken = window.localStorage.id_token;
const tokenContext = idToken ? JSON.parse(atob(idToken.split('.')[1])) : undefined;

const LDProvider = withLDProvider({
  clientSideID: envConfig.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID,
  timeout: Number(envConfig.REACT_APP_LAUNCHDARKLY_TIMEOUT),
  ...(tokenContext && {
    context: {
      kind: 'user',
      key: tokenContext.ams_user_id,
      email: tokenContext.email,
    },
  }),
})(App);

export default LDProvider;
