import { AdFormSection } from '../../../../../../common/AdForm/AdSideNav/AdSideNav';

export const getSelectedSections = (
  hasSchedulingConflict: boolean,
  previousSelectedSections?: AdFormSection[]
): AdFormSection[] => {
  let isSchedulingPreselected = false;

  const result: AdFormSection[] = previousSelectedSections
    ? previousSelectedSections.reduce((acc, section) => {
        if (section === AdFormSection.SCHEDULING) {
          isSchedulingPreselected = true;

          return acc;
        }

        acc.push(section);

        return acc;
      }, new Array<AdFormSection>())
    : [AdFormSection.CREATIVE, AdFormSection.METADATA, AdFormSection.TARGETING, AdFormSection.TAGS];

  const shouldApplySchedulingFromPreselectedSections = !hasSchedulingConflict && isSchedulingPreselected;
  const shouldApplySchedulingSectionsFromInitialSections = !hasSchedulingConflict && !previousSelectedSections;

  if (shouldApplySchedulingFromPreselectedSections || shouldApplySchedulingSectionsFromInitialSections) {
    result.push(AdFormSection.SCHEDULING);
  }

  return result;
};
