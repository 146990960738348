import './ReviewContent.scss';

import { IconClipboardBlank } from '@hulu-react-style-components/icons';
import { Button as TrekLink, TextField } from '@hulu/react-style-components';
import { noop } from 'lodash';
import React, { useCallback } from 'react';

import Loader from '../../../../common/assets/Loader.svg';
import { copyToClipboard } from '../../../../common/utils';
import bem from '../../../../utils/bem';
import type { ReviewTableName } from '../types';

interface Props {
  contentName: ReviewTableName;
  selectedRowsCount: number;
  reviewPath: string;
  loading: boolean;
}

const [block, element] = bem('review-content');

function ReviewContent({ contentName, selectedRowsCount, reviewPath, loading }: Props): JSX.Element {
  const reviewLink = window.location.origin + reviewPath;

  const copyText = useCallback(async (): Promise<void> => {
    await copyToClipboard({
      text: reviewLink,
      className: element('toast'),
    });
  }, [reviewLink]);

  const contentNameToDisplay = contentName === 'lineItems' ? 'line item' : 'campaign';

  return (
    <div className={block()}>
      <div className={element('title-wrapper')}>
        <p className={element('title')} data-testid="review-content-header">
          Here’s Your Link!
        </p>
        {loading && (
          <div className={element('loading-spinner-wrapper')} data-testid="review-content-loader">
            <img src={Loader} alt="loading spinner" />
          </div>
        )}
      </div>
      <>
        <p data-testid="review-content-text" className={element('prompt')}>
          Use this link to review {selectedRowsCount} selected {contentNameToDisplay}
          {selectedRowsCount > 1 && 's'}.
        </p>
        <div className={element('text-area-wrap')}>
          <TextField
            data-testid="review-link-input"
            onChange={noop}
            type="text"
            value={reviewLink}
            className={element('text-area')}
          />
          {/*If clipboard is undefined, we don't show the copy button*/}
          {navigator.clipboard && (
            <div data-testid="copy-to-clipboard" className={element('clipboard-icon')} onClick={copyText}>
              <TrekLink aria-label="submit create review" className={element('submit-button')} disabled={false}>
                <IconClipboardBlank />
              </TrekLink>
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export default ReviewContent;
