import './MultipleDuplicateAdsDrawer.scss';

import { bem } from 'mission-control-common-components';
import React from 'react';

import Drawer from '../../../../common/Drawer';
import type { MultipleDuplicateAdsDrawerData } from '../../TraffickingPage/hooks/makePageDrawerPlugin';
import MultipleDuplicateAds from './MuiltipleDuplicateAds/MultipleDuplicateAds';

export type MultipleDuplicateDrawerProps = {
  isOpen: boolean;
  className: string;
  data: MultipleDuplicateAdsDrawerData;
  onClose: () => void;
  onToggleExpansion: () => void;
};

const [block] = bem('multiple-duplicate-ads-drawer ');

const MultipleDuplicateAdsDrawer = ({
  data,
  isOpen,
  onClose,
  onToggleExpansion,
}: MultipleDuplicateDrawerProps): JSX.Element => {
  return (
    <Drawer<MultipleDuplicateAdsDrawerData>
      data={data}
      content={MultipleDuplicateAds}
      onClose={onClose}
      isOpen={isOpen}
      isExpanded={true}
      onToggleExpansion={onToggleExpansion}
      emptyHeader={true}
      className={block()}
    />
  );
};

export default MultipleDuplicateAdsDrawer;
