import './CreativeRejectionReasonsModal.scss';

import { bem } from 'mission-control-common-components';
import { useCallback, useMemo } from 'react';

import { ReviewStatus } from '../../_ForCommonLibrary/components/atoms/AssetStatusPill/constants';
import type { AssetExtended } from '../../_ForCommonLibrary/types/extended';
import type { CreativesBuilderValues } from '../../AdForm/CreativeBuilders/types';
import { copyToClipboard } from '../../utils';
import { StatusModal } from '../StatusModal';
import CreativeRejectionReasonsModalTitle from './CreativeRejectionReasonsModalTitle/CreativeRejectionReasonsModalTitle';

type Props = {
  creative: CreativesBuilderValues;
  assets: AssetExtended[];
};

const [block, element] = bem('creative-rejection-reasons-modal');
const [, modalElement] = bem('status-container');

const DETAILS_LABEL = '(Details)';

const CreativeRejectionReasonsModal = ({ creative, assets }: Props): React.JSX.Element => {
  const rejectedAssets = useMemo(
    () =>
      assets.filter(
        ({ rejectionReasons, review }) =>
          review === ReviewStatus.REJECTED && rejectionReasons && rejectionReasons.length
      ),
    [assets]
  );

  const copyText = useCallback(async (): Promise<void> => {
    let text = `Rejection reasons for creative: ${creative.name} - ${creative.id}\r\n\r\n`;

    rejectedAssets.forEach((asset) => {
      text += `Asset: ${asset.name} - ${asset.id}\r\n`;
      asset.rejectionReasons!.forEach((reason) => {
        text += `${reason}\r\n`;
      });
      text += '\r\n';
    });

    await copyToClipboard({
      text,
      className: element('toast'),
    });
  }, [creative.id, creative.name, rejectedAssets]);

  const creativeTitle = useMemo(
    () => (
      <span data-testid="creative-title">
        For Creative <span className={element('id-text')}>{creative.id}</span> with{' '}
        <span className={element('accent-text')}>{creative.rejectionReason}</span>
      </span>
    ),
    [creative.id, creative.rejectionReason]
  );

  return (
    <StatusModal
      activator={<span>{DETAILS_LABEL}</span>}
      title={<CreativeRejectionReasonsModalTitle copyText={copyText} />}
    >
      <div className={block()}>
        <div>{creativeTitle}</div>
        <div>
          {rejectedAssets.map((asset) => {
            return (
              <div key={asset.id}>
                <div className={modalElement('asset-name')}>Asset: {asset.name}</div>
                <div className={element('id-text')}>{asset.id}</div>
                <ul>
                  {asset.rejectionReasons?.map((rejectionReason) => (
                    <li className={element('accent-text')} key={asset.id + '_' + rejectionReason}>
                      {rejectionReason}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </StatusModal>
  );
};

export default CreativeRejectionReasonsModal;
