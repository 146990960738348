import './DuplicateAd.scss';

import { IconCopy } from '@hulu-react-style-components/icons';
import envConfig from '@hulu/env-config';
import { Modal } from 'antd';
import React, { memo, useCallback, useState } from 'react';

import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import bem from '../../../utils/bem';
import type { TraffickingPageDrawerData } from '../TraffickingPage/hooks/makePageDrawerPlugin';
import { DrawerType } from '../TraffickingPage/hooks/makePageDrawerPlugin';
import type { AdModel, LineItemModel } from '../TraffickingPage/modelConverters';
import { TOOLTIP_MESSAGE } from './constants';
import DuplicateAdForm from './DuplicateAdForm';
import { arePropsEqual } from './utils';

const [block, element] = bem('duplicate-ad-button');

export interface DuplicateAdProps {
  selectedAds: AdModel[];
  selectedLineItems: LineItemModel[];
  isDisabled: boolean;
  openTraffickingPageDrawer: (data: TraffickingPageDrawerData) => void;
}

const DuplicateAd = ({
  selectedAds,
  selectedLineItems,
  isDisabled,
  openTraffickingPageDrawer,
}: DuplicateAdProps): JSX.Element => {
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);

  const handleOpenDuplicateModal = useCallback((): void => {
    if (isDisabled || !selectedAds.length) return;

    if (selectedAds.length === 1) {
      setShowDuplicateModal(true);
    } else if (envConfig.REACT_APP_FEATURE_ENABLE_MULTIPLE_DUPLICATE_ADS === 'true') {
      openTraffickingPageDrawer({
        drawerType: DrawerType.MULTIPLE_DUPLICATE,
        selectedAds: selectedAds,
        selectedLineItems: selectedLineItems,
      });
    }
  }, [isDisabled, selectedAds, openTraffickingPageDrawer, selectedLineItems]);

  const handleCloseDuplicateModal = useCallback((): void => {
    setShowDuplicateModal(false);
  }, []);

  return (
    <>
      <Tooltip id="duplicate-ad" className={element('tooltip')} message={TOOLTIP_MESSAGE} disable={!isDisabled}>
        <Button
          size="medium"
          variant="outlined"
          className={block()}
          disabled={isDisabled}
          aria-label="Copy Ad(s) to Line Items"
          icon={<IconCopy />}
          onClick={handleOpenDuplicateModal}
          data-testid="duplicate-ad-button"
        >
          Copy Ad(s) to Line Items
        </Button>
      </Tooltip>

      {showDuplicateModal && selectedAds && (
        <Modal
          visible={showDuplicateModal}
          footer={null}
          width={'85%'}
          onCancel={handleCloseDuplicateModal}
          data-testid="duplicate-ad-modal"
        >
          <DuplicateAdForm
            ad={selectedAds[0]}
            selectedLineItems={selectedLineItems}
            onClose={handleCloseDuplicateModal}
          />
        </Modal>
      )}
    </>
  );
};

export default memo(DuplicateAd, arePropsEqual);
