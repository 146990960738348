import React from 'react';

import { AlertType } from '../../../../../common/Alert';
import { openToastAlert } from '../../../../../common/ToastAlert/toastAlert';
import { GENERAL_ERROR_MESSAGE } from '../../../../../constants';
import DuplicateAdsStepperProvider from '../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import MultipleDuplicateAdsStepper from '../MultipleDuplicateAdsStepper/MultipleDuplicateAdsStepper';
import type { MultipleDuplicateAdsProps } from '../types';

const MultipleDuplicateAds = ({ data, onClose }: Partial<MultipleDuplicateAdsProps>): JSX.Element => {
  if (!data || !onClose || !data.selectedAds.length) {
    openToastAlert({
      alertType: AlertType.ERROR,
      message: GENERAL_ERROR_MESSAGE,
    });

    return <></>;
  }

  return (
    <DuplicateAdsStepperProvider
      preSelectedLineItems={data.selectedLineItems}
      originalDuplicatedAds={data.selectedAds}
      onCloseStepper={onClose}
    >
      <MultipleDuplicateAdsStepper />
    </DuplicateAdsStepperProvider>
  );
};

export default MultipleDuplicateAds;
