import React, { createContext, useContext, useMemo } from 'react';

import useDuplicateAds from './hooks/useDuplicateAds';
import useStepper from './hooks/useStepper';
import type { DuplicateAdsContextType, DuplicateAdsStepperProviderProps } from './types';

const DuplicateAdsStepperContext = createContext({} as DuplicateAdsContextType);

export const useDuplicateAdsStepperContext = (): DuplicateAdsContextType => useContext(DuplicateAdsStepperContext);

const DuplicateAdsStepperProvider = ({
  children,
  onCloseStepper,
  originalDuplicatedAds,
  preSelectedLineItems,
}: DuplicateAdsStepperProviderProps): React.JSX.Element => {
  const { step, goToNextStep, goToPrevStep } = useStepper({ onCloseStepper });
  const {
    lineItems,
    replaceLineItems,
    ads,
    setAds,
    updateLineItemAdName,
    handleUpdateLineItemsSelection,
    handleRemoveLineItem,
    adType,
    isSelectedSectionsChanged,
    setIsSelectedSectionsChanged,
    lastAppliedSectionsAdName,
    setLastAppliedSectionsAdName,
    selectedLineItems,
  } = useDuplicateAds({
    preSelectedLineItems,
    originalDuplicatedAds,
  });

  const value = useMemo(() => {
    return {
      lineItems,
      replaceLineItems,
      ads,
      setAds,
      updateLineItemAdName,
      onCloseStepper,
      step,
      goToNextStep,
      goToPrevStep,
      originalDuplicatedAds,
      handleRemoveLineItem,
      handleUpdateLineItemsSelection,
      adType,
      isSelectedSectionsChanged,
      setIsSelectedSectionsChanged,
      lastAppliedSectionsAdName,
      setLastAppliedSectionsAdName,
      selectedLineItems,
    };
  }, [
    lineItems,
    replaceLineItems,
    ads,
    setAds,
    updateLineItemAdName,
    onCloseStepper,
    step,
    goToNextStep,
    goToPrevStep,
    originalDuplicatedAds,
    handleRemoveLineItem,
    handleUpdateLineItemsSelection,
    adType,
    isSelectedSectionsChanged,
    setIsSelectedSectionsChanged,
    lastAppliedSectionsAdName,
    setLastAppliedSectionsAdName,
    selectedLineItems,
  ]);

  return <DuplicateAdsStepperContext.Provider value={value}>{children}</DuplicateAdsStepperContext.Provider>;
};

export default DuplicateAdsStepperProvider;
