import './StatusModal.scss';

import { Modal } from 'antd';
import React from 'react';

import { bem } from '../_ForCommonLibrary/util/bem';
import Button from '../Button';
import { useStatusModal } from './hooks';

const [block, element] = bem('status-container');

export const StatusModal: React.FC<{
  activator: React.ReactNode;
  children: React.ReactNode;
  title: string | React.ReactNode;
}> = ({ activator, children, title }) => {
  const { closeModal, openModal, visible } = useStatusModal();

  return (
    <div className={block()}>
      <div className={element('activator')} onClick={openModal}>
        {activator}
      </div>
      {visible && (
        <Modal
          onCancel={closeModal}
          visible={visible}
          title={title}
          className={element('modal')}
          footer={
            <Button key="submit" aria-label="close" tabIndex={0} onClick={closeModal}>
              Close
            </Button>
          }
        >
          {children}
        </Modal>
      )}
    </div>
  );
};
