import './AssetUploader.scss';
import 'antd/lib/progress/style/index.css';

import { IconLink, IconSearch, IconUpload } from '@hulu-react-style-components/icons';
import { colorBlue2, colorGray3 } from '@hulu/design-tokens/lib/colors.common';
import { Typography } from '@hulu/react-style-components';
import { Progress } from 'antd';
import React, { useState } from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import Button from '../../common/Button';
import type { AssetType } from '../../configs';
import bem from '../../utils/bem';
import type { Dimensions } from '../AdForm/CreativeBuilders/CreativeBuilder';
import { ACCEPTABLE_FILE_TYPES_MAP } from './const';
import { getFilesFromEvent, getFormatDimensionLabelsValue, validateFile } from './utils';

export interface AssetUploaderProps {
  assetType: AssetType;
  assetName?: string;
  adTypeDimensions?: Dimensions;
  switchToSearch: () => void;
  switchToTag?: () => void;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  aspectRatio: number;
  loading: boolean;
  percentUploaded?: number;
  allowVast: boolean;
  readonly?: boolean;
  cancelRequest: () => void;
}

const [, element] = bem('asset-uploader');

function AssetUploader({
  assetType,
  assetName,
  switchToSearch,
  switchToTag,
  onDrop,
  loading,
  percentUploaded,
  cancelRequest,
  adTypeDimensions,
  allowVast,
  readonly,
}: AssetUploaderProps): JSX.Element {
  const [hovering, setHovering] = useState<boolean>(false);
  const onDragEnter = (): void => setHovering(true);
  const onDragLeave = (): void => setHovering(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTABLE_FILE_TYPES_MAP[assetType],
    multiple: false,
    disabled: readonly,
    onDragEnter,
    onDragLeave,
    onDrop,
    getFilesFromEvent,
    validator: (file: File) => validateFile(file, adTypeDimensions),
  });

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    // Prevent Dropzone from activating
    e.stopPropagation();
    switchToSearch();
  };

  const onClickTagUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    // Prevent Dropzone from activating
    e.stopPropagation();
    switchToTag && switchToTag();
  };

  const messaging = allowVast ? 'Click link to add VAST/VPAID URL:' : 'Drop files here or import from:';
  const formatDimensionLabels = getFormatDimensionLabelsValue(assetType, adTypeDimensions);

  return loading ? (
    <div className={element('dropzone')}>
      <span className={element('upload-message')}>
        Uploading <strong>{assetName}</strong>...
      </span>
      <Progress
        className={element('progress-bar')}
        percent={percentUploaded}
        strokeColor={colorBlue2}
        trailColor={colorGray3}
        strokeWidth={7}
        success={{
          strokeColor: colorBlue2,
        }}
        showInfo={false}
        data-testid="progress-bar"
      />
      <div className={element('progress-details')}>
        <Typography variant="body3">{`${percentUploaded?.toFixed(0)}% complete`}</Typography>
        <Button variant="outlined" className={element('cancel-btn')} onClick={cancelRequest}>
          <Typography variant="link3">Cancel</Typography>
        </Button>
      </div>
    </div>
  ) : (
    <div data-testid="drop-input" {...getRootProps({ className: element('dropzone', hovering ? 'hovering' : '') })}>
      {!allowVast && <input aria-label="file drag and drop" {...getInputProps()} />}
      <p className={element('head-info')}>{messaging}</p>
      <div className={element('select-container')}>
        {!allowVast && (
          <>
            <button
              data-testid="my-device-upload"
              type="button"
              className={element('select-button')}
              disabled={readonly}
            >
              <IconUpload title="upload icon" />
              <span>My Device</span>
            </button>
            <button
              data-testid="file-library-upload"
              type="button"
              className={element('select-button')}
              onClick={onClickSearch}
              disabled={readonly}
            >
              <IconSearch title="search icon" />
              <span>File Library</span>
            </button>
          </>
        )}
        {allowVast
          ? switchToTag && (
              <button
                type="button"
                className={element('select-button')}
                onClick={onClickTagUpload}
                disabled={readonly}
                data-testid="vast-url-tag"
              >
                <IconLink title="link icon" />
                <span>VAST/VPAID</span>
              </button>
            )
          : null}
      </div>
      <div className={element('info')}>
        <p>Save ad AND creative first before uploading site-served assets</p>
        {!allowVast && formatDimensionLabels && (
          <>
            <div className={element('sub-info')}>
              <p>Format: {formatDimensionLabels.format}</p>
              <p>Dimensions: {formatDimensionLabels.dimension}</p>
            </div>
            {formatDimensionLabels?.fileSize ? (
              <p className={element('file-size-info')}>File Size: {formatDimensionLabels.fileSize}</p>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default AssetUploader;
