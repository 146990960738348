import './RejectionMessage.scss';

import { IconAlertErrorOutlined } from '@hulu-react-style-components/icons';
import { bem } from 'mission-control-common-components';
import type { ReactNode } from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import CreativeRejectionReasonsModal from '../../../../StatusModal/CreativeRejectionReasonsModal/CreativeRejectionReasonsModal';
import type { CreativesBuilderValues } from '../../types';

type Props = {
  bodyText?: ReactNode;
  assets: AssetExtended[];
  creative: CreativesBuilderValues;
};

const [block, element] = bem('rejection-message');

const RejectionMessage = ({ bodyText, assets, creative }: Props): React.JSX.Element => {
  return (
    <div className={block()}>
      <IconAlertErrorOutlined className={element('error-svg')} />
      <div>
        {bodyText}
        {creative.rejectionReason && (
          <div>
            Rejection Reason: <span className={element('rejection-reason')}>{creative.rejectionReason}</span>
            {creative.rejectionReasonDetails && creative.rejectionReasonDetails.length > 1 && (
              <CreativeRejectionReasonsModal creative={creative} assets={assets} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RejectionMessage;
