import './TaskTabNavigation.scss';

import type { TabProps } from '@hulu/react-style-components';
import { Tab, TabNavigation } from '@hulu/react-style-components';
import React, { Children } from 'react';

import bem from '../../utils/bem';
import TodoItem from '../TodoItem';

const [b, e] = bem('task-tab-navigation');

interface TaskTabProps {
  title: string;
  className?: string;
  isComplete: boolean;
  isFailed?: boolean;
  children?: React.ReactNode;
  tooltip?: string;
  tooltipId?: string;
  iconComplete?: React.ReactNode;
  iconFailed?: React.ReactNode;
  iconIncomplete?: React.ReactNode;
  showCheck?: boolean;
}

interface TaskTabNavigationProps {
  children: React.ReactElement<TaskTabProps>[];
  maxChildren?: number;
  /** Index of the selected tab. */
  selectedIndex?: number;
  /** Callback is called when a Tab has clicked. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTabClick?: React.EventHandler<any>;
  className?: string;
}

function toTrekTabs({ props }: React.ReactElement<TaskTabProps>, index: number): React.ReactElement<TabProps> {
  const {
    children,
    isComplete,
    title,
    isFailed,
    showCheck,
    iconComplete,
    iconFailed,
    iconIncomplete,
    tooltip,
    tooltipId,
  }: TaskTabProps = props;

  const element = (
    <button type="button">
      <TodoItem
        text={title}
        isComplete={isComplete}
        iconComplete={iconComplete}
        iconFailed={iconFailed}
        iconIncomplete={iconIncomplete}
        tooltip={tooltip}
        tooltipId={tooltipId}
        isFailed={isFailed}
        showCheck={showCheck}
      />
    </button>
  );

  return (
    <Tab key={index} className={e('task-tab')} title={title} element={element}>
      {children}
    </Tab>
  );
}

// TaskTab is a component that is meant to be used as a direct child of <TaskTabNavigation />
// and is meant to "decorate" whatever `children` content is passed to it.
function TaskTab({ children }: TaskTabProps): JSX.Element {
  return <>{children}</>;
}

// TaskTabNavigation is a wrapper around Trek's TabNavigation that embeds
// a <TodoItem /> in a tab's title that can be marked as completed.
function TaskTabNavigation({ children, ...props }: TaskTabNavigationProps): React.JSX.Element {
  return (
    <div className={b()}>
      <TabNavigation {...props}>
        {Children.map<React.JSX.Element, React.ReactElement<TaskTabProps>>(children, toTrekTabs)}
      </TabNavigation>
    </div>
  );
}

TaskTabNavigation.TaskTab = TaskTab;

export default TaskTabNavigation;
