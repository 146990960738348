import './SelectableAdFormSectionWrapper.scss';

import { bem, ControlledCheckbox } from 'mission-control-common-components';
import React, { useCallback } from 'react';
import { Element } from 'react-scroll';

import type { AdFormSection } from '../../../../../../../../common/AdForm/AdSideNav/AdSideNav';
import Card from '../../../../../../../../common/Card';
import type { Nullable } from '../../../../../../../../models';

const [block, element] = bem('selectable-ad-form-section-wrapper');

export type SelectableAdFormSectionWrapperProps = {
  title: string;
  sectionName: AdFormSection;
  customTitle?: React.JSX.Element;
  children: React.JSX.Element;
  isChecked: boolean;
  toggleSection: (sectionName: AdFormSection, state: boolean) => void;
  disabled?: boolean;
  error?: Nullable<string>;
};

const SelectableAdFormSectionWrapper = ({
  title,
  sectionName,
  customTitle,
  children,
  disabled,
  error,
  isChecked,
  toggleSection,
}: SelectableAdFormSectionWrapperProps): React.JSX.Element => {
  const onToggleCheck = useCallback((): void => {
    if (!disabled) toggleSection(sectionName, !isChecked);
  }, [disabled, isChecked, sectionName, toggleSection]);

  return (
    <Element name={sectionName} className={block()} data-testid="form-section">
      <div className={element('checkbox-container')} onClick={onToggleCheck}>
        <ControlledCheckbox disabled={disabled} checked={isChecked} indeterminate={false} onChange={onToggleCheck} />
        {customTitle || <span className={element('title')}>{title}</span>}
      </div>
      {error && (
        <div data-testid="error-message" className={element('error')}>
          {error}
        </div>
      )}
      <Card>{children}</Card>
    </Element>
  );
};

export default SelectableAdFormSectionWrapper;
