import type { ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import type { AdTypeV5 } from '../../../../../../../apis/graphql';
import { convertAdsPageAdToFormValues, getInitialValues } from '../../../../../../../common/AdForm/adFormik';
import { isNielsenTag } from '../../../../../../../common/AdForm/utils';
import { getConflictLineItemsIdsWithAdDateRangeList } from '../../../../../../../utils/validation';
import { useDuplicateAdsStepperContext } from '../../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import type { DuplicateAdFormValue } from '../types';
import { useFetchDuplicateAds } from './useFetchDuplicateAds';

export type UseDuplicateAdFormValues = {
  loading: boolean;
  duplicateAdFromValues: DuplicateAdFormValue[];
  error?: ApolloError;
};

export const useDuplicateAdFormValues = (): UseDuplicateAdFormValues => {
  const { ads, selectedLineItems } = useDuplicateAdsStepperContext();

  const uniqueIdsParams = useMemo(() => {
    const adIds = new Set<string>();
    const lineItemIds = new Set<string>();

    ads.forEach((ad) => {
      adIds.add(ad.id);

      if (ad.lineItem) lineItemIds.add(ad.lineItem.id);
    });

    return {
      adIds: Array.from(adIds),
      lineItemIds: Array.from(lineItemIds),
    };
  }, [ads]);

  const { ads: fetchedAds, lineItemTargetingTermValues, loading: loadingDuplicateAds, error } = useFetchDuplicateAds(
    uniqueIdsParams.adIds,
    uniqueIdsParams.lineItemIds
  );

  const duplicateAdFromValues = useMemo(() => {
    return ads.reduce((acc, ad) => {
      const fetchedAd = fetchedAds?.find(({ id }) => id === ad.id);

      if (fetchedAd) {
        const lineItemTargetingTermValue = lineItemTargetingTermValues.find(
          ({ lineItemId }) => lineItemId === ad.lineItem?.id
        );

        const formValues = convertAdsPageAdToFormValues(
          getInitialValues({ type: fetchedAd.type.key as AdTypeV5 }),
          fetchedAd
        );

        acc.push({
          ...formValues,
          isVisited: ad.isVisited,
          isFailed: ad.isFailed,
          selectedSections: ad.selectedSections,
          lineItem: ad.lineItem,
          lineItemTargetingTermValues: lineItemTargetingTermValue?.targetingTermValues ?? [],
          schedulingConflictLineItemIds: getConflictLineItemsIdsWithAdDateRangeList(
            selectedLineItems,
            formValues.schedule.dateRangeList
          ),
          hasNielsenTag: formValues.adTagList.some((tag) => isNielsenTag(tag.url)),
        });
      }

      return acc;
    }, new Array<DuplicateAdFormValue>());
  }, [ads, fetchedAds, lineItemTargetingTermValues, selectedLineItems]);

  return {
    duplicateAdFromValues,
    loading: loadingDuplicateAds,
    error,
  };
};
