export const DUPLICATE_ADS_TITLE = 'Duplicate Ads';

export const SAVE_BUTTON_TOOLTIP_TEXT = 'Review each ad tab before saving changes.';
export const CONFIRM_LABEL = 'Save';

export const DEFAULT_APPLIED_NO_CRITERIA_TEXT = 'Currently, no criteria selections are applied to all ads.';
export const APPLY_TO_ALL_APPLIED_TEXT = 'Selected criteria has been applied to all ads.';
export const APPLY_TO_ALL_APPLIED_WITH_ERRORS_TEXT =
  'Selected criteria has been applied to eligible ads. Error icons indicate conflicts and these properties will not be copied.';
export const APPLY_TO_ALL_LABEL = 'APPLY CRITERIA SELECTIONS TO ALL ADS';

export const AD_FORM_VALUES_MAPPING_ERROR = 'Fetched ad cannot be mapped to the ads, selected in the table!';

export const AD_SECTIONS_TITLE_TEXT = 'Select Criteria for Duplicate(s)';
export const AD_SECTIONS_SUBTITLE_TEXT =
  'Select ad properties to keep in the duplicated ads. Deselected properties will not be duplicated. Properties to be copied are read only. You can edit ads individually or in bulk after the duplication workflow is completed.';
export const AD_SECTIONS_ERROR_TEXT = 'Something went wrong with fetching ads to duplicate.';
export const ERROR_ICON_TOOLTIP_TEXT = 'Review each ad before proceeding.';
