import type { AdInputV5, CampaignTagV5, LineItemV5 } from '../../../../../apis/graphql';
import { AdReviewV5, AdStatusV5, AdTypeV5, removeTypeNamesFromObject } from '../../../../../apis/graphql';
import { AdFormSection } from '../../../../../common/AdForm/AdSideNav/AdSideNav';
import {
  convertAdTagToAdTagInput,
  getAdTagInput,
  getFrequencyCapInput,
  getSchedule,
  getTargetingTermValuesInput,
  getViewabilityVendorEnumsFromFormValue,
} from '../../../../../common/AdForm/hooks/modelConverters/adsPageAdModelToNode';
import type { NielsenTagProps } from '../../../../../common/AdForm/utils/nielsenTag';
import { getNielsenTag, isNielsenTag } from '../../../../../common/AdForm/utils/nielsenTag';
import { convertAdProduct, convertBillableThirdParty } from '../../../../../configs';
import type { AdTag, Nullable } from '../../../../../models';
import type { DuplicateAdFormValues } from '../types';
import type { DuplicateLineItem } from './useDuplicateLineItemsState';

const getCreativeData = (formValues: DuplicateAdFormValues): Pick<AdInputV5, 'creativeId'> => {
  if (!formValues.selectedSections.includes(AdFormSection.CREATIVE)) return {};

  return { creativeId: formValues.creative.id || null };
};

const generateNewNielsenTagIfEligible = (lineItem: DuplicateLineItem, isPausedAd: boolean = false): Nullable<AdTag> => {
  const campaignTags: Nullable<CampaignTagV5[]> =
    lineItem.campaign?.campaignTagList?.map((tag) => ({
      ...tag,
      id: '',
      isVerified: true,
    })) ?? null;

  const nielsenTagProps: NielsenTagProps = {
    campaignTagList: campaignTags,
    lineItem_billableThirdParty: lineItem.billableThirdParty,
    lineItem_isCoppaOrCaru: lineItem.isCoppaOrCaru,
    lineItem_country: lineItem.country || '',
    isPausedAd,
  };

  return getNielsenTag(nielsenTagProps);
};

const getTagsData = (formValues: DuplicateAdFormValues, lineItem: DuplicateLineItem): Pick<AdInputV5, 'adTagList'> => {
  const newNielsenTag = generateNewNielsenTagIfEligible(lineItem, formValues.type === AdTypeV5.Pause);

  // If tags unselected to copy, return new Nielsen Tag if eligible or else no tags
  if (!formValues.selectedSections.includes(AdFormSection.TAGS)) {
    return newNielsenTag ? { adTagList: [convertAdTagToAdTagInput(newNielsenTag)] } : {};
  }

  // tags should be unverified and nielsen tags should be sorted out for duplicated ads due to business requirements.
  let adTagList = getAdTagInput(formValues.adTagList)
    .filter((tag) => !isNielsenTag(tag.url))
    .map((tag) => ({ ...tag, isVerified: false }));

  // Set New Nielsen Tag as first Ad Tag
  if (newNielsenTag) {
    adTagList.unshift(convertAdTagToAdTagInput(newNielsenTag));
  }

  return { adTagList };
};

const getTargetingData = (
  formValues: DuplicateAdFormValues
): Pick<AdInputV5, 'targetingTermValues' | 'targetingRule'> => {
  if (!formValues.selectedSections.includes(AdFormSection.TARGETING)) return {};

  return {
    targetingTermValues: getTargetingTermValuesInput(formValues.targetingTermValues),
    targetingRule: removeTypeNamesFromObject(formValues.targetingRule),
  };
};

const getScheduleData = (formValues: DuplicateAdFormValues): Pick<AdInputV5, 'schedule'> => {
  if (!formValues.selectedSections.includes(AdFormSection.SCHEDULING)) return {};

  return {
    schedule: getSchedule(formValues.schedule),
  };
};

const getMetadata = (
  formValues: DuplicateAdFormValues
): Pick<AdInputV5, 'frequencyCapList' | 'viewabilityVendorList'> => {
  if (!formValues.selectedSections.includes(AdFormSection.METADATA)) return {};

  return {
    frequencyCapList: getFrequencyCapInput(formValues.frequencyCapList),
    viewabilityVendorList: getViewabilityVendorEnumsFromFormValue(formValues.viewabilityVendorList),
  };
};

const getAdInputFromSelectedSections = (
  formValues: DuplicateAdFormValues,
  lineItem: DuplicateLineItem
): Omit<AdInputV5, 'name' | 'lineItemId'> => {
  return {
    status: AdStatusV5.Off,
    review: AdReviewV5.Draft,
    type: formValues.type,
    rating: formValues.rating,
    ...getCreativeData(formValues),
    ...getTagsData(formValues, lineItem),
    ...getTargetingData(formValues),
    ...getScheduleData(formValues),
    ...getMetadata(formValues),
  };
};

export const convertFormValuesToDuplicateAdsInput = (formValues: DuplicateAdFormValues): AdInputV5[] => {
  if (!formValues.lineItems) {
    return [];
  }

  return formValues.lineItems.map((lineItem) => {
    return {
      lineItemId: lineItem.id,
      name: lineItem?.adName ?? '',
      ...getAdInputFromSelectedSections(formValues, lineItem),
    };
  });
};

export const convertLineItemToDuplicateLineItem = (lineItem: LineItemV5): DuplicateLineItem => {
  return {
    id: lineItem.id,
    name: lineItem.name,
    schedule: lineItem.schedule,
    startDate: lineItem.startDate,
    endDate: lineItem.endDate,
    adProduct: convertAdProduct(lineItem.adProduct),
    billableThirdParty: lineItem.billableThirdParty ? convertBillableThirdParty(lineItem.billableThirdParty) : null,
    isCoppaOrCaru: lineItem.isCoppaOrCaru,
    campaign: lineItem.campaign,
    country: lineItem.country,
  };
};
