/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconCalendar } from '@hulu-react-style-components/icons';
import envConfig from '@hulu/env-config';
import React from 'react';
import type { Cell, CellProps } from 'react-table';

import Badge from '../../../../common/Badge';
import { resizingData, stickyBlock } from '../../../../common/TraffickingTable/utils';
import { TraffickingTableName } from '../../../../constants';
import bem from '../../../../utils/bem';
import { DEFAULT_COLUMN_MIN_WIDTH } from '../../constants';
import { AdColumn } from '../../constants/ad';
import {
  AdEditDateCell,
  AdFrequencyCapCell,
  AdStatusCell,
  AlertCell,
  CreateAndEditAdCell,
  DeleteAdCell,
  NameCell,
  RatingCell,
  ReviewCell,
  RotationGroupNameCell,
  SequenceWeightCell,
  TimeZoneCell,
  ViewabilityVendorCell,
} from '../cells';
import type { AlertFlagProps } from '../cells/AlertCell';
import { AlertFiled } from '../cells/AlertCell/AlertCell';
import InventoryBlockCell from '../cells/InventoryBlockCell';
import SequenceCell from '../cells/SequenceCell';
import { EntityType } from '../hooks/makePageDrawerPlugin';
import type { AdModel } from '../modelConverters';
import { CellType } from '../types';
import { AdColumnHeaders, AdsColumnTooltips } from './types';
import type { CustomColumnConfig } from './utils';
import { getAdSequenceName, getPublisherTargetingList, stringToDateTimeString } from './utils';

const [, element] = bem('alert-cell');

export const getAdsAlertFlags = (ad: AdModel): AlertFlagProps[] => {
  const { id, hasMisalignedSchedule, lineItem } = ad;

  return [
    {
      id,
      field: AlertFiled.MisalignedSchedule,
      isShown: !!hasMisalignedSchedule,
      icon: <IconCalendar width={18} height={18} />,
      hoverMessage: 'This ad has a misaligned schedule.',
      className: element('red-icon'),
    },
    {
      id,
      field: AlertFiled.LineItem,
      isShown: !lineItem,
      icon: <Badge text={'LI'} />,
      hoverMessage: 'Ad contains unlinked Line Item.',
      className: element('red-icon'),
    },
    {
      id,
      field: AlertFiled.InventoryBlock,
      icon: <InventoryBlockCell exclusiveBlock={lineItem?.exclusiveBlock} />,
      isShown: !!lineItem?.exclusiveBlock,
      hoverMessage: 'This ad has an inventory block.',
      className: element('red-icon', 'clickable'),
    },
  ];
};

// NOTE: `Cell` property cannot return `undefined` or the page will crash
const getAdsColumns = (): CustomColumnConfig<AdModel>[] => {
  let columns: CustomColumnConfig<AdModel>[] = [
    {
      id: AdColumn.Alert,
      Header: AdColumnHeaders.Alert,
      Cell: (cell: Cell<AdModel>) => <AlertCell flags={getAdsAlertFlags(cell.row.original)} />,
      width: resizingData.getData(90, TraffickingTableName.campaigns, 'Alert'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.Alert],
    },
    {
      id: AdColumn.Name,
      accessor: 'name',
      Header: AdColumnHeaders.AdName,
      Cell: ({ row: { original }, openTraffickingPageDrawer }) => (
        <NameCell
          rowOriginal={original}
          entityType={EntityType.AD}
          openTraffickingPageDrawer={openTraffickingPageDrawer}
        />
      ),
      disableHiding: true,
      width: resizingData.getData(400, TraffickingTableName.ads, 'Name'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: stickyBlock('name'),
      tooltipMessage: AdsColumnTooltips[AdColumn.Name],
    },
    {
      id: AdColumn.EditAd,
      Header: AdColumnHeaders.EditAd,
      Cell: (cell: Cell<AdModel>) => <CreateAndEditAdCell cell={cell} cellType={CellType.edit} />,
      width: resizingData.getData(100, TraffickingTableName.ads, 'EditAd'),
      tooltipMessage: AdsColumnTooltips[AdColumn.EditAd],
    },
    {
      id: AdColumn.TrackingId,
      accessor: 'trackingId',
      Header: AdColumnHeaders.TrackingId,
      width: resizingData.getData(185, TraffickingTableName.ads, 'TrackingId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.TrackingId],
    },
    {
      id: AdColumn.DeleteAd,
      Header: AdColumnHeaders.DeleteAd,
      Cell: DeleteAdCell,
      isEditable: true,
      width: resizingData.getData(100, TraffickingTableName.ads, 'deleteAd'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: AdColumn.AdID,
      accessor: 'id',
      Header: AdColumnHeaders.AdId,
      width: resizingData.getData(320, TraffickingTableName.ads, 'adID'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.AdID],
    },
    {
      id: AdColumn.Country,
      accessor: ({ lineItem }) => lineItem?.country || '',
      Header: AdColumnHeaders.Country,
      width: resizingData.getData(115, TraffickingTableName.lineItems, 'Country'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.Country],
    },
    {
      id: AdColumn.Type,
      accessor: (a) => a.type.displayName,
      Header: AdColumnHeaders.AdType,
      width: resizingData.getData(250, TraffickingTableName.ads, 'Type'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.Type],
    },
    {
      id: AdColumn.Status,
      accessor: (a) => a.status,
      Header: AdColumnHeaders.Status,
      isEditable: true,
      Cell: AdStatusCell,
      width: resizingData.getData(130, TraffickingTableName.ads, 'Status'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.Status],
    },
    {
      id: AdColumn.Review,
      accessor: (a) => a.review,
      Header: AdColumnHeaders.Review,
      Cell: ReviewCell,
      width: resizingData.getData(150, TraffickingTableName.ads, 'Review'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.Review],
    },
    {
      id: AdColumn.ScheduleStart,
      accessor: (ad) => ad.startDate,
      Header: AdColumnHeaders.StartDate,
      isEditable: true,
      Cell: (cellProps: CellProps<AdModel>): JSX.Element => <AdEditDateCell {...cellProps} />,
      width: resizingData.getData(210, TraffickingTableName.ads, 'ScheduleStart'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.ScheduleStart],
    },
    {
      id: AdColumn.ScheduleEnd,
      accessor: (ad) => ad.endDate,
      Header: AdColumnHeaders.EndDate,
      isEditable: true,
      Cell: (cellProps: CellProps<AdModel>): JSX.Element => <AdEditDateCell {...cellProps} isAdStartDate={false} />,
      width: resizingData.getData(210, TraffickingTableName.ads, 'ScheduleEnd'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.ScheduleEnd],
    },
    {
      id: AdColumn.LegalApprovedCreative,
      accessor: (ad) => (ad.creative?.metadata?.isLegalApproved ? 'Yes' : ''),
      isEditable: false,
      Header: AdColumnHeaders.LegalApprovedCreative,
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      width: resizingData.getData(225, TraffickingTableName.ads, 'legalApprovedCreative'),
      tooltipMessage: AdsColumnTooltips[AdColumn.LegalApprovedCreative],
    },
    {
      id: AdColumn.DisplayPublisherTarget,
      accessor: 'displayPublisherTarget',
      Header: AdColumnHeaders.Publisher,
      Cell: getPublisherTargetingList,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'DisplayPublisherTarget'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.DisplayPublisherTarget],
    },
    {
      id: AdColumn.ScheduleTimezoneOffset,
      accessor: (a) => ({ timezone: a.schedule?.timezone, deliveryInViewerTime: a.schedule?.deliveryInViewerTime }),
      Header: AdColumnHeaders.TimeZone,
      Cell: TimeZoneCell,
      isEditable: true,
      width: resizingData.getData(310, TraffickingTableName.ads, 'ScheduleTimezoneOffset'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.ScheduleTimezoneOffset],
    },
    {
      id: AdColumn.Rating,
      accessor: (a) => a.rating,
      Header: AdColumnHeaders.AdRating,
      isEditable: true,
      Cell: RatingCell,
      width: resizingData.getData(120, TraffickingTableName.ads, 'rating'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.Rating],
    },
    {
      id: AdColumn.Weight,
      Header: AdColumnHeaders.Weight,
      isEditable: true,
      accessor: 'adRotation',
      Cell: ({ row: { original } }) => (
        <SequenceWeightCell weight={original.adRotation?.weight} lineItemId={original.lineItem?.id || ''} />
      ),
      width: resizingData.getData(140, TraffickingTableName.ads, 'Weight'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.Weight],
    },
    {
      id: AdColumn.ViewabilityVendorList,
      accessor: 'viewabilityVendorList',
      Header: AdColumnHeaders.ViewabilityVendor,
      isEditable: true,
      Cell: ViewabilityVendorCell,
      width: resizingData.getData(220, TraffickingTableName.ads, 'ViewabilityVendorList'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.ViewabilityVendorList],
    },
    {
      id: AdColumn.FrequencyCapList,
      accessor: (a) => a.frequencyCapList,
      isEditable: true,
      Header: AdColumnHeaders.FrequencyCap,
      Cell: AdFrequencyCapCell,
      width: resizingData.getData(385, TraffickingTableName.ads, 'frequencyCapList'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.FrequencyCapList],
    },
    {
      id: AdColumn.CreatedAt,
      accessor: 'createdAt',
      Header: AdColumnHeaders.CreatedDate,
      Cell: stringToDateTimeString,
      width: resizingData.getData(190, TraffickingTableName.ads, 'CreatedAt'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.CreatedAt],
    },
    {
      id: AdColumn.UpdatedAt,
      accessor: 'updatedAt',
      Header: AdColumnHeaders.UpdatedDate,
      Cell: stringToDateTimeString,
      width: resizingData.getData(185, TraffickingTableName.ads, 'UpdatedAt'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.UpdatedAt],
    },
    {
      id: AdColumn.LineItemName,
      accessor: (a) => a.lineItem?.name,
      Header: AdColumnHeaders.LineItemName,
      width: resizingData.getData(675, TraffickingTableName.ads, 'LineItemName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.LineItemName],
    },
    {
      id: AdColumn.LineItemId,
      accessor: (a) => a.lineItem?.id,
      Header: AdColumnHeaders.LineItemId,
      width: resizingData.getData(330, TraffickingTableName.ads, 'lineItemId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.LineItemId],
    },
    {
      id: AdColumn.CampaignName,
      accessor: (a) => a.lineItem?.campaign?.name,
      Header: AdColumnHeaders.CampaignName,
      width: resizingData.getData(440, TraffickingTableName.ads, 'CampaignName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.CampaignName],
    },
    {
      id: AdColumn.CampaignId,
      accessor: (a) => a.lineItem?.campaign?.id,
      Header: AdColumnHeaders.CampaignId,
      width: resizingData.getData(330, TraffickingTableName.ads, 'campaignId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      tooltipMessage: AdsColumnTooltips[AdColumn.CampaignId],
    },
    {
      id: AdColumn.RotationName,
      accessor: (a) => a.adRotation,
      isEditable: true,
      Header: AdColumnHeaders.RotationGroupName,
      Cell: RotationGroupNameCell,
      minWidth: 330,
      width: resizingData.getData(330, TraffickingTableName.ads, 'RotationName'),
      tooltipMessage: AdsColumnTooltips[AdColumn.RotationName],
    },
  ];

  if (envConfig.REACT_APP_FEATURE_ENABLE_CREATE_SEQUENCE === 'true') {
    columns = columns.concat([
      {
        id: AdColumn.SequenceId,
        accessor: (a) => a?.adSequence?.sequenceId,
        Cell: ({ row: { original } }: CellProps<AdModel>) => (
          <SequenceCell
            title={original?.adSequence?.sequenceId}
            parentLineOmsLinkId={original?.lineItem?.parentLineOmsLink?.id}
          />
        ),
        isEditable: true,
        Header: AdColumnHeaders.SequenceId,
        width: resizingData.getData(140, TraffickingTableName.ads, 'SequenceId'),
        minWidth: DEFAULT_COLUMN_MIN_WIDTH,
        tooltipMessage: AdsColumnTooltips[AdColumn.SequenceId],
      },
      {
        id: AdColumn.SequenceName,
        accessor: (a) => a?.adSequence,
        Cell: ({ row: { original } }: CellProps<AdModel>) => (
          <SequenceCell
            title={getAdSequenceName(original?.adSequence)}
            parentLineOmsLinkId={original?.lineItem?.parentLineOmsLink?.id}
          />
        ),
        isEditable: true,
        Header: AdColumnHeaders.SequenceName,
        width: resizingData.getData(170, TraffickingTableName.ads, 'SequenceName'),
        minWidth: DEFAULT_COLUMN_MIN_WIDTH,
        tooltipMessage: AdsColumnTooltips[AdColumn.SequenceName],
      },
      {
        id: AdColumn.SequenceOrder,
        accessor: (a) => a?.adSequence?.order,
        Cell: ({ row: { original } }: CellProps<AdModel>) => (
          <SequenceCell
            title={original?.adSequence?.order}
            parentLineOmsLinkId={original?.lineItem?.parentLineOmsLink?.id}
          />
        ),
        isEditable: true,
        Header: AdColumnHeaders.SequenceOrder,
        width: resizingData.getData(140, TraffickingTableName.ads, 'SequenceOrder'),
        minWidth: DEFAULT_COLUMN_MIN_WIDTH,
        tooltipMessage: AdsColumnTooltips[AdColumn.SequenceOrder],
      },
    ]);
  }

  return columns;
};

export default getAdsColumns;
