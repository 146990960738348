import { useCallback, useState } from 'react';

import { STEPS_COUNT } from '../constants';
import type { UseStepperResponse } from '../types';
import type { DuplicateAdsStepperProviderProps } from '../types';

type Props = Pick<DuplicateAdsStepperProviderProps, 'onCloseStepper'>;

const useStepper = ({ onCloseStepper }: Props): UseStepperResponse => {
  const [step, setStep] = useState<number>(0);

  const goToNextStep = useCallback((): void => {
    if (step < STEPS_COUNT - 1) {
      setStep(step + 1);
    } else {
      onCloseStepper();
    }
  }, [onCloseStepper, step]);

  const goToPrevStep = useCallback((): void => {
    if (step === 0) return;

    setStep(step - 1);
  }, [step]);

  return {
    step,
    goToNextStep,
    goToPrevStep,
    onCloseStepper,
  };
};

export default useStepper;
