import './OptionAssetForm.scss';

import { useFormikContext } from 'formik';
import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';

import type { AdSelectorOption } from '../../../../../apis/graphql';
import type { DropdownOption } from '../../../../Dropdown';
import Dropdown from '../../../../Dropdown';
import CustomMenuWithTooltip from '../../../../Dropdown/CustomMenuWithTooltip';
import CustomOptionWithTooltip from '../../../../Dropdown/CustomOptionWithTooltip';
import { commonCustomComponents } from '../../../../Dropdown/Dropdown';
import { AssetImporterField, TextField } from '../../../../Form';
import Tooltip from '../../../../Tooltip';
import type { AdFormValues } from '../../../adFormik';
import { AdFormCreativeFields } from '../../../adFormik/fields';
import type { AdDimensionsType } from '../../CreativeBuilder/CreativeBuilder';
import {
  AD_SELECTOR_VIDEO_CREATIVE_HELP_TEXT,
  AD_SELECTOR_VIDEO_CREATIVE_LABEL,
  IMAGE_ASPECT_RATIO,
  SEQUENCE_ORDER_DISABLED_TOOLTIP_MESSAGE,
  SEQUENCE_ORDER_ENABLED_TOOLTIP_MESSAGE,
  SEQUENCE_ORDER_HELP_TEXT,
  SEQUENCE_ORDER_LABEL,
  THUMBNAIL_CAPTION_CHARACTERS_RESTRICTION,
  THUMBNAIL_CAPTION_LABEL,
  THUMBNAIL_CAPTION_PLACEHOLDER,
  THUMBNAIL_IMAGE_LABEL,
} from '../constants';
import { useFetchAdSelectorVideoCreativeOptions } from './hooks';
import { getIsOptionUsed, getSelectedCreativeOption, transformCreativeOption } from './utils';

type OptionAssetFormProps = {
  option: AdSelectorOption;
  isReadonly: boolean;
  isPreview: boolean;
  adTypeDimensions?: AdDimensionsType;
};

const [block, element] = bem('option-asset-form');

export const DEFAULT_TOOLTIP_ID = 'tooltip-id';

const OptionAssetForm = ({
  option,
  isReadonly,
  isPreview,
  adTypeDimensions,
}: OptionAssetFormProps): React.JSX.Element => {
  const { index, adSelectorVideoCreativeId } = option;

  // index in the option is +1 compared to the option list
  const indexInOptionList = index - 1;

  const {
    adSelectorVideoCreativeOptions,
    loading: loadingOptions,
    searchTerm,
    handleSearch,
  } = useFetchAdSelectorVideoCreativeOptions();

  const {
    values: { creative },
    errors,
    setFieldValue,
  } = useFormikContext<AdFormValues>();

  // if option is used in another tab, we disable it from selection and show the tooltip
  // if option is used in this tab, we still allow user to de-select it
  const uniqueAdSelectorVideoCreativeOptions = useMemo((): DropdownOption[] => {
    return adSelectorVideoCreativeOptions.map((creativeOption) =>
      transformCreativeOption(
        creativeOption,
        getIsOptionUsed(adSelectorVideoCreativeId, creativeOption?.id, creative.optionList)
      )
    );
  }, [adSelectorVideoCreativeOptions, creative.optionList, adSelectorVideoCreativeId]);

  const selectedAdSelectorVideoIdOption = useMemo(
    (): DropdownOption | null =>
      getSelectedCreativeOption(uniqueAdSelectorVideoCreativeOptions, indexInOptionList, creative.optionList),
    [creative.optionList, indexInOptionList, uniqueAdSelectorVideoCreativeOptions]
  );

  const handleSelectChange = (value: DropdownOption<string> | null): void => {
    // allows to de-select value
    const updateValue =
      creative.optionList && creative.optionList[indexInOptionList].adSelectorVideoCreativeId === value?.id
        ? ''
        : value?.id;

    setFieldValue(`${AdFormCreativeFields.OptionList}[${indexInOptionList}].adSelectorVideoCreativeId`, updateValue);
  };

  return (
    <div className={block()}>
      <AssetImporterField
        adTypeDimensions={adTypeDimensions?.option?.display}
        assetType="DISPLAY"
        id="display.thumbnail"
        formFieldName={`${AdFormCreativeFields.OptionList}[${indexInOptionList}].thumbnail`}
        aspectRatio={IMAGE_ASPECT_RATIO}
        label={THUMBNAIL_IMAGE_LABEL}
        isPreview={isPreview}
        readonly={isReadonly}
      />
      <div className={element('side-block')}>
        <TextField
          id={`caption.${indexInOptionList}`}
          data-testid="caption-text-field"
          formFieldName={`${AdFormCreativeFields.OptionList}[${indexInOptionList}].caption`}
          label={THUMBNAIL_CAPTION_LABEL}
          disabled={isReadonly}
          placeholder={THUMBNAIL_CAPTION_PLACEHOLDER}
          maxLength={THUMBNAIL_CAPTION_CHARACTERS_RESTRICTION}
        />
        <Dropdown<string>
          value={selectedAdSelectorVideoIdOption}
          options={uniqueAdSelectorVideoCreativeOptions}
          onChange={handleSelectChange}
          inputValue={searchTerm}
          isDisabled={isReadonly}
          isLoading={loadingOptions}
          tooltip={{
            id: DEFAULT_TOOLTIP_ID,
            effect: 'solid',
          }}
          components={{ ...commonCustomComponents, Option: CustomOptionWithTooltip, Menu: CustomMenuWithTooltip }}
          onInputChange={handleSearch}
          errMsg={
            errors[
              `${AdFormCreativeFields.OptionList}[${indexInOptionList}].adSelectorVideoCreativeId` as keyof AdFormValues
            ] as string
          }
          label={AD_SELECTOR_VIDEO_CREATIVE_LABEL}
          help={AD_SELECTOR_VIDEO_CREATIVE_HELP_TEXT}
        />
        <Tooltip
          id={`sequenceOrder.${indexInOptionList}`}
          message={
            creative.randomOrder ? SEQUENCE_ORDER_DISABLED_TOOLTIP_MESSAGE : SEQUENCE_ORDER_ENABLED_TOOLTIP_MESSAGE
          }
        >
          <TextField
            className={element('random-order')}
            data-testid="sequence-order-text-field"
            id={`sequenceOrder.${indexInOptionList}`}
            formFieldName={`${AdFormCreativeFields.OptionList}[${indexInOptionList}].index`}
            label={SEQUENCE_ORDER_LABEL}
            disabled={true}
            help={SEQUENCE_ORDER_HELP_TEXT}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default React.memo(OptionAssetForm);
