import './Targeting.scss';

import TextViewer from '@ad-platform/tm-rule-text-viewer';
import { SearchInput } from '@hulu/react-style-components';
import React, { useMemo, useState } from 'react';

import { useFieldArray } from '../../../common/Form/hooks';
import TargetingDropdowns from '../../../common/TargetingDropdowns';
import type { TargetingTableRowGroup } from '../../../common/TargetingTable/TargetingTable';
import TargetingTable from '../../../common/TargetingTable/TargetingTable';
import type { TargetingTermValue } from '../../../models';
import {
  addCountryCode,
  filterOutCountryTarget,
  getCountryTargetLineItem,
  getPublisherTargetLineItem,
} from '../../../pages/Trafficking/DrawerTargetingTable/utils/utils';
import bem from '../../../utils/bem';
import { DEFAULT_DEBOUNCE_TIMEOUT } from '../../hooks/constants';
import useDebounceFilter from '../../hooks/useDebounceFilter';
import TargetingViewButton from '../TargetingViewButton';
import { filterTargetingTermValuesAsSet } from './util/filterTargetingTerms';
// using commonjs require as tslint breaks for launch-darkly
const { useFlags, withLDConsumer } = require('launchdarkly-react-client-sdk');

const [block, element] = bem('targeting');

export type TargetingProps = {
  lineItemTargetingTermValues: TargetingTermValue[];
  publisher: string;
  readonly?: boolean;
  countryCode?: string;
  publisherIds?: string[];
};

function Targeting({
  lineItemTargetingTermValues,
  publisher,
  readonly,
  countryCode,
  publisherIds,
}: TargetingProps): JSX.Element {
  const [, { value: adTargetingTermValues, error }, listHelpers] = useFieldArray<TargetingTermValue>(
    'targetingTermValues'
  );
  const { filterValue, debouncedFilterValue, onChange, onClear } = useDebounceFilter(DEFAULT_DEBOUNCE_TIMEOUT);

  const data: TargetingTableRowGroup[] = useMemo(
    (): TargetingTableRowGroup[] => [
      {
        groupType: 'Ad Targeting',
        targetingTermValues: adTargetingTermValues,
      },
      {
        groupType: 'Line Item Targeting',
        targetingTermValues: filterOutCountryTarget({ value: lineItemTargetingTermValues }) ?? [],
      },
    ],
    [adTargetingTermValues, lineItemTargetingTermValues]
  );

  // ids in this set should be displayed in the table
  const filteredTargetingValues: Set<string> = useMemo(
    () =>
      filterTargetingTermValuesAsSet({
        searchTerm: debouncedFilterValue,
        targetingTermValues: [...adTargetingTermValues, ...lineItemTargetingTermValues],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedFilterValue, adTargetingTermValues?.length, lineItemTargetingTermValues?.length]
  );

  const flags = useFlags();

  const [textView, setTextView] = useState(false);
  let countryLineItem = getCountryTargetLineItem({ value: lineItemTargetingTermValues });
  countryLineItem = addCountryCode(countryLineItem, countryCode);

  return (
    <div className={block()}>
      <div className={element('header')}>
        <div>
          <p className={element('country')}>Country: {countryLineItem?.value.name}</p>
          <p className={element('publisher')}>Publisher: {publisher}</p>
        </div>
        {flags.targetingTextViewRelease && (
          <div className={element('view')}>
            <TargetingViewButton isActive={textView} setActive={setTextView} />
          </div>
        )}
      </div>
      {!flags.targetingTextViewRelease || !textView ? (
        <div>
          <TargetingDropdowns
            listHelpers={listHelpers}
            targetings={adTargetingTermValues}
            readonly={readonly}
            validationError={error}
            countryOption={countryLineItem || undefined}
            publisherOption={getPublisherTargetLineItem(publisherIds || []) || undefined}
          />
          <SearchInput
            className={element('targeting-filter')}
            placeholder="Search Targeted Values"
            onInput={onChange}
            onClear={onClear}
            value={filterValue}
          />
          <TargetingTable
            data={data}
            listHelpers={listHelpers}
            editMode={!readonly}
            isAd
            isReadOnly
            isTableToggleable
            isTableDataFiltered={!!debouncedFilterValue?.length}
            filteredTargetingValues={filteredTargetingValues}
          />
        </div>
      ) : (
        <p className={element('placeholder-text')}>
          This area is under construction.
          <br />
          <TextViewer rule={{ placeholder: 'rule text view' }} />
        </p>
      )}
    </div>
  );
}

export default withLDConsumer()(Targeting);
