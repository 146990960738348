import { bem } from 'mission-control-common-components';
import React from 'react';

import type { CoppaViewabilityVendor, ViewabilityVendor } from '../../../../configs';
import { coppaApprovedViewabilityVendors, viewabilityVendors } from '../../../../configs';
import { MultiSelectDropdown } from '../../../Form';
import { useViewabilityVendors, VIEWABILITY_VENDORS_LIST_FIELD_NAME } from '../hooks';
import IASCampaignLineItemIdField from '../IASCampaignLineItemIdField';

const [viewabilityVendorBlock] = bem('viewability-vendor');

type ViewabilityVendorsSectionProps = {
  readonly?: boolean;
  isCoppaOrCaru?: boolean;
};

const ViewabilityVendorsSection = ({ readonly, isCoppaOrCaru }: ViewabilityVendorsSectionProps): React.JSX.Element => {
  const {
    handleChangeViewabilityVendor,
    handleChangeIASCampaignLineItemIdValue,
    isIASSet,
    iasCampaignLineItemIdValue,
  } = useViewabilityVendors();

  return (
    <div className={viewabilityVendorBlock()}>
      {isCoppaOrCaru ? (
        <MultiSelectDropdown<CoppaViewabilityVendor>
          label="Viewability Vendor"
          formFieldName={VIEWABILITY_VENDORS_LIST_FIELD_NAME}
          options={coppaApprovedViewabilityVendors}
          isDisabled={readonly}
          handleChange={handleChangeViewabilityVendor}
        />
      ) : (
        <MultiSelectDropdown<ViewabilityVendor>
          label="Viewability Vendor"
          formFieldName={VIEWABILITY_VENDORS_LIST_FIELD_NAME}
          options={viewabilityVendors}
          isDisabled={readonly}
          handleChange={handleChangeViewabilityVendor}
        />
      )}
      {isIASSet && (
        <IASCampaignLineItemIdField
          value={iasCampaignLineItemIdValue}
          isDisabled={readonly}
          handleChange={handleChangeIASCampaignLineItemIdValue}
        />
      )}
    </div>
  );
};

export default ViewabilityVendorsSection;
