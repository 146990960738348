import { IconClipboardBlank } from '@hulu-react-style-components/icons';
import { Button as TrekLink } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import ReactTooltip from 'react-tooltip';
import { useEffectOnce } from 'react-use';

import Tooltip from '../../../Tooltip';

const [, element] = bem('creative-rejection-reasons-modal');

const REASONS_FOR_REJECTION_LABEL = 'Reasons for Rejection';
const CLIPBOARD_TOOLTIP_MESSAGE = 'Copy rejection reasons';

type Props = {
  copyText: () => Promise<void>;
};

const CreativeRejectionReasonsModalTitle = ({ copyText }: Props): React.JSX.Element => {
  useEffectOnce(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className={element('modal-title-wrapper')}>
      <div className={element('modal-title')}>{REASONS_FOR_REJECTION_LABEL}</div>
      <div className={element('clipboard-wrapper')}>
        {/*If clipboard is undefined, we don't show the copy button*/}
        {navigator.clipboard && (
          <Tooltip id="clipboard-tooltip" message={CLIPBOARD_TOOLTIP_MESSAGE} shouldRenderInPortal={false}>
            <div data-testid="copy-to-clipboard" onClick={copyText} className={element('clipboard-button')}>
              <TrekLink>
                <IconClipboardBlank />
              </TrekLink>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default CreativeRejectionReasonsModalTitle;
