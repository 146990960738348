import './SetAdSectionsStep.scss';

import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';

import Alert, { AlertType } from '../../../../../../common/Alert';
import Loader from '../../../../../../common/Loader';
import { useDuplicateAdsStepperContext } from '../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import type { DuplicateAdTab } from '../../DuplicateAdsTabs/DuplicateAdsTabs';
import DuplicateAdsTabs from '../../DuplicateAdsTabs/DuplicateAdsTabs';
import MultipleDuplicateAdsContentWrapper from '../../MultipleDuplicateAdsContentWrapper/MultipleDuplicateAdsContentWrapper';
import {
  AD_SECTIONS_ERROR_TEXT,
  AD_SECTIONS_SUBTITLE_TEXT,
  AD_SECTIONS_TITLE_TEXT,
  CONFIRM_LABEL,
  DUPLICATE_ADS_TITLE,
  ERROR_ICON_TOOLTIP_TEXT,
  SAVE_BUTTON_TOOLTIP_TEXT,
} from '../constants';
import MultipleDuplicateAdsStepperHeader from '../MultipleDuplicateAdsStepperHeader/MultipleDuplicateAdsStepperHeader';
import AdSectionsForm from './AdSectionsForm/AdSectionsForm';
import { useDuplicateAdFormValues, useSaveAds } from './hooks';

const [block, element] = bem('set-ad-sections-step');

const SetAdSectionsStep = (): React.JSX.Element => {
  const { duplicateAdFromValues, loading: loadingDuplicateAds, error } = useDuplicateAdFormValues();
  const { selectedLineItems, onCloseStepper } = useDuplicateAdsStepperContext();
  const { saveAds, loading: loadingSaveAds } = useSaveAds();

  const isSaveAdsDisabled = useMemo(
    () => !duplicateAdFromValues.every(({ isVisited }) => isVisited) || loadingSaveAds || loadingDuplicateAds,
    [duplicateAdFromValues, loadingDuplicateAds, loadingSaveAds]
  );

  const tabs = useMemo(
    () =>
      duplicateAdFromValues.map(
        (value): DuplicateAdTab => {
          return {
            id: value.id!,
            name: value.name,
            showCheck: true,
            component: (
              <AdSectionsForm
                duplicateAdFormValue={value}
                allFormValues={duplicateAdFromValues}
                lineItem={value.lineItem}
                lineItemTargetingTermValues={value.lineItemTargetingTermValues}
                loading={loadingSaveAds}
              />
            ),
            errorTooltipText: ERROR_ICON_TOOLTIP_TEXT,
            isComplete: value.isVisited,
            isFailed: !!value.isFailed,
          };
        }
      ),
    [duplicateAdFromValues, loadingSaveAds]
  );

  return (
    <div className={block()}>
      <MultipleDuplicateAdsStepperHeader
        confirmButtonDisabled={isSaveAdsDisabled}
        tooltipText={SAVE_BUTTON_TOOLTIP_TEXT}
        loading={loadingSaveAds}
        title={DUPLICATE_ADS_TITLE}
        onNext={(): Promise<void> => saveAds(selectedLineItems, duplicateAdFromValues, onCloseStepper)}
        confirmText={CONFIRM_LABEL}
      />
      <MultipleDuplicateAdsContentWrapper>
        <div className={element('title')}>{AD_SECTIONS_TITLE_TEXT}</div>
        <div className={element('subtitle')}>{AD_SECTIONS_SUBTITLE_TEXT}</div>
        {!loadingDuplicateAds && (!tabs.length || error) && (
          <Alert className={element('error')} type={AlertType.ERROR} bodyText={AD_SECTIONS_ERROR_TEXT} />
        )}
        {loadingDuplicateAds ? <Loader /> : <DuplicateAdsTabs tabs={tabs} />}
      </MultipleDuplicateAdsContentWrapper>
    </div>
  );
};

export default SetAdSectionsStep;
