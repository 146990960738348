import React from 'react';

import { useDuplicateAdsStepperContext } from '../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import SelectLineItemsStep from './SelectLineItemsStep/SelectLineItemsStep';
import SetAdNamesStep from './SetAdNamesStep/SetAdNamesStep';
import SetAdSectionsStep from './SetAdSectionsStep/SetAdSectionsStep';

const MultipleDuplicateAdsStepper = (): React.JSX.Element => {
  const { step } = useDuplicateAdsStepperContext();

  switch (step) {
    case 0:
      return <SelectLineItemsStep />;
    case 1:
      return <SetAdNamesStep />;
    case 2:
      return <SetAdSectionsStep />;
    default:
      return <></>;
  }
};

export default MultipleDuplicateAdsStepper;
