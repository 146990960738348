import './InventoryBlockForm.scss';

import { SearchInput } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import React, { useCallback, useMemo, useState } from 'react';

import type { ExclusiveBlock } from '../../models/exclusiveBlock';
import { filterTargetingTermValues } from '../AdForm/Targeting/util/filterTargetingTerms';
import TargetingTable from '../TargetingTable';

type Props = {
  exclusiveBlock: ExclusiveBlock;
};

const [block, element] = bem('inventory-block-form');

const INDUSTRY_TITLE = 'Industry';
const TARGETING_TITLE = 'Targeting';
const SEARCH_INPUT_PLACEHOLDER = 'Search by movie, series, etc.';
const NO_BLOCKED_INDUSTRIES_TITLE = 'No blocked industries added';

// We need this to be able to see, if value is on or off.
// According to API, we need to props list helpers to see toggles.
// All values are readonly, so we do not need any real helpers.
const DEFAULT_LIST_HELPERS = {
  removeAt: (): void => {},
  replaceAt: (): void => {},
  removeAll: (): void => {},
};

const InventoryBlockForm = ({ exclusiveBlock }: Props): React.JSX.Element => {
  const { name, targetingTermValues, blockedIndustryList } = exclusiveBlock;
  const [searchTerm, setSearchTerm] = useState('');

  const inventoryBlockTitle = useMemo(() => {
    return `Inventory Block "${name}" is being applied`;
  }, [name]);

  const filteredTargetingTermValues = useMemo(
    () => filterTargetingTermValues({ searchTerm, targetingTermValues: targetingTermValues ?? [] }),
    [searchTerm, targetingTermValues]
  );

  const onClearSearch = useCallback(() => setSearchTerm(''), []);

  return (
    <div className={block()} data-testid="inventory-block-form">
      <div className={element('title')}>{inventoryBlockTitle}</div>
      <div className={element('industry-title')}>
        <div className={element('text')}>{INDUSTRY_TITLE}</div>
        <SearchInput
          placeholder={SEARCH_INPUT_PLACEHOLDER}
          value={searchTerm}
          onInput={setSearchTerm}
          onClear={onClearSearch}
        />
      </div>
      <div className={element('industry-section')}>
        {blockedIndustryList?.length ? (
          blockedIndustryList.map(({ name, id }) => (
            <div key={id} className={element('industry-pill')}>
              {name}
            </div>
          ))
        ) : (
          <div className={element('industry-empty')}>
            <span>{NO_BLOCKED_INDUSTRIES_TITLE}</span>
          </div>
        )}
      </div>
      <div className={element('targeting-section')}>
        <div className={element('text')}>{TARGETING_TITLE}</div>
        <TargetingTable
          data={[{ targetingTermValues: filteredTargetingTermValues }]}
          isReadOnly={true}
          isTableToggleable={false}
          editMode={false}
          listHelpers={DEFAULT_LIST_HELPERS}
        />
      </div>
    </div>
  );
};

export default InventoryBlockForm;
