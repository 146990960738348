import { IconShare } from '@hulu-react-style-components/icons';
import envConfig from '@hulu/env-config';
import { AssetDownloadButton } from 'mission-control-common-components';
import React from 'react';

import type { SpReviewV5, VideoAssetV5 } from '../../../../../apis/graphql';
import { stringifyTagList } from '../../../../../utils/formatting';
import AssetRejectionReasonsModal from '../../../../StatusModal/AssetRejectionReasonsModal/AssetRejectionReasonsModal';
import { resourcePaths } from '../../../constants';
import type { AssetExtended } from '../../../types/extended';
import { bytesToLabel, msToTime } from '../../../util/formatters';
import { ReviewStatus } from '../../atoms/AssetStatusPill/constants';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { StatusModalWithPill } from './atoms/StatusModalWithPill/StatusModalWithPill';
import { Card, FileData, FileDetails, FileDownload, FileName, IconAnchor, MetaDataTable, Preview } from './styles';
import { RejectionReasonsActivator } from './styles/RejectionReasonsActivator';

export type Props = {
  asset: AssetExtended;
  creativeId: string;
  spReview?: SpReviewV5 | null;
};

const ID_LABEL = 'ID';
const CREATED_LABEL = 'Created';
const LAST_SEE_LABEL = 'Last Seen';
const ASSET_TAG_LABEL = 'Asset Tag';
const SEE_REJECTION_REASONS_LABEL = 'See Rejection Reason';

export const AssetPreviewCard: React.FC<Props> = ({ asset, creativeId }) => {
  const { type, review, s3Url, filename, fileSize, id, createdAt, updatedAt, assetTagList } = asset;

  const isVideo = type === 'VIDEO';

  const downloadUrl = `${envConfig.REACT_APP_CAMPAIGN_GRAPHQL_ORIGIN}/api/asset/${id}`;
  const downloadFilename = filename || '';
  const acceptableUrl =
    creativeId && id ? `${resourcePaths.ASSET_REVIEW_ASSET}?creative-id=${creativeId}&asset-id=${id}` : '';

  const previewComponent = s3Url ? (
    <div data-testid="preview-component">
      <Preview imageUrl={s3Url}>
        <StatusModalWithPill asset={asset} />
      </Preview>
    </div>
  ) : null;

  return (
    <Card published={review === ReviewStatus.PUBLISHED}>
      {isVideo ? (
        <VideoPlayer asset={asset as VideoAssetV5}>
          <StatusModalWithPill asset={asset} />
        </VideoPlayer>
      ) : (
        previewComponent
      )}
      <FileData>
        <FileName>{isVideo && filename}</FileName>
        <FileDetails style={{ display: 'flex' }}>
          {isVideo && (
            <>
              <span>{msToTime((asset as VideoAssetV5).duration)}</span>
              <span>{bytesToLabel(fileSize)}</span>
            </>
          )}
          <FileDownload>
            <AssetDownloadButton filename={downloadFilename} url={downloadUrl} />
          </FileDownload>
        </FileDetails>
      </FileData>
      <MetaDataTable>
        <tbody>
          <tr>
            <th>{ID_LABEL}</th>
            <td>
              {id}{' '}
              {acceptableUrl && (
                <IconAnchor href={acceptableUrl} target="_blank">
                  <IconShare />
                </IconAnchor>
              )}
            </td>
          </tr>
          <tr>
            <th>{CREATED_LABEL}</th>
            <td>{createdAt}</td>
          </tr>
          <tr>
            <th>{LAST_SEE_LABEL}</th>
            <td>{updatedAt}</td>
          </tr>
          <tr>
            <th>{ASSET_TAG_LABEL}</th>
            <td>{stringifyTagList(assetTagList ?? [])}</td>
          </tr>
        </tbody>
      </MetaDataTable>
      {review === 'REJECTED' && asset.rejectionReasons && (
        <AssetRejectionReasonsModal
          asset={asset}
          customActivator={
            <RejectionReasonsActivator data-testid="rejection-reason-button">
              {`${SEE_REJECTION_REASONS_LABEL}${asset.rejectionReasons.length > 1 ? 's' : ''}`}
            </RejectionReasonsActivator>
          }
        />
      )}
    </Card>
  );
};
