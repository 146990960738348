import './DuplicateAdsTabs.scss';

import { IconAlertErrorRed, IconAlertWarningYellow, IconCheck } from '@hulu-react-style-components/icons';
import { bem } from 'mission-control-common-components';

import TaskTabNavigation from '../../../../../common/TaskTabNavigation';
import Tooltip from '../../../../../common/Tooltip';

const { TaskTab } = TaskTabNavigation;

const [block, element] = bem('duplicate-ads-tabs');

export type DuplicateAdTab = {
  id: string;
  name: string;
  isComplete?: boolean;
  isFailed?: boolean;
  showCheck?: boolean;
  component: React.JSX.Element;
  errorTooltipText?: string;
};

type Props = {
  tabs: DuplicateAdTab[];
};

const DuplicateAdsTabs = ({ tabs }: Props): React.JSX.Element => {
  return (
    <div className={block()} data-testid="duplicate-ads-tabs">
      <TaskTabNavigation maxChildren={tabs.length} className={element('tabs-wrapper')}>
        {tabs.map(({ id, name, isComplete, showCheck, component, isFailed, errorTooltipText }) => {
          const errorIcon = errorTooltipText ? (
            <div className={element('icon-tooltip')}>
              <Tooltip id={`error-icon-tooltip-${id}`} message={errorTooltipText} place="top">
                <IconAlertErrorRed data-testid="duplicate-ads-tab-error-state" />
              </Tooltip>
            </div>
          ) : (
            <IconAlertErrorRed data-testid="duplicate-ads-tab-error-state" />
          );

          return (
            <TaskTab
              title={name}
              isComplete={!!isComplete}
              showCheck={!!showCheck}
              isFailed={!!isFailed}
              tooltip={name}
              tooltipId={id}
              iconComplete={
                <IconCheck data-testid="duplicate-ads-tab-complete-state" className={element('green-icon')} />
              }
              iconIncomplete={<IconAlertWarningYellow data-testid="duplicate-ads-tab-warning-state" />}
              iconFailed={errorIcon}
              key={id}
            >
              {component}
            </TaskTab>
          );
        })}
      </TaskTabNavigation>
    </div>
  );
};

export default DuplicateAdsTabs;
