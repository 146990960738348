import './InstreamDxcCreativeBuilder.scss';

import { Tab, TabNavigation } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import { orderBy as _orderBy } from 'lodash';
import { bem } from 'mission-control-common-components';
import { useMemo } from 'react';

import type { AssetExtended } from '../../../_ForCommonLibrary/types/extended';
import type { DisplayAssetV5, TextAssetV5 } from '../../../../models';
import { isDisplayAsset, isTextAsset, isVideoAsset } from '../../../../utils/assets';
import Button from '../../../Button';
import Loader from '../../../Loader';
import type { AdFormValues } from '../../adFormik';
import { getExperiencePreviewLink } from '../../utils/previewLink';
import type { CreativeBuilderProps } from '../CreativeBuilder';
import CreativeBuilder from '../CreativeBuilder';
import { useCreativeData } from '../hooks/useCreativeData';
import { CreativeEditMode } from '../types';
import AssetSubTab from './AssetSubTab';
import { PREVIEW_IN_DXC_TITLE } from './constants';
import { useInstreamDxcTabs } from './hooks';
import InstreamDxcCreativeFooter from './InstreamDxcCreativeFooter';

const [block, element] = bem('instream-dxc-builder');

const CLASS_NAME_MODIFIER = 'instream-dxc';

const InstreamDxcCreativeBuilder = ({ editMode }: CreativeBuilderProps): React.JSX.Element => {
  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();
  const { data, loading: loadingCreativeData } = useCreativeData(creative);

  const sortedAssets = useMemo(() => _orderBy(data.assets, ['id', 'name'], 'desc'), [data.assets]);

  const categorizedAssets = useMemo(() => {
    const displayAssets: DisplayAssetV5[] = [];
    const videoAssets: AssetExtended[] = [];
    const textAssets: TextAssetV5[] = [];

    sortedAssets.forEach((asset) => {
      if (isDisplayAsset(asset)) {
        displayAssets.push(asset);
      } else if (isVideoAsset(asset)) {
        videoAssets.push(asset);
      } else if (isTextAsset(asset)) {
        textAssets.push(asset);
      }
    });

    return {
      displayAssets,
      videoAssets,
      textAssets,
    };
  }, [sortedAssets]);

  const { selectedTab, tabs, handleTabClick } = useInstreamDxcTabs({
    videoAssets: categorizedAssets.videoAssets,
    displayAssets: categorizedAssets.displayAssets,
    textAssets: categorizedAssets.textAssets,
    creativeId: creative.id,
  });

  const handleClickOnExperienceLink = (): void => {
    if (creative.experienceId) window.open(getExperiencePreviewLink(creative.experienceId), '_blank');
  };

  const convertedEditMode = editMode === CreativeEditMode.readonly ? editMode : CreativeEditMode.partly;

  return (
    <CreativeBuilder classNameModifier={CLASS_NAME_MODIFIER} editMode={convertedEditMode}>
      <div className={block()} data-testid="dxc-template">
        {loadingCreativeData ? (
          <div data-testid="assets-loader" className={element('loader')}>
            <Loader />
          </div>
        ) : (
          <div data-testid="asset-tabs" className={element('asset-tabs')}>
            <TabNavigation selectedTab={selectedTab} onTabClick={handleTabClick}>
              {tabs.map(
                ({ key, title, element: componentElement, tabIndex, itemProps, previewElement, emptyMessage }) => (
                  <Tab key={key} title={title} element={componentElement} tabIndex={tabIndex}>
                    <AssetSubTab emptyMessage={emptyMessage} itemsProps={itemProps} previewElement={previewElement} />
                  </Tab>
                )
              )}
            </TabNavigation>
          </div>
        )}
      </div>
      <InstreamDxcCreativeFooter
        assets={sortedAssets}
        creative={creative}
        rightSideComponent={
          <Button
            variant="outlined"
            data-testid="experience-preview-link"
            onClick={handleClickOnExperienceLink}
            disabled={!creative.experienceId}
            tabIndex={0}
            aria-label="experience-preview-link"
            className={element('preview-button')}
          >
            {PREVIEW_IN_DXC_TITLE}
          </Button>
        }
      />
    </CreativeBuilder>
  );
};

export default InstreamDxcCreativeBuilder;
