import { cloneDeep } from 'lodash';
import type React from 'react';
import type { RefObject } from 'react';
import { useCallback, useState } from 'react';
import type { ColumnInstance, IdType } from 'react-table';

import type { TraffickingTableName } from '../../../constants';
import { SELECTION_COLUMN_ID } from '../../../pages/Trafficking/TraffickingPage/hooks/constants';
import type { EntityModel } from '../../../pages/Trafficking/TraffickingPage/modelConverters';
import { draggedElementId } from '../constants';
import { orderData } from '../utils';
import { SCROLL_BY, SCROLL_THRESHOLD } from './constants';

type Props = {
  allColumns: ColumnInstance<EntityModel>[];
  tableName: TraffickingTableName;
  setColumnOrder: (
    updater: ((columnOrder: IdType<EntityModel>[]) => IdType<EntityModel>[]) | IdType<EntityModel>[]
  ) => void;
};

export type DragAndDropColumns = {
  handleDragEnter: (rowIndex: number) => void;
  handleDragStart: (event: React.DragEvent, rowIndex: number) => void;
  handleDragOver: (event: React.DragEvent<HTMLTableCellElement>) => void;
  handleDrop: (dropIndex: number) => void;
  handleDragEnd: () => void;
  handleDrag: (event: React.DragEvent) => void;
  draggedOverColumnId: number | null;
  draggedColumnIndex: number | null;
};

type DragPosition = {
  x: number;
  y: number;
};

export type DragAndDropResult = DragAndDropColumns & {
  draggingColumn: ColumnInstance<EntityModel> | null;
  dragPosition: DragPosition | null;
  handleDragOverContainer: (event: React.DragEvent, scrollRef: RefObject<HTMLDivElement>) => void;
};

const useDragAndDropColumns = ({ allColumns, tableName, setColumnOrder }: Props): DragAndDropResult => {
  const [draggedColumnIndex, setDraggedColumnIndex] = useState<number | null>(null);
  const [draggedOverColumnId, setDraggedOverColumnId] = useState<number | null>(null);
  const [dragPosition, setDragPosition] = useState<DragPosition | null>(null);
  const [draggingColumn, setDraggingColumn] = useState<ColumnInstance<EntityModel> | null>(null);

  const handleDragEnter = useCallback((rowIndex: number): void => {
    setDraggedOverColumnId(rowIndex);
  }, []);

  const handleDragStart = useCallback(
    (event: React.DragEvent, rowIndex: number): void => {
      setDraggedColumnIndex(rowIndex);
      const visibleColumns = allColumns.filter((column) => column.isVisible);

      const draggableColumnFromCurrentColumns = visibleColumns.find((column, index) => index === rowIndex);
      setDraggingColumn(draggableColumnFromCurrentColumns || null);
      let dragImage = document.getElementById(draggedElementId);

      if (dragImage) {
        event.dataTransfer.effectAllowed = 'copyMove';
        event.dataTransfer.setDragImage(dragImage, 0, 0);
      }
    },
    [allColumns]
  );

  const handleDrag = useCallback((event: React.DragEvent) => {
    if (event.clientX === 0 && event.clientY === 0) return;
    setDragPosition({
      x: event.clientX,
      y: event.clientY,
    });
  }, []);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLTableCellElement>): void => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (dropIndex: number): void => {
      if (draggedColumnIndex === null || draggedColumnIndex === dropIndex) return;

      const newColumns = cloneDeep(allColumns);
      const visibleColumns = allColumns.filter((column) => column.isVisible);

      const draggableColumnFromCurrentColumns = visibleColumns.find((column, index) => index === draggedColumnIndex);
      const dropColumnFromCurrentColumns = visibleColumns.find((column, index) => index === dropIndex);

      if (!draggableColumnFromCurrentColumns || !dropColumnFromCurrentColumns) return;

      let allColumnsDragColumnIndex = -1;
      const draggableColumn = newColumns.find((column, index) => {
        if (column.id === draggableColumnFromCurrentColumns.id) {
          allColumnsDragColumnIndex = index;

          return true;
        }

        return false;
      });

      let allColumnsDropColumnIndex = -1;
      const dropColumn = newColumns.find((column, index) => {
        if (column.id === dropColumnFromCurrentColumns.id) {
          allColumnsDropColumnIndex = index;

          return true;
        }

        return false;
      });

      if (!draggableColumn || !dropColumn) return;

      newColumns.splice(allColumnsDragColumnIndex, 1);
      newColumns.splice(allColumnsDropColumnIndex, 0, draggableColumn);

      const columnsIds = [SELECTION_COLUMN_ID, ...newColumns.map((column) => column.id)];
      setColumnOrder(columnsIds);
      orderData.setStorageData(tableName, columnsIds);
    },
    [allColumns, draggedColumnIndex, setColumnOrder, tableName]
  );

  const handleDragEnd = useCallback(() => {
    setDraggedColumnIndex(null);
    setDraggedOverColumnId(null);
    setDraggingColumn(null);
    setDragPosition(null);
  }, []);

  const handleDragOverContainer = useCallback((event: React.DragEvent, scrollRef: RefObject<HTMLDivElement>) => {
    event.preventDefault();

    const container = scrollRef.current;
    if (!container) return;

    const { clientX } = event;
    const rect = container.getBoundingClientRect();

    if (clientX < rect.left + SCROLL_THRESHOLD) {
      container.scrollBy({ left: -SCROLL_BY });
    } else if (clientX > rect.right - SCROLL_THRESHOLD) {
      container.scrollBy({ left: SCROLL_BY });
    }
  }, []);

  return {
    handleDragEnter,
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    draggedOverColumnId,
    handleDrag,
    draggingColumn,
    dragPosition,
    handleDragOverContainer,
    draggedColumnIndex,
  };
};

export default useDragAndDropColumns;
