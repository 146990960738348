import './MultipleDuplicateAdsContentWrapper.scss';

import { bem } from 'mission-control-common-components';

type Props = {
  children: React.ReactNode;
};

const [block] = bem('multiple-duplicate-ads-content');

const MultipleDuplicateAdsContentWrapper = ({ children }: Props): React.JSX.Element => {
  return <div className={block()}>{children}</div>;
};

export default MultipleDuplicateAdsContentWrapper;
