import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION } from '../../../../apis/graphql';
import { ADS_PAGE_AD } from '../../../../common/AdForm/hooks/fragments';

// Data for Create Ads Page
export const GET_SAVED_ADS = gql`
  query getSavedAds($lineItemIds: [ID!]!, $lineItemId: String!) {
    lineItems: lineItemsV5(filterOptions: { idList: $lineItemIds }) {
      edges {
        node {
          schedule {
            timezone
            dateRangeList {
              startDate
              endDate
              pacingShare
            }
          }
          targetingRule {
            ...targetingRuleDefinition
          }
          isCoppaOrCaru
          startDate
          endDate
          displayPublisherTarget
          metrics {
            completions
          }
          metadata {
            actualStartDate
          }
          draxDeal {
            dealId
          }
          campaign {
            name
            id
            campaignTagList {
              id
              type
              url
            }
            startDate
          }
        }
      }
    }
    ads: adsV5(filterOptions: { lineItemIdList: $lineItemIds }) {
      edges {
        node {
          ...adsPageAd
        }
      }
    }
    getTargetingTermValuesByLineItemIDV5(lineItemId: $lineItemId) {
      category {
        id
        name
        displayName
      }
      dimension {
        id
        name
        displayName
      }
      value {
        id
        name
        displayName
      }
      include
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;
