import type { ChangeEvent } from 'react';
import React, { memo, useCallback, useMemo } from 'react';
import type { CellProps, Column } from 'react-table';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';

import { useDuplicateAdsStepperContext } from '../../../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import type { DuplicateLineItem } from '../../../../DuplicateAdsStepperProvider/types';
import { EMPTY_LINE_ITEM_SELECTION_MESSAGE, sharedTableColumns } from '../constants';
import DuplicateLineItemsTable from '../DuplicateLineItemsTable/DuplicateLineItemsTable';
import CheckboxLineItemBasicCell from './CheckboxLineItemBasicCell/CheckboxLineItemBasicCell';
import CheckboxLineItemHeaderCell from './CheckboxLineItemHeaderCell/CheckboxLineItemHeaderCell';
import DeleteSavedLineItemCell from './DeleteSavedLineItemCell/DeleteSavedLineItemCell';

const DuplicateLineItemsSavedTable = (): JSX.Element => {
  const { lineItems, handleUpdateLineItemsSelection, handleRemoveLineItem } = useDuplicateAdsStepperContext();

  const onChangeLineItemSelection = useCallback(
    (e: ChangeEvent<HTMLInputElement>, lineItemId: string) => {
      handleUpdateLineItemsSelection(e.target.checked, [lineItemId]);
    },
    [handleUpdateLineItemsSelection]
  );

  const columns: Column<DuplicateLineItem>[] = useMemo(
    () => [
      {
        id: 'Delete',
        Header: 'Delete',
        width: 70,
        Cell: ({
          row: {
            original: { id },
            toggleRowSelected,
          },
        }: CellProps<DuplicateLineItem>): JSX.Element => (
          <DeleteSavedLineItemCell
            id={id}
            handleRemoveSavedItem={handleRemoveLineItem}
            toggleRowSelected={toggleRowSelected}
          />
        ),
      },
      ...sharedTableColumns,
    ],
    [handleRemoveLineItem]
  );

  const { rows, headerGroups, prepareRow, getTableBodyProps, getTableProps } = useTable<DuplicateLineItem>(
    {
      columns,
      data: lineItems,
      getRowId: (originalRow) => originalRow.id,
      autoResetSelectedRows: false,
    },
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((tableColumns) => [
        {
          id: 'selection',
          Header: (): React.JSX.Element => (
            <CheckboxLineItemHeaderCell
              lineItems={lineItems}
              handleUpdateLineItemsSelection={handleUpdateLineItemsSelection}
            />
          ),
          Cell: (props: CellProps<DuplicateLineItem>): JSX.Element => (
            <CheckboxLineItemBasicCell onChange={onChangeLineItemSelection} {...props} />
          ),
          width: 30,
        },
        ...tableColumns,
      ]);
    }
  );

  return (
    <DuplicateLineItemsTable
      tableProps={getTableProps()}
      headerGroups={headerGroups}
      tableBodyProps={getTableBodyProps()}
      rows={rows}
      prepareRow={prepareRow}
      emptyMessage={EMPTY_LINE_ITEM_SELECTION_MESSAGE}
    />
  );
};

export default memo(DuplicateLineItemsSavedTable);
