import { AdColumn } from '../../constants/ad';
import { CampaignColumn } from '../../constants/campaign';
import { LineItemColumn } from '../../constants/lineItems';

export enum CampaignColumnHeaders {
  Alert = 'Alert',
  CampaignName = 'Campaign Name',
  TrackingId = 'Tracking ID',
  Brand = 'Brand',
  Country = 'Country',
  OrderType = 'Order Type',
  OperativeSalesOrderId = 'Operative Sales Order ID',
  AccountManager = 'Account Manager',
  AccountExecutive = 'Account Executive',
  StartDate = 'Start Date',
  EndDate = 'End Date',
  Advertiser = 'Advertiser',
  Industry = 'Industry',
  Agency = 'Agency',
  CampaignId = 'Campaign ID',
  CreatedDate = 'Created Date',
  UpdatedDate = 'Updated Date',
  AdAccount = 'Ad Account',
  AdAccountId = 'Ad Account Id',
  BrandId = 'Brand Id',
  CampaignBudget = 'Campaign Budget',
  CampaignStatus = 'Campaign Status',
}

export enum LineItemColumnHeaders {
  Alert = 'Alert',
  LineItemName = 'Line Item Name',
  CreateAd = 'Create Ad',
  Country = 'Country',
  OMSPackageName = 'OMS Package Name',
  TrackingId = 'Tracking ID',
  ParentOmsId = 'Parent OMS ID',
  Status = 'Status',
  Review = 'Review',
  StartDate = 'Start Date',
  ActualStartDate = 'Actual Start Date',
  EndDate = 'End Date',
  Publisher = 'Publisher',
  TimeZone = 'Time Zone',
  GeneralPacingStatus = 'General Pacing Status',
  CompletionGoal = 'Completion Goal',
  Completions = 'Completions',
  PercentPacing = '% Pacing',
  PercentExpected = '% Expected',
  PercentDelivered = '% Delivered',
  PacingType = 'Pacing Type',
  TierName = 'Tier Name',
  YodaThreshold = 'Yoda Threshold',
  YodaPacingStatus = 'Yoda Pacing Status',
  PriorityName = 'Priority Name',
  PriorityValue = 'Priority Value',
  AdProduct = 'Ad Product',
  FrequencyCap = 'Frequency Cap',
  Buffer = 'Buffer',
  Frontload = 'Frontload',
  DF = 'DF',
  LineItemId = 'Line Item ID',
  CompletionsRemaining = 'Completions Remaining',
  CompletionsAtRisk = 'Completions at Risk',
  Revenue = 'Revenue',
  RevenueAtRisk = 'Revenue at Risk',
  CreatedDate = 'Created Date',
  UpdatedDate = 'Updated Date',
  BillableThirdParty = 'Billable Third Party',
  CPM = 'CPM',
  UnderPacingRevenue = 'Under-pacing Revenue',
  LineOmsId = 'Line OMS ID',
  MaxDuration = 'Max Duration',
  CampaignName = 'Campaign Name',
  DeliveryGoal = 'Delivery Goal',
  IsCoppaOrCaru = 'Needs Fort D Review',
  IsAddedValue = 'Is Added Value?',
  IsMakegood = 'Is Makegood?',
  FillerAd = 'Filler Ad',
  BuyerName = 'Buyer Name',
  BuyerSeatId = 'Buyer Seat ID',
  DSPName = 'DSP Name',
  DealId = 'Deal ID',
  AccountManager = 'Account Manager',
  AccountExecutive = 'Account Executive',
  CurrencyType = 'Currency Type',
  Sequence = 'Sequence',
}

export enum AdColumnHeaders {
  Alert = 'Alert',
  AdName = 'Ad Name',
  TrackingId = 'Tracking ID',
  AdId = 'Ad ID',
  Country = 'Country',
  AdType = 'Ad Type',
  Status = 'Status',
  Review = 'Review',
  StartDate = 'Start Date',
  EndDate = 'End Date',
  LegalApprovedCreative = 'Legal-Approved Creative',
  Publisher = 'Publisher',
  TimeZone = 'Time Zone',
  AdRating = 'Ad Rating',
  Weight = 'Weight',
  ViewabilityVendor = 'Viewability Vendor',
  FrequencyCap = 'Frequency Cap',
  CreatedDate = 'Created Date',
  UpdatedDate = 'Updated Date',
  LineItemName = 'Line Item Name',
  LineItemId = 'Line Item ID',
  CampaignName = 'Campaign Name',
  CampaignId = 'Campaign ID',
  RotationGroupName = 'Rotation Group Name',
  SequenceId = 'Sequence ID',
  SequenceName = 'Sequence Name',
  SequenceOrder = 'Sequence #',
  EditAd = 'Edit Ad',
  DeleteAd = 'Delete Ad',
}

type ColumnTooltips = {
  [key: string]: string;
};

export const AdsColumnTooltips: ColumnTooltips = {
  [AdColumn.Alert]:
    'The calendar icon indicates a scheduling conflict, meaning the line item schedule was modified in Operative after you created ads in Mission Control.',
  [AdColumn.Name]: 'The name entered when the ad was created in Mission Control.',
  [AdColumn.EditAd]:
    'Click the pencil icon to modify ad properties, such as creative assets, tags, targeting, and schedule.',
  [AdColumn.TrackingId]:
    '16 digit identifier for the ad used for external purposes and shared with 3rd-party vendors, gets passed via our macros.',
  [AdColumn.AdID]:
    '36 character identifier used when communicating with internal teams, for example when creating a JIRA ticket to report an issue.',
  [AdColumn.Country]: 'The country where the ad is eligible to serve.',
  [AdColumn.Type]: 'Format allowed under the ad product.',
  [AdColumn.Status]: 'Indicates whether the ad is active or not.',
  [AdColumn.Review]: 'Campaign review status for the ad.',
  [AdColumn.ScheduleStart]: 'The date the ad is first eligible for delivery.',
  [AdColumn.ScheduleEnd]: 'The last date the ad is eligible for delivery.',
  [AdColumn.LegalApprovedCreative]:
    'A Yes value in this column indicates that the ad has already been approved by the legal department. There is a toggle in the Create/Edit Ad workflow account managers can set to True or False.',
  [AdColumn.DisplayPublisherTarget]: 'The name of the app that will run the ad.',
  [AdColumn.ScheduleTimezoneOffset]: 'The time zone for the ad date range.',
  [AdColumn.Rating]:
    'The acceptability of the ad based on the creative asset, indicates the target audience for the ad.',
  [AdColumn.Weight]: 'Value for an ad within a rotation group that determines priority for how often it is served.',
  [AdColumn.ViewabilityVendorList]: 'The third-party vendor whose data we use to confirm if ads were viewable.',
  [AdColumn.FrequencyCapList]: 'The limit for how many times an ad can be served within a given timeframe.',
  [AdColumn.CreatedAt]: 'The date the ad was first created.',
  [AdColumn.UpdatedAt]: 'The most recent date changes were applied to the ad.',
  [AdColumn.LineItemName]: 'The name of the line item the ad belongs to.',
  [AdColumn.LineItemId]: 'External ID for the line item shared with third-party vendors and populated in tag macros.',
  [AdColumn.CampaignName]: 'The name of the campaign the ad belongs to.',
  [AdColumn.CampaignId]: 'Internal UUID used for troubleshooting. Should not be shared externally.',
  [AdColumn.RotationName]:
    'User-defined name for a collection of ads that will be served based on relative weighted values.',
  [AdColumn.SequenceId]:
    'System-generated identifier for the sequence the ad belongs to. This column will only display a value if the ad is part of a sequence.',
  [AdColumn.SequenceName]:
    'User-entered value identifying the sequence. This column will only display a value if the ad is part of a sequence.',
  [AdColumn.SequenceOrder]:
    'The order in which the ad appears relative to other ads in the sequence. This column will only display a value if the ad is part of a sequence.',
};

export const LineItemColumnTooltips: ColumnTooltips = {
  [LineItemColumn.Alert]:
    'The red flag indicates the campaign contains at least one line item with zero delivery. The calendar icon indicates a scheduling conflict. The block icon indicates there is an inventory block applied to a line item.',
  [LineItemColumn.Name]: 'The name of the line item as booked in Salesforce/Operative.',
  [LineItemColumn.CreateAd]: 'Click the plus sign to create a new ad within the line item.',
  [LineItemColumn.Country]: 'The country where the line item is eligible to serve.',
  [LineItemColumn.DeliverableLineItemPackageName]: 'The parent package name as shown in Operative.',
  [LineItemColumn.TrackingId]:
    '16 digit identifier for the line item used for external purposes and shared with 3rd-party vendors, gets passed via our macros.',
  [LineItemColumn.ParentOmsLinkId]: 'The parent package ID as shown in Operative.',
  [LineItemColumn.Status]: 'Indicates whether the campaign is active or not.',
  [LineItemColumn.Review]: 'Campaign review status for the line.',
  [LineItemColumn.ScheduleStart]: 'The date the line item can begin delivery.',
  [LineItemColumn.ActualStartDate]:
    'The date the line item began delivering. The table will populate this value once the line reaches 200 completions.',
  [LineItemColumn.ScheduleEnd]: 'The date at which the line item will stop delivering ads.',
  [LineItemColumn.DisplayPublisherTarget]: 'The name of the app(s) that will run the ad.',
  [LineItemColumn.ScheduleTimezoneOffset]: 'The time zone for the line item date range.',
  [LineItemColumn.GeneralPacingStatus]: 'Indicates whether the line item is on schedule to fulfill the delivery goal.',
  [LineItemColumn.Goal]: 'Contracted goal for the line item, pushed from Operative.',
  [LineItemColumn.MetricsCompletions]: 'Number of completions that have been delivered.',
  [LineItemColumn.MetricsPercentPacing]: 'Percent delivered vs. percent expected.',
  [LineItemColumn.MetricsPercentExpected]:
    'Percent the ad server expects to have delivered at that moment in time in order for the line item to fulfill the delivery goal.',
  [LineItemColumn.MetricsPercentCompletions]: 'Percentage of completions that have been delivered.',
  [LineItemColumn.PacingType]:
    'Completions distribution for the line item: Even = consistent delivery. Yield = serves if guaranteed lines are on pace and inventory is sufficient. AFAP = fulfills delivery goal as quickly as possible.',
  [LineItemColumn.Tier]:
    'Controls how the ad server handles delivery of the line item and whether we restrict pacing, factors into priority value.',
  [LineItemColumn.YodaThreshold]:
    'The minimum delivery percentage derived from the Priority value for the line. This threshold is defined by the business and enables the ad server to determine when Biddable can compete with the line.',
  [LineItemColumn.YodaPacingStatus]:
    'Under pacing: % delivered < YODA Threshold. On pace: % delivered between YODA Threshold and Frontload. Overpacing: % delivered > YODA Threshold + Frontload.',
  [LineItemColumn.PriorityName]: 'Ranking within a priority tier: high, standard, low.',
  [LineItemColumn.PriorityValue]: 'Number calculated based on the priority tier and priority name combination.',
  [LineItemColumn.AdProduct]: 'Type of ad that can be trafficked on the line as defined in the Operative package.',
  [LineItemColumn.FrequencyCapList]: 'The limit for how many times a line item can be served within a given timeframe.',
  [LineItemColumn.DeliveryBufferPercent]:
    'The percentage the line item can over deliver in order to meet the completion goal given potential third-party data discrepancies.',
  [LineItemColumn.FrontLoadPercent]:
    'A delivery ceiling that allows your line item to over-pace up to the frontloaded percentage to take advantage of open inventory.',
  [LineItemColumn.DeliveryForensics]: 'Click graph icon to launch Delivery Forensics for the line item.',
  [LineItemColumn.AccessorId]:
    'External ID for the line item shared with third-party vendors and populated in tag macros.',
  [LineItemColumn.MetricsRemainingExpectedCompletionsAcc]:
    'Difference between completion goal and delivered completions.',
  [LineItemColumn.MetricsCompletionsAtRisk]:
    'What the ad server anticipates we might not fulfill based on current pacing.',
  [LineItemColumn.Revenue]: 'CPM multiplied by completion goal.',
  [LineItemColumn.MetricsRevenueAtRisk]:
    'The calculated dollar amount we may not fulfill based on current underpacing.',
  [LineItemColumn.BillableThirdParty]: '3rd party vendor whose data we use for billing purposes.',
  [LineItemColumn.UnitCost]: 'Cost per mille (thousand) billing method.',
  [LineItemColumn.MetricsUnderPacingRevenue]:
    'How much revenue we have not fulfilled vs. what was expected, at the current point in time.',
  [LineItemColumn.LineOmsLinkId]: 'Corresponds to the Operative ID for the line.',
  [LineItemColumn.CreatedAt]: 'The date the line item was pushed from Operative.',
  [LineItemColumn.UpdatedAt]: 'Most recent date line changes were pushed from Operative.',
  [LineItemColumn.CreativeMaxSeconds]: 'Specifies how long the creative asset can be, as defined in Operative package.',
  [LineItemColumn.CampaignName]: 'The name of the campaign that the line item belongs to.',
  [LineItemColumn.DeliveryGoal]: 'Completion goal plus buffer value.',
  [LineItemColumn.IsCoppaOrCaru]:
    'A Yes value indicates that a COPPA line item requires Fort D review before trafficking.',
  [LineItemColumn.IsAddedValue]:
    'A Yes value in this column indicates that the line item was created to give the advertiser free or bonus impressions to encourage them to buy with us.',
  [LineItemColumn.IsMakegood]:
    'A Yes value in this column indicates that the line item was created to make up some delivery issue to the advertiser.',
  [LineItemColumn.IsFiller]:
    'A Yes value indicates the line contains ads that are used to fill empty slots within a live pod. This ad product is managed by Live Tech Ops, and only available via the O1 Filler package.',
  [LineItemColumn.DeliverableLineItemBuyerName]:
    "The name of the demand entity within a DSP's platform that is responsible for fills. It may correspond to an advertiser, brand, or agency.",
  [LineItemColumn.DeliverableLineItemBuyerId]:
    "The DSP-provided code of a demand entity within a DSP's own platform that is responsible for filling bid requests.",
  [LineItemColumn.MagniteDealDspName]: 'The name of the entity that will bid on Programmatic Guaranteed deals.',
  [LineItemColumn.MagniteDealDealName]:
    'The ID sent to DSPs in openRTB requests so that they know the parameters of the commercial agreement when bidding.',
  [LineItemColumn.CampaignTraffickerEmail]:
    'The Ad Ops Account Manager responsible for managing campaign setup and delivery fulfillment. Name of the AM that pushed the campaign from Operative.One.',
  [LineItemColumn.CampaignOrderSalespersonEmail]: 'The primary salesperson on the campaign.',
  [LineItemColumn.Currency]: 'The currency symbol that the line item will be billed in.',
  [LineItemColumn.Sequence]: 'Whether the line contains ads that are set up to be delivered in sequence.',
};

export const CampaignColumnTooltips: ColumnTooltips = {
  [CampaignColumn.Alert]:
    'The red flag indicates the campaign contains at least one line item with zero delivery. The calendar icon indicates a scheduling conflict.',
  [CampaignColumn.Name]: 'The name of the campaign as booked in Salesforce/Operative.',
  [CampaignColumn.TrackingId]:
    'External ID for the campaign shared with third-party vendors and populated in tag macros.',
  [CampaignColumn.Brand]: 'The specific brand under the larger parent advertising company.',
  [CampaignColumn.Country]: 'The country where the campaign is eligible to serve.',
  [CampaignColumn.OrderType]: 'Identifies the type of order booked.',
  [CampaignColumn.OrderOmsLinkId]: 'The identifier for the order in Operative.',
  [CampaignColumn.TraffickerEmail]:
    'The Ad Ops Account Manager responsible for managing campaign setup and delivery fulfillment. Name of the AM that pushed the campaign from Operative.One.',
  [CampaignColumn.OrderSalespersonEmail]: 'The primary salesperson on the campaign.',
  [CampaignColumn.Start]: 'The campaign start date derived from the earliest line item start date.',
  [CampaignColumn.End]: 'The campaign end date derived from the latest line item end date.',
  [CampaignColumn.Advertiser]: 'The name of the advertising company the campaign belongs to.',
  [CampaignColumn.IndustryName]: 'The industry category assigned to the brand.',
  [CampaignColumn.Agency]: 'The buying agency contracting the campaign.',
  [CampaignColumn.CampaignID]:
    'UUID used for troubleshooting and internal communications. Should not be shared externally.',
  [CampaignColumn.CreatedAt]: 'The date the campaign was initially pushed from Operative.One to Mission Control.',
  [CampaignColumn.UpdatedAt]: 'The date the campaign was subsequently pushed from Operative.One to Mission Control.',
  [CampaignColumn.AdAccount]:
    'The advertiser/agency ad account as designated upstream in the Account Manager backend service.',
  [CampaignColumn.AdAccountId]: 'The ID associated with the ad account in the Account Manager backend service.',
  [CampaignColumn.BrandId]:
    'The identifier associated with the advertiser brand as designated upstream in Customer Manager.',
  [CampaignColumn.CampaignBudget]: 'The campaign booked revenue derived from total line item booked revenue.',
  [CampaignColumn.Status]: 'Indicates the status of the campaign as derived from the line items within the campaign.',
};
