import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type BillableThirdParty = keyof typeof billableThirdPartyDisplayNames;

const billableThirdPartyDisplayNames = {
  ADFORM: enumToTitleCase,
  ADVERTISER_NIELSEN_DTA_DEMO: enumToTitleCase,
  ADVERTISER_NIELSEN_DTA_P2: 'Advertiser Nielsen DTA P2+',
  ATLAS: enumToTitleCase,
  BRIGHTLINE: enumToTitleCase,
  COMSCORE: enumToTitleCase,
  CONVERSANT: enumToTitleCase,
  DCM: enumToTitleCase,
  EXTREME_REACH: enumToTitleCase,
  FLASHTALKING: enumToTitleCase,
  IAS: enumToTitleCase,
  INNOVID: enumToTitleCase,
  JIVOX: enumToTitleCase,
  NIELSEN: enumToTitleCase,
  NIELSEN_DTA_DEMO: enumToTitleCase,
  NIELSEN_DTA_P2: 'Nielsen DTA P2+',
  PENDING: enumToTitleCase,
  SIZMEK: enumToTitleCase,
  TELARIA: enumToTitleCase,
  TUBE_MOGUL: enumToTitleCase,
  XAXIS: enumToTitleCase,
};

export const billableThirdParties = makeConfig<BillableThirdParty>(billableThirdPartyDisplayNames);

export function convertBillableThirdParty(apiValue: string): DisplayEnum<BillableThirdParty> {
  return convertToDisplayEnum(apiValue, billableThirdParties);
}
