import { gql } from '@apollo/client';

export const GET_REVIEW_STATUS_MODAL_CHANGE_LOG = gql`
  query GetReviewModalStatusChangeLog(
    $id: ID!
    $entityType: EntityType
    $paginationOptions: PaginationOptions
    $filterOptions: GetChangelogFilterOptions
  ) {
    getChangeLogByAdObjectID(
      id: $id
      entityType: $entityType
      paginationOptions: $paginationOptions
      filterOptions: $filterOptions
    ) {
      total
      edges {
        node {
          audits {
            modifiedDate
            modifiedBy
            changeList {
              fieldName
              __typename
              ... on SingleValueChange {
                __typename
                oldValue {
                  action
                  payload
                }
                newValue {
                  action
                  payload
                }
              }
            }
          }
        }
      }
    }
  }
`;
