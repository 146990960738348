import './TargetingViewButton.scss';

import React from 'react';

import bem from '../../../utils/bem';

const [block, element] = bem('targeting-view-button');

interface Props {
  isActive: boolean;
  setActive: (value: boolean) => void;
  editMode?: boolean;
}

function TargetingViewButton({ isActive, setActive, editMode = false }: Props): JSX.Element | null {
  if (editMode) {
    return null;
  }

  return (
    <div className={block()} onClick={(e): void => e.preventDefault()}>
      <button
        className={`${element('table-view')} ${!isActive ? 'active' : 'inactive'}`}
        onClick={(): void => setActive(false)}
      >
        Table View
      </button>
      <button
        className={`${element('text-view')} ${isActive ? 'active' : 'inactive'}`}
        onClick={(): void => setActive(true)}
      >
        Text View
      </button>
    </div>
  );
}

export default TargetingViewButton;
