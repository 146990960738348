import type { DisplayEnum, ViewabilityVendor } from '../../../../../../configs';
import { UNRECOGNIZED } from '../../../../../../configs';

export const getViewabilityVendorInput = (
  viewabilityVendorList: DisplayEnum<ViewabilityVendor | typeof UNRECOGNIZED>[]
): ViewabilityVendor[] => {
  return viewabilityVendorList.map((vv) => {
    if (vv.key === UNRECOGNIZED) {
      throw new Error(`"${vv.displayName}" is not a recognized viewability vendor.`);
    } else {
      return vv.key;
    }
  });
};
