import type { CellProps } from 'react-table';

import Tooltip from '../../../../../../../../common/Tooltip';
import type { DuplicateLineItem } from '../../../../DuplicateAdsStepperProvider/types';
import { pickerElement } from '../constants';

const DuplicateLineItemsNameCell = ({ value }: CellProps<DuplicateLineItem>): JSX.Element => (
  <Tooltip id={value} message={value} className={pickerElement('name-tooltip')}>
    <>{value}</>
  </Tooltip>
);

export default DuplicateLineItemsNameCell;
