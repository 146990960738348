import type { GraphQLError } from 'graphql';

import type { GQLError, Maybe, Metrics, MultiEditLineItemMutation } from '../../../../../../../apis/graphql';
import { campaignClient, createGraphQLErrorMap } from '../../../../../../../apis/graphql';
import { AlertType } from '../../../../../../../common/Alert';
import { openToastAlert } from '../../../../../../../common/ToastAlert/toastAlert';
import { getEarliestDateRange, getLatestDateRange } from '../../../../../../../common/utils';
import { AD_TRAFFICKING_COLUMN, LINE_ITEM_TRAFFICKING_COLUMNS } from '../../../../../TraffickingPage/hooks/fragments';
import type { MetadataV5, MultiEditAdsMutation, ScheduleV5 } from './../../../../../../../apis/graphql/types';
import type { AdFields, GetNewSchedule, LineItemFields, OldCacheData } from './types';
import {
  AD_EDITS_FAILED,
  AD_EDITS_HAVE_BEEN_SAVED,
  LINE_ITEMS_EDITS_FAILED,
  LINE_ITEMS_EDITS_HAVE_BEEN_SAVED,
} from './utils/getRows/constants';

export const getConflictIdsWithoutDuplicates = (conflicts: Record<string, string[]> | undefined | null): string[] => {
  const result: string[] = [];

  if (conflicts) {
    Object.keys(conflicts).forEach((conflictKey) => {
      conflicts[conflictKey].forEach((id) => {
        if (!result.includes(id)) {
          result.push(id);
        }
      });
    });
  }

  return result;
};

export const getOldCacheData = (isLineItem: boolean, selectedEntitiesIds: string[]): OldCacheData[] => {
  return selectedEntitiesIds.map((id) => {
    const fragment = isLineItem ? LINE_ITEM_TRAFFICKING_COLUMNS : AD_TRAFFICKING_COLUMN;
    const cacheId = isLineItem ? `LineItemV5:${id}` : `AdV5:${id}`;

    const cacheData = campaignClient.readFragment({
      id: cacheId,
      fragment,
    });

    return {
      id,
      cacheId,
      cacheData,
    };
  });
};

export const getAdScheduleRanges = (schedule: ScheduleV5): GetNewSchedule => {
  const earliestStartDate = getEarliestDateRange(schedule)?.startDate;
  const latestEndDate = getLatestDateRange(schedule)?.endDate;

  return { earliestStartDate, latestEndDate };
};

export function getCacheUpdateFields({
  isLineItem,
  lineItemStartDate,
  lineItemEndDate,
  metrics,
  deliveryGoal,
  metadata,
  displayPublisherTarget,
  schedule,
}: {
  isLineItem: boolean;
  lineItemStartDate: string;
  lineItemEndDate: string;
  metrics?: Maybe<Metrics>;
  deliveryGoal?: Maybe<number>;
  metadata?: Maybe<MetadataV5>;
  displayPublisherTarget?: Maybe<string[]>;
  schedule?: Maybe<ScheduleV5>;
}): LineItemFields | AdFields | Pick<AdFields, 'displayPublisherTarget'> {
  const fieldsData = { displayPublisherTarget };

  if (isLineItem) {
    const lineItemData = {
      metrics,
      deliveryGoal,
      metadata,
      startDate: lineItemStartDate,
      endDate: lineItemEndDate,
    };
    Object.assign(fieldsData, lineItemData);
  }

  if (schedule) {
    const { earliestStartDate, latestEndDate } = getAdScheduleRanges(schedule);
    const scheduleData = { startDate: earliestStartDate, endDate: latestEndDate };

    Object.assign(fieldsData, scheduleData);
  }

  return fieldsData;
}

export const updateCacheItem = (
  isLineItem: boolean,
  data: MultiEditLineItemMutation | MultiEditAdsMutation,
  id: string
): MultiEditLineItemMutation['multiEditLineItem'][number] | MultiEditAdsMutation['multiEditAds'][number] | null => {
  if (isLineItem) {
    const lineItems = (data as MultiEditLineItemMutation).multiEditLineItem;
    const lineItem = lineItems.find((lineItem) => lineItem.id === id);

    return lineItem || null;
  } else {
    const ads = (data as MultiEditAdsMutation).multiEditAds;
    const ad = ads.find((ad) => ad.id === id);

    return ad || null;
  }
};

export const updateCacheData = (
  isLineItem: boolean,
  data: MultiEditLineItemMutation | MultiEditAdsMutation,
  oldCacheData: OldCacheData[]
): void => {
  oldCacheData.forEach(({ id, cacheId, cacheData }) => {
    const { displayPublisherTarget, metrics, deliveryGoal, metadata, startDate, endDate } = cacheData;
    const fragment = isLineItem ? LINE_ITEM_TRAFFICKING_COLUMNS : AD_TRAFFICKING_COLUMN;

    const cacheUpdateData = updateCacheItem(isLineItem, data, id);

    if (cacheUpdateData !== null) {
      const fields = getCacheUpdateFields({
        isLineItem,
        lineItemEndDate: endDate,
        lineItemStartDate: startDate,
        metrics,
        deliveryGoal,
        metadata,
        displayPublisherTarget,
        schedule: cacheUpdateData.schedule,
      });

      campaignClient.writeFragment({
        id: cacheId,
        fragment,
        data: {
          ...cacheUpdateData,
          ...fields,
        },
      });
    }
  });
};

export const getErrorDescription = (errors: GraphQLError[], filteredErrors?: (string | GQLError)[]): string => {
  let description: string = errors[0].message;

  if (filteredErrors && filteredErrors.length > 0) {
    if (typeof filteredErrors[0] === 'string') {
      description = filteredErrors[0];
    } else if (filteredErrors[0]?.error?.message) {
      description = filteredErrors[0].error.message;
    }
  }

  return description;
};

export const onHandleUpdateError = (isLineItem: boolean, errors?: GraphQLError[]): void => {
  if (errors && errors.length > 0) {
    const filteredErrors = Object.values(createGraphQLErrorMap(errors))[0];

    openToastAlert({
      alertType: AlertType.ERROR,
      message: isLineItem ? LINE_ITEMS_EDITS_FAILED : AD_EDITS_FAILED,
      description: getErrorDescription(errors, filteredErrors),
    });
  }
};

export const onHandleUpdateSuccess = (isLineItem: boolean, onSubmit: () => void): void => {
  openToastAlert({
    alertType: AlertType.SUCCESS,
    message: isLineItem ? LINE_ITEMS_EDITS_HAVE_BEEN_SAVED : AD_EDITS_HAVE_BEEN_SAVED,
  });

  onSubmit();
};
