import './AssetRejectionReasonsModal.scss';

import { bem } from 'mission-control-common-components';

import type { BaseAssetV5 } from '../../../apis/graphql';
import { useStatusModal } from '../hooks';
import { StatusModal } from '../StatusModal';
import RejectionReasonToggle from './RejectionReasonToggle';

type Props = {
  asset: BaseAssetV5;
  customActivator?: string | React.ReactNode;
};

const [blockModal, modalElement] = bem('asset-rejection-reasons-modal');
const [, containerElement] = bem('asset-rejection-reasons-modal-container');

export const SEE_REASON_LABEL = 'See reason';
export const PLEASE_RESUBMIT_MESSAGE = 'Please address the below issues and re-submit creative for review.';
export const REASONS_FOR_REJECTION_TITLE = 'Reasons for Rejection';

const AssetRejectionReasonsModal = ({ asset, customActivator }: Props): React.JSX.Element => {
  const { toggles, toggleAt } = useStatusModal(asset);
  const hasDetails = !!asset.rejectionReasons && !!asset.rejectionReasons.length;
  const isMultipleReasons = asset.rejectionReasons?.length !== undefined && asset.rejectionReasons?.length > 1;

  return (
    <div className={blockModal()}>
      <StatusModal
        activator={
          customActivator ??
          (hasDetails && (
            <span data-testid="rejection-rejection-button" className={containerElement('text')}>
              {`${SEE_REASON_LABEL}${isMultipleReasons ? 's' : ''}`}
            </span>
          ))
        }
        title={REASONS_FOR_REJECTION_TITLE}
      >
        {PLEASE_RESUBMIT_MESSAGE}
        <>
          <p className={modalElement('asset-name')}>{asset.name}</p>
          <RejectionReasonToggle
            key={`rejection-reason-${asset.id}`}
            reasonIndex={0}
            rejectionReasons={asset.rejectionReasons || []}
            toggleAt={toggleAt}
            toggles={toggles}
            assetId={asset.id}
          />
        </>
      </StatusModal>
    </div>
  );
};

export default AssetRejectionReasonsModal;
