import { add, isAfter, parseISO } from 'date-fns';

import type { DateRange as Range } from '../../../models';

export const isStartDateAfterEndDate = (dateRange: Range): boolean => {
  const startDate = new Date(dateRange.startDate);

  if (!dateRange.startDate) return true;

  if (dateRange.endDate) {
    return isAfter(startDate, new Date(dateRange.endDate));
  }

  return isAfter(startDate, new Date());
};

/**
 * Converts a local date (YYYY-MM-DD) to its UTC equivalent at the start or end of the local day.
 *
 * @param date - The date string in 'YYYY-MM-DD' format (user's local timezone).
 * @returns UTC date-time string in ISO format (without milliseconds).
 */
export const convertLocalDateToUTC = (date: string): string => {
  const parsedDate = parseISO(date);

  const localTimeOffSet = parsedDate.getTimezoneOffset();

  const utcDate = add(parsedDate, {
    minutes: localTimeOffSet,
  });

  // Format as ISO string without milliseconds
  return utcDate.toISOString().split('.')[0] + 'Z';
};
