import './ChangeLogList.scss';

import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { pickerElement } from '../../../pages/Trafficking/DuplicateAd/DuplicateAdForm/DuplicateAdFormFirstStep/DuplicateLineItemsPicker/constants';
import Loader from '../../Loader';
import ChangeLogListEntry from '../ChangeLogListEntry';
import { Collapse } from '../Collapse/Collapse';
import type { ChangesPrefixes } from '../constants';
import type { ChangeLogEntryNode, TargetingEntry } from '../hooks/useChangeLog';
import type { Toggles } from '../hooks/useChangeLog';
import { useChangeLogList } from './useChangeLogList';
import type { FilterVariables } from './usePagination';
import { usePagination } from './usePagination';

export interface ChangeLogListProps {
  changeLogEntries: ChangeLogEntryNode[];
  targetingEntries?: TargetingEntry[];
  toggles: Toggles[];
  toggleAt: (index: number, value: boolean | boolean[]) => void;
  toggleAll: (isOpen: boolean) => void;
  nestedChangesPrefix?: ChangesPrefixes;
  refetch: (variables?: FilterVariables) => void;
  total?: number;
  loading?: boolean;
  entityType?: string;
}

const ChangeLogList = ({
  changeLogEntries,
  targetingEntries,
  toggles,
  toggleAt,
  toggleAll,
  nestedChangesPrefix,
  refetch,
  total,
  entityType,
  loading,
}: ChangeLogListProps): JSX.Element => {
  const { filteredChangeLogList } = useChangeLogList({
    changeLogEntries,
    targetingEntries,
    nestedChangesPrefix,
    entityType,
  });
  const { resultFilteredLogs, hasMore, onNext } = usePagination({
    filteredChangeLogList,
    changeLogEntries,
    refetch,
    total,
  });

  if (loading) {
    return (
      <div className="change-log-loader">
        <Loader text="Loading..." />
      </div>
    );
  }

  if (!loading && !changeLogEntries?.length) {
    return <p>No current change log data for this {entityType || 'entity'}.</p>;
  }

  return (
    <section data-testid="change-log-list" id="scrollableDiv">
      <Collapse toggleAll={toggleAll} />
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={resultFilteredLogs.length}
        next={onNext}
        hasMore={hasMore}
        loader={<div className={pickerElement('scroll-loader')}>{<Loader />}</div>}
      >
        {resultFilteredLogs.map(
          (filteredChangeLogEntry): JSX.Element => {
            return (
              <ChangeLogListEntry
                {...filteredChangeLogEntry}
                toggles={toggles}
                toggleAt={toggleAt}
                originalIndex={filteredChangeLogEntry.originalIndex}
                key={`change-log-list-${filteredChangeLogEntry.originalIndex}`}
              />
            );
          }
        )}
      </InfiniteScroll>
    </section>
  );
};

export default ChangeLogList;
