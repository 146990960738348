// line items
export const PRIORITY_CONFLICT_MESSAGE =
  'Priority cannot be changed  for line items with pacing type SOV. List of IDs:';
export const BUFFER_CONFLICT_MESSAGE = 'Buffer cannot be changed. List of IDs that have conflict:';
export const FRONTLOAD_CONFLICT_MESSAGE = 'Frontload cannot be changed. List of IDs that have conflict:';
export const TARGETING_CONFLICT_MESSAGE =
  'Cannot add targeting to some line item(s) due to targeting conflict. List of IDs that have conflict:';
export const TARGETING_ALREADY_EXIST_MESSAGE =
  'Line Item(s) already contain target. List of IDs that already contain target:';
export const TARGETING_TYPE_INCLUDE_CONFLICT_MESSAGE =
  'Cannot INCLUDE targetings which has the same targeting type as some targetings that are already EXCLUDED. List of IDs that have conflict:';
export const TARGETING_TYPE_EXCLUDE_CONFLICT_MESSAGE =
  'Cannot EXCLUDE targetings which has the same targeting type as some targetings that are already INCLUDED. List of IDs that have conflict:';
export const LINE_ITEM_DOES_NOT_CONTAIN_ACTIVE_AD_MESSAGE = 'The Line Item does not contain an active ad.';
export const LINE_ITEMS_DO_NOT_CONTAIN_ACTIVE_ADS_MESSAGE = 'The Line Items do not contain active ads.';
export const LINE_ITEMS_EDITS_HAVE_BEEN_SAVED = 'Line items edits have been saved!';
export const LINE_ITEMS_EDITS_FAILED = 'Error! Line items edits are not saved!';

// ads
export const AD_TARGETING_CONFLICT_MESSAGE =
  'Cannot add targeting to some ad(s) due to targeting conflict. List of IDs that have conflict:';
export const AD_TARGETING_ALREADY_EXIST_MESSAGE =
  'Ad(s) already contain target. List of IDs that already contain target:';
export const AD_SCHEDULING_SECOND_PART_MESSAGE = '. List of Ids that have conflict:';
export const AD_HAS_CLICK_TAG = 'Ad(s) already contain a click tag. List of Ids that have conflict:';
export const AD_HAS_BILLABLE_TAG = 'Ad(s) already contain a billable tag. List of Ids that have conflict:';
export const AD_HAS_NON_BILLABLE_TAG_DUPLICATES =
  'Ad(s) contain non-billable tag(s) with the same URL. List of Ids that have conflict:';
export const AD_STATUS_CONFLICT_MESSAGE =
  'Cannot activate all. Some ad(s) not approved in Campaign Review. List of Ids that have conflict:';
export const AD_EDITS_HAVE_BEEN_SAVED = 'Ad edits have been saved!';
export const AD_EDITS_FAILED = 'Error! Ad edits are not saved!';
export const AD_SCHEDULE_HAS_CONFLICT_WITH_LINE_ITEM =
  'Cannot apply schedule to some ad(s) due to line item schedule conflict. List of Ids that have conflict:';
