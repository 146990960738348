import { debounce as _debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { DEFAULT_DEBOUNCE_TIMEOUT } from './constants';

interface UseDebounceSearchResult {
  // useful for value of controlled input
  filterValue: string;
  // useful for queries
  debouncedFilterValue: string;
  onChange: (term: string) => void;
  onClear: () => void;
}

export default function useDebounceFilter(debounceTimeout: number = DEFAULT_DEBOUNCE_TIMEOUT): UseDebounceSearchResult {
  const [filterValue, setFilterValue] = useState<string>('');
  const [debouncedFilterValue, setDebouncedFilterValue] = useState<string>('');

  const debouncedSetFilterValue = useMemo(
    () =>
      _debounce((term: string) => {
        setDebouncedFilterValue(term);
      }, debounceTimeout),
    [debounceTimeout]
  );

  const onChange = useCallback(
    (term: string) => {
      setFilterValue(term);
      debouncedSetFilterValue(term);
    },
    [debouncedSetFilterValue]
  );

  const onClear = useCallback(() => {
    setFilterValue('');
    setDebouncedFilterValue('');
  }, []);

  return {
    filterValue,
    debouncedFilterValue,
    onChange,
    onClear,
  };
}
