import type { DropEvent } from 'react-dropzone';

import { bytesToLabel } from '../_ForCommonLibrary/util/formatters';
import type { AssetType } from '../../configs';
import type { Nullable } from '../../models';
import type { Dimensions } from '../AdForm/CreativeBuilders/CreativeBuilder';
import type { Size } from '../AdForm/CreativeBuilders/CreativeBuilder/CreativeBuilder';
import {
  COMMON_INCOMPATIBLE_DIMENSIONS_MESSAGE,
  IMAGE_TYPES,
  INCOMPATIBLE_DIMENSIONS,
  INCOMPATIBLE_IMAGE_TYPE,
  INCOMPATIBLE_JPG_IMAGE_TYPE,
  INVALID_FILE_TYPE,
  VIDEO_WRONG_CODEC,
  VIDEO_WRONG_CODEC_MESSAGE,
} from './const';
import { FileUploaderType } from './enum';
import type {
  FileEvent,
  FileExtended,
  FileHandler,
  FileUploadValidationFunction,
  FileUploadValidationObject,
  FormatDimensionLabel,
} from './type';

export const getIncompatibleDimensionsValidationMessage = (sizes: Size[]): string =>
  `Asset must be ${sizes.map(({ width, height }) => `${width} x ${height}`).join(', ')}.`;

export const uploadImageHandler = (file: FileExtended): Promise<void> => {
  return new Promise<void>((resolve) => {
    const image = new Image();

    image.onload = (): void => {
      file.width = image.width;
      file.height = image.height;
      resolve();
    };

    const url = URL.createObjectURL(file);
    image.src = url;
  });
};

export const uploadVideoHandler = (file: FileExtended): Promise<void> => {
  return new Promise<void>((resolve) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      function () {
        file.width = this.videoWidth;
        file.height = this.videoHeight;
        resolve();
      },
      false
    );

    const url = URL.createObjectURL(file);
    video.src = url;
  });
};

export const incompatibleImageTypeValidation = (isJPGTypeAllowed?: boolean): FileUploadValidationObject => {
  const message = isJPGTypeAllowed ? INCOMPATIBLE_JPG_IMAGE_TYPE : INCOMPATIBLE_IMAGE_TYPE;

  return {
    code: INVALID_FILE_TYPE,
    message,
  };
};

export const incompatibleImageDimensionValidation: FileUploadValidationFunction = (
  file: FileExtended,
  sizes: Size[]
) => {
  const isCorrectDimensionFound = sizes.some(({ width, height }) => {
    return file.width === width && file.height === height;
  });

  if (!isCorrectDimensionFound) {
    return {
      code: INCOMPATIBLE_DIMENSIONS,
      message: getIncompatibleDimensionsValidationMessage(sizes),
    };
  }

  return null;
};

export const validateStandardVideo: FileUploadValidationFunction = (file: FileExtended, sizes: Size[]) => {
  // First, check if the codec is unsupported before checking dimensions
  // codec is unsupported if the file is parsed with width and height of 0.
  // There is a known rare case where the file may be corrupted and then as a result
  // will also have a height and width of 0, but we are okay with this case being
  // a possibility.
  if (file.width === 0 && file.height === 0) {
    return {
      code: VIDEO_WRONG_CODEC,
      message: VIDEO_WRONG_CODEC_MESSAGE,
    };
  }
  return incompatibleVideoDimensionValidation(file, sizes);
};

export const incompatibleVideoDimensionValidation: FileUploadValidationFunction = (
  file: FileExtended,
  sizes: Size[]
) => {
  const isCorrectDimensionFound = sizes.some(({ width, height }) => {
    return file.width === width && file.height === height;
  });

  if (!isCorrectDimensionFound) {
    return {
      code: INCOMPATIBLE_DIMENSIONS,
      message: COMMON_INCOMPATIBLE_DIMENSIONS_MESSAGE,
    };
  }

  return null;
};

export const validateQuickTimeVideo: FileUploadValidationFunction = (file: FileExtended, sizes: Size[]) => {
  // some of .mov files that may be upload have codecs that are not supported by HTML5 video tag specifications,
  // so to avoid blocking users from uploading valid files, we skip validation on the FE and validate these videos on the BE
  if (file.type === FileUploaderType.videoQuickTime && file.width === 0 && file.height === 0) return null;

  return incompatibleVideoDimensionValidation(file, sizes);
};

export const fileUploadImageTypeValidations = (
  isJPGTypeAllowed?: boolean
): Record<FileUploaderType, FileUploadValidationObject> =>
  IMAGE_TYPES.reduce(
    (validations, key) => ({
      ...validations,
      [key]: incompatibleImageTypeValidation(isJPGTypeAllowed),
    }),
    {} as Record<FileUploaderType, FileUploadValidationObject>
  );

export const assetFileUploaderHandlers: Partial<Record<FileUploaderType, FileHandler>> = {
  [FileUploaderType.imgJPEG]: uploadImageHandler,
  [FileUploaderType.imgPNG]: uploadImageHandler,
  [FileUploaderType.videoQuickTime]: uploadVideoHandler,
  [FileUploaderType.videoMP4]: uploadVideoHandler,
};

export const assetFileUploaderValidationsConfig = (
  file: FileExtended,
  adTypeDimensions: Dimensions
): FileUploadValidationObject => {
  const { sizes, isJPGTypeAllowed } = adTypeDimensions;

  const imageTypeValidations = fileUploadImageTypeValidations(isJPGTypeAllowed);

  const assetFileUploaderValidations: Record<FileUploaderType, FileUploadValidationObject> = {
    ...imageTypeValidations,
    [FileUploaderType.imgPNG]: incompatibleImageDimensionValidation(file, sizes),
    [FileUploaderType.videoQuickTime]: validateQuickTimeVideo(file, sizes),
    [FileUploaderType.videoMP4]: validateStandardVideo(file, sizes),
  };

  if (isJPGTypeAllowed) {
    assetFileUploaderValidations[FileUploaderType.imgJPEG] = incompatibleImageDimensionValidation(file, sizes);
  }
  return assetFileUploaderValidations[file.type as FileUploaderType];
};

export const getFilesFromEvent = async (event: DropEvent): Promise<(File | DataTransferItem)[]> => {
  /*  We create image/video elements to attach file to in order to retrieve
      width/height properties from the file used for validation before onDrop is called.
  */
  const targetEvent = event as FileEvent;

  if (event.target === null) {
    return [];
  }

  const files = (targetEvent.target.files || targetEvent.dataTransfer?.files) ?? [];

  const promises: Promise<FileExtended | DataTransferItem>[] = [];

  for (const file of files) {
    const assetFileUploaderHandler = assetFileUploaderHandlers[file.type as FileUploaderType];

    const promise = assetFileUploaderHandler
      ? assetFileUploaderHandler(file)
          .then(() => file)
          .catch(() => file)
      : Promise.resolve(file);

    promises.push(promise);
  }

  return await Promise.all(promises);
};

export const validateFile = (file: File, adTypeDimensions?: Dimensions): FileUploadValidationObject => {
  const fileModified = file as FileExtended;

  if (adTypeDimensions) {
    return assetFileUploaderValidationsConfig(fileModified, adTypeDimensions);
  }

  return null;
};

export const getFormatDimensionLabelsValue = (
  assetType: AssetType,
  adTypeDimensions?: Dimensions
): Nullable<FormatDimensionLabel> => {
  if (!adTypeDimensions) {
    return null;
  }

  const { sizes, isJPGTypeAllowed, fileSize } = adTypeDimensions;

  const dimension = `${sizes.map(({ width, height }) => `${width} x ${height}`).join(', ')}`;
  const imageFormat = isJPGTypeAllowed ? '.PNG, .JPG, .JPEG' : '.PNG';
  const videoFormat = '.MOV, .MP4';

  const assetFormat = assetType === 'VIDEO' ? videoFormat : imageFormat;

  const formattedFileSize = fileSize ? bytesToLabel(fileSize) : '';

  return { dimension, format: assetFormat, fileSize: formattedFileSize };
};
