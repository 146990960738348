import { useQuery } from '@apollo/client';

import type {
  GetCreativeLibraryIdByOrganizationIdQuery,
  GetCreativeLibraryIdByOrganizationIdQueryVariables,
} from '../../../apis/graphql';
import { GET_CREATIVE_LIBRARY_ID_BY_ORGANIZATION_ID } from '../../../common/AdForm/hooks/queries/getCreativeLibraryIdByOrganizationId';

interface UseGetCreativeLibraryIdResult {
  creativeLibraryId: string;
  loading: boolean;
}

type Props = {
  organizationId?: string | null;
};

export const useGetCreativeLibraryIdByOrganizationId = ({ organizationId }: Props): UseGetCreativeLibraryIdResult => {
  const { data, loading } = useQuery<
    GetCreativeLibraryIdByOrganizationIdQuery,
    GetCreativeLibraryIdByOrganizationIdQueryVariables
  >(GET_CREATIVE_LIBRARY_ID_BY_ORGANIZATION_ID, {
    variables: {
      organizationId: organizationId ?? '',
    },
    skip: !organizationId,
  });

  const creativeLibraryId = data?.getCreativeLibraryIdByOrganizationIDV5 || '';

  return {
    creativeLibraryId,
    loading,
  };
};
