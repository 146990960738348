import './DuplicateAdAssigningToLineItem.scss';

import { bem } from 'mission-control-common-components';
import React, { memo } from 'react';

import { useDuplicateAdsStepperContext } from '../../../DuplicateAdsStepperProvider/DuplicateAdsStepperProvider';
import DuplicatedAdDetails from '../../../DuplicatedAdDetails';
import type { DuplicatedAdDetailsProps } from '../../../DuplicatedAdDetails/types';
import DuplicatedAdData from './DuplicatedAdData/DuplicatedAdData';

const [block] = bem('set-ad-name-wrapper');

const DuplicateAdAssigningToLineItem = (props: DuplicatedAdDetailsProps): JSX.Element => {
  const { lineItems, updateLineItemAdName } = useDuplicateAdsStepperContext();

  return (
    <section className={block()}>
      <DuplicatedAdDetails {...props} />
      <div>
        {lineItems
          .filter((lineItem) => lineItem.selected && !lineItem.conflicted)
          .map((lineItem) => {
            const adName = lineItem.adNames[props.adId]
              ? lineItem.adNames[props.adId].editedName || lineItem.adNames[props.adId].name
              : '';

            return (
              <DuplicatedAdData
                lineItemName={lineItem.name}
                lineItemId={lineItem.id}
                adName={adName}
                adId={props.adId}
                onSetAdName={updateLineItemAdName}
                key={`li_ad_${lineItem.id}_${props.adId}`}
              />
            );
          })}
      </div>
    </section>
  );
};

export default memo(DuplicateAdAssigningToLineItem);
