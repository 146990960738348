import type { AdSelectorOption, CreativeV5Type, Maybe, ScheduleV5 } from '../../../apis/graphql';
import { AdTypeV5 } from '../../../apis/graphql';
import { defaultSupportedTimezones } from '../../../common/commonMocks/timezones';
import { brightlineTemplates } from '../../../configs';
import type { AdTag, AssetV5, DateRange, MarqueeDisplayAsset, Nullable } from '../../../models';
import { defaultDateRange } from '../../../utils/validation';
import { getDateTimeWithPredefinedValues } from '../../DateInput/utils';
import type { CreativesBuilderValues } from '../CreativeBuilders/types';
import { getIASCampaignLineItemIdFromIASUrl } from '../Tags/utils';
import type { AdsPageLineItem } from '../types';
import type { NielsenTagProps } from '../utils';
import { getNielsenTag } from '../utils';
import { adTypeToCreativeType } from '.';
import type { AdFormValues } from './types';

export type AdFormInitialValuesProps = {
  type: AdTypeV5;
  nielsenTagProps?: Maybe<NielsenTagProps>;
  lineItem?: Maybe<AdsPageLineItem>;
  creativeLibraryId?: Nullable<string>;
  organizationCreativeLibraryId?: Nullable<string>;
  adTagList?: AdTag[];
};

export function makeEmptyAsset<T extends AssetV5>(): Partial<T> {
  return {} as Partial<T>;
}

export function makeEmptyMarqueeAsset(): MarqueeDisplayAsset {
  return {
    id: '',
    image: { id: '' },
    altText: '',
  };
}

export const AD_SELECTOR_OPTIONS_AMOUNT = 3;

export const getDefaultAdSelectorOption = (index: number): AdSelectorOption => {
  return {
    index,
    thumbnailId: '',
    thumbnail: null,
    adSelectorVideoCreativeId: '',
    caption: '',
  };
};

export const getDefaultAdSelectorSlateOptionList = (optionList?: AdSelectorOption[] | null): AdSelectorOption[] => {
  const newOptionList: AdSelectorOption[] = [];

  for (let i = 0; i < AD_SELECTOR_OPTIONS_AMOUNT; i++) {
    const foundOption = optionList?.find(({ index }) => index === i + 1);

    if (foundOption) {
      newOptionList.push(foundOption);
    } else {
      newOptionList.push(getDefaultAdSelectorOption(i + 1));
    }
  }

  return newOptionList.sort((a, b) => (a.index > b.index ? 1 : -1));
};

export const defaultAdRating = 'CHILD_SAFE';

export function baseCreative(
  type: CreativeV5Type,
  creativeLibraryId?: Nullable<string>
): Omit<CreativesBuilderValues, 'mode'> & {
  filesize?: number;
  importMethod: 'search' | 'upload' | 'tag';
} {
  return {
    id: '',
    name: '',
    type,
    filesize: 0,
    importMethod: 'upload',
    creativeLibraryId: creativeLibraryId || '',
  };
}

const getDefaultTemplate = (adType: AdTypeV5): string => {
  switch (adType) {
    case AdTypeV5.AdSelector:
      return brightlineTemplates.AD_SELECTOR.key;
    case AdTypeV5.MaxSelector:
      return brightlineTemplates.MAX_SELECTOR.key;
    case AdTypeV5.GatewayShop:
      return brightlineTemplates.GATEWAY_SHOP.key;
    default:
      return '';
  }
};

export const getInitialCreativeBuilderValues = (
  mode: CreativesBuilderValues['mode'] = 'selectOption',
  adType: AdTypeV5 = AdTypeV5.Video,
  brand: string = '',
  creativeLibraryId?: Nullable<string>
): CreativesBuilderValues => {
  return {
    ...baseCreative(adTypeToCreativeType(adType), creativeLibraryId),
    mode,
    type: adTypeToCreativeType(adType),
    featureList: [],
    image: { id: '' },
    logo: { id: '' },
    offerImage: { id: '' },
    poster: { id: '' },
    slate: { id: '' },
    template: getDefaultTemplate(adType),
    url: '',
    isSubmitted: false,
    video: { id: '' },
    videoAssetList: [],
    accent: null,
    altText: brand,
    destinationUrl: '',
    pushBody: '',
    pushTitle: '',
    emailBody: '',
    livingRoomCopy: '',
    emailSubject: '',
    voiceoverUrl: '',
    optionList: getDefaultAdSelectorSlateOptionList(),
    headline: '',
    randomOrder: false,
  };
};

const defaultDateRangeShaped: DateRange = {
  ...defaultDateRange,
  pacingShare:
    defaultDateRange.pacingShare && typeof defaultDateRange.pacingShare === 'number' ? defaultDateRange.pacingShare : 0,
};

export const defaultScheduleFormikValues: ScheduleV5 = {
  dateRangeList: [defaultDateRangeShaped],
  dayPartList: [],
  timezone: defaultSupportedTimezones.ET,
  deliveryInViewerTime: false,
};

export const isLineItemScheduleIsValid = (lineItemSchedule: ScheduleV5): boolean => {
  return lineItemSchedule && !!lineItemSchedule.timezone && !!lineItemSchedule.dateRangeList.length;
};

export const getScheduleFromLineItem = (lineItemSchedule: ScheduleV5): ScheduleV5 => {
  const dayPartList = lineItemSchedule.dayPartList?.filter((dayPart) => dayPart.activeDayList.length) || [];
  const dateRangeList = lineItemSchedule.dateRangeList.map((dateRange) => ({
    ...dateRange,
    startDate: getDateTimeWithPredefinedValues(dateRange.startDate, { hours: 0, minutes: 0, seconds: 0 }),
    endDate: dateRange.endDate
      ? getDateTimeWithPredefinedValues(dateRange.endDate, { hours: 23, minutes: 59, seconds: 59 })
      : null,
    pacingShare: null,
  }));

  return {
    ...lineItemSchedule,
    deliveryInViewerTime: !!lineItemSchedule.deliveryInViewerTime,
    dateRangeList,
    dayPartList,
  };
};

export const getInitialIASCampaignLineItemId = (adTagList: AdTag[]): string => {
  const IASTag = adTagList.find(({ isIas }) => !!isIas);
  const { campaignId: iasCampaignId, lineItemId: iasLineItemId } = getIASCampaignLineItemIdFromIASUrl(
    IASTag?.url || ''
  );

  return iasCampaignId || iasLineItemId ? `${iasCampaignId}/${iasLineItemId}` : '';
};

export const getInitialValues = ({
  type,
  nielsenTagProps = null,
  lineItem = null,
  creativeLibraryId,
  organizationCreativeLibraryId,
  adTagList = [],
}: AdFormInitialValuesProps): AdFormValues => {
  const schedule =
    lineItem?.schedule && isLineItemScheduleIsValid(lineItem?.schedule)
      ? getScheduleFromLineItem(lineItem?.schedule)
      : defaultScheduleFormikValues;
  const nielsenTag: Nullable<AdTag> = nielsenTagProps ? getNielsenTag(nielsenTagProps) : null;
  const formattedAdTagList = nielsenTag ? [nielsenTag, ...adTagList] : [...adTagList];
  const brandName = lineItem?.campaign?.crmLink?.name || '';

  return {
    name: '',
    type,
    schedule,
    deliveryStatus: false,
    rating: defaultAdRating,
    creative: getInitialCreativeBuilderValues(
      'selectOption',
      type,
      brandName,
      creativeLibraryId ?? organizationCreativeLibraryId
    ),
    organizationCreativeLibraryId: organizationCreativeLibraryId,
    frequencyCapList: [],
    viewabilityVendorList: [],
    adTagList: formattedAdTagList,
    targetingTermValues: [],
    targetingRule: { id: '', definition: null },
    actualStart: null,
    newAdTag: {
      url: '',
    },
    newTargeting: {
      category: null,
      dimension: null,
      values: [],
    },
    status: {
      displayName: 'Off',
      key: 'OFF',
    },
    creativeChanged: false,
    iasCampaignLineItemId: getInitialIASCampaignLineItemId(adTagList),
    isLastDateRangeDisabled: false,
    lineItem: {
      id: lineItem?.id ?? '',
      parentLineOmsLink: {
        id: lineItem?.parentLineOmsLink?.id ?? '',
      },
    },
  };
};
