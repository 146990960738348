import React, { memo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import type { TableProps } from 'react-table';

import Loader from '../../../../../../../../common/Loader/Loader';
import TableStatus from '../../../../../../../../common/TableStatus';
import { pickerElement } from '../constants';
import { scrollStyle } from './constants';
import type { BodyProps } from './DuplicateLineItemsTableBody/DuplicateLineItemsTableBody';
import DuplicateLineItemsTableBody from './DuplicateLineItemsTableBody/DuplicateLineItemsTableBody';
import type { HeaderProps } from './DuplicateLineItemsTableHeader/DuplicateLineItemsTableHeader';
import DuplicateLineItemsTableHeader from './DuplicateLineItemsTableHeader/DuplicateLineItemsTableHeader';

export type Props = {
  tableProps: TableProps;
  isLoading?: boolean;
  isSearching?: boolean;
  emptyMessage?: string;
  hasMore?: boolean;
  onNext?: () => void;
} & HeaderProps &
  BodyProps;

const DuplicateLineItemsTable = ({
  tableBodyProps,
  rows,
  prepareRow,
  emptyMessage,
  headerGroups,
  tableProps,
  isLoading,
  isSearching,
  hasMore = false,
  onNext = (): void => {},
}: Props): React.JSX.Element => {
  return (
    <div
      className={pickerElement('table-wrapper')}
      id="duplicate-line-items-table-scroll-container"
      data-testid="duplicate-line-items-table-scroll-container"
    >
      <InfiniteScroll
        scrollableTarget="duplicate-line-items-table-scroll-container"
        dataLength={rows.length}
        next={onNext}
        hasMore={hasMore}
        style={scrollStyle}
        loader={<div className={pickerElement('scroll-loader')}>{!isSearching && <Loader />}</div>}
      >
        <table {...tableProps} cellSpacing={0} cellPadding={0} data-testid="duplicate-line-items-table">
          <DuplicateLineItemsTableHeader headerGroups={headerGroups} />
          {!isLoading && !isSearching && (
            <DuplicateLineItemsTableBody tableBodyProps={tableBodyProps} rows={rows} prepareRow={prepareRow} />
          )}
          <TableStatus
            loading={!!(isLoading || isSearching)}
            loadingMessage={isSearching ? 'Searching' : 'Loading'}
            empty={rows.length === 0}
            emptyMessage={emptyMessage}
          />
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default memo(DuplicateLineItemsTable);
