import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { EntityType } from '../../../apis/graphql';
import { GET_REVIEW_STATUS_MODAL_CHANGE_LOG } from '../queries';
import type { CommentsData } from '../utils';
import { getChangelogComments } from '../utils';

type UseAssetsComments = {
  changeLogComments: CommentsData[];
  loading: boolean;
  error?: ApolloError;
};

export const useAssetComments = (assetId?: string): UseAssetsComments => {
  const { data, loading, error } = useQuery(GET_REVIEW_STATUS_MODAL_CHANGE_LOG, {
    variables: {
      id: assetId,
      entityType: EntityType.Ad,
      paginationOptions: {
        limit: 100,
      },
    },
    skip: !assetId,
  });

  const changeLogComments = useMemo(() => getChangelogComments(data?.getChangeLogByAdObjectID), [
    data?.getChangeLogByAdObjectID,
  ]);

  return { changeLogComments, loading, error };
};
