import './DuplicatedAdDetails.scss';

import React, { memo } from 'react';

import TextInput from '../../../../../common/TextInput';
import bem from '../../../../../utils/bem';
import type { DuplicatedAdDetailsProps } from './types';

const [block, element] = bem('ad-details');

const DuplicatedAdDetails = ({
  adId,
  adName,
  adType,
  campaignName,
  lineItemName,
}: DuplicatedAdDetailsProps): React.JSX.Element => {
  const DUPLICATED_AD_DETAILS: { title: string; subtitle?: string }[] = [
    { title: 'Campaign', subtitle: campaignName },
    { title: 'Line Item', subtitle: lineItemName },
    { title: 'Ad Type', subtitle: adType },
    { title: 'Ad ID', subtitle: adId },
  ];

  return (
    <div className={block()}>
      <TextInput<string>
        name="adName"
        type="text"
        placeholder="Enter Ad Name"
        value={adName}
        disabled={true}
        className={element('field-input')}
        data-testid="ad-details"
      />
      <ul>
        {DUPLICATED_AD_DETAILS.map((detail) =>
          detail.subtitle ? (
            <li key={detail.title}>
              {detail.title}: <span>{detail.subtitle}</span>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};

export default memo(DuplicatedAdDetails);
