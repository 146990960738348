import './AdPreviewForm.scss';

import { useFormikContext } from 'formik';
import { bem } from 'mission-control-common-components';
import React, { memo, useMemo, useState } from 'react';

import type { DayPartV5 } from '../../../../../../../apis/graphql';
import { Creative, Metadata, Scheduling, Tags, Targeting } from '../../../../../../../common/AdForm';
import { AdFormSection } from '../../../../../../../common/AdForm/AdSideNav/AdSideNav';
import CreativeBreadcrumb from '../../../../../../../common/AdForm/CreativeBreadcrumb';
import { CreativeEditMode } from '../../../../../../../common/AdForm/CreativeBuilders/types';
import { isNielsenTag, NIELSEN_CUTOFF_DAYS } from '../../../../../../../common/AdForm/utils';
import { useFieldArray } from '../../../../../../../common/Form/hooks';
import type { ClearableFrequencyCap } from '../../../../../../../common/FrequencyCaps/FrequencyCaps';
import type { AdTag, TargetingTermValue } from '../../../../../../../models';
import { getPublisherTargetingList } from '../../../../../TraffickingPage/columns';
import type { AdModel } from '../../../../../TraffickingPage/modelConverters';
import type { DuplicateAdFormValue } from '../types';
import SelectableAdFormSectionWrapper from './SelectableAdFormSectionWrapper';
import { getScheduleErrorMessage } from './SelectableAdFormSectionWrapper/utils';

type AdPreviewFormProps = {
  lineItem: AdModel['lineItem'];
  lineItemTargetingTermValues: TargetingTermValue[];
  disableSelectSections?: boolean;
  toggleSection: (sectionName: AdFormSection, state: boolean) => void;
};

const [block, element] = bem('ad-preview-form');

export const NIELSEN_TAGS_CANNOT_COPY_MSG = `Cannot copy over the existing Disney-generated DAR tag. A new DAR tag will be generated unless the campaign starts beyond ${NIELSEN_CUTOFF_DAYS} days.`;
const AD_TAG_LIST_FILED = 'adTagList';

const AdPreviewForm = ({
  lineItem,
  lineItemTargetingTermValues,
  disableSelectSections,
  toggleSection,
}: AdPreviewFormProps): React.JSX.Element => {
  const publisher = getPublisherTargetingList({ value: lineItem?.displayPublisherTarget || null });

  const [stashedDayPartList, setStashedDayPartList] = useState<DayPartV5[]>([]);
  const [stashedFrequencyCapList, setStashedFrequencyCapList] = useState<ClearableFrequencyCap[]>([]);
  const [, { value: adTagList }, { removeAt, replaceAt, push }] = useFieldArray<AdTag>(AD_TAG_LIST_FILED);
  const {
    values: { hasNielsenTag, schedulingConflictLineItemIds, selectedSections },
  } = useFormikContext<DuplicateAdFormValue>();

  const scheduleError = schedulingConflictLineItemIds.length
    ? getScheduleErrorMessage(schedulingConflictLineItemIds)
    : '';

  const tagsError = useMemo(() => {
    if (hasNielsenTag) {
      return {
        message: NIELSEN_TAGS_CANNOT_COPY_MSG,
        isError: false,
      };
    }
    return null;
  }, [hasNielsenTag]);

  const listHelpers = useMemo(() => ({ removeAt, replaceAt, push }), [removeAt, replaceAt, push]);
  const nonNielsenAdTags = useMemo(() => adTagList.filter((adTag) => !isNielsenTag(adTag.url)), [adTagList]);

  const toggledSections = useMemo((): Record<AdFormSection, boolean> => {
    const result: Record<AdFormSection, boolean> = Object.values(AdFormSection).reduce((acc, sectionName) => {
      acc[sectionName] = false;

      return acc;
    }, {} as Record<AdFormSection, boolean>);

    selectedSections.forEach((sectionName) => {
      result[sectionName] = true;
    });

    return result;
  }, [selectedSections]);

  return (
    <form className={block()} id="ad-preview-form-id" data-testid="ad-preview-form">
      <div className={element('content')}>
        <SelectableAdFormSectionWrapper
          title="Creative"
          sectionName={AdFormSection.CREATIVE}
          isChecked={toggledSections[AdFormSection.CREATIVE]}
          customTitle={<CreativeBreadcrumb readonly />}
          disabled={!!disableSelectSections}
          toggleSection={toggleSection}
        >
          <Creative lineItemType={'UNKNOWN'} editMode={CreativeEditMode.readonly} />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper
          disabled={!!disableSelectSections}
          title="Tags for Third Party Measurement"
          sectionName={AdFormSection.TAGS}
          isChecked={toggledSections[AdFormSection.TAGS]}
          toggleSection={toggleSection}
        >
          <Tags
            isAdInitiallyWithBillableTag
            unDeletableAd
            readonly
            adTagList={nonNielsenAdTags}
            listHelpers={listHelpers}
            nielsenTagWarning={tagsError}
          />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper
          disabled={!!disableSelectSections}
          title="Targeting"
          sectionName={AdFormSection.TARGETING}
          isChecked={toggledSections[AdFormSection.TARGETING]}
          toggleSection={toggleSection}
        >
          <Targeting
            lineItemTargetingTermValues={lineItemTargetingTermValues}
            publisher={publisher}
            readonly
            publisherIds={lineItem?.displayPublisherTarget || []}
          />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper
          error={scheduleError}
          disabled={Boolean(scheduleError) || !!disableSelectSections}
          title="Scheduling"
          sectionName={AdFormSection.SCHEDULING}
          isChecked={toggledSections[AdFormSection.SCHEDULING]}
          toggleSection={toggleSection}
        >
          <Scheduling
            lineItemSchedule={lineItem?.schedule}
            stashedDayPartList={stashedDayPartList}
            setStashedDayPartList={setStashedDayPartList}
            readonly
          />
        </SelectableAdFormSectionWrapper>
        <SelectableAdFormSectionWrapper
          disabled={!!disableSelectSections}
          title="Metadata"
          sectionName={AdFormSection.METADATA}
          isChecked={toggledSections[AdFormSection.METADATA]}
          toggleSection={toggleSection}
        >
          <Metadata
            stashedFrequencyCapList={stashedFrequencyCapList}
            setStashedFrequencyCapList={setStashedFrequencyCapList}
            readonly
          />
        </SelectableAdFormSectionWrapper>
      </div>
    </form>
  );
};

export default memo(AdPreviewForm);
