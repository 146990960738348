import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { omit as _omit } from 'lodash';
import React, { useRef, useState } from 'react';
import type { FileRejection } from 'react-dropzone';

import { uploadAsset } from '../../../../apis/assetManager';
import type { UploadError } from '../../../../apis/assetManager/types';
import type { BaseAssetV5 } from '../../../../apis/graphql';
import type { AssetType } from '../../../../configs';
import type { AssetV5 } from '../../../../models';
import useGetAssetById from '../../../AdForm/CreativeBuilders/hooks/useGetAssetById';
import { INCOMPATIBLE_DIMENSIONS, INVALID_FILE_TYPE, VIDEO_WRONG_CODEC } from '../../../AssetUploader/const';
import { openToastAlert } from '../../../ToastAlert/toastAlert';
import { useFieldFast } from '../../hooks';

export type UseAssetUploadResult = {
  asset: Partial<AssetV5>;
  setAsset: (value: Partial<AssetV5>, shouldValidate?: boolean | undefined) => void;
  loading: boolean;
  percentUploaded?: number;
  onDrop: (acceptedFile: File, fileRejections: FileRejection[]) => Promise<void>;
  cancelRequest: () => void;
};

export const catchUploadError = (fileName: string, e: UploadError): void => {
  let prompt = '';
  if (e.type === 'API') {
    if (e.apiStatusCode === 400) {
      prompt = 'Please check the asset requirements and try again';
    } else {
      prompt = 'Please try again.';
    }

    openToastAlert({
      alertType: 'error',
      message: 'Upload Failed',
      description: (
        <>
          Something went wrong when uploading <strong>{fileName}</strong>. {prompt}
        </>
      ),
    });
  }
};

export const onDrop = async (
  acceptedFile: File,
  fileRejections: FileRejection[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setAsset: (value: Partial<AssetV5>, shouldValidate?: boolean | undefined) => void,
  asset: Partial<AssetV5> | undefined,
  assetType: AssetType,
  setPercentUploaded: React.Dispatch<React.SetStateAction<number | undefined>>,
  cancelTokenSourceRef: React.MutableRefObject<CancelTokenSource>,
  creativeLibraryId: string,
  fetchAssetById: (assetID: string) => Promise<BaseAssetV5>,
  countries: string[]
): Promise<void> => {
  if (fileRejections.length) {
    fileRejections.forEach((file) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === INCOMPATIBLE_DIMENSIONS || err.code === INVALID_FILE_TYPE || err.code === VIDEO_WRONG_CODEC) {
          openToastAlert({
            alertType: 'error',
            message: err.code,
            description: <>{err.message}</>,
          });
        }
      });
    });
  } else {
    const file: File = acceptedFile;
    setLoading(true);
    // Need ability to display file name while loading
    setAsset({ ..._omit(asset, ['createdAt']), name: file.name });
    let caughtError = false;
    try {
      const assetModel: AssetV5 = await uploadAsset(
        file,
        assetType,
        setPercentUploaded,
        cancelTokenSourceRef.current,
        creativeLibraryId,
        countries
      );
      const { review } = await fetchAssetById(assetModel.id);
      setAsset({
        ...assetModel,
        review: review || undefined,
      });
    } catch (e) {
      caughtError = true;
      catchUploadError(file.name, e as UploadError);
    } finally {
      setLoading(false);
      !caughtError &&
        openToastAlert({
          alertType: 'success',
          message: 'Upload Succeeded',
          description: (
            <>
              <strong>{file.name}</strong> has been uploaded
            </>
          ),
        });
    }
  }
};

export default function useAssetUpload(
  formFieldName: string,
  assetType: AssetType,
  creativeLibraryId: string,
  countries: string[]
): UseAssetUploadResult {
  const { fetchAssetById } = useGetAssetById();
  const [, { value: asset }, { setValue: setAsset }] = useFieldFast<Partial<AssetV5> | undefined>(formFieldName);
  const [percentUploaded, setPercentUploaded] = useState<number>();
  const [loading, setLoading] = useState(false);

  // The CancelToken source needs to be managed in a ref so that it can be mutated by axios when requests are made
  // and so that any React components (like the "Cancel" button in the AssetUploader) has access to the current instance of the cancel function.
  const cancelTokenSourceRef = useRef<CancelTokenSource>(axios.CancelToken.source());

  function cancelRequest(): void {
    cancelTokenSourceRef.current.cancel();
    // reset cancelTokenSource for next request
    cancelTokenSourceRef.current = axios.CancelToken.source();
    setAsset(undefined);
  }

  const onDropProxy = async (acceptedFile: File, fileRejections: FileRejection[]): Promise<void> => {
    return onDrop(
      acceptedFile,
      fileRejections,
      setLoading,
      setAsset,
      asset,
      assetType,
      setPercentUploaded,
      cancelTokenSourceRef,
      creativeLibraryId,
      fetchAssetById,
      countries
    );
  };

  const omittedAsset = { ..._omit(asset, ['createdAt', 'review', 'fileSize']), type: assetType };

  return {
    asset: omittedAsset,
    setAsset,
    loading,
    percentUploaded,
    onDrop: onDropProxy,
    cancelRequest,
  };
}
