import { CampaignStatusV5 } from '../../../../../apis/graphql';
import { PillColor } from '../../../../../common/Pill';
import type { DisplayEnum } from '../../../../../configs';
import type { CampaignStatus } from '../../../../../configs/campaignStatus';

type CampaignStatusKey = DisplayEnum<CampaignStatus>['key'];

export const CAMPAIGN_STATUS_PILL_COLOR_CONFIG: Record<CampaignStatusKey, PillColor> = {
  UNRECOGNIZED: PillColor.GRAY,
  [CampaignStatusV5.Ready]: PillColor.GREEN,
  [CampaignStatusV5.Live]: PillColor.GREEN,
  [CampaignStatusV5.Late]: PillColor.YELLOW,
  [CampaignStatusV5.Ended]: PillColor.GRAY,
  [CampaignStatusV5.DidNotRun]: PillColor.YELLOW,
  [CampaignStatusV5.Off]: PillColor.GRAY,
  [CampaignStatusV5.Paused]: PillColor.ORANGE,
  [CampaignStatusV5.Cancelled]: PillColor.RED,
};
