import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { getDataFromNodes } from '../../../../../../../apis/graphql';
import { adsPageAdNodeToModel } from '../../../../../../../common/AdForm/hooks/modelConverters';
import type { AdsPageAd } from '../../../../../../../common/AdForm/hooks/types';
import { AlertType } from '../../../../../../../common/Alert';
import { openToastAlert } from '../../../../../../../common/ToastAlert/toastAlert';
import { filterTermValues } from '../../../../../../../common/utils';
import type { LineItemTargetingValueBody, Nullable } from '../../../../../../../models';
import { GET_ADS_PAGE_ADS_BY_IDS } from '../queries/getAdsPageAdsByIDs';

export type AdFetchResponse = {
  ads: Nullable<AdsPageAd[]>;
  lineItemTargetingTermValues: LineItemTargetingValueBody[];
  loading: boolean;
  error?: ApolloError;
};

export const onError = (e: ApolloError): void => {
  openToastAlert({
    alertType: AlertType.ERROR,
    message: e.message,
    description: e.extraInfo,
  });
};

export const useFetchDuplicateAds = (adIds: string[], lineItemIds: string[]): AdFetchResponse => {
  const { data, loading, error } = useQuery(GET_ADS_PAGE_ADS_BY_IDS, {
    variables: { adIds, lineItemIds, adsLimit: adIds.length },
    onError,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ads = useMemo<AdsPageAd[]>(getDataFromNodes(data, 'ads', adsPageAdNodeToModel), [data]);

  const lineItemTargetingTermValues = useMemo<LineItemTargetingValueBody[]>(
    () =>
      ((data?.getTargetingTermValuesByLineItemIDsV5 ?? []) as LineItemTargetingValueBody[]).map(
        ({ lineItemId, targetingTermValues }) => ({
          lineItemId,
          targetingTermValues: filterTermValues(targetingTermValues),
        })
      ),
    [data]
  );

  return { ads, lineItemTargetingTermValues, loading, error };
};
