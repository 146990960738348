import { gql } from '@apollo/client';

import { CREATIVE_ASSET_FRAGMENT } from './creativeAssetFragment';

export const CREATIVES_FRAGMENTS = gql`
  fragment creatives on BaseCreativeV5 {
    __typename
    id
    creativeLibraryId
    name
    rating
    review
    status
    createdAt
    updatedAt
    type
    assetTagList {
      id
      value
      description
      displayName
    }
    rejectionReason
    rejectionReasonDetails
    ... on AdSelectorSlateCreativeV5 {
      logo {
        id
      }
      headline
      randomOrder
      optionList {
        index
        thumbnailId
        thumbnail {
          ...creativeAsset
        }
        adSelectorVideoCreativeId
        caption
      }
      voiceoverUrl
    }
    ... on AdSelectorVideoCreativeV5 {
      video {
        id
      }
    }
    ... on BrightlineCreativeV5 {
      url
      vpaid
      videoAssetList {
        id
      }
      template
      featureList {
        type
      }
    }
    ... on GatewayGoCreativeV5 {
      offerImage {
        id
      }
      video {
        id
      }
      livingRoomCopy
      emailSubject
      emailBody
      pushTitle
      pushBody
      destinationUrl
    }
    ... on InnovidCreativeV5 {
      url
      vpaid
      videoAssetList {
        id
      }
      template
      featureList {
        type
      }
    }
    ... on MagniteCreativeV5 {
      url
      vpaid
      videoAssetList {
        id
      }
      adSourceId
    }
    ... on DraxCreativeV5 {
      url
      vpaid
      videoAssetList {
        id
      }
    }
    ... on PauseCreativeV5 {
      altText
      image {
        id
      }
      accent {
        r
        g
        b
      }
      status
    }
    ... on SlateCreativeV5 {
      slate {
        id
      }
    }
    ... on VASTVideoCreativeV5 {
      url
      vpaid
      videoAssetList {
        id
        asset {
          name
          s3Url
        }
      }
    }
    ... on VideoCreativeV5 {
      video {
        id
      }
    }
    ... on InstreamDxcCreativeV5 {
      video {
        id
      }
      templateAssetList {
        id
      }
      experienceId
    }
  }
  ${CREATIVE_ASSET_FRAGMENT}
`;
