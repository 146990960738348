import React, { memo } from 'react';
import type { Row, TableBodyProps } from 'react-table';

import type { DuplicateLineItem } from '../../../../../DuplicateAdsStepperProvider/types';
import { pickerElement } from '../../constants';

export type BodyProps = {
  tableBodyProps: TableBodyProps;
  rows: Row<DuplicateLineItem>[];
  prepareRow(row: Row<DuplicateLineItem>): void;
};

const DuplicateLineItemsTableBody = ({ tableBodyProps, rows, prepareRow }: BodyProps): JSX.Element => {
  return (
    <tbody {...tableBodyProps} data-testid="duplicate-line-items-table-body">
      {rows.map((row) => {
        prepareRow(row);

        return (
          <tr {...row.getRowProps()} className={pickerElement('row')} data-testid="duplicate-line-items-table-row">
            {row.cells.map((cell, index) => {
              return (
                <td {...cell.getCellProps()} key={`line-items-duplicate-table-cell-${index}`}>
                  <div className={pickerElement('cell-container')}>{cell.render('Cell')}</div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default memo(DuplicateLineItemsTableBody);
