import { User } from 'mission-control-common-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { PaginationOptions, TraffickingFilters } from '../../../../apis/graphql';
import { AdSortOptionV5, CampaignSortOptionV5, LineItemSortOptionV5 } from '../../../../apis/graphql';
import type { DrawerProps } from '../../../../common/Drawer/useDrawerProps';
import useDrawerProps from '../../../../common/Drawer/useDrawerProps';
import type { PermissionsNames } from '../../../../constants';
import { TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import { ResultKeys } from '../../constants';
import type { DateType } from '../../TableFilterBar/DateFilter/DateFilter';
import { generateQueryVariables, getSelectedRowIdsFromState } from '../../utils';
import { getAdsColumns, getCampaignsColumns, getLineItemsColumns } from '../columns';
import type { AdModel, AdNode, CampaignModel, CampaignNode, LineItemModel, LineItemNode } from '../modelConverters';
import { adNodeToModel, campaignNodeToModel, lineItemNodeToModel } from '../modelConverters';
import type { TraffickingPageDrawerData } from './makePageDrawerPlugin';
import { GET_ADS, GET_CAMPAIGNS, GET_LINEITEMS_BY_CAMPAIGNID_LIST } from './queries';
import { useAssociatedEntities } from './useAssociatedEntities';
import type { TraffickerTableState } from './useTraffickerTable';
import useTraffickerTable from './useTraffickerTable';
import {
  clearTableSearchResults,
  clearTablesSelections,
  determineClearAllBadgeVisibility,
  getDeselectedEntities,
  getDifferenceFromSelectedEntities,
  getRepresentedInSelectedEntities,
  getSelectedEntities,
  getUnselectedIds,
  hasSelectedRowIds,
  updateOffsetsBasedOnSelectedTab,
} from './utils';

export interface SelectedRowIds {
  [id: string]: boolean;
}

export interface TraffickerTableStates {
  campaigns: TraffickerTableState<CampaignModel>;
  lineItems: TraffickerTableState<LineItemModel>;
  ads: TraffickerTableState<AdModel>;
}

export interface TraffickerState {
  drawerProps: DrawerProps<TraffickingPageDrawerData>;
  tables: TraffickerTableStates;
  applyCommonFilter: <T extends keyof TraffickingFilters>(filterName: T, filterValue: TraffickingFilters[T]) => void;
  isClearAllBadgeVisible: boolean;
  clearAllSearchResultsAndSelections: () => void;
}

export interface QueryVariables {
  ids?: string[];
  campaignIds?: string[];
  lineItemIds?: string[];
  traffickerEmailList?: string[];
  accountExecutiveList?: string[];
  adProductList?: string[];
  generalPacingStatusList?: string[];
  reviewStatusList?: string[];
  adStatusList?: string[];
  adPotentialTypeList?: string[];
  schedule?: DateType;
  sort?: string;
  order?: string;
  limit?: number;
  offset?: number;
  searchTerm?: string;
  roleName?: string;
  entityType?: string;
  entityId?: string;
  pacingRiskList?: string[];
  hasMisalignedSchedule?: boolean;
  orderTypeList?: string[];
  paginationOptions?: PaginationOptions;
  statusList?: string[];
  subStatusList?: string[];
  lineItemStatusList?: string[];
  lineItemSubStatusList?: string[];
  tierNameList?: string[];
  priorityValueList?: string[];
  billableThirdPartyList?: string[];
  publisherList?: string[];
  adRatingList?: string[];
  adTypeList?: string[];
  creativeTypeList?: string[];
  creativeReviewList?: string[];
  isCoppaOrCaru?: boolean | null;
  isMakegood?: boolean | null;
  isAddedValue?: boolean | null;
  brandIdList?: string[];
  agencyIdList?: string[];
  industryIdList?: string[];
  currencyList?: string[];
  isRotation?: boolean | null;
  isSequence?: boolean | null;
  countryList?: string[];
}

type SortOptions = Record<string, CampaignSortOptionV5 | LineItemSortOptionV5 | AdSortOptionV5>;

export const sortOptionMap: Record<TraffickingTableName, SortOptions> = {
  campaigns: CampaignSortOptionV5,
  lineItems: LineItemSortOptionV5,
  ads: AdSortOptionV5,
};

function useTraffickerState(): TraffickerState {
  const permissions = User.getPermissions<PermissionsNames>();
  const { filters, applyTableFilter, applyFilter, clearTableFilters } = useFiltersContext();

  const [campaignIdsToQuery, setCampaignIdsToQuery] = useState<SelectedRowIds>(
    getSelectedRowIdsFromState(filters.campaigns?.selectedRowIds ?? [])
  );
  const [lineItemIdsToQuery, setLineItemIdsToQuery] = useState<SelectedRowIds>(
    getSelectedRowIdsFromState(filters.lineItems?.selectedRowIds ?? [])
  );
  const [adsIdsToQuery, setAdsIdsToQuery] = useState<SelectedRowIds>(
    getSelectedRowIdsFromState(filters.ads?.selectedRowIds ?? [])
  );
  const [drawerProps, setDrawerProps] = useDrawerProps<TraffickingPageDrawerData>({ data: {} });

  // this flag is used to avoid requests only with selectedRowIds without the actual filter in applyCommonFilter method
  const [skipTableQueries, setSkipTableQueries] = useState(false);

  //  initialState from the URL params is used to initialize local state
  //  selectedRowIds and sortBy are tracked with react-table state

  const [campaignOffset, setCampaignOffset] = useState<number>(0);
  const [lineItemOffset, setLineItemOffset] = useState<number>(0);
  const [adOffset, setAdOffset] = useState<number>(0);

  const [campaignSearchTerm, setCampaignSearchTerm] = useState(filters?.campaigns?.searchTerm ?? '');
  const [lineItemSearchTerm, setLineItemSearchTerm] = useState(filters.lineItems?.searchTerm ?? '');
  const [adSearchTerm, setAdSearchTerm] = useState(filters?.ads?.searchTerm ?? '');

  const campaignQueryVariables = generateQueryVariables({
    ...filters,
    searchTerm: campaignSearchTerm,
    sortOption: filters?.campaigns?.sortBy,
    tableName: TraffickingTableName.campaigns,
    selectedRowIds: campaignIdsToQuery,
  });

  const lineItemQueryVariables = generateQueryVariables({
    ...filters,
    searchTerm: lineItemSearchTerm,
    sortOption: filters.lineItems?.sortBy,
    tableName: TraffickingTableName.lineItems,
    selectedRowIds: lineItemIdsToQuery,
    campaignRowsIds: getSelectedRowIdsFromState(filters?.campaigns?.selectedRowIds ?? []),
  });

  const adsQueryVariables = generateQueryVariables({
    ...filters,
    searchTerm: adSearchTerm,
    sortOption: filters?.ads?.sortBy,
    tableName: TraffickingTableName.ads,
    selectedRowIds: adsIdsToQuery,
    campaignRowsIds: getSelectedRowIdsFromState(filters?.campaigns?.selectedRowIds ?? []),
    lineItemRowIds: getSelectedRowIdsFromState(filters.lineItems?.selectedRowIds ?? []),
  });

  const campaignsColumns = useMemo(() => {
    return getCampaignsColumns(permissions?.readTraffickingChangeLog);
  }, [permissions?.readTraffickingChangeLog]);

  const lineItemsColumns = useMemo(() => getLineItemsColumns(), []);
  const adsColumns = useMemo(() => getAdsColumns(), []);

  const adsTable = useTraffickerTable<AdNode, AdModel>(
    GET_ADS,
    adsQueryVariables,
    ResultKeys.ads,
    adNodeToModel,
    adsColumns,
    TraffickingTableName.ads,
    {
      setDrawerProps,
      searchTerm: adSearchTerm,
      setSearchTerm: setAdSearchTerm,
      offset: adOffset,
      setOffset: setAdOffset,
      setLineItemOffset,
      setAdOffset,
      setIdsToQuery: setAdsIdsToQuery,
      skip: !permissions?.readTraffickingAds || skipTableQueries,
    }
  );
  const campaignsTable = useTraffickerTable<CampaignNode, CampaignModel>(
    GET_CAMPAIGNS,
    campaignQueryVariables,
    ResultKeys.campaigns,
    campaignNodeToModel,
    campaignsColumns,
    TraffickingTableName.campaigns,
    {
      setDrawerProps,
      searchTerm: campaignSearchTerm,
      setSearchTerm: setCampaignSearchTerm,
      offset: campaignOffset,
      setOffset: setCampaignOffset,
      setLineItemOffset: setLineItemOffset,
      setAdOffset: setAdOffset,
      setIdsToQuery: setCampaignIdsToQuery,
      skip: !permissions?.readTraffickingCampaigns || skipTableQueries,
    }
  );
  const lineItemsTable = useTraffickerTable<LineItemNode, LineItemModel>(
    GET_LINEITEMS_BY_CAMPAIGNID_LIST,
    lineItemQueryVariables,
    ResultKeys.lineItems,
    lineItemNodeToModel,
    lineItemsColumns,
    TraffickingTableName.lineItems,
    {
      setDrawerProps,
      searchTerm: lineItemSearchTerm,
      setSearchTerm: setLineItemSearchTerm,
      offset: lineItemOffset,
      setOffset: setLineItemOffset,
      setLineItemOffset: setLineItemOffset,
      setAdOffset: setAdOffset,
      setIdsToQuery: setLineItemIdsToQuery,
      skip: !permissions?.readTraffickingLineItems || skipTableQueries,
    }
  );

  const traffickerTables = useMemo(() => {
    return {
      campaigns: campaignsTable,
      lineItems: lineItemsTable,
      ads: adsTable,
    };
  }, [adsTable, lineItemsTable, campaignsTable]);

  const isClearAllBadgeVisible = determineClearAllBadgeVisibility({
    adSearchTerm,
    campaignSearchTerm,
    lineItemSearchTerm,
    traffickerTables,
  });

  const { getAssociatedEntities } = useAssociatedEntities({ lineItemQueryVariables, adsQueryVariables });

  const unselectAssociatedEntities = useCallback(
    (associatedEntities: Partial<Record<TraffickingTableName, string[]>>, tableName: TraffickingTableName): void => {
      const selectedLineItems = filters?.lineItems?.selectedRowIds ?? [];
      const selectedAds = filters?.ads?.selectedRowIds ?? [];
      const associatedLineItems = associatedEntities.lineItems;
      const associatedAds = associatedEntities.ads ?? [];
      const areRowIdsSelected = hasSelectedRowIds(tableName, traffickerTables);

      if (associatedLineItems) {
        const newSelectedLineItems = getDifferenceFromSelectedEntities(selectedLineItems, associatedLineItems);
        applyTableFilter(
          'lineItems',
          'selectedRowIds',
          getDifferenceFromSelectedEntities(selectedLineItems, associatedLineItems)
        );
        traffickerTables.lineItems.tableInstance.state.selectedRowIds = getSelectedRowIdsFromState(
          newSelectedLineItems
        );
      }
      if (areRowIdsSelected) {
        const newSelectedAds = getDifferenceFromSelectedEntities(selectedAds, associatedAds);
        applyTableFilter('ads', 'selectedRowIds', getDifferenceFromSelectedEntities(selectedAds, associatedAds));
        traffickerTables.ads.tableInstance.state.selectedRowIds = getSelectedRowIdsFromState(newSelectedAds);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applyTableFilter,
      filters?.ads?.selectedRowIds,
      filters?.campaigns?.selectedRowIds,
      filters?.lineItems?.selectedRowIds,
      traffickerTables.ads.tableInstance.state,
      traffickerTables.campaigns.tableInstance.state,
      traffickerTables.lineItems.tableInstance.state,
    ]
  );

  const selectAssociatedEntities = useCallback(
    (associatedEntities: Partial<Record<TraffickingTableName, string[]>>, tableName: TraffickingTableName): void => {
      const selectedAds = filters?.ads?.selectedRowIds ?? [];
      const associatedAds = associatedEntities.ads ?? [];
      const areRowIdsSelected = hasSelectedRowIds(tableName, traffickerTables);

      if (areRowIdsSelected) {
        const newSelectedAds = getRepresentedInSelectedEntities(selectedAds, associatedAds);
        applyTableFilter('ads', 'selectedRowIds', getRepresentedInSelectedEntities(selectedAds, associatedAds));
        traffickerTables.ads.tableInstance.state.selectedRowIds = getSelectedRowIdsFromState(newSelectedAds);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      applyTableFilter,
      filters?.ads?.selectedRowIds,
      traffickerTables.ads.tableInstance.state,
      traffickerTables.campaigns.tableInstance.state,
      traffickerTables.lineItems.tableInstance.state,
    ]
  );

  const clearAssociatedEntities = useCallback(
    async (tableName: TraffickingTableName, prevSelected: string[], newSelected: string[]): Promise<void> => {
      const unselectedIds = getUnselectedIds(prevSelected, newSelected);

      if (unselectedIds.length) {
        const associatedEntities = await getAssociatedEntities(tableName, unselectedIds);

        unselectAssociatedEntities(associatedEntities, tableName);
      }
    },
    [getAssociatedEntities, unselectAssociatedEntities]
  );

  const addAssociatedEntities = useCallback(
    async (tableName: TraffickingTableName, newSelected: string[]): Promise<void> => {
      if (newSelected.length) {
        const associatedEntities = await getAssociatedEntities(tableName, newSelected);

        selectAssociatedEntities(associatedEntities, tableName);
      }
    },
    [getAssociatedEntities, selectAssociatedEntities]
  );

  useEffect(() => {
    const campaignEntitiesScope = {
      previous: filters?.campaigns?.selectedRowIds ?? [],
      current: Object.keys(traffickerTables.campaigns.tableInstance.state.selectedRowIds),
    };
    const lineItemsEntitiesScope = {
      previous: filters?.lineItems?.selectedRowIds ?? [],
      current: Object.keys(traffickerTables.lineItems.tableInstance.state.selectedRowIds),
    };
    const updatedDeselectedEntities = getDeselectedEntities(campaignEntitiesScope, lineItemsEntitiesScope);
    const updatedSelectedEntities = getSelectedEntities(campaignEntitiesScope, lineItemsEntitiesScope);

    if (updatedDeselectedEntities) {
      clearAssociatedEntities(
        updatedDeselectedEntities.tableName,
        updatedDeselectedEntities.previous,
        updatedDeselectedEntities.current
      );
    }
    if (updatedSelectedEntities) {
      addAssociatedEntities(updatedSelectedEntities.tableName, updatedSelectedEntities.current);
    }
  }, [
    addAssociatedEntities,
    clearAssociatedEntities,
    filters?.campaigns?.selectedRowIds,
    filters?.lineItems?.selectedRowIds,
    traffickerTables.campaigns.tableInstance.state.selectedRowIds,
    traffickerTables.lineItems.tableInstance.state.selectedRowIds,
  ]);

  const resetSelectedIdSearchParams = useCallback((): void => {
    applyTableFilter('ads', 'selectedRowIds', []);
  }, [applyTableFilter]);

  const resetIdsToQuery = useCallback((): void => {
    setAdsIdsToQuery({});
  }, []);

  const resetSelectedRows = useCallback((): void => {
    traffickerTables.ads.tableInstance.state.selectedRowIds = {};
    resetSelectedIdSearchParams();
    resetIdsToQuery();
  }, [resetIdsToQuery, resetSelectedIdSearchParams, traffickerTables.ads.tableInstance.state]);

  const clearAllSearchResultsAndSelections = useCallback((): void => {
    updateOffsetsBasedOnSelectedTab(filters.selectedTab, traffickerTables);

    clearTableFilters();

    clearTablesSelections(traffickerTables);
    clearTableSearchResults(traffickerTables);
  }, [clearTableFilters, filters, traffickerTables]);

  const applyCommonFilter = useCallback(
    <T extends keyof TraffickingFilters>(filterName: T, filterValue: TraffickingFilters[T]): void => {
      setSkipTableQueries(true);
      resetSelectedRows();
      setCampaignOffset(0);
      setLineItemOffset(0);
      setAdOffset(0);
      setCampaignIdsToQuery(getSelectedRowIdsFromState(filters?.campaigns?.selectedRowIds ?? []));
      setLineItemIdsToQuery(getSelectedRowIdsFromState(filters.lineItems?.selectedRowIds ?? []));

      applyFilter(filterName, filterValue);
      setSkipTableQueries(false);
    },
    [applyFilter, filters?.campaigns?.selectedRowIds, filters.lineItems?.selectedRowIds, resetSelectedRows]
  );

  return {
    drawerProps,
    applyCommonFilter,
    tables: traffickerTables,
    isClearAllBadgeVisible,
    clearAllSearchResultsAndSelections,
  };
}

export default useTraffickerState;
